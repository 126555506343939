import { LessonAPI } from 'api';
import React, { Component } from 'react'
import { Alert, Badge, Col, Container, Row, Input, Spinner, Button, Modal, ModalBody, ModalFooter, FormGroup, Label, FormText, FormFeedback } from 'reactstrap';

export default class EditLesson extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            id_course: '',
            id: '',
            loading: false,
            errors: null,
            showEdit: false,
        };
    }

    componentDidMount(){
        const {name, id_course, id} = this.props;
        this.setState({name, id_course, id})
    }

    saveLesson = () =>{
        let form = this.state;
        let error = {};

        if (form.name === '')
            error.name = "Il campo nome è obbligatorio";

        this.setState({ errors: error });
        if (Object.keys(error).length === 0) {
            const {name, id_course, id} = form;
            const payload = {
                name, id_course, id
            }
            LessonAPI.edit(payload).then(() => {
                global.onSetModal({ loading: false });
                global.SweetAlert.fire({
                    title: 'lesson modifica',
                    text: 'La lezione è stata modificata.',
                    icon: 'success',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    confirmButtonText: 'Chiudi',
                    buttonsStyling: false
                })
                this.setState({
                    name: '',
                    id_course: '',
                    id: '',
                    loading: false,
                    errors: null,
                    showEdit: false,
                })
                this.props.onResolve();
            }).catch(()=>{
                global.SweetAlert.fire({
                    title: 'Errore',
                    text: 'Si è verificato un errore durante la modifica della lezione',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                });
                this.setState({
                    name: '',
                    id_course: '',
                    id: '',
                    loading: false,
                    errors: null,
                    showEdit: false,
                })
            })
        }
    }

    render() {
        const errors = this.state.errors
        return (
            <React.Fragment>
                <i className='ri-pencil-fill fs-20 text-info' onClick={()=> this.setState({showEdit:true})}></i>&nbsp;
                <Modal style={{ overflowY: "auto" }} isOpen={this.state.showEdit} scrollable={true} className={'modal-dialog-scrollable modal-lg '}>
                    <ModalBody className='px-sm-5 pt-5 pb-50'>
                        <div className='text-center mb-5'>
                            <h3 className='mb-1'>MODIFICA LEZIONE</h3>
                        </div>
                        <Row>
                            <Col md="12">
                                <div className='mb-2'>
                                    <Label className='form-label'>
                                        Nome
                                    </Label>
                                    <Input
                                        type="text"
                                        value={this.state.name}
                                        invalid={Boolean(errors?.name)}
                                        onChange={(e) => this.setState({ name: e.target.value })}
                                    />
                                    <FormFeedback>{errors?.name}</FormFeedback>
                                </div>
                            </Col>

                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color='success'
                            onClick={this.saveLesson}
                        >
                            SALVA
                        </Button>
                        <Button
                            color='danger'
                            onClick={()=> this.setState({
                                showEdit: false,
                            })}
                        >
                            CHIUDI
                        </Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        )
    }
}
