const paginationClickHandler = (event) => {
    const target = event.target;

    if (target.tagName === 'BUTTON' && (target.title.startsWith('Page') || target.title.startsWith('pagination.firstPage'))) {
        const page = Number(target.textContent);

        if (!isNaN(page)) {
            updatePage("page", page)
        } else {
            updatePage("page", null)
        }
    }

    if (target.title.startsWith('Avanti')) {
        let page = getActualPage()
        console.log("page", page)
        updatePage("page", (page === null) ? 2 : page+1)
    }

    if (target.title.startsWith('Indietro')) {
        let page = getActualPage()
        if (page !== null)
            updatePage("page", page-1)
    }
};

const updatePage = (key, value) => {
    const newurl = new URL(window.location.href);

    if (value !== null) {
        newurl.searchParams.set(key, value);
    } else {
        newurl.searchParams.delete(key);
    }

    window.history.pushState({ path: newurl.href }, '', newurl.href);
}

let getActualPage = function () {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let page = urlParams.get('page');

    if (page) {
        page = Number(page);

        if (!isNaN(page))
            return page-1
    }

    return 0;
};

let addHandlePaginator = function () {
    const paginationContainer = document.querySelector('.gridjs-pages');
    if (paginationContainer) {
        paginationContainer.addEventListener('click', paginationClickHandler);
    }
};

let removeHandlePaginator = function () {
    const paginationContainer = document.querySelector('.gridjs-pages');
    if (paginationContainer) {
        paginationContainer.removeEventListener('click', paginationClickHandler);
    }
};


const Params = {
    addHandlePaginator: addHandlePaginator,
    removeHandlePaginator: removeHandlePaginator,
    getActualPage: getActualPage
};

module.exports = Params;
