import React, { Component } from 'react';
import {
    Card,
    CardBody,
    Col,
    Container,
    Input,
    Label,
    Row,
    Button,
    Form,
    FormFeedback,
    Alert,
    Spinner
} from 'reactstrap';
import {Link} from "react-router-dom";

import {Utils} from "custom";

import {AuthAPI} from "api";

const md5 = require('md5');

class ForgotPasswordForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            password: '',
            rePassword: '',
            errors: false,
            loading: false
        };
    }


    onResetPassword() {
        let password = this.state.password;
        let rePassword = this.state.rePassword;
        let error = {};

        if (password.trim() !== "" && !Utils.isValidPassword(password))
            error.password = "La password deve essere almeno di 6 caratteri con almeno una lettera maiuscola, una lettera minuscola e un numero";

        if (password.trim() === "")
            error.password = "La password non può essere vuota";

        if (password !== rePassword)
            error.rePassword = "Le password inserite non coincidono";

        this.setState({errors: (Object.keys(error).length > 0) ? error : false});

        if (Object.keys(error).length === 0) {
            this.setState({loading: true});
            AuthAPI.completeResetPassword(md5(password), this.props.token.user.email, this.props.token.string).then(() => {
                global.SweetAlert.fire({
                    title: 'Password aggiornata',
                    text: 'La tua password è stata aggiornata correttamente.',
                    icon: 'success',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    confirmButtonText: 'Torna a login',
                    buttonsStyling: false
                }).then(function (result) {
                    if (result.value) {
                        window.location.replace('/login');
                    }
                })
            }).catch(() => {
                this.setState({loading: false});
                this.setState({
                    errors: {password: 'Si è verificato un errore, non è stato possibile aggiornare la password'},
                });
            })
        }
    }


    render() {
        document.title = "Reset Password | CLTV Cloud";

        return <React.Fragment>
            <div className="text-center mt-2">
                <h5 className="text-primary">Reset Password 🔒</h5>
                <p className="text-muted">Scegli la tua nuova password per accedere al portale.</p>
            </div>
            <div className="p-2 mt-4">
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        this.onResetPassword()
                    }}
                    action="#">
                    <div className="mb-3">
                        <Label htmlFor="password" className="form-label">Nuova password</Label>
                        <Input
                            name="password"
                            className="form-control"
                            placeholder="Inserisci la tua nuova password"
                            type="password"
                            value={this.state.password}
                            invalid={this.state.errors.password}
                            onChange={(e) => this.setState({password: e.target.value})}
                        />
                        {(this.state.errors.password) ? <FormFeedback type="invalid">{this.state.errors.password}</FormFeedback> : null}
                    </div>
                    <div className="mb-3">
                        <Label htmlFor="rePassword" className="form-label">Ridigita password</Label>
                        <Input
                            name="rePassword"
                            className="form-control"
                            placeholder="Ridigita la tua nuova password"
                            type="password"
                            value={this.state.rePassword}
                            invalid={this.state.errors.rePassword}
                            onChange={(e) => this.setState({rePassword: e.target.value})}
                        />
                        {(this.state.errors.rePassword) ? <FormFeedback type="invalid">{this.state.errors.rePassword}</FormFeedback> : null}
                    </div>
                    <div className="mt-4">
                        <Button color="success" className="btn btn-success w-100" type="submit">Reset Password {(this.state.loading ? <Spinner size='sm' type='grow' /> : null)}</Button><br /><br />
                        <div className="float-start" >
                            <Link onClick={() => this.props.changeModeLogin()} className="text-muted">Torna a login</Link>
                        </div>
                    </div>
                </Form>
            </div>

        </React.Fragment>
    }
}

export default ForgotPasswordForm;
