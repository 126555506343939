import { LessonAPI } from 'api';
import React, { Component } from 'react'
import { Alert, Badge, Col, Container, Row, Input, Spinner, Button, Modal, ModalBody, ModalFooter, FormGroup, Label, FormText, FormFeedback } from 'reactstrap';

export default class DeleteLesson extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id_course: '',
            id: '',
            loading: false,
            errors: null,
            showDelete: false,
        };
    }

    componentDidMount() {
        const { id_course, id } = this.props;
        this.setState({ id_course, id })
    }

    DeleteLesson = () => {
        const { id_course, id } = this.props;

        LessonAPI.remove(id_course, id).then(() => {
            global.onSetModal({ loading: false });
            global.SweetAlert.fire({
                title: 'lesson Elimina',
                text: 'La lezione è stata eliminata.',
                icon: 'success',
                allowOutsideClick: false,
                customClass: {
                    confirmButton: 'btn btn-primary'
                },
                confirmButtonText: 'Chiudi',
                buttonsStyling: false
            })
            this.setState({
                name: '',
                id_course: '',
                id: '',
                loading: false,
                errors: null,
                showDelete: false,
            })
            this.props.onResolve();
        }).catch(() => {
            global.SweetAlert.fire({
                title: 'Errore',
                text: 'Si è verificato un errore durante la rimozione della lezione',
                icon: 'error',
                customClass: {
                    confirmButton: 'btn btn-primary'
                }
            });
            this.setState({
                name: '',
                id_course: '',
                id: '',
                loading: false,
                errors: null,
                showDelete: false,
            })
        })
    }

    render() {
        const errors = this.state.errors
        return (
            <React.Fragment>
                <i className='ri-delete-bin-5-fill fs-20 text-danger' onClick={() => this.setState({ showDelete: true })}></i>&nbsp;

                <Modal style={{ overflowY: "auto" }} isOpen={this.state.showDelete} scrollable={true} className={'modal-dialog-scrollable modal-lg'}>
                    <ModalBody className='px-sm-5 pt-5 pb-50'>
                        <div className='text-center mb-5'>
                            <h3 className='mb-1'>Sei sicuro di voler eliminare la lezione?</h3>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color='success'
                            onClick={this.DeleteLesson}
                        >
                            SI
                        </Button>
                        <Button
                            color='danger'
                            onClick={() => this.setState({
                                showDelete: false,
                            })}
                        >
                            ANNULLA
                        </Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        )
    }
}
