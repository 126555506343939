import React, {useEffect, useState} from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
    Row,
    Col,
    Label,
    FormFeedback,
    Input,
    FormText,
    Badge,
} from 'reactstrap';

import { Utils } from 'custom';
import {ModuleAPI, ReporterAPI, UserAPI} from 'api';
import Select from 'react-select';

const moment = require('moment');


const EditReporter = ({ reporter, onResolve, users}) => {

    const [form, setForm] = useState(reporter);

    const [errors, setErrors] = useState({});
    const [modalOpen, setModalOpen] = useState(false);

    const [reporters, setReporters] = useState([]);

    const [accounts, setAccounts] = useState([]);

    useEffect(() => {
        let newReporters = [...users.filter((u) => u.id !== Utils.getUserData().id).map((a) => {
            return {
                name: a.name,
                role: a.role,
                value: a.id,
                label: a.name
            }
        })];
        let newAccounts = [...users.map((a) => {
            return {
                name: a.name,
                role: a.role,
                value: a.id,
                label: a.name
            }
        })];

        newReporters.unshift({name: <small>Non Assegnato</small>, role: "", label: "", value: null});
        newAccounts.unshift({name: <small>Non Assegnato</small>, role: "", label: "", value: null});

        setReporters(() => newReporters);
        setAccounts(() => newAccounts);
    }, []);


    const changeForm = (key, text) => {
        setForm((prevForm) => ({ ...prevForm, [key]: text }));
    };

    const showModal = () => {
        setErrors({});
        setModalOpen(true);
    };

    const resetForm = () => {
        // setForm({
        //     id: preset?.id || null,
        //     name: preset?.name || '',
        //     referral: preset ? referral_list[referral_list.findIndex((r) => r.value === preset.referral)] : null,
        //     consulents: preset ? preset.consulents.map((c) => ({ value: c.id, label: `${c.firstname} ${c.lastname}` })) : [],
        // });
        setModalOpen(false);
    };

    const saveModule = () => {
        const self = this;
        const error = {};

        if (form.name === "")
            error.name = "Il campo nome è obbligatorio";

        if (form.cell === "")
            error.cell = "Il campo cellulare è obbligatorio";

        if (form.email !== "" && !Utils.isValidEmail(form.email))
            error.email = "La mail inserita non è corretta";

        setErrors(error);


        if (Object.keys(error).length === 0) {
            global.onSetModal({loading: true});

            let obj = {
                id: form.id,
                name: form.name,
                email: form.email,
                cell: form.cell,
                bank_iban: form.bank_iban,
                father_id: form.father_id,
                user_id: form.user_id
            }


            ReporterAPI.edit(obj)
                .then(() => {
                    global.onSetModal({loading: false});
                    global.SweetAlert.fire({
                        title: 'Reporter modificato',
                        text: 'Il reporter è stato modificato correttamente.',
                        icon: 'success',
                        allowOutsideClick: false,
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        confirmButtonText: 'Chiudi',
                        buttonsStyling: false,
                    }).then(function (result) {
                        if (result.value) {
                            resetForm();
                            onResolve();
                        }
                    });
                })
                .catch((err) => {
                    // global.onSetModal({loading: false});
                    global.SweetAlert.fire({
                        title: 'Errore',
                        text: 'Si è verificato un errore durante la modifica del reporter',
                        icon: 'error',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                    });
                });
        }
    };

    return (
        <React.Fragment>
            <li className="list-inline-item cursor-pointer" onClick={() => showModal()}>
                <i className="ri-pencil-fill fs-20 text-info"></i>
            </li>

            <Modal isOpen={modalOpen} size="lg">
                <ModalHeader>MODIFICA REFERENTE</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <div className="mb-2">
                                <Label className="form-label">Seleziona Utente</Label>
                                <Select
                                    value={accounts.filter((a) => a.value === form.user_id)}
                                    options={accounts}
                                    onChange={(val) => {
                                        changeForm('user_id', val.value);
                                    }}
                                    formatOptionLabel={(user) => (
                                        <div className="referral-option">
                                            <span>&nbsp;&nbsp;{user.name}</span>
                                            <small>&nbsp;&nbsp;({Utils.getRoleLabel(user.role)})</small>
                                        </div>
                                    )}
                                ></Select>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="mb-2">
                                <Label className="form-label">Seleziona Referente</Label>
                                <Select
                                    value={accounts.filter((a) => a.value === form.father_id)}
                                    options={reporters}
                                    onChange={(val) => {
                                        changeForm('father_id', val.value);
                                    }}
                                    formatOptionLabel={(user) => (
                                        <div className="referral-option">
                                            <span>&nbsp;&nbsp;{user.name}</span>
                                            <small>&nbsp;&nbsp;({Utils.getRoleLabel(user.role)})</small>
                                        </div>
                                    )}
                                ></Select>
                            </div>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <div className="mb-2">
                                <Label className="form-label">Nome</Label>
                                <Input
                                    type="text"
                                    value={form.name}
                                    invalid={Boolean(errors.name)}
                                    onChange={(e) => changeForm('name', e.target.value)}
                                />
                                <FormFeedback>{errors.name}</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <div className="mb-2">
                                <Label className="form-label">Email</Label>
                                <Input
                                    type="email"
                                    value={form.email}
                                    invalid={Boolean(errors.email)}
                                    onChange={(e) => changeForm('email', e.target.value)}
                                />
                                <FormFeedback>{errors.email}</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <div className="mb-2">
                                <Label className="form-label">Telefono</Label>
                                <Input
                                    type="tel"
                                    value={form.cell}
                                    invalid={Boolean(errors.cell)}
                                    onChange={(e) => changeForm('cell', e.target.value)}
                                />
                                <FormFeedback>{errors.cell}</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <div className="mb-2">
                                <Label className="form-label">IBAN</Label>
                                <Input
                                    type="text"
                                    value={form.bank_iban}
                                    invalid={Boolean(errors.bank_iban)}
                                    onChange={(e) => changeForm('bank_iban', e.target.value)}
                                />
                                <FormFeedback>{errors.bank_iban}</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={saveModule}>
                        SALVA
                    </Button>
                    <Button color="danger" onClick={resetForm}>
                        CHIUDI
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};

export default EditReporter;
