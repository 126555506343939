import React from 'react';
import { Alert, Badge, Col, Container, Row, Input, Spinner, Button, Modal, ModalBody, ModalFooter, FormGroup, Label, FormText, FormFeedback } from 'reactstrap';

import { AdminAPI, CourseAPI, TeacherAPI } from "api";
import Select, { components } from "react-select";

class CreateCouse extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            id_teacher: '',
            teachers: null,
            selectedteacher: null,
            loading: false,
            errors: null,
            showCreate: false,
        };
    }

    componentDidMount() {
        this.getTeachers()
    }

    getTeachers = () => {
        TeacherAPI.list().then((teachers) => {
            this.setState({ loading: false, errors: null, teachers: teachers });
        }).catch(() => {
            this.setState({ error: true });
        })
    }

    resetForm = () => {
        this.setState({ showCreate:false, loading: false, errors: null, selectedteacher:null});
    }

    CreateCourse = () => {
        const self = this;
        let form = this.state;
        let error = {};

        if (form.name === '')
            error.name = "Il campo nome è obbligatorio";

        if (form.selectedteacher == null)
            error.id_teacher = "Devi specificare il docente";

        this.setState({ errors: error });
        console.log('error',error);
        if (Object.keys(error).length === 0) {
            global.onSetModal({ loading: true });
            const payload = {
                name: this.state.name,
                id_teacher: this.state.selectedteacher.id
            }
            console.log('payload',payload);
            CourseAPI.add(payload).then(() => {
                global.onSetModal({ loading: false });
                global.SweetAlert.fire({
                    title: 'Corso creato',
                    text: 'Il corso è stato creato correttamente.',
                    icon: 'success',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    confirmButtonText: 'Chiudi',
                    buttonsStyling: false
                }).then(function (result) {
                    if (result.value) {
                        self.resetForm();
                        self.props.onResolve();
                    }
                })
            }).catch((err) => {
                global.onSetModal({ loading: false });
                global.SweetAlert.fire({
                    title: 'Errore',
                    text: 'Si è verificato un errore durante la creazione del corso',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                });
            })
        }
    }


    render() {
        let form = this.state;
        let errors = this.state.errors;

        return (
            <React.Fragment>
                <button
                    type="button" style={{ margin: 10 }}
                    className="btn btn-success add-btn" onClick={() => this.setState({showCreate:true})}>
                    <i className="ri-add-line align-bottom me-1"></i> CREA CORSO
                </button>
                <Modal style={{ overflowY: "auto" }} isOpen={form.showCreate} scrollable={true} className={'modal-dialog-scrollable modal-lg'}>
                    <ModalBody className='px-sm-5 pt-5 pb-50'>
                        <div className='text-center mb-5'>
                            <h3 className='mb-1'>CREA CORSO</h3>
                        </div>
                        <Row>
                            <Col md="12">
                                <div className='mb-2'>
                                    <Label className='form-label'>
                                        Seleziona Docente
                                    </Label>
                                    <Select
                                        value={form.selectedteacher}
                                        options={form.teachers}
                                        // name="teacher"
                                        // id="teacher"
                                        onChange={(val) => {
                                            console.log('val', val);
                                            this.setState({
                                                selectedteacher: val
                                            })
                                        }}
                                        getOptionLabel={(opt) => opt.firstname + ' ' + opt.lastname}
                                        getOptionValue={(opt) => opt.firstname + ' ' + opt.lastname}
                                    ></Select>
                                    <Input invalid={Boolean(errors?.id_teacher)} hidden/>
                                    <FormFeedback>{errors?.id_teacher}</FormFeedback>
                                </div>
                            </Col>
                            <Col md="12">
                                <div className='mb-2'>
                                    <Label className='form-label'>
                                        Nome
                                    </Label>
                                    <Input
                                        type="text"
                                        value={form.name}
                                        invalid={Boolean(errors?.name)}
                                        onChange={(e) => this.setState({ name: e.target.value })}
                                    />
                                    <FormFeedback>{errors?.name}</FormFeedback>
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color='success'
                            onClick={this.CreateCourse}
                        >
                            SALVA
                        </Button>
                        <Button
                            color='danger'
                            onClick={this.resetForm}
                        >
                            CHIUDI
                        </Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        )
    }
}

export default CreateCouse;
