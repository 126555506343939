import {DeleteLead, EditLead, Params, Utils} from 'custom';
import React, {useEffect, useState} from 'react'
import { Grid, _ } from 'gridjs-react';
import { Badge, Input } from 'reactstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {ClassRoomTrialAPI} from "api";

const moment = require('moment');

const LeadTableRender = ({ onSelectLead, leads, leadIds, getReferralLabel, getLeads, signCheck }) => {

    const [lead, setLead] = useState(null)
    const history = useHistory()
    let isAdmin = Utils.getUserData().role === "ADMIN";
    const countries = Utils.printListCountries();

    useEffect(() => {
        return () => {
            Params.removeHandlePaginator()
        };
    }, [])

    const getStatusLabel = (id, status, consulent) => {

        let data_trial = null;

        let lead = null;
        let pastTrial = false;

        let check = false;

        if (status === "TRIAL") {
            lead = leads[leads.findIndex((l) => l.id === id)];
            pastTrial = (moment(lead.trial_date).diff(moment()) < 0);
            data_trial = moment(lead.trial_date).format("DD/MM/YYYY HH:mm");
            check = lead.trial_check;
        }


        switch (status) {
            case null:
                return (consulent === null) ? <div className="custom-badge status-unassigned" >Non assegnato</div> : <div className="custom-badge status-waiting" >In attesa</div>;
            case "WORKING":
                return <div className="custom-badge status-working" >In lavorazione</div>;
            case "TRIAL":
                return (Utils.getUserData().role === "CONSULENT") ? <span className={(!check) ? "cursor-pointer" : ""} onClick={() => (!check) ? signCheck(lead.id_trial, lead.id) : {}}><div className="custom-badge status-trial" >In prova</div><i className={"mdi mdi-disc " + ((check) ? "text-success" : "text-danger")}></i><br /><small className={(pastTrial) ? "text-danger" : "text-primary"}>{data_trial}</small></span> : <span><div className="custom-badge status-trial" title={data_trial}>In prova</div><i className={"mdi mdi-disc " + ((check) ? "text-success" : "text-danger")}></i></span>;
            case "CONVERTED":
                return <div className="custom-badge status-converted" >Completato</div>;
            case "APPOINTMENT":
                return <div className="custom-badge status-appointment" >Da Richiamare</div>;
            case "UNREPLY":
                return <div className="custom-badge status-unreply" >Nessuna Risposta</div>;
            case "REMARKETING":
                return <div className="custom-badge status-remarketing" >Remarketing</div>;
            case "ENDED":
                return <div className="custom-badge status-closed" >Scartato</div>;
            default:
                return null;
        }
    }


    return (
        <div>
            <Grid
                data={leads}
                columns={[
                    (isAdmin) ? {
                        id: 'id',
                        formatter: (cell, row) => _(
                            <div className="form-check mb-2">
                                <Input className="form-check-input" type="checkbox" checked={leadIds.find((el) => el == cell)} onChange={(e) => onSelectLead(cell, e.target.checked)} />
                                &nbsp;&nbsp;
                                {cell}
                            </div>
                        ),
                        width: "50px"
                    } : {
                        id: 'id',
                        hidden: true
                    },
                    {
                        id: 'consulent',
                        hidden: true
                    },
                    {
                        id: 'country',
                        name: 'Nazione',
                        width: "15px",
                        sort: false,
                        formatter: (cell, row) => _(
                            <span className="text-nowrap">
                                <div className="text-center">
                                    {/* {console.log("row",row)} */}
                                    {(cell !== null) ? <img src={countries.find((el)=> el.value == cell)?.img} width="25" alt='' /> : null}
                                </div>
                            </span>
                        )
                    },
                    {
                        id: 'referral_platform',
                        name: 'Provenienza',
                        width: "15px",
                        formatter: (cell, row) => _(<div className="text-center">{getReferralLabel(cell)}</div>)
                    },
                    {
                        id: 'name',
                        name: 'Nominativo',
                        formatter: (cell, row, t) => _(
                            <span className="text-nowrap">
                                <a href="javascript:void(0)" onClick={() => history.push("/lead/detail/" + row.cells[0].data)} style={{ color: '#4b38b3', cursor: 'pointer' }}><b>{cell}</b></a>
                            </span>)
                    },
                    (!isAdmin) ? {
                        id: 'status',
                        name: 'Stato',
                        sort: false,
                        formatter: (cell, row) => _(<div className="text-center">
                            {getStatusLabel(row.cells[0].data, cell, row.cells[1].data)}<br />
                            {(row.cells[7].data !== null) ? <small className={(moment(row.cells[7].data).isBefore(moment())) ? "text-danger" : undefined}>{moment(row.cells[7].data).fromNow()}</small> : null}
                        </div>)
                    } : {
                        id: 'status',
                        name: 'Stato',
                        formatter: (cell, row) => _(<div className="text-center">
                            {getStatusLabel(row.cells[0].data, cell, row.cells[1].data)}<br />
                            {(row.cells[1].data !== null) ? <small>{row.cells[1].data.firstname} {row.cells[1].data.lastname}</small> : null}
                        </div>)
                    },
                    {
                        id: 'note',
                        name: 'Nota',
                        sort: false,
                        formatter: (cell, row) => _(<div>
                            <small>{cell}</small>
                        </div>)
                    },
                    {
                        id: 'reminder',
                        hidden: true
                    },
                    (isAdmin) ? {
                        id: 'module',
                        name: 'Modulo',
                        formatter: (cell, row) => _(<span className="text-nowrap">{cell}</span>)
                    } : undefined,
                    (isAdmin) ? {
                        id: 'email',
                        name: 'Email',
                        formatter: (cell, row) => _(<span className="text-nowrap"><a href={"mailto:" + cell}>{cell}</a></span>)
                    } : undefined,
                    (isAdmin) ? {
                        id: 'phone',
                        name: 'Telefono',
                        sort: false,
                        formatter: (cell, row) => _(<span className="text-nowrap"><a href={"tel:" + cell}>{cell}</a></span>)
                    } : undefined,
                    {
                        id: 'date_hour',
                        name: 'Data / Ora',
                        formatter: (cell, row) => _(<span className="text-nowrap"><div className="text-center">{moment(cell).format("DD/MM/YYYY HH:mm")}</div></span>)
                    },
                    (isAdmin) ? {
                        id: 'id',
                        name: '',
                        sort: false,
                        formatter: (cell, row, i, d) => _(
                            <ul className="list-inline hstack gap-2 mb-0">
                                {(row.cells[4].data !== "CONVERTED") ? <div>
                                    <EditLead lead={{
                                        editId: cell,
                                        editName: row.cells[4].data,
                                        editEmail: row.cells[9].data,
                                        editPhone: row.cells[10].data
                                    }} getLeads={getLeads}/>
                                    <DeleteLead id={cell} getLeads={getLeads}/>
                                </div> : null}
                            </ul>)
                    } : undefined
                ]}
                style={{ table: { 'white-space': 'nowrap' } }}
                resizable={true}
                search={{
                    selector: (cell, rowIndex, cellIndex) => JSON.stringify(cell)
                }}
                sort={true}
                pagination={{ enabled: true, limit: 8, page: Params.getActualPage() }}
                language={{
                    'search': {
                        'placeholder': '🔍 Cerca...'
                    },
                    'pagination': {
                        'previous': 'Indietro',
                        'next': 'Avanti',
                        'showing': 'Stai visualizzando da',
                        'results': 'Leads',
                        'to': 'a',
                        'of': 'di',
                    },
                    'noRecordsFound': 'Nessun lead trovato',
                    'error': 'Si è verificato un errore non previsto',
                }}
            />

        </div>
    )
}

export default LeadTableRender
