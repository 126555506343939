import React, {useEffect, useState} from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
    Row,
    Col,
    Label,
    FormFeedback,
    Input,
    FormText,
    Badge,
} from 'reactstrap';

import { Utils } from 'custom';
import {ModuleAPI, ReporterAPI, UserAPI} from 'api';
import Select from 'react-select';

const moment = require('moment');


const ContactReporter = ({reporter}) => {

    const [modalOpen, setModalOpen] = useState(false);


    const showModal = () => {
        setModalOpen(true);
    };

    const resetForm = () => {
        // setForm({
        //     id: preset?.id || null,
        //     name: preset?.name || '',
        //     referral: preset ? referral_list[referral_list.findIndex((r) => r.value === preset.referral)] : null,
        //     consulents: preset ? preset.consulents.map((c) => ({ value: c.id, label: `${c.firstname} ${c.lastname}` })) : [],
        // });
        setModalOpen(false);
    };

    return (
        <React.Fragment>
            <li className="list-inline-item cursor-pointer" onClick={() => showModal()}>
                <i className="ri-contacts-fill fs-20 text-dark"></i>
            </li>

            <Modal isOpen={modalOpen} size="lg">
                <ModalHeader>CONTATTI REFERENTE</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <div className="mb-2">
                                <Label className="form-label">Nome</Label>
                                <Input
                                    type="text"
                                    disabled
                                    value={reporter.name}
                                />
                            </div>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <div className="mb-2">
                                <Label className="form-label">Email</Label>
                                <Input
                                    type="email"
                                    disabled
                                    value={reporter.email}
                                />
                            </div>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <div className="mb-2">
                                <Label className="form-label">Telefono</Label>
                                <Input
                                    type="tel"
                                    value={reporter.cell}
                                    disabled
                                /><br />
                                <a href={"tel:" + reporter.cell}><Badge
                                    style={{fontSize: "12px"}} color="primary"><i
                                    className="mdi mdi-phone"></i> Chiama</Badge></a>&nbsp;&nbsp;
                                <a href={"https://api.whatsapp.com/send?phone=" + reporter.cell.slice(1)}><Badge
                                    style={{fontSize: "12px"}} color="success"><i
                                    className="mdi mdi-whatsapp"></i> Whatsapp</Badge></a>
                            </div>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <div className="mb-2">
                                <Label className="form-label">IBAN</Label>
                                <Input
                                    type="text"
                                    value={reporter.bank_iban}
                                    disabled
                                />
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={() => setModalOpen(false)}>
                        CHIUDI
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};

export default ContactReporter;
