import {ConsulentAPI, CourseAPI, SellAPI} from 'api';
import React, { Component } from 'react'
import {Card, CardBody, CardHeader, Col, FormFeedback, Input, Label, Row} from 'reactstrap';
import Select from "react-select";
import {Link, withRouter} from 'react-router-dom';
import {EditSell, ListPayment, Utils} from "custom";
const moment = require("moment")

class Video extends Component {
    constructor(props) {
        super(props);

        this.state = {
            form: {
                id: null,
                id_course: [],
                total: 0,
                date_sell: null,
                id_consulent: null,
                note: ""
            },
            courses: [],
            consulentList: [],
            loading: false,
            errors: false
        };
    }

    componentDidMount() {
        if (this.props.sell !== null) {
            const sell = this.props.sell;
            this.setState({courses: sell.courses, form: {id: sell.id, total: sell.total, note: sell.note, date_sell: moment(sell.createdAt).format("YYYY-MM-DD")}})
        } else {
            this.getCourses()
        }
    }

    getCourses() {
        CourseAPI.list().then(async (courses) => {
            let res = [];
            for (let d = 0; d < courses.length; d++) {
                res.push({ value: courses[d].id, label: courses[d].name })
            }

            let consulents = [];

            if (Utils.getUserData().role === "ADMIN") {
                consulents = await ConsulentAPI.list();
                consulents = consulents.map((cons) => {return {id: cons.id, name: cons.firstname + " " + cons.lastname}})
            }

            this.setState({ courses: res, consulentList: consulents });
        }).catch(() => {
            this.setState({ courses: [] });
        })
    }

    changeForm(key, text) {
        const form = this.state.form;
        form[key] = text;

        this.setState({ form: form });
    }

    resetForm() {
        this.setState({
            form: {
                id: null,
                id_course: '',
                id_client: null,
                date_sell: null,
                id_consulent: null,
                total: 0,
                note: ''
            },
            errors: false,
        });
    }

    saveSell(type) {
        const self = this;
        let form = this.state.form;

        let error = {};

        if (form.total === '')
            error.total = "Il valore è obbligatorio";

        if (this.props.sell === null)
            if (form.id_course.length === 0)
                error.id_course = "Seleziona almeno un corso acquistato";

        this.setState({ errors: error });

        if (Object.keys(error).length === 0) {

            let add = (form.id === null);

            if (add) {
                const query = new URLSearchParams(window.location.search)
                form["id_client"] = query.get('client');
                form['id_lead'] = Number(query.get('lead'));
                form["type"] = type;
                if (Utils.getUserData().role === "ADMIN" && form.id_consulent !== null) {
                    form.id_consulent = form.id_consulent.id;
                }
            }

            let api = (add) ? SellAPI.addvideo : SellAPI.edit

            this.setState({loading: true});

            api(form).then((response) => {
                this.setState({loading: false});
                global.SweetAlert.fire({
                    title: 'Vendita ' + ((add) ? "creata" : "modificata"),
                    text: 'La vendita è stata ' + ((add) ? "creata" : "modificata") + ' correttamente.',
                    icon: 'success',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    confirmButtonText: 'Chiudi',
                    buttonsStyling: false
                }).then(function (result) {
                    if (result.value) {
                        if (add) {
                            window.location.replace("/sell/" + response)
                        } else {
                            self.props.onResolve()
                        }
                    }
                })
            }).catch((err) => {
                this.setState({loading: false});
                let message = 'Si è verificato un errore durante la creazione della vendita';
                if (!add && err.status === "INVALID_TOTAL") {
                    message = 'I pagamenti già effettuati non possono superare il totale della vendita'
                }

                global.SweetAlert.fire({
                    title: 'Errore',
                    text: message,
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                });
            })
        }
    }

    reloadSell() {
        this.props.onResolve()
    }
    render() {
        const self = this;
        let form = this.state.form;
        let errors = this.state.errors;

        return (
            <div className='p-4'>
                <Card>
                    <CardBody>
                        <Row>
                            {(this.props.sell || Utils.getUserData().role === "CONSULENT") ? null : <Col md="12">
                                <div className='mb-2'>
                                    <Label className='form-label'>
                                        Data
                                    </Label>
                                    <Input type="date" value={(form.date_sell === null) ? moment().format("YYYY-MM-DD") : form.date_sell} onChange={(e) => this.changeForm('date_sell', e.target.value)}/>
                                </div>
                            </Col>}
                            {(Utils.getUserData().role === "ADMIN") ? <Col md="12">
                                <div className='mb-4'>
                                    <Label className='form-label'>
                                        Consulente
                                    </Label>
                                    {(this.props.sell === null) ? <Select
                                        value={this.state.form.id_consulent}
                                        placeholder={"Seleziona il Consulente..."}
                                        onChange={(selectedOption) => {
                                            let form = this.state.form;
                                            form.id_consulent = selectedOption;
                                            this.setState({form: form})
                                        }}
                                        getOptionLabel={(opt) => opt.name}
                                        getOptionValue={(opt) => opt.id}
                                        options={this.state.consulentList}
                                    /> : <h5>{(this.props.sell.consulent !== null) ? this.props.sell.consulent.firstname + " " + this.props.sell.consulent.lastname : "N/D"}</h5>}
                                </div>
                            </Col> : null}
                            <Col md="12">
                                <div className='mb-4'>
                                    <Label className='form-label'>
                                        Cliente
                                    </Label>
                                    {(this.props.sell === null) ? <h4>{(this.props.client?.firstname + ' ' + this.props.client?.lastname)}</h4> : <h4 className="text-primary"><Link to={"/client/" + this.props.sell.client.id}>{(this.props.sell.client?.firstname + ' ' + this.props.sell.client?.lastname)}</Link></h4>}
                                </div>
                            </Col>
                            {(this.props.sell === null) ? <Col md="12">
                                {this.state.courses.map((course, i) => {
                                    return <div className="form-check form-check-outline form-check-primary mb-3" key={i}>
                                        <Input className="form-check-input" type="checkbox" checked={this.props.client.videosBought.includes(course.value) || form.id_course.includes(course.value)} disabled={this.props.client.videosBought.includes(course.value)}
                                               onChange={(e) => {
                                                   let newArr = [...form.id_course];
                                                   if (e.target.checked) {
                                                       newArr.push(course.value);
                                                   } else {
                                                       newArr.splice(newArr.findIndex((c) => c === course.value), 1);
                                                   }
                                                   let newForm = form;
                                                   newForm.id_course = newArr;

                                                   this.setState({form: newForm});
                                               }}/>
                                        <Label className="form-check-label">
                                            {course.label}
                                        </Label>
                                    </div>
                                })}
                                {(errors.id_course) ? <span className="text-danger">{errors.id_course}</span> : null}
                            </Col> : <Col md="12">
                                {this.state.courses.map((course, i) => {
                                    return <div className="form-check form-check-outline form-check-primary mb-3" key={i}>
                                        <Input className="form-check-input" type="checkbox" checked disabled/>
                                        <Label className="form-check-label">
                                            {course.name}
                                        </Label>
                                    </div>
                                })}
                            </Col>}
                        </Row>
                        <br />
                        <Row>
                            <Col md="12">
                                <div className='mb-2'>
                                    <Label className='form-label'>
                                        Importo totale (€)
                                    </Label>
                                    <Input type="number" value={form.total}
                                           invalid={Boolean(errors.total)}
                                           onChange={(e) => this.changeForm('total', e.target.value)} />
                                    <FormFeedback>{errors.total}</FormFeedback>
                                </div>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md="12">
                                <div className='mb-2'>
                                    <Label className='form-label'>
                                        Note vendita
                                    </Label>
                                    <Input type="textarea" rows={5} value={form.note}
                                           onChange={(e) => this.changeForm('note', e.target.value)} />
                                </div>
                            </Col>

                            <div className='mt-3 text-end'>
                                <button
                                    type="button"
                                    className="btn btn-success"
                                    onClick={() => this.saveSell('VIDEO')}
                                >
                                    <i className="ri-save-2-fill align-bottom me-1"></i> SALVA
                                </button>
                            </div>
                        </Row>
                    </CardBody>
                </Card>
                {(this.props.sell !== null) ? <Card>
                    <CardHeader>
                        <h5>
                            <i className="ri-money-euro-circle-fill align-bottom me-1" style={{ margin: 10 }}></i> PAGAMENTI
                        </h5>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <ListPayment payments={this.props.sell.payments} sell={this.props.sell} onReload={this.reloadSell.bind(this)}/>
                        </Row>
                    </CardBody>
                </Card> : null}
            </div>
        )
    }
}
export default withRouter(Video)
