import React, {useEffect, useState} from 'react';
import {Badge, Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { withRouter } from 'react-router-dom';
import LeadInfo from "../Lead/nav/LeadInfo";
import Question from "../Lead/nav/Question";
import {ListConsulentClassroom, ListReminder, Utils} from "custom";
import Select from "react-select";
// import NetworkTab from "./NetworkTab";
import ReporterTab from "./ReporterTab";
import ReportTab from "./ReportTab";
import Flatpickr from "react-flatpickr";
import {Italian} from "flatpickr/dist/l10n/it";
import moment from "moment/moment";
import ReporterTabNetworker from "./ReporterTabNetworker";
import ReportTabNetworker from "./ReportTabNetworker";
import NetworkerTab from "./NetworkerTab";
import Skeleton from "react-loading-skeleton";
import CountUp from "react-countup";

const Main = () => {

    const [tabSelected, setTabSelected] = useState(2);
    const [reporterFilter, setReporterFilter] = useState(null);

    const [counter, setCounter] = useState(null);

    const [range, setRange] = useState({start_date: null, end_date: null});

    useEffect(() => {
        document.title = 'Network | CLTV';
    }, []);

    const onClickCommission = (id) => {
        setReporterFilter((id) ? id : null)
        setTabSelected(3)
    };

    let is_networker = Utils.getUserData().is_networker;

    const handlePromoteSuccess = () => {
        if (!is_networker && tabSelected === 2) {
            setTabSelected(1);
        }
    };

    let isConsulent = Utils.getUserData().role === "CONSULENT";


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Network" pageTitle="CLTV" />
                    {(!is_networker) ? <Row className="mb-3">
                        <Col md={4}>
                            <div className="input-group">
                                <Flatpickr
                                    className="form-control border-0 dash-filter-picker shadow"
                                    options={{
                                        mode: "range",
                                        dateFormat: "d/m/Y",
                                        locale: Italian,
                                    }}
                                    value={(range.start_date !== null && range.end_date !== null) ? [range.start_date.format("DD/MM/YYYY"), range.end_date.format("DD/MM/YYYY")] : []}
                                    onChange={(selectedDates) => {
                                        if (selectedDates.length === 2) {
                                            setRange({
                                                start_date: moment(selectedDates[0]),
                                                end_date: moment(selectedDates[1]),
                                            });
                                        }
                                    }}
                                />
                                <div className="input-group-text bg-primary border-primary text-white"><i className="ri-calendar-2-line"></i></div>
                            </div>
                        </Col>
                    </Row> : null}

                    <Row>
                        <Col md={6} xl={6}>
                            <Card>
                                <CardBody>
                                    {(counter === null) ? <Skeleton height={45}/> :
                                        <div className="d-flex align-items-center">
                                            <div className="avatar-sm flex-shrink-0">
                                            <span
                                                className="avatar-title bg-light text-primary rounded-circle fs-3">
                                                <i className={"align-middle bx bx-euro"}></i>
                                            </span>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">FATTURATO</p>
                                                <h4 className=" mb-0">
                                                    <CountUp
                                                        suffix=""
                                                        prefix="€"
                                                        decimal=","
                                                        separator="."
                                                        start={0}
                                                        decimals={2}
                                                        end={counter.revenue}
                                                        duration={3}
                                                    />
                                                </h4>
                                            </div>
                                        </div>}
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={6} xl={6}>
                            <Card>
                                <CardBody>
                                    {(counter === null) ? <Skeleton height={45}/> :
                                        <div className="d-flex align-items-center">
                                            <div className="avatar-sm flex-shrink-0">
                                            <span
                                                className="avatar-title bg-light text-success rounded-circle fs-3">
                                                <i className={"align-middle bx bx-euro"}></i>
                                            </span>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">COMMISSIONI</p>
                                                <h4 className=" mb-0">
                                                    <CountUp
                                                        suffix=""
                                                        prefix="€"
                                                        decimal=","
                                                        separator="."
                                                        start={0}
                                                        decimals={2}
                                                        end={counter.commission}
                                                        duration={3}
                                                    />
                                                </h4>
                                            </div>
                                        </div>}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody className="checkout-tab">
                                    <div className="step-arrow-nav mt-n3 mx-n3 mb-3">
                                        <Nav className="nav-pills nav-justified custom-nav" role="tablist">
                                            {(!is_networker && !isConsulent) ? <NavItem>
                                                <NavLink href="#"
                                                         className={(tabSelected === 1) ? "active fs-15 p-3" : "fs-15 p-3"}
                                                         onClick={() => setTabSelected(1)}>
                                                    <i className="mdi mdi-star fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                                                    Manager
                                                </NavLink>
                                            </NavItem> : null}
                                            <NavItem>
                                                <NavLink href="#"
                                                         className={(tabSelected === 2) ? "active fs-15 p-3" : "fs-15 p-3"}
                                                         onClick={() => setTabSelected(2)}>
                                                    <i className="mdi mdi-sitemap fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                                                    {(is_networker) ? "Affiliati" : "Segnalatori"}
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href="#"
                                                         className={(tabSelected === 3) ? "active fs-15 p-3" : "fs-15 p-3"}
                                                         onClick={() => setTabSelected(3)}>
                                                    <i className="mdi mdi-phone fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                                                    Segnalazioni{/*&nbsp;{(lead.id_group !== null) ? <Badge color="success">1</Badge> : null}*/}
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </div>

                                    <TabContent activeTab={tabSelected}>
                                        {(!is_networker && !isConsulent) ? <TabPane tabId={1} id="pills-bill-info">
                                            <NetworkerTab onClickCommission={onClickCommission} range={range} tabSelected={tabSelected}/>
                                        </TabPane> : null}
                                        {(is_networker) ? <TabPane tabId={2} id="pills-bill-info">
                                            <ReporterTabNetworker onClickCommission={onClickCommission} range={range} />
                                        </TabPane> : <TabPane tabId={2} id="pills-bill-info">
                                            <ReporterTab onClickCommission={onClickCommission} range={range} onPromoteSuccess={handlePromoteSuccess} />
                                        </TabPane>}
                                        <TabPane tabId={3} id="pills-bill-info">
                                            {(is_networker) ? <TabPane tabId={2} id="pills-bill-info">
                                                <ReportTabNetworker reporterFilter={reporterFilter} setReporterFilter={onClickCommission} range={range} setCounter={setCounter}/>
                                            </TabPane> : <TabPane tabId={2} id="pills-bill-info">
                                                <ReportTab reporterFilter={reporterFilter} setReporterFilter={onClickCommission} range={range} setCounter={setCounter}/>
                                            </TabPane>}
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(Main);
