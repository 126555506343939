import React, {Component, Fragment} from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
    Row,
    Col,
    Label,
    FormFeedback,
    Input,
    Badge
} from 'reactstrap';

import {Utils, Loading, GestSell, DeleteSell, SellSetting, PaymentSetting, Params} from "custom";
import {AdminAPI, PaymentAPI, SellAPI} from "api";

import {Grid, _} from 'gridjs-react';
import GestAdmin from "../AdminAction/GestAdmin";
import Select from "react-select";
import paymentList from "../../../pages/Payment/PaymentList";

const moment = require("moment")

class ListPayment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            errors: false,
            receivedDate: null,
            form: {
                id: null,
                id_sell: null,
                value: "",
                type_payment: {value: "BANK_TRANSFER", label: "BONIFICO BANCARIO"},
                commission: "",
                transaction_fee: 0,
                sender: ""
            },
            valueNetFee: 0
        };
    }

    componentDidMount() {
        setTimeout(() => {
            Params.addHandlePaginator()
        }, 1000)
    }

    componentWillUnmount() {
        return Params.removeHandlePaginator()
    }


    changeForm(key, text) {
        const form = this.state.form;
        if (key === "value" || key === "commission") {
            if (isNaN(text))
                return;
        }

        form[key] = text;
        this.setState({form: form}, () => this.showModal());
    }

    editstart(id) {
        let payment = this.props.payments[this.props.payments.findIndex((payment) => (payment.id === id))]
        this.setState({
            form: {
                id: payment.id,
                value: payment.value,
                commission: payment.commission,
                transaction_fee: payment.transaction_fee,
                type_payment: Utils.getPaymentMethod().find(m => m.value === payment.type_payment),
                sender: payment.sender
            }
        }, () => this.showModal())
    }

    showModal() {
        const self = this;
        let form = this.state.form;
        let errors = this.state.errors;

        global.onSetModal({
            title: (this.state.form.id !== null) ? "MODIFICA PAGAMENTO" : "AGGIUNGI PAGAMENTO",
            content: <div>
                <Row>
                    <Col md="12">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Metodo Pagamento
                            </Label>
                            <Select
                                value={form.type_payment}
                                options={Utils.getPaymentMethod()}
                                onChange={(val) => {
                                    this.changeForm('type_payment', val)
                                }}
                            ></Select>
                        </div>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col md="12">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Intestatario Bonifico
                            </Label>
                            <Input type="text" value={form.sender}
                                   invalid={Boolean(errors.sender)}
                                   onChange={(e) => this.changeForm('sender', e.target.value)}/>
                            <FormFeedback>{errors.sender}</FormFeedback>
                        </div>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col md="12">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Importo (€)
                            </Label>
                            <Input type="number" value={form.value}
                                   invalid={Boolean(errors.value)}
                                   onChange={(e) => this.changeForm('value', e.target.value)}/>
                            <FormFeedback>{errors.value}</FormFeedback>
                        </div>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col md="12">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Provvigione (€)
                            </Label>
                            <Input type="number" value={form.commission}
                                   invalid={Boolean(errors.commission)}
                                   onChange={(e) => this.changeForm('commission', e.target.value)}/>
                            <FormFeedback>{errors.commission}</FormFeedback>
                        </div>
                    </Col>
                </Row>
                <br />
                {(Utils.getUserData().role === "ADMIN") ? <Row>
                    <Col md="12">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Costi di transazione (€)
                            </Label>
                            <Input type="number" value={form.transaction_fee}
                                   invalid={Boolean(errors.transaction_fee)}
                                   onChange={(e) => this.changeForm('transaction_fee', e.target.value)}/>
                            <FormFeedback>{errors.transaction_fee}</FormFeedback>
                        </div>
                    </Col>
                </Row> : null}
            </div>,
            isOpen: true,
            toggle: false,
            loading: false,
            size: 'lg',
            type: 'info',
            buttons: [
                {label: 'SALVA', color: 'success', onClick: () => this.savePayment()},
                {
                    label: 'CHIUDI',
                    color: 'danger',
                    onClick: () => this.resetForm()
                }
            ]
        })
    }

    savePayment() {
        const self = this;
        let form = this.state.form;


        let error = {};

        if (form.commission === "")
            error.commission = "Il campo provvigione è obbligatorio";

        if (form.value === "") {
            error.value = "Il campo importo è obbligatorio";
        } else if (form.value === 0) {
            error.value = "Il campo importo non può essere 0";
        }

        if (form.transaction_fee > form.value) {
            error.transaction_fee = "Il campo costi transazione non può essere superiore alla vendita";
        }

        if (form.sender === '')
            error.sender = "Il campo intestatario è obbligatorio";

        this.setState({errors: error}, () => this.showModal());

        if (Object.keys(error).length === 0) {
            global.onSetModal({loading: true});

            let add = (form.id === null);
            let api = (add) ? PaymentAPI.add : PaymentAPI.edit;

            if (add)
                form["id_sell"] = this.props.sell.id;

            form.type_payment = form.type_payment.value;

            api(form).then(() => {
                global.onSetModal({loading: false});
                global.SweetAlert.fire({
                    title: (add) ? 'Pagamento inserito' : 'Pagamento modificato',
                    text: (add) ? 'Il pagamento è stato caricato correttamente.' : 'Il pagamento è stato modificato correttamente.',
                    icon: 'success',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    confirmButtonText: 'Chiudi',
                    buttonsStyling: false
                }).then(function (result) {
                    if (result.value) {
                        self.resetForm();
                        self.props.onReload()
                    }
                })
            }).catch((err) => {
                global.onSetModal({loading: false});

                if (err.status === "INVALID_TOTAL") {
                    this.setState({errors: {value: "L'importo supera il valore rimanente della vendita"}}, () => this.showModal());
                    return;
                }

                global.SweetAlert.fire({
                    title: 'Errore',
                    text: (add) ? 'Si è verificato un errore durante la creazione del pagamento' : 'Si è verificato un errore durante la modifica del pagamento',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                });
            })
        }
    }

    resetForm() {
        this.setState({
            form: {
                id: null,
                id_sell: null,
                type_payment: {value: "BANK_TRANSFER", label: "BONIFICO BANCARIO"},
                value: "",
                sender: "",
                transaction_fee: 0
            },
            errors: false
        });
        global.onSetModal({isOpen: false, loading: false, content: ""});
    }

    checkPayment(payment) {

        const self = this;

        this.setState({receivedDate: moment(payment.createdAt).format("YYYY-MM-DD"), valueNetFee: payment.value}, () => global.SweetAlert.fire({
            title: "Conferma pagamento",
            html: <div>
                <p>Specifica la data di ricezione del pagamento</p>
                <strong>{Utils.getValueMoneyLabel(payment.value)}</strong><br />
                <small>{payment.sender}</small><br />
                <Input type="date" defaultValue={self.state.receivedDate} onChange={(e) => self.setState({receivedDate: e.target.value})}/>
                <small style={{fontSize: '0.675em'}}>Verifica che la data sia corretta</small>
                <br />
                <Input type="text" defaultValue={self.state.valueNetFee} onChange={(e) => (!isNaN(e.target.value)) ? self.setState({valueNetFee: e.target.value}) : {}}/>
                <small style={{fontSize: '0.675em'}}>Inserisci il netto transazione</small>

            </div>,
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Conferma',
            cancelButtonText: 'Annulla',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ms-1'
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm: function() {
                return new Promise( function(resolve, reject) {

                    if (self.state.receivedDate === null) {
                        global.SweetAlert.fire({
                            icon: 'error',
                            title: 'La data inserita non è corretta',
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        });
                        self.setState({receivedDate: null})
                        return;
                    }

                    if (self.state.valueNetFee === null) {
                        global.SweetAlert.fire({
                            icon: 'error',
                            title: 'La valore netto inserito non è corretto',
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        });
                        self.setState({receivedDate: null})
                        return;
                    }

                    PaymentAPI.edit({
                        id: payment.id,
                        value: Number(payment.value),
                        type_payment: payment.type_payment,
                        commission: Number(payment.commission),
                        verified: payment.verified,
                        transaction_fee: Number(payment.value) - Number(self.state.valueNetFee),
                        sender: payment.sender
                    }).then(() => {
                        PaymentAPI.check({
                            id: payment.id,
                            receivedDate: self.state.receivedDate
                        }).then(() => {
                            self.setState({receivedDate: null})
                            self.props.onReload();
                            resolve();
                        }).catch((e) => {
                            global.SweetAlert.fire({
                                icon: 'error',
                                title: 'Si è verificato un errore :(',
                                text: 'Purtroppo qualcosa è andato storto e non è stato possibile confermare il pagamento. Riprova più tardi.',
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            });
                            reject()
                        })
                    }).catch((e) => {
                        self.setState({receivedDate: null})
                        global.SweetAlert.fire({
                            icon: 'error',
                            title: 'Si è verificato un errore :(',
                            text: 'Purtroppo qualcosa è andato storto e non è stato possibile confermare il pagamento. Riprova più tardi.',
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        });
                        reject()
                    })
                });
            },
        }).then(function (result) {
            self.setState({receivedDate: null})
            if (result.value) {
                global.SweetAlert.fire({
                    icon: 'success',
                    title: 'Pagamento confermato!',
                    text: 'Pagamento confermato correttamente.',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                });
            }
        }))


    }

    unCheckPayment(id) {
        const self = this;
        global.SweetAlert.fire({
            title: "Ripristina verifica pagamento",
            text: 'Attenzione, vuoi veramente ripristinare il pagamento come da verificare?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, conferma',
            cancelButtonText: 'Annulla',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ms-1'
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm: function() {
                return new Promise(function(resolve, reject) {
                    PaymentAPI.check({
                        id: id,
                        receivedDate: null
                    }).then(() => {
                        resolve();
                    }).catch((e) => {
                        global.SweetAlert.fire({
                            icon: 'error',
                            title: 'Si è verificato un errore :(',
                            text: 'Purtroppo qualcosa è andato storto e non è stato possibile ripristinare il pagamento. Riprova più tardi.',
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        });
                        reject()
                    })
                });
            },
        }).then(function (result) {
            if (result.value) {
                global.SweetAlert.fire({
                    icon: 'success',
                    title: 'Pagamento ripristinato!',
                    text: 'Pagamento ripristinato correttamente.',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                });
                self.props.onReload();
            }
        })
    }

    deletePayment(id) {
        const self = this;
        global.SweetAlert.fire({
            title: "Elimina pagamento",
            text: 'Attenzione, vuoi veramente eliminare il pagamento?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, elimina',
            cancelButtonText: 'Annulla',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ms-1'
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm: function() {
                return new Promise(function(resolve, reject) {
                    PaymentAPI.remove(id).then(() => {
                        resolve();
                    }).catch(() => {
                        global.SweetAlert.fire({
                            icon: 'error',
                            title: 'Si è verificato un errore :(',
                            text: 'Purtroppo qualcosa è andato storto e non è stato possibile eliminare il pagamento. Riprova più tardi.',
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        });
                        reject()
                    })
                });
            },
        }).then(function (result) {
            if (result.value) {
                global.SweetAlert.fire({
                    icon: 'success',
                    title: 'Pagamento eliminato!',
                    text: 'Pagamento eliminato correttamente.',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                });
                self.props.onReload();
            }
        })
    }

    render() {
        let totalToPay = 0

        if (this.props.sell) {
            totalToPay = this.props.sell.total;

            for (let i = 0; i < this.props.payments.length; i++) {
                totalToPay = totalToPay - this.props.payments[i].value;
            }
        }

        return <React.Fragment>
            {(this.props.sell && totalToPay > 0) ? <div style={{margin: 10}} className="text-end">
                <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    onClick={() => this.showModal()}>
                    <i className="ri-add-line align-bottom me-1"></i> AGGIUNGI PAGAMENTO
                </button>
                {(totalToPay > 0) ? <h4 className="text-danger text-start">Rimanenti da saldare: <b>{Utils.getValueMoneyLabel(totalToPay)}</b></h4> : null}
            </div> : null}
            <Grid
                data={this.props.payments}
                columns={[
                    {
                        id: 'id',
                        hidden: true
                    },
                    {
                        id: 'createdAt',
                        name: "Data",
                        formatter: (cell, row) => _(<div className="text-nowrap text-center">
                            {moment(cell).format("DD/MM/YYYY")}
                        </div>)
                    },
                    {
                        id: 'type_payment',
                        name: "Metodo",
                        formatter: (cell, row) => _(<div className="text-nowrap text-center">
                            <span className="text-nowrap">{Utils.getPaymentMethod().find((p) => p.value === cell).label}</span>
                        </div>)
                    },
                    {
                        id: 'sender',
                        name: "Pagante",
                        formatter: (cell, row) => _(<div className="text-nowrap text-center">
                            <span className="text-nowrap"><b>{cell}</b></span>
                        </div>)
                    },
                    {
                        id: 'value',
                        name: "Importo",
                        formatter: (cell, row) => _(<div className="text-nowrap text-center">
                            <strong>{Utils.getValueMoneyLabel(cell)}</strong><br />
                            {(!this.props.sell) ? <small className="text-primary cursor-pointer" onClick={() => this.props.history.push("/sell/" + row.cells[5].data?.id)}>Dettaglio Vendita</small> : null}
                        </div>)
                    },
                    {
                        id: 'sell',
                        hidden: true
                    },
                    {
                        id: 'transaction_fee',
                        name: "Costi di transazione",
                        formatter: (cell, row) => _(<div className="text-nowrap text-center">
                            {(cell > 0) ? <strong className="text-danger">{Utils.getValueMoneyLabel(cell)}</strong> : <strong>--</strong>}
                        </div>)
                    },
                    {
                        id: 'commission',
                        name: "Provvigione",
                        formatter: (cell, row) => _(<div className="text-nowrap text-center">
                            <strong>{Utils.getValueMoneyLabel(cell)}</strong>
                        </div>)
                    },
                    {
                        id: 'verified',
                        name: "Stato",
                        search: false,
                        formatter: (cell, row) => _(<div className="text-nowrap text-center">
                            {(cell) ? <Badge color="success" className={(Utils.getUserData().role === "ADMIN") ? "cursor-pointer" : undefined} onClick={() => (Utils.getUserData().role === "ADMIN") ? this.unCheckPayment(row.cells[0].data) : undefined}>CONFERMATO</Badge> : <span className={(Utils.getUserData().role === "ADMIN") ? "cursor-pointer" : undefined} onClick={() => (Utils.getUserData().role === "ADMIN") ? this.checkPayment(this.props.payments.find((p) => p.id === row.cells[0].data)) : undefined}><Badge color="warning">DA VERIFICARE</Badge></span>}
                        </div>)
                    },
                    (!this.props.sell) ? {
                        id: 'client',
                        name: "Cliente",
                        formatter: (cell, row) => _(<div className="text-nowrap text-center">
                            <span className="text-nowrap"><a href="javascript:void(0)" onClick={() => this.props.history.push("/client/" + cell?.id)}><b>{cell?.firstname} {cell.lastname}</b></a></span>
                        </div>)
                    } : undefined,
                    (!this.props.sell) ? {
                        id: 'consulent',
                        name: "Consulente",
                        formatter: (cell, row) => _(<div className="text-nowrap">
                            {(cell !== null) ? <span className="text-nowrap">{cell.firstname + ' ' + cell.lastname}</span> : "N/D"}
                        </div>)
                    } : undefined,
                    {
                        id: 'id',
                        name: '',
                        formatter: (cell, row, i) => _(<ul
                            className="list-inline hstack gap-2 mb-0">
                            {(Utils.getUserData().role === "ADMIN" || !row.cells[5].data) ? <li className="list-inline-item cursor-pointer" onClick={() => this.editstart(row.cells[0].data)}>
                                <i className="ri-pencil-fill fs-20 text-info"></i>
                            </li> : null}

                            {(Utils.getUserData().role === "ADMIN" || !row.cells[5].data) ? <li className="list-inline-item cursor-pointer" onClick={() => this.deletePayment(row.cells[0].data)}>
                                <i className="ri-delete-bin-2-fill fs-20 text-danger"></i>
                            </li> : null}
                        </ul>)
                    }
                ]}
                sort={!(this.props.sell)}
                pagination={{enabled: true, limit: 8, page: Params.getActualPage()}}
                search={!(this.props.sell)}
                language={{
                    'search': {
                        'placeholder': '🔍 Cerca...'
                    },
                    'pagination': {
                        'previous': 'Indietro',
                        'next': 'Avanti',
                        'showing': 'Stai visualizzando da',
                        'results': 'Pagamenti',
                        'to': 'a',
                        'of': 'di',
                    },
                    'noRecordsFound': 'Nessun pagamento trovato',
                    'error': 'Si è verificato un errore non previsto',
                }}
            />
        </React.Fragment>
    }
}

export default ListPayment;
