let request = function(id) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');
        fetch(global.route.streamingRequest + "/" + id, {
            headers: {'x-access-token': hash},
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('request streaming', responseJson);
                if (responseJson.status === "REQUEST_STREAMING_OK")
                    resolve(responseJson.file);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let view = function(hashView) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');
        fetch(global.route.streamingView + "/" + hashView, {
            headers: {'x-access-token': hash},
            method: 'GET'
        })
            .then((response) => {
                if (response.status !== 500) {
                    return response.blob();
                } else {
                    throw new Error('Server response with status 500');
                }
            })
            .then((blob) => {
                let url = URL.createObjectURL(blob);
                resolve(url);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};


const StreamingAPI = {
    request: request,
    view: view
};

module.exports = StreamingAPI;
