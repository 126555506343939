import React, {Fragment} from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
    Row,
    Col,
    Label,
    FormFeedback,
    Input,
    FormText
} from 'reactstrap';

import { Utils } from "custom";
import Select from "react-select";
import {CourseAPI, SellAPI} from "api";

const moment = require("moment");

class GestSell extends React.Component {
    constructor(props) {
        super(props);
        let preset = this.props.preset;

        this.state = {
            form: {
                id: preset?.id || null,
                id_course: preset?.id_course || null,
                value: preset?.value || 0,
                enabled: preset?.enabled || false
            },
            courses: [],
            loading: false,
            errors: false
        };
    }

    changeForm(key, text) {
        const form = this.state.form;
        form[key] = text;

        this.setState({form: form}, () => this.showModal());
    }

    getCourses() {

        global.onSetModal({
            content: "",
            loading: true,
            isOpen: true
        })

        CourseAPI.list().then((courses) => {
            let res = [];
            for (let d = 0; d < courses.length; d++) {
                res.push({value: courses[d].id, label: courses[d].name})
            }

            this.setState({courses: res}, () => this.showModal());
        }).catch(() => {
            this.setState({courses: []}, () => this.showModal());
        })
    }

    showModal() {
        const self = this;
        let form = this.state.form;
        let errors = this.state.errors;

        global.onSetModal({
            title: (this.props.preset) ? "MODIFICA VENDITA" : "CREA VENDITA",
            content: <div>
                <Row>
                    <Col md="12">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Corso acquistato
                            </Label>
                            <Select
                                isDisabled={(form.id !== null)}
                                value={this.state.courses[this.state.courses.findIndex((sell) => form.id_course === sell.value)]}
                                placeholder={"Seleziona il corso..."}
                                onChange={(selectedOption, triggeredAction) => {
                                    self.changeForm("id_course", selectedOption.value)
                                }}
                                options={this.state.courses}
                            />
                            {(errors.id_course) ? <span className="text-danger">La selezione del corso è obbligatoria</span> : null}
                        </div>
                    </Col>
                </Row>
                <br />
                <br />
                <Row>
                    <Col md="12">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Valore (€)
                            </Label>
                            <Input type="text" value={form.value}
                                   invalid={Boolean(errors.value)}
                                   onChange={(e) => this.changeForm('value', Number(e.target.value))}/>
                            <FormFeedback>{errors.value}</FormFeedback>
                        </div>
                    </Col>

                </Row>
                <br />
                <Row>
                    <Col md="12">
                        <div className="form-check form-switch form-switch-custom form-switch-success mb-3">
                            <Input className="form-check-input"  type="checkbox" role="switch"  checked={form.enabled}
                                   onChange={(e) => this.changeForm('enabled', e.target.checked)}/>
                            <Label className="form-check-label">Corso Pagato</Label>
                        </div>
                    </Col>
                </Row>
            </div>,
            isOpen: true,
            toggle: false,
            loading: false,
            size: 'lg',
            type: 'info',
            buttons: [
                {label: 'SALVA', color: 'success', onClick: () => this.saveSell()},
                {
                    label: 'CHIUDI',
                    color: 'danger',
                    onClick: () => this.resetForm()
                }
            ]
        })
    }

    resetForm() {
        let preset = this.props.preset;
        this.setState({
            form: {
                id: preset?.id || null,
                id_course: preset?.id_course || null,
                id_client: preset?.id_client || null,
                value: preset?.value || 0,
                enabled: preset?.enabled || false
            },
            errors: false
        });
        global.onSetModal({isOpen: false, loading: false, content: ""})
    }

    saveSell() {
        const self = this;
        let form = this.state.form;

        let error = {};

        if (form.value === '')
            error.value = "Il campo valore è obbligatorio";

        if (form.id_course === null)
            error.id_course = "Seleziona il corso acquistato";

        this.setState({errors: error}, () => this.showModal());

        if (Object.keys(error).length === 0) {
            global.onSetModal({loading: true});

            let add = (form.id === null);
            let api = (add) ? SellAPI.add : SellAPI.edit;

            form["id_client"] = this.props.id_client

            api(form).then(() => {
                global.onSetModal({loading: false});
                global.SweetAlert.fire({
                    title: (add) ? 'Vendita creata' : 'Vendita modificata',
                    text: (add) ? 'La vendita è stata creata correttamente.' : 'La vendita è stata modificata correttamente.',
                    icon: 'success',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    confirmButtonText: 'Chiudi',
                    buttonsStyling: false
                }).then(function (result) {
                    if (result.value) {
                        self.resetForm();
                        self.props.onResolve();
                    }
                })
            }).catch(() => {
                global.onSetModal({loading: false});
                global.SweetAlert.fire({
                    title: 'Errore',
                    text: (add) ? 'Si è verificato un errore durante la creazione della vendita' : 'Si è verificato un errore durante la modifica della vendita',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                });
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                {(this.props.preset) ? <li className="list-inline-item cursor-pointer" onClick={() => this.getCourses()}>
                    <i className="ri-pencil-fill fs-20 text-info"></i>
                </li> : <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => this.getCourses()}>
                    <i className="ri-add-line align-bottom me-1"></i> CREA VENDITA
                </button>}
            </React.Fragment>
        )
    }
}

export default GestSell;
