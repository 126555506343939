import React, { Component } from 'react';
import {Alert, Button, Card, CardBody, CardHeader, Col, Container, Row} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import {withRouter} from "react-router-dom";

import {ListPayment, PaymentSetting, SellSetting, Utils} from "custom";
import {LeadAPI, PaymentAPI} from "api";
import moment from "moment/moment";
import * as XLSX from "xlsx";

class PaymentList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            payments: [],
            paymentsFiltered: [],
            error: false,
            start_date: moment().startOf("month").format("DD-MM-YYYY"),
            end_date: moment().format("DD-MM-YYYY")
        }
    }

    componentDidMount() {
        this.getPayments()
    }

    getPayments(range) {
        let start_date = (range) ? range.start_date : this.state.start_date;
        let end_date = (range) ? range.end_date : this.state.end_date;

        this.setState({
            start_date: start_date,
            end_date: end_date
        })

        PaymentAPI.list(start_date, end_date).then((payments) => {
            console.log(payments)
            this.setState({ loading: false, error: false, payments: payments });
        }).catch(() => {
            this.setState({ error: true });
        })
    }

    onFilterPayments(filterType, filterConsulent, filterMethod) {
        let payments = this.state.payments;

        if (filterConsulent !== null) {
            if (filterConsulent.value === -1) {
                payments = payments.filter((s) => s.consulent === null);
            } else {
                payments = payments.filter((s) => s.consulent?.id === filterConsulent.value);
            }
        }

        if (filterType !== null) {
            payments = payments.filter((s) => (s.verified === filterType.value));
        }

        if (filterMethod !== null) {
            payments = payments.filter((s) => (s.type_payment === filterMethod.value));
        }

        if (filterConsulent === null && filterType === null && filterMethod === null) {
            this.setState({paymentsFiltered: null})
        } else {
            this.setState({paymentsFiltered: payments})
        }
    }

    exportToExcel() {

        let payments = [];

        const fileType =
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        let data = (this.state.paymentsFiltered !== null) ? this.state.paymentsFiltered : this.state.payments;

        for (let i = 0; i < data.length; i++) {
            payments.push({
                id: data[i].id,
                cliente: data[i].client.firstname + " " + data[i].client.lastname,
                pagante: data[i].sender,
                consulente: (data[i].consulent !== null) ? data[i].consulent.firstname + " " + data[i].consulent.lastname : "",
                metodo: Utils.getPaymentMethod().find((p) => data[i].type_payment === p.value).label,
                valore: data[i].value,
                costi: data[i].transaction_fee,
                commissione: data[i].commission
            })
        }

        const ws = XLSX.utils.json_to_sheet(payments);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
        const dataBlob = new Blob([excelBuffer], {type: fileType});

        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(dataBlob);
        downloadLink.download = "payments_" + moment().format('DD-MM-YYYY_HH-mm') + fileExtension;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    render() {
        document.title ="Pagamenti | CLTV";

        if (this.state.error) {
            return <React.Fragment>
                <Alert color="danger" >Si è verificato un errore durante il recupero della lista pagamenti.</Alert>
            </React.Fragment>
        }

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <BreadCrumb title="Pagamenti" pageTitle="CLTV"/>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <PaymentSetting onFilterPayments={this.onFilterPayments.bind(this)} onReload={this.getPayments.bind(this)}/>
                                        <Button color='primary' size="sm" style={{margin: 5}} onClick={() => this.exportToExcel()} disabled={this.state.payments.length === 0}>
                                            <i className="ri-file-excel-2-fill me-1"></i>
                                            ESPORTA
                                        </Button>
                                        <ListPayment history={this.props.history} payments={(this.state.paymentsFiltered !== null) ? this.state.paymentsFiltered : this.state.payments} onReload={this.getPayments.bind(this)}/>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(PaymentList);
