import React, {Component} from 'react';
import {
    Row,
    Col,
    Label,
    FormFeedback,
    Input,
    Badge
} from 'reactstrap';

import {Utils, Loading, GestSell, DeleteSell} from "custom";
import {LeadReminderAPI, PaymentAPI} from "api";

import {Grid, _} from 'gridjs-react';

const moment = require("moment")

class ListReminder extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            errors: false,
            form: {
                id: null,
                date_hour: moment().format("YYYY-MM-DDTHH:mm"),
                note: ""
            }
        };
    }


    changeForm(key, text) {
        const form = this.state.form;
        form[key] = text;
        this.setState({form: form}, () => this.showModal());
    }

    editstart(id) {
        let reminder = this.props.reminders[this.props.reminders.findIndex((reminder) => (reminder.id === id))]
        this.setState({
            form: {
                id: reminder.id,
                date_hour: reminder.date_hour,
                note: reminder.note,
            }
        }, () => this.showModal())
    }

    showModal() {
        const self = this;
        let form = this.state.form;
        let errors = this.state.errors;

        global.onSetModal({
            title: (this.state.form.id !== null) ? "MODIFICA PROMEMORIA" : "AGGIUNGI PROMEMORIA",
            content: <div>
                <Row>
                    <Col md="12">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Data / ora
                            </Label>
                            <Input type="datetime-local" value={moment(form.date_hour).format("YYYY-MM-DDTHH:mm")}
                                   invalid={Boolean(errors.date_hour)}
                                   onChange={(e) => this.changeForm('date_hour', e.target.value)}/>
                            <FormFeedback>{errors.date_hour}</FormFeedback>
                        </div>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col md="12">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Nota
                            </Label>
                            <Input type="textarea" value={form.note}
                                   invalid={Boolean(errors.note)}
                                   onChange={(e) => this.changeForm('note', e.target.value)}/>
                            <FormFeedback>{errors.note}</FormFeedback>
                        </div>
                    </Col>
                </Row>
            </div>,
            isOpen: true,
            toggle: false,
            loading: false,
            size: 'lg',
            type: 'info',
            buttons: [
                {label: 'SALVA', color: 'success', onClick: () => this.saveReminder()},
                {
                    label: 'CHIUDI',
                    color: 'danger',
                    onClick: () => this.resetForm()
                }
            ]
        })
    }

    saveReminder() {
        const self = this;
        let form = this.state.form;

        let error = {};

        if (form.date_hour === "")
            error.date_hour = "Il campo data e ora è obbligatorio";

        this.setState({errors: error}, () => this.showModal());

        if (Object.keys(error).length === 0) {
            global.onSetModal({loading: true});

            let add = (form.id === null);
            let api = (add) ? LeadReminderAPI.add : LeadReminderAPI.edit;

            if (add)
                form["id_lead"] = this.props.id_lead;

            form.date_hour = moment(form.date_hour).format("YYYY-MM-DD HH:mm") + ":00";

            api(form).then(() => {
                global.onSetModal({loading: false});
                global.SweetAlert.fire({
                    title: (add) ? 'Promemoria inserito' : 'Promemoria modificato',
                    text: (add) ? 'Il promemoria è stato caricato correttamente.' : 'Il promemoria è stato modificato correttamente.',
                    icon: 'success',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    confirmButtonText: 'Chiudi',
                    buttonsStyling: false
                }).then(function (result) {
                    if (result.value) {
                        self.resetForm();
                        self.props.onResolve()
                    }
                })
            }).catch((err) => {
                global.onSetModal({loading: false});

                global.SweetAlert.fire({
                    title: 'Errore',
                    text: (add) ? 'Si è verificato un errore durante la creazione del promemoria' : 'Si è verificato un errore durante la modifica del promemoria',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                });
            })
        }
    }

    checkReminder(id) {

        if (Utils.getUserData().role !== "CONSULENT")
            return

        const self = this;

        let reminder = this.props.reminders[this.props.reminders.findIndex((reminder) => (reminder.id === id))];

        global.SweetAlert.fire({
            title: "Completa Promemoria",
            text: "Vuoi veramente contrassegnare il promemoria come completato?",
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Si, conferma',
            cancelButtonText: 'Annulla',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ms-1'
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm: function() {
                return new Promise(function(resolve, reject) {
                    LeadReminderAPI.confirm({
                        id: reminder.id
                    }).then(() => {
                        resolve();
                    }).catch(() => {
                        global.SweetAlert.fire({
                            icon: 'error',
                            title: 'Si è verificato un errore :(',
                            text: 'Purtroppo qualcosa è andato storto e non è stato possibile completare il promemoria. Riprova più tardi.',
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        });
                        reject()
                    })
                });
            },
        }).then(function (result) {
            if (result.value) {
                global.SweetAlert.fire({
                    icon: 'success',
                    title: 'Promemoria completato!',
                    text: 'Promemoria completato correttamente.',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                });
                self.props.onResolve();
            }
        })
    }

    resetForm() {
        this.setState({
            form: {
                id: null,
                id_sell: null,
                value: "",
                sender: ""
            },
            errors: false
        });
        global.onSetModal({isOpen: false, loading: false, content: ""});
    }

    deleteReminder(id) {
        const self = this;
        global.SweetAlert.fire({
            title: "Elimina promemoria",
            text: 'Attenzione, vuoi veramente eliminare il promemoria?',
            icon: 'danger',
            showCancelButton: true,
            confirmButtonText: 'Si, elimina',
            cancelButtonText: 'Annulla',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ms-1'
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm: function() {
                return new Promise(function(resolve, reject) {
                    LeadReminderAPI.remove(id).then(() => {
                        resolve();
                    }).catch(() => {
                        global.SweetAlert.fire({
                            icon: 'error',
                            title: 'Si è verificato un errore :(',
                            text: 'Purtroppo qualcosa è andato storto e non è stato possibile eliminare il promemoria. Riprova più tardi.',
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        });
                        reject()
                    })
                });
            },
        }).then(function (result) {
            if (result.value) {
                global.SweetAlert.fire({
                    icon: 'success',
                    title: 'Promemoria eliminato!',
                    text: 'Promemoria eliminato correttamente.',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                });
                self.props.onResolve();
            }
        })
    }

    render() {
        let totalToPay = 0

        if (this.props.sell) {
            totalToPay = this.props.sell.total;

            for (let i = 0; i < this.props.payments.length; i++) {
                totalToPay = totalToPay - this.props.payments[i].value;
            }
        }

        return <React.Fragment>
            {(Utils.getUserData().role === "CONSULENT") ? <div style={{margin: 10}} className="text-end">
                <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    onClick={() => this.showModal()}>
                    <i className="ri-add-line align-bottom me-1"></i> AGGIUNGI PROMEMORIA
                </button>
            </div> : null}
            <Grid
                data={this.props.reminders}
                columns={[
                    {
                        id: 'id',
                        hidden: true
                    },
                    {
                        id: 'date_hour',
                        name: "Data e ora",
                        formatter: (cell, row) => _(<div className="text-nowrap text-center">
                            <b>{moment(cell).format("DD/MM/YYYY HH:mm")}</b><br />
                            {moment(cell).fromNow()}
                        </div>)
                    },
                    {
                        id: 'note',
                        name: "Nota",
                        formatter: (cell, row) => _(<div className="text-center">
                            {cell}
                        </div>)
                    },
                    {
                        id: 'completed',
                        name: "Stato",
                        search: false,
                        formatter: (cell, row) => _(<div className="text-nowrap text-center cursor-pointer">
                            {(cell) ? <Badge color="success" >COMPLETATO</Badge> : <Badge color="info" onClick={() => this.checkReminder(row.cells[0].data)}>PIANIFICATO</Badge>}
                        </div>)
                    },
                    (Utils.getUserData().role === "CONSULENT") ? {
                        id: 'id',
                        name: '',
                        formatter: (cell, row, i) => _(<ul
                            className="list-inline hstack gap-2 mb-0">
                            <li className="list-inline-item cursor-pointer" onClick={() => this.editstart(cell)}>
                                <i className="ri-pencil-fill fs-20 text-info"></i>
                            </li>

                            <li className="list-inline-item cursor-pointer" onClick={() => this.deleteReminder(cell)}>
                                <i className="ri-delete-bin-2-fill fs-20 text-danger"></i>
                            </li>
                        </ul>)
                    } : undefined
                ]}
                sort={false}
                pagination={{enabled: true, limit: 10}}
                search={false}
                language={{
                    'search': {
                        'placeholder': '🔍 Cerca...'
                    },
                    'pagination': {
                        'previous': 'Indietro',
                        'next': 'Avanti',
                        'showing': 'Stai visualizzando da',
                        'results': 'Promemoria',
                        'to': 'a',
                        'of': 'di',
                    },
                    'noRecordsFound': 'Nessun promemoria trovato',
                    'error': 'Si è verificato un errore non previsto',
                }}
            />
        </React.Fragment>
    }
}

export default ListReminder;
