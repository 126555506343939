import { ConsulentAPI } from 'api';
import React, { Component } from 'react';
import { Alert, Badge, Col, Container, Row, Label, FormFeedback, Input, Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Utils } from '../..';

class CreateConsulent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstname: '',
            lastname: '',
            email: '',
            cell: '',
            id: '',
            active: false,
            consulent: [],
            loading: true,
            error: false,
            errors: null,
            showCreateModal: false,
            showEditModal: false
        };
    }
    createConsulent = async () => {
        try {
            let error = {};

            if (this.state.firstname === '')
                error.firstname = "Il campo nome è obbligatorio";

            if (this.state.lastname === '')
                error.lastname = "Il campo cognome è obbligatorio";

            if (this.state.cell !== '' && !Utils.isValidCell(this.state.cell))
                error.cell = "Il cellulare inserito non è corretto";

            if (this.state.cell === '')
                error.cell = "Il campo cellulare è obbligatorio";

            if (!Utils.isValidEmail(this.state.email))
                error.email = "La mail inserita non è corretta";

            if (this.state.email === '')
                error.email = "Il campo email è obbligatorio";

            this.setState({ errors: error })
            if (Object.keys(error)?.length === 0) {
                const user = {
                    firstname: this.state.firstname,
                    lastname: this.state.lastname,
                    email: this.state.email,
                    cell: this.state.cell
                }
                ConsulentAPI.add(user).then((data) => {
                    this.setState({ showCreateModal: false });
                    this.props.onResolve();
                }).catch((err) => {
                    console.log('err', err);
                    this.setState({ error: true });
                })
            }
        } catch (err) {
            console.log('err', err);
            this.setState({ error: true });
        }
    }
    render() {
        let errors = this.state.errors;
        return (
            <>
                <button
                    type="button"
                    className="btn btn-success btn-sm"
                    onClick={() => this.setState({ showCreateModal: true })}
                >
                    <i className="ri-add-line align-bottom me-1"></i> CREA CONSULENTE
                </button>
                <Modal style={{ overflowY: "auto" }} isOpen={this.state.showCreateModal} size="lg" scrollable={true} className={'modal-dialog-scrollable modal-md'}>
                    <ModalBody className='px-sm-5 pt-5 pb-50'>
                        <div className='text-center mb-5'>
                            <h3 className='mb-1'>CREA CONSULENTE</h3>
                        </div>
                        <div>
                            <Row>
                                <Col md="6">
                                    <div className='mb-2'>
                                        <Label className='form-label'>
                                            Nome
                                        </Label>
                                        <Input
                                            type="text"
                                            value={this.state.firstname}
                                            invalid={Boolean(errors?.firstname)}
                                            onChange={(e) => this.setState({ firstname: e.target.value })}
                                        />
                                        <FormFeedback>{errors?.firstname}</FormFeedback>
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className='mb-2'>
                                        <Label className='form-label'>
                                            Cognome
                                        </Label>
                                        <Input
                                            type="text"
                                            value={this.state.lastname}
                                            invalid={Boolean(errors?.lastname)}
                                            onChange={(e) => this.setState({ lastname: e.target.value })}
                                        />
                                        <FormFeedback>{errors?.lastname}</FormFeedback>
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className='mb-2'>
                                        <Label className='form-label'>
                                            Email
                                        </Label>
                                        <Input
                                            type="email"
                                            value={this.state.email}
                                            invalid={Boolean(errors?.email)}
                                            onChange={(e) => this.setState({ email: e.target.value })}
                                        />
                                        <FormFeedback>{errors?.email}</FormFeedback>
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className='mb-3'>
                                        <Label className='form-label'>
                                            Telefono
                                        </Label>
                                        <Input
                                            type="tel"
                                            value={this.state.cell}
                                            invalid={Boolean(errors?.cell)}
                                            // onChange={(e)=> console.log(e.target.value)}
                                            onChange={(e) => this.setState({ cell: e.target.value })}
                                        />
                                        <FormFeedback>{errors?.cell}</FormFeedback>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color='success'
                            onClick={this.createConsulent}
                        >
                            SALVA
                        </Button>
                        <Button
                            color='danger'
                            onClick={() => this.setState({
                                firstname: "",
                                lastname: "",
                                email: "",
                                cell: "",
                                showCreateModal: false
                            })}
                        >
                            CHIUDI
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}
export default CreateConsulent
