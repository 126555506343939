import React, {Fragment} from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
    Row,
    Col,
    Label,
    FormFeedback,
    Input,
    FormText, Badge
} from 'reactstrap';

import {Utils} from "custom";
import {ModuleAPI} from "api";
import Select from "react-select";
import logoTikTok from "../../../assets/images/logo-tiktok.png";

const moment = require("moment")

const referral_list = [
    {value: "META", label: "META", icon: "ri-facebook-box-fill align-middle fs-20 text-secondary"},
    {value: "FUNNEL", label: "FUNNEL", icon: "ri-global-fill align-middle fs-20 text-primary"},
    {value: "REPORT", label: "SEGNALAZIONE", icon: "mdi mdi-bullhorn align-middle fs-20 text-danger"},
    {value: "WHATSAPP", label: "WHATSAPP", icon: "ri-whatsapp-fill align-middle fs-20 text-success"},
    {value: "TIK_TOK", label: "TIK TOK", icon: ''},
]

class GestModule extends React.Component {
    constructor(props) {
        super(props);
        let preset = this.props.preset;

        this.state = {
            form: {
                id: (preset) ? preset.id : null,
                name: (preset) ? preset.name : "",
                referral: (preset) ? referral_list[referral_list.findIndex((r) => r.value === preset.referral)] : null,
                consulents: (preset) ? preset.consulents.map((c) => {return {value: c.id, label: c.firstname + " " + c.lastname}}) : [],
            },
            loading: false,
            errors: false
        };
    }

    changeForm(key, text) {
        const form = this.state.form;
        form[key] = text;
        this.setState({form: form}, () => this.showModal());
    }

    showModal() {

        let form = this.state.form;
        let errors = this.state.errors;

        global.onSetModal({
            title: (this.props.preset) ? "MODIFICA MODULO" : "CREA MODULO",
            content: <div>
                <Row>
                    <Col>
                        <Select
                            value={this.state.form.consulents}
                            isMulti
                            isClearable
                            isSearchable
                            name="Consulenti"
                            placeholder="Seleziona i consulenti..."
                            options={this.props.consulents}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            closeMenuOnSelect={false}
                            onChange={(c) => {
                                this.changeForm("consulents", c)
                            }}
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Seleziona provenienza
                            </Label>
                            <Select
                                value={this.state.form.referral}
                                options={referral_list}
                                onChange={(val) => {
                                    this.changeForm('referral', val)
                                }}
                                formatOptionLabel={referral => (
                                    <div className="referral-option">
                                        {(referral.value === "TIK_TOK") ? <img src={logoTikTok} alt={'TIK TOK'} width="20px"/> : <i className={referral.icon}/>}
                                        <span>&nbsp;&nbsp;{referral.label}</span>
                                    </div>
                                )}
                            ></Select>
                            <Input invalid={Boolean(errors?.referral)} hidden/>
                            <FormFeedback>{errors?.referral}</FormFeedback>
                        </div>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Nome
                            </Label>
                            <Input type="text" value={form.name}
                                   invalid={Boolean(errors.name)}
                                   onChange={(e) => this.changeForm('name', e.target.value)}/>
                            <FormFeedback>{errors.name}</FormFeedback>
                        </div>
                    </Col>
                </Row>
            </div>,
            isOpen: true,
            toggle: false,
            size: 'lg',
            type: 'info',
            buttons: [
                {label: 'SALVA', color: 'success', onClick: () => this.saveModule()},
                {
                    label: 'CHIUDI',
                    color: 'danger',
                    onClick: () => this.resetForm()
                }
            ]
        })
    }

    resetForm() {
        let preset = this.props.preset;

        this.setState({
            form: {
                id: (preset) ? preset.id : null,
                name: (preset) ? preset.name : "",
                referral: (preset) ? referral_list[referral_list.findIndex((r) => r.value === preset.referral)] : null,
                consulents: (preset) ? preset.consulents.map((c) => {return {value: c.id, label: c.firstname + " " + c.lastname}}) : [],
            }
        })
        global.onSetModal({isOpen: false, loading: false, content: ""})
    }

    saveModule() {
        const self = this;
        let form = this.state.form;
        let error = {};

        if (form.name === "")
            error.name = "Il campo nome è obbligatorio";

        if (form.referral === null)
            error.referral = "Il campo referral è obbligatorio";

        this.setState({errors: error}, () => this.showModal());

        if (Object.keys(error).length === 0) {
            global.onSetModal({loading: true});

            let add = (form.id === null);
            let api = (add) ? ModuleAPI.add : ModuleAPI.edit;

            api({
                id: form.id,
                name: form.name,
                referral: form.referral.value,
                consulents: form.consulents.map(c => c.value)
            }).then(() => {
                global.onSetModal({loading: false});
                global.SweetAlert.fire({
                    title: (add) ? 'Modulo creato' : 'Modulo modificato',
                    text: (add) ? 'Il modulo è stato creato correttamente.' : 'Il modulo è stato modificato correttamente.',
                    icon: 'success',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    confirmButtonText: 'Chiudi',
                    buttonsStyling: false
                }).then(function (result) {
                    if (result.value) {
                        self.resetForm();
                        self.props.onResolve();
                    }
                })
            }).catch((err) => {
                global.onSetModal({loading: false});
                global.SweetAlert.fire({
                    title: 'Errore',
                    text: (add) ? 'Si è verificato un errore durante la creazione del modulo' : 'Si è verificato un errore durante la modifica del modulo',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                });
            })
        }
    }


    render() {
        return (
            <React.Fragment>
                {(this.props.preset) ? <li className="list-inline-item cursor-pointer" onClick={() => this.showModal()}>
                    <i className="ri-pencil-fill fs-20 text-info"></i>
                </li> : <button
                    type="button"
                    className="btn btn-sm btn-success add-btn"
                    onClick={() => this.showModal()}>
                    <i className="ri-add-line align-bottom me-1"></i> CREA MODULO
                </button>}
            </React.Fragment>
        )
    }
}

export default GestModule;
