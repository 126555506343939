import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import {withRouter} from "react-router-dom";

import AdministratorTable from "./AdministratorTable";

import {AddAdmin} from "custom";

class AdministratorList extends Component {

    render() {
        document.title ="Amministratori | CLTV Cloud";
        console.log(this.props);
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <BreadCrumb title="Amministratori" pageTitle="CLTV Cloud"/>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <AdministratorTable />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(AdministratorList);
