import React, { Component } from 'react';
import {Alert, Badge, Button, Col, Container, FormFeedback, Input, Label, NavLink, Row} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import {withRouter, Link} from "react-router-dom";
import { Grid, _ } from 'gridjs-react';

import {DeleteAdmin, GestAdmin, Loading, Params, Utils} from "custom";
import {AuthAPI, ClientAPI, UserAPI} from "api";


class ClientTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clients: [],
            alerts: [],
            loading: true,
            error: false
        };
    }

    componentDidMount() {
        this.getClients();
    }

    componentWillUnmount() {
        return Params.removeHandlePaginator()
    }

    getClients() {
        ClientAPI.list().then((clients) => {
            let alerts = [];
            for (let i = 0; i < clients.length; i++) {
                if (clients[i].sessions > 5)
                    alerts.push({name: clients[i].firstname + " " + clients[i].lastname, sessions: clients[i].sessions});
            }
            this.setState({loading: false, error: false, clients: clients, alerts: alerts});
            setTimeout(() => {
                Params.addHandlePaginator()
            }, 1000)
        }).catch((err) => {
            console.error(err)
            this.setState({error: true});
        })
    }

    resendEmailSignup(id) {
        const self = this;
        global.SweetAlert.fire({
            title: 'Vuoi reinviare la mail di registrazione?',
            text: "Stai per reinviare una mail all'utente con il link per la registrazione, vuoi procedere?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, conferma',
            cancelButtonText: 'Annulla',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ms-1'
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm: function() {
                return new Promise(function(resolve, reject) {
                    UserAPI.resendSignup(id).then(() => {
                        resolve();
                    }).catch(() => {
                        global.SweetAlert.fire({
                            icon: 'error',
                            title: 'Si è verificato un errore :(',
                            text: 'Purtroppo qualcosa è andato storto e non è stato possibile inviare nuovamente il codice di registrazione. Riprova più tardi.',
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        });
                        reject()
                    })
                });
            },
        }).then(function (result) {
            if (result.value) {
                global.SweetAlert.fire({
                    icon: 'success',
                    title: 'Email registrazione inviata!',
                    text: 'È stata inviata la mail di registrazione all\'utente.',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                });
                self.getClients();
            }
        })
    }


    disconnectUser(id, sessions) {
        if (sessions === 0)
            return;

        const self = this;
        global.SweetAlert.fire({
            title: 'Vuoi eliminare le sessioni attive ?',
            text: "Con questa azione l'utente dovrà rifare login in tutti i dispositivi.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, conferma',
            cancelButtonText: 'Annulla',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ms-1'
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm: function() {
                return new Promise(function(resolve, reject) {
                    AuthAPI.clearSession(id).then(() => {
                        resolve();
                    }).catch(() => {
                        global.SweetAlert.fire({
                            icon: 'error',
                            title: 'Si è verificato un errore :(',
                            text: 'Purtroppo qualcosa è andato storto e non è stato possibile rimuovere le sessioni. Riprova più tardi.',
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        });
                        reject()
                    })
                });
            },
        }).then(function (result) {
            if (result.value) {
                global.SweetAlert.fire({
                    icon: 'success',
                    title: 'Sessioni utente eliminate correttamente!',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                });
                self.getClients();
            }
        })
    }


    render() {

        const self = this;

        if (this.state.error) {
            return <React.Fragment>
                <Alert color="danger" >Si è verificato un errore durante il recupero della lista clienti.</Alert>
            </React.Fragment>
        }

        const isAdmin = (Utils.getUserData().role === "ADMIN");

        return (
            <React.Fragment>
                {(this.state.loading) ? <Loading /> : <React.Fragment>
                    {(this.state.alerts.map((session, i) => {
                        return <Alert key={i} color="warning" ><b>{session.name}</b> ha <b>{session.sessions}</b> sessioni attive negli ultimi 7 giorni</Alert>
                    }))}
                    <Grid
                    data={this.state.clients}
                    columns={[
                        {
                            id: 'id',
                            name: '#',
                            formatter: (cell, row) => _(
                                <span className="text-nowrap">{cell}</span>
                            ),
                            width: "50px"
                        },
                        {
                            id: 'country',
                            formatter: (cell, row) => _(<span className="text-nowrap"><div className="text-center"><img src={Utils.getFlagByCountry(cell)} width="25"/></div></span>)
                        },
                        {
                            id: 'name',
                            formatter: (cell, row) => _(
                                <span className="text-nowrap"><a href="javascript:void(0)" onClick={() => self.props.history.push("/client/" + row.cells[0].data)}><b>{cell}</b></a></span>
                            ),
                            name: "Nome"
                        },
                        {
                            id: 'sessions',
                            name: "Sessioni",
                            formatter: (cell, row) => _(<span className="text-nowrap cursor-pointer"><Badge color="info" onClick={() => self.disconnectUser(row.cells[0].data, cell)}><b>{cell}</b></Badge></span>)
                        },
                        {
                            id: "isRegistered",
                            hidden: true
                        },
                        (isAdmin) ? {
                            id: 'consulent',
                            name: "Consulente",
                            formatter: (cell, row) => _(<div className="d-flex flex-column text-center text-nowrap">
                                {(cell !== null) ? cell.firstname + " " + cell.lastname : ""}
                            </div>),
                        } : undefined,
                        {
                            id: 'cell',
                            name: "Cellulare",
                            formatter: (cell, row) => _(<div className="text-nowrap">
                                <a href={"tel:" + cell}>{cell.slice(0, 3) + " " + cell.slice(3) }</a>
                            </div>)
                        },
                        {
                            id: 'email',
                            name: "Email",
                            formatter: (cell, row) => _(<div className="text-nowrap">
                                <a href={"mailto:" + cell} target="_blank">{cell}</a>
                            </div>)
                        },
                        {
                            id: 'active',
                            name: 'Stato',
                            formatter: (cell, row) => _(<span className="text-nowrap">
                                {(cell) ? <Badge color="success" >Attivo</Badge> : ((row.cells[4].data) ? <Badge color="danger" >Non Attivo</Badge> : <Badge color="warning" className="cursor-pointer" onClick={() => this.resendEmailSignup(row.cells[0].data)}>In attesa registrazione</Badge>)}
                            </span>)
                        },
                    ]}
                    style={{table: {'white-space': 'nowrap'}}}
                    resizable={true}
                    sort={true}
                    search={true}
                    pagination={{ enabled: true, limit: 8, page: Params.getActualPage()}}
                    language={{
                        'search': {
                            'placeholder': '🔍 Cerca...'
                        },
                        'pagination': {
                            'previous': 'Indietro',
                            'next': 'Avanti',
                            'showing': 'Stai visualizzando da',
                            'results': 'Clienti',
                            'to': 'a',
                            'of': 'di',
                        },
                        'noRecordsFound': 'Nessun cliente trovato',
                        'error': 'Si è verificato un errore non previsto',
                    }}
                /></React.Fragment>}
            </React.Fragment>
        );
    }
}

export default withRouter(ClientTable);
