let list = function(filter) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');
        let route = "";

        if (typeof filter !== "undefined") {
            route = "?";

            if (typeof filter.search !== "undefined")
                route = route + "search=" + filter.search
        }

        fetch(global.route.client + route, {
            headers: {'x-access-token': hash},
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                // global.checkAuth(responseJson.status);
                console.log('client list', responseJson);
                if (responseJson.status === "LIST_CLIENTS")
                    resolve(responseJson.list);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let detail = function(id) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');
        fetch(global.route.client + "/" + id, {
            headers: {'x-access-token': hash},
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('client detail', responseJson);
                if (responseJson.status === "CLIENT_DETAIL_OK")
                    resolve(responseJson.client);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let add = function(client) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.client, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify({
                firstname: client.firstname,
                lastname: client.lastname,
                vat_id: client.vat_id,
                cell: client.cell,
                email: client.email,
                country: client.country,
                fiscal_code: client.fiscal_code,
                full_address: client.full_address,
                active: client.active,
                job: client.job,
                shift: client.shift,
                details: client.details,
                id_lead: client.id_lead ?? null
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : console.log(response))
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('client add', responseJson);
                if (responseJson.status === "CLIENT_CREATED")
                    resolve(responseJson.user);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let edit = function(client) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.client, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                id: client.id,
                firstname: client.firstname,
                lastname: client.lastname,
                vat_id: client.vat_id,
                cell: client.cell,
                email: client.email,
                country: client.country,
                fiscal_code: client.fiscal_code,
                full_address: client.full_address,
                job: client.job,
                shift: client.shift,
                details: client.details,
                active: client.active
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                 global.checkAuth(responseJson.status);
                console.log('client edit', responseJson);
                if (responseJson.status === "CLIENT_EDITED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let editQuestion = function(id, details) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');
        fetch(global.route.clientQuestion, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                id: id,
                details: details
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : global.printError(response))
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('client edit questions', responseJson);
                if (responseJson.status === "CLIENT_EDITED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let remove = function(id) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.client, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'DELETE',
            body: JSON.stringify({
                id: id
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('client delete', responseJson);
                if (responseJson.status === "CLIENT_DELETED")
                    resolve(responseJson.client);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

const ClientAPI = {
    list: list,
    detail: detail,
    add: add,
    edit: edit,
    editQuestion: editQuestion,
    remove: remove
};

module.exports = ClientAPI;
