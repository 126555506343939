import React, { useEffect, useState } from 'react';
import { Alert, Badge, Card, CardBody } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { Grid, _ } from 'gridjs-react';

import {Loading, Utils, DeleteReporter, EditReporter, ContactReporter, Params, TransferNetworker} from 'custom';
import {ReporterAPI, ReportAPI, UserAPI, NetworkAPI} from 'api';

const ReporterTabNetworker = ({ history, onClickCommission, tabSelected }) => {
    const [reporters, setReporters] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const [users, setUsers] = useState([])

    useEffect(() => {
        document.title = 'Network | CLTV';
        getReporters();
        return () => {
            Params.removeHandlePaginator()
        };
    }, [tabSelected]);



    const getReporters = () => {

        NetworkAPI.listReporter().then((rep) => {
                setReporters(rep);
                setLoading(false);
                setError(false);
                setTimeout(() => {
                    Params.addHandlePaginator()
                }, 1000)
            })
            .catch(() => {
                setError(true);
            });
    };


    if (error) {
        return (
            <React.Fragment>
                <Alert color="danger">Si è verificato un errore durante il recupero della lista dei segnalatori.</Alert>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <div className="flex-shrink-0" style={{ marginBottom: 10 }}>
                <TransferNetworker onResolve={getReporters}/>
            </div>
            {loading ? (
                <Loading />
            ) : (
                <div>
                    <Grid
                        data={reporters.sort((a, b) => b.reports_n - a.reports_n)}
                        columns={[
                            {
                                id: 'id',
                                hidden: true
                            },
                            {
                                id: 'name',
                                name: 'Nome',
                                formatter: (cell, row) => _(
                                    <div className='text-left'>
                                         <b>{cell}</b>
                                    </div>
                                )
                            },
                            {
                                id: 'reports_n',
                                name: 'Segnalazioni',
                                sort: true,
                                formatter: (cell, row) => _(<div className="text-nowrap text-center">
                                    <Badge className="cursor-pointer" color="secondary" onClick={() => onClickCommission(row.cells[0].data)} style={{fontSize: 11}}><b>{cell}</b></Badge>
                                </div>)
                            }
                        ]}
                        style={{ table: { 'white-space': 'nowrap' } }}
                        resizable={true}
                        search={true}
                        sort={true}
                        pagination={{ enabled: true, limit: 25, page: Params.getActualPage() }}
                        language={{
                            'search': {
                                'placeholder': '🔍 Cerca...'
                            },
                            'pagination': {
                                'previous': 'Indietro',
                                'next': 'Avanti',
                                'showing': 'Stai visualizzando da',
                                'results': 'Affiliati',
                                'to': 'a',
                                'of': 'di',
                            },
                            'noRecordsFound': 'Nessun affiliato trovato',
                            'error': 'Si è verificato un errore non previsto',
                        }}
                    />
                </div>
            )}
        </React.Fragment>
    );
};

export default withRouter(ReporterTabNetworker);
