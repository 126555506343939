import React, {Fragment} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert, Row, Col, Label, FormFeedback, Input, Spinner} from 'reactstrap';

import Select, {components} from "react-select";

import {Utils} from "custom";
import {GeoAPI, MonitorAPI} from "api";

import { debounce } from "lodash";

class GeoForm extends React.Component {
    constructor(props) {
        super(props);
        let preset = this.props.preset;

        this.state = {
            loadingGps: false,
            result: {
                full_address: (preset) ? preset.full_address : "",
                address: (preset) ? preset.address : "",
                lat: (preset) ? preset.lat : "",
                lon: (preset) ? preset.lon : "",
                city: (preset) ? preset.city : "",
                region: (preset) ? preset.region : "",
                province: (preset) ? preset.province : "",
                cap: (preset) ? preset.cap : ""
            },
            addressSuggestion: [],
            loading: false,
            error: false
        };
    }

    resetForm() {
        let result = {
            full_address: "",
            address: "",
            lat: "",
            lon: "",
            city: "",
            region: "",
            province: "",
            cap: ""
        };
        this.setState({result: result});
        this.props.onResolve(result);
    }

    changeFormResult(key, text) {
        const form = this.state.result;
        if (key === "cap")
            if (text.length > 5)
                return;

        form[key] = text;
        this.setState({result: form});
        this.props.onResolve(form);
    }

    onSelectAddress(suggestion) {
        this.setState({loading: true});
        GeoAPI.detail(suggestion.value).then((result) => {
            let res = {
                full_address: result.fullAddress,
                address: result.address,
                lat: result.lat,
                lon: result.lon,
                city: result.city,
                region: result.region,
                province: result.province,
                cap: result.cap,
                placeId: suggestion.value
            };

            this.setState({loading: false, error: false, result: res});
            this.props.onResolve(res);
        }).catch(() => {
            this.setState({error: true, loading: false});
        });
    }

    onUseGPS() {
        const self = this;
        self.setState({loadingGps: true});
        navigator.geolocation.getCurrentPosition(function(position) {
            GeoAPI.geoGps({lat: position.coords.latitude, lon: position.coords.longitude }).then((place) => {
                let res = {
                    full_address: place.full_address,
                    address: place.address,
                    lat: place.lat,
                    lon: place.lon,
                    city: place.city,
                    region: place.region,
                    province: place.province,
                    cap: place.cap
                };

                self.setState({loadingGps: false, error: false, addressSuggestion: [], result: res});
                self.props.onResolve(res);
            }).catch((err) => {
                self.setState({error: true, loadingGps: false});
            })
        });
    }

    render() {
        const self = this;
        const marker = this.state.result;
        let errors = this.props.errors;


        let full_address = (typeof this.props.preset !== "undefined") ? this.props.preset.full_address : ((this.state.result.full_address === "") ? "Cerca indirizzo" : this.state.result.full_address);

        const onChangeGeoForm = debounce((search) => {
            if (search === "")
                return;
            this.setState({loading: true});
            GeoAPI.autocomplete(search, "address").then((suggestions) => {
                let options = [];
                for (let i = 0; i < suggestions.length; i++) {
                    options.push({value: suggestions[i].place_id, label: suggestions[i].fullAddress.replace(", ", " ")})
                }
                self.setState({loading: false, error: false, addressSuggestion: options});
            }).catch(() => {
                self.setState({error: true, loading: false});
            });
        }, 1500);

        const Control = ({ children, ...props }) => (
            <components.Control {...props}>
                <Button color="dark" outline className="btn btn-ghost-dark" onClick={() => this.onUseGPS()}>
                    {(self.state.loadingGps) ? <Spinner animation="border" size="sm"/> : <i className="ri-navigation-fill align-middle"></i>}
                </Button> {children}
            </components.Control>
        );

        return (
            <React.Fragment>
                <Select
                    // components={{ Control }}
                    placeholder={full_address}
                    onChange={(selectedOption, triggeredAction) => {
                        if (triggeredAction.action === 'clear')
                            self.setState({addressSuggestion: []}, () => self.resetForm());
                        else
                            self.onSelectAddress(selectedOption);
                    }}
                    onInputChange={(search) => onChangeGeoForm(search)}
                    options={this.state.addressSuggestion}
                    isLoading={this.state.loading}
                    isClearable={true}
                />
                {(this.state.error) ? <Alert color="danger">Si è verificato un errore durante il recupero dell'indirizzo</Alert> : null}
                <br />
                {(!this.props.hideDetail) ? <div>
                    <Row>
                        <Col md="6">
                            <div className='mb-2'>
                                <Label className='form-label'>
                                    Lat
                                </Label>
                                <Input type="number" value={marker.lat}
                                       onChange={(e) => this.changeFormResult('lat', e.target.value)}
                                       invalid={Boolean(errors.lat)}/>
                                <FormFeedback>{errors.lat}</FormFeedback>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className='mb-2'>
                                <Label className='form-label'>
                                    Lon
                                </Label>
                                <Input type="number" value={marker.lon}
                                       onChange={(e) => this.changeFormResult('lon', e.target.value)}
                                       invalid={Boolean(errors.lon)}/>
                                <FormFeedback>{errors.lon}</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <div className='mb-2'>
                                <Label className='form-label'>
                                    Città
                                </Label>
                                <Input type="text" value={marker.city}
                                       onChange={(e) => this.changeFormResult('city', e.target.value)}
                                       invalid={Boolean(errors.city)}/>
                                <FormFeedback>{errors.city}</FormFeedback>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className='mb-2'>
                                <Label className='form-label'>
                                    Provincia
                                </Label>
                                <Input type="text" value={marker.province} maxLength={2}
                                       onChange={(e) => this.changeFormResult('province', e.target.value.toUpperCase())}
                                       invalid={Boolean(errors.province)}/>
                                <FormFeedback>{errors.province}</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <div className='mb-2'>
                                <Label className='form-label'>
                                    Regione
                                </Label>
                                <Input type="text" value={marker.region}
                                       onChange={(e) => this.changeFormResult('region', e.target.value)}
                                       invalid={Boolean(errors.region)}/>
                                <FormFeedback>{errors.region}</FormFeedback>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className='mb-2'>
                                <Label className='form-label'>
                                    CAP
                                </Label>
                                <Input type="number" value={marker.cap}
                                       onChange={(e) => this.changeFormResult('cap', e.target.value)}
                                       invalid={Boolean(errors.cap)}/>
                                <FormFeedback>{errors.cap}</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                </div> : null}
            </React.Fragment>
        )
    }
}

export default GeoForm;
