import React, {Fragment} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert, Row, Col, Label, FormFeedback, Input} from 'reactstrap';

import {Utils} from "custom";
import { LeadLogAPI } from "api";

class EditLeadLog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logedit: {
                id:null,
                note:null
            },
            loading: false,
            errors: false
        };
    }

    componentDidMount() {
        console.log('preset',this.props.preset);
        this.setState({logedit:this.props.preset})
    }

    changeForm(key, text) {
        const form = this.state.logedit;
        form[key] = text;
        this.setState({form: form}, () => this.showModal());
    }

    resetForm() {
        // this.setState({
        //     logedit: {
        //         id:null,
        //         note:null
        //     },
        // }, () => {
            global.onSetModal({ isOpen: false });
        // });
    }

    showModal() {
        let form = this.state.logedit;
        let errors = this.state.errors;
        console.log('form',form);
        global.onSetModal({
            title: this.props.label,
            content: <div>
                <Row>
                    <Col md="12">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Nome
                            </Label>
                            <textarea
                                className="form-control bg-light border-light"
                                value={form.note}
                                rows="3"
                                placeholder="Inserisci nota"
                                onChange={(e) => this.changeForm('note', e.target.value)}
                            >
                            </textarea>
                            {/* <Input type="tectarea" value={form.note}
                                   invalid={Boolean(errors.note)}
                                   onChange={(e) => this.changeForm('note', e.target.value)}/> */}
                            <FormFeedback>{errors.note}</FormFeedback>
                        </div>
                    </Col>

                </Row>
            </div>,
            isOpen: true,
            toggle: false,
            size: 'lg',
            type: 'info',
            buttons: [
                {label: 'SALVA', color: 'success', onClick: () => this.saveEditLog()},
                {
                    label: 'CHIUDI',
                    color: 'danger',
                    onClick: () => this.resetForm()
                },
            ]
        })
    }



    saveEditLog() {
        const self = this;
        let form = this.state.logedit;
        let error = {};

        if (form.note === '')
            error.note = "Il campo note è obbligatorio";

        this.setState({errors: error}, () => this.showModal());

        if (Object.keys(error).length === 0) {
            global.onSetModal({loading: true});

            LeadLogAPI.edit(form).then(() => {
                global.onSetModal({loading: false});
                global.SweetAlert.fire({
                    title: 'Nota modificata',
                    text: 'La nota è stata modificata correttamente.',
                    icon: 'success',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    confirmButtonText: 'Chiudi',
                    buttonsStyling: false
                }).then(function (result) {
                    if (result.value) {
                        self.props.onResolve();
                        self.resetForm()
                    }
                })
            }).catch(() => {
                global.onSetModal({loading: false});
                global.SweetAlert.fire({
                    title: 'Errore',
                    text: 'Si è verificato un errore durante la modifica della nota',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                });
            })
        }
    }


    render() {
        return (
            <React.Fragment>
                <li className="list-inline-item cursor-pointer"
                    onClick={() => this.showModal()}>
                    <i className="ri-pencil-fill fs-20 text-info"></i>
                </li>
            </React.Fragment>
        )
    }
}

export default EditLeadLog;
