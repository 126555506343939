import React, { Component } from 'react';
import {Card, CardBody, CardHeader, Col, Container, Row} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import {withRouter, Link} from "react-router-dom";
import ClientTable from "./ClientTable";

import { Utils } from "custom";

class ClientList extends Component {

    constructor(props) {
        super(props);
        document.title ="Lista Clienti | CLTV Cloud";
    }

    render() {

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <BreadCrumb title="Clienti" pageTitle="CLTV Cloud"/>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardHeader>
                                        {(Utils.getUserData().role === "ADMIN") ? <Link to={"/client/add"}>
                                            <button
                                                type="button"
                                                className="btn btn-success btn-sm">
                                                <i className="ri-add-line align-bottom me-1"></i> CREA CLIENTE
                                            </button>
                                        </Link> : null}
                                    </CardHeader>
                                    <CardBody>
                                        <ClientTable />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(ClientList);
