import React, {Fragment} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert} from 'reactstrap';
import {AdminAPI, ClassRoomAPI} from "api";

import { Utils } from "custom";

class RevertClassroom extends React.Component {
    constructor(props) {
        super(props);
    }

    revertClassroom() {
        const self = this;
        global.SweetAlert.fire({
            title: "Ripristina Bozza",
            text: 'Vuoi veramente ripristinare la classe in modalità bozza? Assicurati prima che tutti i partecipanti siano in lista di attesa.',
            icon: 'danger',
            showCancelButton: true,
            confirmButtonText: 'Si, ripristina',
            cancelButtonText: 'Annulla',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ms-1'
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm: function() {
                return new Promise(function(resolve, reject) {
                    ClassRoomAPI.revert(self.props.classroom.id).then(() => {
                        resolve();
                    }).catch((err) => {
                        global.SweetAlert.fire({
                            icon: 'error',
                            title: 'Si è verificato un errore :(',
                            text: (err.status === "REVERT_NOT_ALLOWED") ? 'Non è permesso ripristinare la bozza di una classe con partecipanti.' : 'Purtroppo qualcosa è andato storto e non è stato possibile ripristinare la bozza. Riprova più tardi.',
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        });
                        reject()
                    })
                });
            },
        }).then(function (result) {
            if (result.value) {
                global.SweetAlert.fire({
                    icon: 'success',
                    title: 'Bozza ripristinata!',
                    text: 'Classe ripristinata in bozza.',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                });
                self.props.onResolve();
            }
        })
    }


    render() {
        return (
            <React.Fragment>
                <button
                    type="button"
                    className="btn btn-danger btn-sm"
                    onClick={() => this.revertClassroom()}>
                    <i className="ri-recycle-fill align-bottom me-1"></i> RIPRISTINA BOZZA
                </button>
            </React.Fragment>
        )
    }
}

export default RevertClassroom;
