import React, { useEffect, useState } from 'react';
import {Alert, Badge, Card, CardBody, Col, Label, Row} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { Grid, _ } from 'gridjs-react';

import { Loading, Utils } from 'custom';
import {ReporterAPI, ReportAPI, UserAPI, NetworkAPI} from 'api';
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import {Italian} from "flatpickr/dist/l10n/it";
import moment from "moment/moment";

const ReportTabNetworker = ({ history, setCounter }) => {
    const [reports, setReports] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        getReports();
    }, []);

    const getReports = async () => {

        NetworkAPI.listReport()
            .then((reports) => {

                let countRevenue = 0;
                let countCommission = 0;

                for (let i = 0; i < reports.length; i++) {
                    countRevenue = countRevenue + reports[i].totalRevenue
                    if (reports[i].status_credit === "ACCREDITED" || reports[i].status_credit === "PAYED")
                        countCommission = countCommission + reports[i].commission
                }

                setCounter({revenue: countRevenue, commission: countCommission})

                setReports(reports);
                setLoading(false);
                setError(false);
            })
            .catch(() => {
                setError(true);
            });
    };


    const getStatus = (report) => {

        if (report.status_credit === "TO_ACCREDIT")
            return <div className="custom-badge status-waiting">Da Accreditare <i className="mdi mdi-piggy-bank"></i></div>;

        if (report.status_credit === "ACCREDITED")
            return <div className="custom-badge status-waiting">Da Bonificare <i className="mdi mdi-bank-transfer-in"></i></div>;

        if (report.status_credit === "PAYED")
            return <div className="custom-badge status-converted" >Bonificato <i className="mdi mdi-bank-check"></i></div>;

        switch (report.status_lead) {
            case null:
                return (report.consulent === null) ? <div className="custom-badge status-unassigned" >Non assegnato</div> : <div className="custom-badge status-unassigned" >In attesa</div>;
            case "WORKING":
                return <div className="custom-badge status-working" >In lavorazione</div>;
            case "TRIAL":
                return <span><div className="custom-badge status-trial" >In prova</div></span>;
            case "CONVERTED":
                return <div className="custom-badge status-working" >Venduto</div>;
            case "APPOINTMENT":
                return <div className="custom-badge status-appointment" >Da Richiamare</div>;
            case "UNREPLY":
                return <div className="custom-badge status-unreply" >Nessuna Risposta</div>;
            case "REMARKETING":
                return <div className="custom-badge status-remarketing" >Remarketing</div>;
            case "ENDED":
                return <div className="custom-badge status-closed" >Scartato</div>;
            default:
                return null;
        }

    };


    if (error) {
        return (
            <React.Fragment>
                <Alert color="danger">Si è verificato un errore durante il recupero della lista delle segnalazioni.</Alert>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            {loading ? (
                <Loading />
            ) : (
                <div>
                    <Grid
                        data={reports}
                        columns={[
                            {
                                id: "id",
                                hidden: true
                            },
                            {
                                id: 'name',
                                name: 'Nome',
                                formatter: (cell, row) => _(
                                    <div className='text-left'>
                                        <b>{cell}</b>
                                    </div>
                                )
                            },
                            {
                                id: 'referent',
                                name: 'Referente',
                                sort: false,
                                formatter: (cell, row) => _(<div className="text-nowrap text-center">
                                    <p>{cell.firstname} {cell.lastname}</p>
                                </div>)
                            },
                            {
                                id: 'commission',
                                name: 'Commissione',
                                sort: false,
                                formatter: (cell, row) => _(<div className="text-nowrap text-center">
                                    {/*{getStatus(reports.find((r) => r.id === row.cells[0].data))}<br />*/}
                                    <b className="text-success">{Utils.getValueMoneyLabel(cell)}</b>
                                </div>)
                            },
                            {
                                id: 'status_lead',
                                name: 'Stato',
                                sort: false,
                                formatter: (cell, row) => _(<div className="text-nowrap text-center">
                                    {getStatus(reports.find((r) => r.id === row.cells[0].data))}<br />
                                </div>)
                            },
                        ]}
                        style={{ table: { 'white-space': 'nowrap' } }}
                        resizable={true}
                        search={true}
                        sort={true}
                        pagination={{ enabled: true, limit: 25 }}
                        language={{
                            'search': {
                                'placeholder': '🔍 Cerca...'
                            },
                            'pagination': {
                                'previous': 'Indietro',
                                'next': 'Avanti',
                                'showing': 'Stai visualizzando da',
                                'results': 'Segnalazioni',
                                'to': 'a',
                                'of': 'di',
                            },
                            'noRecordsFound': 'Nessuna segnalazione trovata',
                            'error': 'Si è verificato un errore non previsto',
                        }}
                    />
                </div>
            )}
        </React.Fragment>
    );
};

export default withRouter(ReportTabNetworker);
