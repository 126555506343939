import React, { Component } from 'react';
import {
    Alert,
    Card,
    CardHeader,
    CardBody,
    Col,
    Container,
    Row,
    Table,
    Input, Label, FormFeedback, Button, Accordion, AccordionItem, Collapse
} from 'reactstrap';
import {withRouter, Redirect, Link,} from "react-router-dom";

import {CourseAPI, StreamingAPI} from "api";

import { Utils } from "custom";
import { Player, BigPlayButton, LoadingSpinner, ControlBar, ForwardControl, PlaybackRateMenuButton, ReplayControl } from 'video-react';

import "react-pdf/dist/esm/Page/AnnotationLayer.css"

import ImageGallery from 'react-image-gallery';

import ReactAudioPlayer from 'react-audio-player';

class CourseStreaming extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: false,
            numPages: 0
        };
    }

    componentDidMount() {
        // this.getStreaming(this.props.streaming);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        // this.getStreaming(this.props.streaming);
    }

    // getStreaming(id) {
    //     console.log(id)
    //     return;
    //     StreamingAPI.request(id).then((streaming) => {
    //         // if (["IMAGE", "PDF", "AUDIO"].includes(streaming.type)) {
    //         //     this.props.onReset();
    //         //     return window.open(streaming.requested_file);
    //         // }
    //
    //         this.setState({streaming: streaming, loading: false});
    //     }).catch((err) => {
    //         console.error(err);
    //         this.setState({error: true});
    //     })
    // }

    getRender() {

        switch(this.props.type) {
            case "IMAGE":
                return <div className="text-center" width="100%"><ImageGallery showThumbnails={false} showPlayButton={false} onClick={() => window.open(this.props.streaming)} items={[{original: this.props.streaming}]} /></div>;
            case "VIDEO":
                return <Player
                            autoPlay={true}
                            preload='none'
                            playsInline={true}
                        >
                    <source src={global.route.streamingView + "/" + this.props.streaming + "?token=" + localStorage.getItem("hash")} />
                    <LoadingSpinner />
                    <ControlBar autoHide={false}>
                        <ForwardControl seconds={10} order={3.1} />
                    </ControlBar>
                    <BigPlayButton position="center" hide={false} />
                </Player>;
            case "AUDIO":
                return <div className="text-center" width="100%" style={{margin: 5}}>
                    <div>
                    <ReactAudioPlayer
                        src={this.props.streaming}
                        autoPlay
                        controls
                    />
                    </div>
                </div>;
            case "PDF":
                return <div className="text-center" width="100%" style={{margin: 5}}><a href={this.props.streaming}><Button color="primary"><i className="ri-file-pdf-line fs-5 text-white-75 align-middle" /> Apri PDF</Button></a></div>;
            default:
                return null;
        }
    }

    render() {
        require("video-react/dist/video-react.css");

        require("react-image-gallery/styles/scss/image-gallery.scss");

        return (
            <React.Fragment>
                {this.getRender()}
            </React.Fragment>
        );
    }
}

export default withRouter(CourseStreaming);
