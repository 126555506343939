import React, {useEffect, useState} from 'react';
import {
    Alert,
    Badge,
    Card,
    CardBody,
    Col,
    Container,
    Row
} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';

import {AvailableAPI} from "api";
import {Link, useHistory} from "react-router-dom";
import {Loading, RevertClassroom} from "custom";

import {_, Grid} from "gridjs-react";

const moment = require("moment");

const ClassroomAvailable = () => {
    document.title = "Calendario Disponibilità | CLTV Cloud";

    const [loading, setLoading] = useState(true);

    const [teachers, setTeachers] = useState([]);

    const [error, setError] = useState(false);

    let history = useHistory();

    useEffect(() => {
        getAvailableClassroom();
    }, []);

    const getAvailableClassroom = () => {
        AvailableAPI.dash().then((teachers) => {

            let res = [];

            for (let i = 0; i < teachers.length; i++) {
                res.push({
                    id: teachers[i].id,
                    name: teachers[i].name,
                    slot08: (teachers[i].availables.find((a) => a.slot === "08:00 - 09:00")) ? teachers[i].availables.find((a) => a.slot === "08:00 - 09:00") : null,
                    slot09: (teachers[i].availables.find((a) => a.slot === "09:00 - 10:00")) ? teachers[i].availables.find((a) => a.slot === "09:00 - 10:00") : null,
                    slot10: (teachers[i].availables.find((a) => a.slot === "10:00 - 11:00")) ? teachers[i].availables.find((a) => a.slot === "10:00 - 11:00") : null,
                    slot15: (teachers[i].availables.find((a) => a.slot === "15:00 - 16:00")) ? teachers[i].availables.find((a) => a.slot === "15:00 - 16:00") : null,
                    slot18: (teachers[i].availables.find((a) => a.slot === "18:00 - 19:00")) ? teachers[i].availables.find((a) => a.slot === "18:00 - 19:00") : null,
                    slot19: (teachers[i].availables.find((a) => a.slot === "19:00 - 20:00")) ? teachers[i].availables.find((a) => a.slot === "19:00 - 20:00") : null,
                    slot20: (teachers[i].availables.find((a) => a.slot === "20:00 - 21:00")) ? teachers[i].availables.find((a) => a.slot === "20:00 - 21:00") : null
                })
            }

            setTeachers(() => res);
            setLoading(false);
            setError(false);
        }).catch((err) => {
            setError(true);
            console.error(err);
        })
    }

    const printAvailable = (available) => {
        if (available === null)
            return null;

        return <div className="d-flex flex-column align-content-center">
            {(available.classrooms.length > 0) ? <Badge color="primary" className="cursor-pointer" onClick={() => showClassrooms(available.classrooms)}><i className="mdi mdi-school" /> {available.classrooms.length}</Badge>: ""}
            <i className="ri-checkbox-fill fs-3 text-success" />
            {(available.available_from !== null) ? <small>Dal {moment(available.available_from).format("DD MMM")}</small> : <small>--</small>}
            {(available.available_to !== null) ? <small>Al {moment(available.available_to).format("DD MMM")}</small> : <small>--</small>}
        </div>
    }

    const showClassrooms = (classrooms) => {
        global.onSetModal({
            title: "ELENCO CLASSI",
            content: <div>


                <div className='gridjs gridjs-container'>
                    <div className='gridjs-wrapper'>
                        <table role="grid" className="gridjs-table">
                            <thead className="gridjs-thead">
                            <tr className="gridjs-tr">
                                <th className="gridjs-th">NOME</th>
                                <th className="gridjs-th">STATO</th>
                                <th className="gridjs-th">PARTENZA</th>
                                <th className="gridjs-th">FINE</th>
                            </tr>
                            </thead>
                            <tbody className="gridjs-tbody">
                                {classrooms.map((c, i) => {
                                    return <tr className="gridjs-tr" key={i}>
                                        <td className="gridjs-td text-nowrap" width="100">
                                            {(c.status !== "DRAFT") ? <Link to={"/classroom/" + c.id} onClick={() => global.onSetModal({isOpen: false, content: ""})}><b>{c.name}</b></Link> : <b>{c.name}</b>}
                                        </td>
                                        <td className="gridjs-td text-nowrap" width="100">
                                            <b>{printStatus(c.status)}</b>
                                        </td>
                                        <td className="gridjs-td text-nowrap" width="100">
                                            <b>{c.start}</b>
                                        </td>
                                        <td className="gridjs-td text-nowrap" width="100">
                                            <b>{c.end}</b>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>,
            isOpen: true,
            toggle: false,
            size: 'lg',
            type: 'info',
            buttons: [{ label: "ANNULLA", color: 'danger', onClick: () => global.onSetModal({isOpen: false, content: ""})}]
        })
    }

    const printStatus = (status) => {
        switch(status) {
            case "DRAFT":
                return <Badge color="info" style={{fontSize: "10px"}}>BOZZA</Badge>;
            case "WAITING":
                return <Badge color="warning" style={{fontSize: "10px"}}>IN PROGRAMMAZIONE</Badge>;
            case "ACTIVE":
                return <Badge color="primary" style={{fontSize: "10px"}}>ATTIVA</Badge>;
            case "COMPLETED":
                return <Badge color="success" style={{fontSize: "10px"}}>COMPLETATA</Badge>;
            default:
                return null;
        }
    }


    if (error) {
        return <React.Fragment>
            <div className="page-content">
                <Alert color="danger" >Si è verificato un errore durante il recupero delle disponibilità classi.</Alert>
            </div>
        </React.Fragment>
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Disponibilità Classi" pageTitle="CLTV Cloud"/>
                    {(loading) ? <Loading/> : <div>

                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        {(loading) ? <Loading /> : <Grid
                                            data={teachers}
                                            columns={[
                                                {
                                                    id: 'name',
                                                    name: 'Docente',
                                                    sort: false,
                                                    formatter: (cell, row) =>_(<span className="text-nowrap">
                                                        <b>{cell}</b>
                                                    </span>)
                                                },
                                                {
                                                    id: 'slot08',
                                                    name: '08:00',
                                                    sort: false,
                                                    formatter: (cell, row) =>_(<div className="text-nowrap text-center">
                                                        {printAvailable(cell)}
                                                    </div>)
                                                },
                                                {
                                                    id: 'slot09',
                                                    name: '09:00',
                                                    sort: false,
                                                    formatter: (cell, row) =>_(<div className="text-nowrap text-center">
                                                        {printAvailable(cell)}
                                                    </div>)
                                                },
                                                {
                                                    id: 'slot10',
                                                    name: '10:00',
                                                    sort: false,
                                                    formatter: (cell, row) =>_(<div className="text-nowrap text-center">
                                                        {printAvailable(cell)}
                                                    </div>)
                                                },
                                                {
                                                    id: 'slot15',
                                                    name: '15:00',
                                                    sort: false,
                                                    formatter: (cell, row) =>_(<div className="text-nowrap text-center">
                                                        {printAvailable(cell)}
                                                    </div>)
                                                },
                                                {
                                                    id: 'slot18',
                                                    name: '18:00',
                                                    sort: false,
                                                    formatter: (cell, row) =>_(<div className="text-nowrap text-center">
                                                        {printAvailable(cell)}
                                                    </div>)
                                                },

                                                {
                                                    id: 'slot19',
                                                    name: '19:00',
                                                    sort: false,
                                                    formatter: (cell, row) =>_(<div className="text-nowrap text-center">
                                                        {printAvailable(cell)}
                                                    </div>)
                                                },
                                                {
                                                    id: 'slot20',
                                                    name: '20:00',
                                                    sort: false,
                                                    formatter: (cell, row) =>_(<div className="text-nowrap text-center">
                                                        {printAvailable(cell)}
                                                    </div>)
                                                }
                                            ]}
                                            style={{ table: { 'white-space': 'nowrap' } }}
                                            resizable={true}
                                            search={true}
                                            sort={false}
                                            pagination={{ enabled: true, limit: 25 }}
                                            language={{
                                                'search': {
                                                    'placeholder': '🔍 Cerca...'
                                                },
                                                'pagination': {
                                                    'previous': 'Indietro',
                                                    'next': 'Avanti',
                                                    'showing': 'Stai visualizzando da',
                                                    'results': 'Docenti',
                                                    'to': 'a',
                                                    'of': 'di',
                                                },
                                                'noRecordsFound': 'Nessun docente trovato',
                                                'error': 'Si è verificato un errore non previsto',
                                            }}
                                        />}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ClassroomAvailable;
