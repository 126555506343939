import React, { useEffect, useState } from 'react';
import { Alert, Badge, Card, CardBody } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { Grid, _ } from 'gridjs-react';

import {Loading, Utils, DeleteReporter, EditReporter, ContactReporter, Params} from 'custom';
import {ReporterAPI, ReportAPI, UserAPI, NetworkAPI} from 'api';

const NetworkerTab = ({ history, onClickCommission, range, tabSelected }) => {
    const [reporters, setReporters] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const [users, setUsers] = useState([])

    useEffect(() => {
        document.title = 'Network | CLTV';
        return () => {
            Params.removeHandlePaginator()
        };
    }, []);


    useEffect(() => {
        if (tabSelected === 1)
            getNetworkers();
    }, [tabSelected]);


    const getNetworkers = async () => {

        NetworkAPI.listNetworkers().then((rep) => {
                setReporters(rep);
                setLoading(false);
                setError(false);
                setTimeout(() => {
                    Params.addHandlePaginator()
                }, 1000)
            })
            .catch(() => {
                setError(true);
            });
    };

    const printCommission = (commissions, id) => {
        return <div onClick={() => signPayNetworker(id)} className="cursor-pointer">
            {(commissions.toAccredit > 0) ? <><Badge color="warning" style={{fontSize: 11}}>Da Accreditare: <b>{Utils.getValueMoneyLabel(commissions.toAccredit)}</b></Badge><br /></> : null}
            {(commissions.toPay > 0) ? <><Badge color="warning" style={{fontSize: 11}}>Da Bonificare: <b>{Utils.getValueMoneyLabel(commissions.toPay)}</b></Badge><br /></> : null}
            {(commissions.total > 0) ? <Badge color="primary" style={{fontSize: 11}}>Totale: <b>{Utils.getValueMoneyLabel(commissions.total)}</b></Badge> : null}
        </div>
    };

    const signPayNetworker = (id) => {
        if (Utils.getUserData().role !== "ADMIN")
            return;

        let reporter = reporters.find((r) => r.id === id);

        if (reporter)
            if (reporter.reportsToPayIds.length === 0)
                return;

        global.SweetAlert.fire({
            title:  "Bonifica Segnalazioni",
            text: 'Sei veramente sicuro di voler segnalare come pagate le segnalazioni del manager? Questa azione è irreversibile',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, conferma',
            cancelButtonText: 'Annulla',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ms-1'
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm: function() {
                return new Promise((resolve, reject) => {
                    async function processPayments() {
                        try {
                            for (let id of reporter.reportsToPayIds) {
                                await NetworkAPI.pay(id);
                            }
                            resolve();
                        } catch (e) {
                            reject();
                        }
                    }

                    processPayments();
                });
            },
        }).then(function (result) {
            if (result.value) {
                global.SweetAlert.fire({
                    icon: 'success',
                    title: 'Segnalazioni manager segnalate come pagate!',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                });
                getNetworkers();
            }
        })
    };

    if (error) {
        return (
            <React.Fragment>
                <Alert color="danger">Si è verificato un errore durante il recupero della lista dei segnalatori.</Alert>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            {loading ? (
                <Loading />
            ) : (
                <div>
                    <Grid
                        data={reporters.sort((a, b) => b.reports - a.reports)}
                        columns={[
                            {
                                id: 'id',
                                name: '#',
                                formatter: (cell, row) => _(<span className="text-nowrap">#{cell}</span>),
                            },
                            {
                                id: 'external_id',
                                name: 'ID Esterno',
                                formatter: (cell, row) => _(<span className="text-nowrap">{cell}</span>)
                            },
                            {
                                id: 'client_id',
                                hidden: true
                            },
                            {
                                id: 'name',
                                name: 'Nome',
                                formatter: (cell, row) => _(
                                    <div className='text-left'>
                                        {(row.cells[2].data !== null) ? <a href="javascript:void(0)" onClick={() => history.push("/client/" + row.cells[2].data)}><b>{cell}</b></a> : <b>{cell}</b>}
                                        <br />
                                        {<small>{Utils.getRoleLabel(row.cells[10].data)}</small>}
                                    </div>
                                )
                            },
                            {
                                id: 'father',
                                name: 'Referente',
                                sort: false,
                                formatter: (cell, row) => _(
                                    <div className='text-left'>
                                        {(cell?.role === "CLIENT") ? <a href="javascript:void(0)" onClick={() => history.push("/client/" + cell.id)}><b>{cell.name}</b></a> : <b>{cell?.name}</b>}
                                        <br />
                                        {<small>{Utils.getRoleLabel(cell?.role)}</small>}
                                    </div>
                                )
                            },
                            {
                                id: 'cell',
                                hidden: true
                            },
                            {
                                id: 'email',
                                hidden: true
                            },
                            {
                                id: 'bank_iban',
                                hidden: true
                            },
                            {
                                id: 'reports',
                                name: 'Segnalazioni',
                                sort: true,
                                formatter: (cell, row) => _(<div className="text-nowrap text-center">
                                    <Badge className="cursor-pointer" color="secondary" onClick={() => onClickCommission(row.cells[0].data)} style={{fontSize: 11}}><b>{cell}</b></Badge>
                                </div>)
                            },
                            {
                                id: 'commissions',
                                name: 'Commissione',
                                sort: false,
                                formatter: (cell, row) => _(<div className="text-nowrap text-center">
                                    {printCommission(cell, row.cells[0].data)}
                                </div>)
                            },
                            {
                                id: 'user_role',
                                hidden: true
                            }
                        ]}
                        style={{ table: { 'white-space': 'nowrap' } }}
                        resizable={true}
                        search={true}
                        sort={true}
                        pagination={{ enabled: true, limit: 25, page: Params.getActualPage() }}
                        language={{
                            'search': {
                                'placeholder': '🔍 Cerca...'
                            },
                            'pagination': {
                                'previous': 'Indietro',
                                'next': 'Avanti',
                                'showing': 'Stai visualizzando da',
                                'results': 'Manager',
                                'to': 'a',
                                'of': 'di',
                            },
                            'noRecordsFound': 'Nessun manager trovato',
                            'error': 'Si è verificato un errore non previsto',
                        }}
                    />
                </div>
            )}
        </React.Fragment>
    );
};

export default withRouter(NetworkerTab);
