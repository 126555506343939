import React, { Fragment } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
    Row,
    Col,
    Label,
    FormFeedback,
    Input,
    FormText
} from 'reactstrap';

import { Utils } from "custom";
import { TeacherAPI } from "api";

class EditTeacher extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            form: {
                id: '',
                active: false,
                firstname: '',
                lastname: '',
                email: '',
                cell: '',
                roles: []
            },
            loading: false,
            errors: false,
            roles: ["TEACHER", "TUTOR", "STAGE", "TRIAL"],
        };
    }

    componentDidMount() {
        //data
        this.resetForm()
        console.log('data', this.props.data);
    }

    changeForm(key, text) {
        const form = this.state.form;

        if (key === "roles") {
            if (text.value) {
                form.roles.push(text.key);
            } else {
                for (let i = 0; i < form.roles.length; i++) {
                    if (form.roles[i] === text.key)
                        form.roles.splice(i, 1);
                }
            }
        } else {
            form[key] = text;
        }

        this.setState({ form: form }, () => this.showModal());
    }

    showModal() {
        let form = this.state.form;
        let errors = this.state.errors;
        global.onSetModal({
            title: this.props.label,
            content: <div>
                <Row hidden={(form.id === null)}>
                    <Col md="12">
                        <div className="form-check form-switch form-switch-custom form-switch-primary mb-3">
                            <Input className="form-check-input" disabled={!this.props.data?.isRegistered} type="checkbox" role="switch"  checked={form.active}
                                   onChange={(e) => this.changeForm('active', e.target.checked)}/>
                            <Label className="form-check-label">Attiva / Disattiva Utente</Label>
                        </div>
                        {(!this.props.data?.isRegistered) ? <Alert color="warning" >L'utente deve ancora completare la registrazione</Alert> : null}
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Nome
                            </Label>
                            <Input type="text" value={form.firstname}
                                invalid={Boolean(errors.firstname)}
                                onChange={(e) => this.changeForm('firstname', e.target.value)} />
                            <FormFeedback>{errors.firstname}</FormFeedback>
                        </div>
                    </Col>
                    <Col md="6">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Cognome
                            </Label>
                            <Input type="text" value={form.lastname}
                                invalid={Boolean(errors.lastname)}
                                onChange={(e) => this.changeForm('lastname', e.target.value)} />
                            <FormFeedback>{errors.lastname}</FormFeedback>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Email
                            </Label>
                            <Input type="email" value={form.email}
                                invalid={Boolean(errors.email)}
                                onChange={(e) => this.changeForm('email', e.target.value)} />
                            <FormFeedback>{errors.email}</FormFeedback>
                        </div>
                    </Col>
                    <Col md="12">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Cellulare
                            </Label>
                            <Input
                                type="tel"
                                value={form.cell}
                                invalid={Boolean(errors.cell)}
                                onChange={(e) => this.changeForm('cell', e.target.value)} />
                            <FormFeedback>{errors.cell}</FormFeedback>
                            <FormText>
                                Assicurati di digitare correttamente il <u>numero di cellulare</u>, è necessario inserire anche il prefisso internazionale (+39 per Italia)
                            </FormText>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <h5>Permessi</h5>
                        <div className={Boolean(errors.roles) ? 'is-invalid mb-2': ' mb-2 '}>
                            {this.state.roles.map((el, i) => {
                                return (
                                    <div className="form-check form-check-outline form-check-primary mb-3" key={i}>
                                        <Input className="form-check-input"
                                            type="checkbox"
                                            id={i}
                                            checked={this.state.form?.roles?.includes(el)}
                                            onChange={(e) => this.changeForm('roles', { key: el, value: e.target.checked })}
                                        />
                                        <Label className="form-check-label" htmlFor={i}>
                                            {el}
                                        </Label>
                                    </div>
                                )
                            })}
                        </div>
                        <FormFeedback>{errors.roles}</FormFeedback>
                    </Col>
                </Row>
            </div>,
            isOpen: true,
            toggle: false,
            size: 'lg',
            type: 'info',
            buttons: [
                { label: 'SALVA', color: 'success', onClick: () => this.saveUser() },
                {
                    label: 'CHIUDI',
                    color: 'danger',
                    onClick: () => this.resetForm()
                },
            ]
        })
    }

    resetForm() {
        this.setState({
            form: this.props.data
        }, () => {
            global.onSetModal({ isOpen: false });
        });
    }

    saveUser() {
        const self = this;
        let form = this.state.form;
        let error = {};

        if (form.firstname === '')
            error.firstname = "Il campo nome è obbligatorio";

        if (form.lastname === '')
            error.lastname = "Il campo cognome è obbligatorio";

        if (form.email === '')
            error.email = "Il campo email è obbligatorio";

        if (form.cell === '')
            error.cell = "Il campo cellulare è obbligatorio";

        if (!Utils.isValidEmail(form.email))
            error.email = "La mail inserita non è corretta";

        if (form.cell !== '' && !Utils.isValidPhone(form.cell))
            error.cell = "Il telefono inserito non è corretto";

        if (!form.roles.length)
            error.roles = "I ruoli inseriti non sono corretti";

        this.setState({ errors: error }, () => this.showModal());

        if (Object.keys(error).length === 0) {
            global.onSetModal({ loading: true });
            TeacherAPI.edit(form).then(() => {
                global.onSetModal({ loading: false });
                global.SweetAlert.fire({
                    title: 'Docente modificato',
                    text: 'L\'utente è stato modificato correttamente.',
                    icon: 'success',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    confirmButtonText: 'Chiudi',
                    buttonsStyling: false
                })
                self.props.onResolve();
                self.resetForm()
            }).catch((error) => {
                console.log('error',error);
                global.onSetModal({ loading: false });
                global.SweetAlert.fire({
                    title: 'Errore',
                    text: 'Si è verificato un errore durante la creazione dell\'utente',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                });
            })
        }
    }


    render() {
        return (
            <React.Fragment>
                <li className="list-inline-item cursor-pointer" onClick={() => this.showModal()}>
                    <i className="ri-pencil-fill fs-20 text-info"></i>
                </li>
            </React.Fragment>
        )
    }
}

export default EditTeacher;
