import { LeadAPI } from 'api';
import React, { Component } from 'react'

export default class DeleteLead extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  deleteLead = () => {
    if (this.props.id) {
      LeadAPI.remove(this.props.id)
        .then((data) => {
          global.SweetAlert.fire({
            title: 'Lead Eliminato',
            text: 'Lead eliminato correttamente',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
          });
          this.props.getLeads();
          this.setState({
            deleteLead: null,
            deleteModal: false
          })
        }).catch(() => {
          global.SweetAlert.fire({
            title: 'Errore',
            text: 'Si è verificato un errore durante la cancellazione lead',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
          });
        })
    }
  }

  showModal() {
    const self = this;
    global.SweetAlert.fire({
      title: "Elimina lead",
      text: 'Vuoi davvero eliminare?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, elimina',
      cancelButtonText: 'Annulla',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ms-1'
      },
      buttonsStyling: false,
      showLoaderOnConfirm: true,

    }).then(function (result) {
      if (result.value) {
        self.deleteLead()
      }
    })
  }

  render() {
    return (
      <li className="list-inline-item cursor-pointer" onClick={() => this.showModal()}>
        <i className="ri-delete-bin-5-fill fs-20 text-danger"></i>
      </li>
    )
  }
}
