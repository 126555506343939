import React, {Fragment} from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
    Row,
    Col,
    Label,
    FormFeedback,
    Input,
    FormText, Badge
} from 'reactstrap';

import { Utils } from "custom";
import { ClassRoomTrialAPI, TeacherAPI } from "api";
import Select from "react-select";

const moment = require("moment")

// const allowedHour = [
//     "08:00",
//     "08:30",
//     "09:00",
//     "09:30",
//     "10:00",
//     "10:30",
//     "11:00",
//     "11:30",
//     "12:00",
//     "12:30",
//     "13:00",
//     "13:30",
//     "14:00",
//     "14:30",
//     "15:00",
//     "15:30",
//     "16:00",
//     "16:30",
//     "17:00",
//     "17:30",
//     "18:00",
//     "18:30",
//     "19:00",
//     "19:30",
//     "20:00",
//     "20:30",
//     "21:00"
// ]

class GestClassroomTrial extends React.Component {
    constructor(props) {
        super(props);
        let preset = this.props.preset;

        let teachers = [];

        for (let i = 0; i < this.props.teachers.length; i++) {
            if (this.props.teachers[i].roles.includes("TRIAL"))
                teachers.push(this.props.teachers[i])
        }

        teachers = teachers.map((t) => {return {value: t.id, label: t.firstname + " " + t.lastname}});

        this.state = {
            form: {
                id: (preset) ? preset.id : null,
                hour: (preset) ? moment(preset.dateTime).format("HH:mm") : null,
                date: (preset) ? moment(preset.dateTime).format("YYYY-MM-DD") : null,
                teacher: (preset) ? ((preset.id_teacher !== null) ? teachers[teachers.findIndex(c => preset.id_teacher === c.value)] : null) : null
            },
            teachers: teachers,
            loading: false,
            errors: false
        };
    }

    changeForm(key, text) {
        const form = this.state.form;
        form[key] = text;
        this.setState({form: form}, () => this.showModal());
    }

    showModal() {

            let form = this.state.form;
            let errors = this.state.errors;

            global.onSetModal({
                title: (this.props.preset) ? "MODIFICA LEZIONE GRATUITA" : "CREA LEZIONE GRATUITA",
                content: <div>
                    <Row>
                        <Col>
                            <div className='mb-2'>
                                <Label className='form-label'>
                                    Seleziona Docente
                                </Label>
                                <Select
                                    value={this.state.form.teacher}
                                    options={this.state.teachers}
                                    onChange={(val) => {
                                        this.changeForm('teacher', val)
                                    }}
                                ></Select>
                                <Input invalid={Boolean(errors?.teacher)} hidden/>
                                <FormFeedback>{errors?.teacher}</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className='mb-2'>
                                <Label className='form-label'>
                                    Data*
                                </Label>
                                <Input type="date" value={form.date}
                                       invalid={Boolean(errors.date)}
                                       onChange={(e) => this.changeForm('date', e.target.value)}/>
                                <FormFeedback>{errors.date}</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className='mb-2'>
                                <Label className='form-label'>
                                    Ora*
                                </Label><br />
                                {/*{allowedHour.map((slot, i) => {*/}
                                {/*    return <span key={i} style={{fontSize: 15}}><Badge className={(form.hour === slot) ? "cursor-pointer text-white" : "cursor-pointer text-dark"} color={(form.hour === slot) ? "primary" : "light"} onClick={() => this.changeForm("hour", slot)}><b>{slot}</b></Badge>&nbsp;</span>*/}
                                {/*})}*/}
                                <Input type="time" value={form.hour} invalid={Boolean(errors?.hour)} onChange={(e) => this.changeForm("hour", e.target.value)}/>
                                <FormFeedback>{errors?.hour}</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                </div>,
                isOpen: true,
                toggle: false,
                size: 'lg',
                type: 'info',
                buttons: [
                    {label: 'SALVA', color: 'success', onClick: () => this.saveTrialClassroom()},
                    {
                        label: 'CHIUDI',
                        color: 'danger',
                        onClick: () => this.resetForm()
                    }
                ]
            })



    }

    resetForm() {
        let preset = this.props.preset;
        let teachers = this.props.teachers.map((t) => {return {value: t.id, label: t.firstname + " " + t.lastname}});

        this.setState({
            form: {
                id: (preset) ? preset.id : null,
                hour: (preset) ? moment(preset.dateTime).format("HH:mm") : null,
                date: (preset) ? moment(preset.dateTime).format("YYYY-MM-DD") : null,
                teacher: (preset) ? ((preset.id_teacher !== null) ? teachers[teachers.findIndex(c => preset.id_teacher === c.value)] : null) : null
            }
        })
        global.onSetModal({isOpen: false, loading: false, content: ""})
    }

    saveTrialClassroom() {
        const self = this;
        let form = this.state.form;
        let error = {};

        if (form.hour === null)
            error.hour = "Il campo ora è obbligatorio";

        if (form.date === null)
            error.date = "Il campo data è obbligatorio";

        this.setState({errors: error}, () => this.showModal());

        if (Object.keys(error).length === 0) {
            global.onSetModal({loading: true});

            let add = (form.id === null);
            let api = (add) ? ClassRoomTrialAPI.add : ClassRoomTrialAPI.edit;

            api({
                id: form.id,
                id_teacher: (form.teacher !== null) ? form.teacher.value : null,
                dateTime: form.date + " " + form.hour + ":00"
            }).then(() => {
                global.onSetModal({loading: false});
                global.SweetAlert.fire({
                    title: (add) ? 'Lezione gratuita creata' : 'Lezione gratuita modificato',
                    text: (add) ? 'La lezione gratuita è stata creata correttamente.' : 'La lezione gratuita è stata modificata correttamente.',
                    icon: 'success',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    confirmButtonText: 'Chiudi',
                    buttonsStyling: false
                }).then(function (result) {
                    if (result.value) {
                        self.resetForm();
                        self.props.onResolve();
                    }
                })
            }).catch((err) => {
                global.onSetModal({loading: false});
                global.SweetAlert.fire({
                    title: 'Errore',
                    text: (add) ? 'Si è verificato un errore durante la creazione della lezione gratuita' : 'Si è verificato un errore durante la modifica della lezione gratuita',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                });
            })
        }
    }


    render() {
        return (
            <React.Fragment>
                {(this.props.preset) ? <li className="list-inline-item cursor-pointer" onClick={() => this.showModal()}>
                    <i className="ri-pencil-fill fs-20 text-info"></i>
                </li> : <button
                    type="button"
                    className="btn btn-sm btn-success add-btn"
                    onClick={() => this.showModal()}>
                    <i className="ri-add-line align-bottom me-1"></i> CREA LEZIONE GRATUITA
                </button>}
            </React.Fragment>
        )
    }
}

export default GestClassroomTrial;
