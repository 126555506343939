import React, { Component } from 'react';
import {
    Alert,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Label, Input, FormFeedback, FormText
} from 'reactstrap';

import { Link } from "react-router-dom";

import { Utils, GestLead, GeoForm } from "custom";
import Select from "react-select";
import { LeadAPI } from "api";
import moment from "moment/moment";

class LeadInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            note: "",
            loading: false,
            errors: false
        }
    }

    closeLead() {
        let note = this.state.note;
        let errors = this.state.errors;

        global.onSetModal({
            title: "SCARTA LEAD",
            content: <div>
                <Row>
                    <Col md="12">
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Specifica il motivo della chiusura
                            </Label>
                            <Input type="textarea" rows={4} value={note}
                                placeholder={"Ad esempio: cliccato per sbaglio, non è interessato... etc etc"}
                                invalid={Boolean(errors.note)}
                                onChange={(e) => this.setState({ note: e.target.value }, () => this.closeLead())} />
                            <FormFeedback>{errors.note}</FormFeedback>
                        </div>
                    </Col>
                </Row>
            </div>,
            isOpen: true,
            toggle: false,
            size: 'lg',
            type: 'info',
            buttons: [
                { label: 'CONFERMA CHIUSURA', color: 'success', onClick: () => this.closeLeadAPI() },
                {
                    label: 'ANNULLA',
                    color: 'danger',
                    onClick: () => global.onSetModal({ isOpen: false, content: "" })
                },
            ]
        })
    }

    closeLeadAPI() {
        const self = this;
        let lead = this.props.lead;
        let note = this.state.note;
        let error = {};
        if (note === '')
            error.note = "Il campo è obbligatorio";

        this.setState({ errors: error }, () => this.closeLead());

        if (Object.keys(error).length === 0) {
            global.onSetModal({ loading: true });

            LeadAPI.close({ id: lead.id, note: note }).then(() => {
                global.onSetModal({ loading: false });
                global.SweetAlert.fire({
                    title: 'Lead chiuso',
                    text: 'Il lead è stato chiuso correttamente.',
                    icon: 'success',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    confirmButtonText: 'Chiudi',
                    buttonsStyling: false
                }).then(function (result) {
                    if (result.value) {
                        global.onSetModal({ isOpen: false, content: "" });
                        self.props.onReload();
                    }
                })
            }).catch(() => {
                global.onSetModal({ loading: false });
                global.SweetAlert.fire({
                    title: 'Errore',
                    text: 'Si è verificato un errore durante la chiusura del lead',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                });
            });
        }
    }

    render() {

        const lead = this.props.lead;
        const isAdmin = (Utils.getUserData().role === "ADMIN");

        let obj = null

        try {
            obj = btoa(JSON.stringify({
                name: lead?.name,
                email: lead?.email,
                phone: lead?.phone
            }))
        } catch (e) {
            obj = null
        }

        return (
            <React.Fragment>
                <Row>
                    <Col>
                        {/* <h5 className="mb-1 text-muted">{(isAdmin) ? <GestLead preset={lead} onResolve={this.props.onReload} /> : null} Dettaglio Lead</h5><br /> */}
                        <hr />
                        <div className="mb-4">
                            <p>Nazionalità</p>
                            {(lead.country !== null) ? <img alt={lead.country.code} width="30px" src={lead.country.flag} /> : null}
                        </div>
                        <hr />
                        <div className="mb-4">
                            <p>Data / Ora Lead</p>
                            <h5>{moment(lead.date_hour).format("DD MMM YYYY HH:mm")}</h5>
                        </div>
                        <hr />
                        <div className="mb-4">
                            <p>Email</p>
                            <h5><a href={"mailto:" + lead.email}>{lead.email}</a></h5>
                        </div>
                        <hr />
                        {(lead.reporter !== null) ? <div className="mb-4">
                            <p>Segnalatore <i className="mdi mdi-bullhorn align-middle fs-20 text-danger" /></p>
                            <h5>{(lead.reporter_id !== null) ? <Link to={"/client/" + lead.reporter_id}>{lead.reporter}</Link> : <b>{lead.reporter}</b>}</h5>
                        </div> : null}
                    </Col>
                </Row>

                {(lead.questions !== null) ? <Row>
                    <hr />
                    <Col>
                        {lead.questions.map((question, i) => {
                            return <div className="mb-4" key={i}>
                                <strong>{question.question}</strong>
                                <p>{question.answer}</p>
                            </div>
                        })}
                    </Col>
                </Row> : null}

                {(!isAdmin) ? <Row>
                    <Col className="text-center" hidden={(lead.status === "ENDED" || lead.status === "CONVERTED")}>
                        <button
                            type="button"
                            className="btn btn-sm btn-danger "
                            onClick={() => this.closeLead()}>
                            <i className="ri-delete-bin-2-fill align-bottom me-1"></i> SCARTA LEAD
                        </button>
                    </Col>
                    <Col className="text-center">
                        {(!Boolean(lead.id_client)) ? <Link to={'/client/add/?lead=' + lead.id  + ((obj !== null) ? '&info=' + obj : "")}>
                            <button
                                type="button"
                                className="btn btn-sm btn-success ">
                                <i className="ri-user-add-fill align-bottom me-1"></i> CREA CLIENTE
                            </button>
                        </Link> : <Link to={'/sell/create/?client=' + lead.id_client + '&lead=' + lead.id}>
                            <button
                                type="button"
                                className="btn btn-sm btn-success ">
                                <i className="ri-add-box-fill align-bottom me-1"></i> CREA VENDITA
                            </button>
                        </Link>}
                    </Col>
                </Row> : null}
            </React.Fragment>
        );
    }
}

export default LeadInfo;
