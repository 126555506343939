let list = function(idClient, startDate, endDate) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');
        fetch(global.route.sell + ((typeof idClient !== "undefined") ? ("?client=" + idClient) : ("?start_date=" + startDate + "&end_date=" + endDate)), {
            headers: {'x-access-token': hash},
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('sell list', responseJson);
                if (responseJson.status === "LIST_SELLS")
                    resolve(responseJson.list);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let detail = function(id) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');
        fetch(global.route.sell + "/" + id, {
            headers: {'x-access-token': hash},
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('sell detail', responseJson);
                if (responseJson.status === "DETAIL_SELL_OK")
                    resolve(responseJson.sell);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let addvideo = function(sell) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.sell, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify({
                id_course: sell.id_course,
                id_client: Number(sell.id_client),
                id_lead: sell.id_lead,
                id_consulent: sell.id_consulent,
                date_sell: (sell.date_sell !== null) ? sell.date_sell : undefined,
                total: Number(sell.total),
                note: sell.note,
                type: 'VIDEO',
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('sell add', responseJson);
                if (responseJson.status === "SELL_CREATED_OK")
                    resolve(responseJson.sell_id);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};
let addCourse = function(sell) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.sell, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify({
                id_level: Number(sell.id_level),
                id_client: Number(sell.id_client),
                id_lead: sell.id_lead,
                id_consulent: sell.id_consulent,
                date_sell: (sell.date_sell !== null) ? sell.date_sell : undefined,
                reconfirm: sell.reconfirm,
                total: Number(sell.total),
                note: sell.note,
                type: 'CLASSROOM',
                id_group: sell.id_group,
                individual: sell.individual
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('sell add', responseJson);
                if (responseJson.status === "SELL_CREATED_OK")
                    resolve(responseJson.sell_id);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};


let edit = function(sell) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.sell, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                id: sell.id,
                total: Number(sell.total),
                reconfirm: sell.reconfirm,
                note: sell.note,
                preferences: sell.preferences
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                 global.checkAuth(responseJson.status);
                console.log('sell edit', responseJson);
                if (responseJson.status === "SELL_EDITED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let remove = function(id) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.sell, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'DELETE',
            body: JSON.stringify({
                id: id
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('sell delete', responseJson);
                if (responseJson.status === "SELL_DELETED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

const SellAPI = {
    list: list,
    detail: detail,
    addvideo: addvideo,
    addCourse: addCourse,
    edit: edit,
    remove: remove
};

module.exports = SellAPI;
