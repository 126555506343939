import React, {Component, Fragment} from "react";
import { Redirect, Route } from "react-router-dom";


const AuthProtected = (props) => {
  let token = localStorage.getItem("hash");

  if (token === null) {
    return (
        <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }

  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
    let userData = sessionStorage.getItem("userData");

    if (userData !== null) {
        if (!rest.auth.includes(JSON.parse(userData).role))
            return (
                <Redirect to={{ pathname: "/unauthorized" }} />
            );
    }

  return (
      <Route
          {...rest}
          render={props => {
            return (<> <Component {...props} /> </>);
          }}
      />
  );
};

export { AuthProtected, AccessRoute };
