import React, {Component} from 'react';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import {Col, Container, Row} from "reactstrap";
import {Link} from "react-router-dom";

class Loading extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'TailSpin',
            label: 'Caricamento..',
            color: '#E24815',
            height: '100',
            width: '100'
        };
    }

    componentWillMount() {
        this.setState({
            type: (typeof this.props.type === 'undefined') ? 'TailSpin' : this.props.type,
            label: (typeof this.props.label === 'undefined') ? 'Caricamento..' : this.props.label,
            color: (typeof this.props.color === 'undefined') ? '#E24815' : this.props.color,
            height: (typeof this.props.height === 'undefined') ? '100' : this.props.height,
            width: (typeof this.props.width === 'undefined') ? '100' : this.props.width
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState(nextProps);
    }

    render() {
        return <div className="auth-page-wrapper py-5 d-flex justify-content-center align-items-center min-vh-100">
            <div className="auth-page-content overflow-hidden p-0">
                <Container fluid={true}>
                    <Row className="justify-content-center">
                        <Col xl={4} className="text-center">
                            <Loader
                                type={this.state.type}
                                color={this.state.color}
                                height={this.state.height}
                                width={this.state.width}
                            />
                            <br/>
                            <h5>{this.state.label}</h5>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>;
    }
}

export default Loading;
