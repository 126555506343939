

let add = function(payload) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.lesson, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify({
                name: payload.name,
                id_course: payload.id_course
            })
        })
        .then((response) => (response.status !== 500) ? response.json() : reject())
        .then((responseJson) => {
            global.checkAuth(responseJson.status);
            console.log('LESSON_CREATED', responseJson);
            if (responseJson.status === "LESSON_CREATED")
                resolve(responseJson);
            else
                reject(responseJson);
        })
        .catch((e) => {
            console.error(e.message);
            reject('APP_ERROR');
        });
    });
};

let edit = function(payload) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.lesson, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                id: payload.id,
                name: payload.name,
                id_course: payload.id_course
            })
        })
        .then((response) => (response.status !== 500) ? response.json() : reject())
        .then((responseJson) => {
            global.checkAuth(responseJson.status);
            console.log('LESSON_EDITED', responseJson);
            if (responseJson.status === "LESSON_EDITED")
                resolve(responseJson);
            else
                reject(responseJson);
        })
        .catch((e) => {
            console.error(e.message);
            reject('APP_ERROR');
        });
    });
};

let remove = function(id_course, id) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.lesson, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'DELETE',
            body: JSON.stringify({
                id: id,
                id_course: id_course,
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('delete', responseJson);
                if (responseJson.status === "LESSON_DELETED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};
let createFile = function(id_lesson,name) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.file, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify({
                id_lesson,
                name,
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('FILE_CREATED', responseJson);
                if (responseJson.status === "FILE_CREATED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let editFile = function(id,name) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.file, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                id,
                name,
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('FILE_EDITED', responseJson);
                if (responseJson.status === "FILE_EDITED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};
let deleteFile = function(id) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.file, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'DELETE',
            body: JSON.stringify({
                id,
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('FILE_DELETED', responseJson);
                if (responseJson.status === "FILE_DELETED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let uploadFile = function(file,id) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');
        const formdata = new FormData();
        formdata.append('file', file)
        fetch(global.route.file+'/'+id, {
            headers: {
                'x-access-token': hash,
                // 'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: formdata
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('FILE_UPLOAD_OK', responseJson);
                if (responseJson.status === "FILE_UPLOAD_OK")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};
const LessonAPI = {
    add: add,
    edit: edit,
    remove: remove,
    createFile: createFile,
    uploadFile: uploadFile,
    editFile: editFile,
    deleteFile: deleteFile,
};

module.exports = LessonAPI;