import React, {useEffect, useState} from 'react';
import {
    Alert,
    Badge,
    Card,
    CardBody,
    Col,
    Container, Input,
    Row, Spinner
} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';

import {ClassRoomAPI, LeadAPI, PaymentAPI} from "api";
import {Link, useParams, useHistory} from "react-router-dom";
import {
    Loading,
    RevertClassroom,
    Utils
} from "custom";

import {_, Grid} from "gridjs-react";

const moment = require("moment");

const ClassroomMain = () => {
    document.title = "Dettaglio Classe | CLTV Cloud";

    const history = useHistory()

    const [loading, setLoading] = useState(true);
    const [classroom, setClassroom] = useState(null);

    const [waitingList, setWaitingList] = useState([]);

    const [loadingWaiting, setLoadingWaiting] = useState(null);
    const [loadingSell, setLoadingSell] = useState(null);

    const [dateEnd, setDateEnd] = useState(null);
    const [dateStart, setDateStart] = useState(null);

    const [error, setError] = useState(false);

    const isAdmin = (Utils.getUserData().role === "ADMIN");
    const isTeacher = (Utils.getUserData().role === "TEACHER");

    const {id} = useParams();

    useEffect(() => {
        getDetailClassroom();
    }, []);


    const getDetailClassroom = () => {
        ClassRoomAPI.detailPartecipants(id).then(async (res) => {
            if(!isTeacher) {
                let wList = await ClassRoomAPI.listWaiting();
                wList = wList.filter((w) => w.level_id === res.level.id);
                setWaitingList(() => wList.reverse());
            }

            setClassroom(() => res)
            setLoading(false)
            setError(false);

        }).catch((err) => {
            setError(true);
            console.error(err);
        })
    }

    const deployClassroom = () => {
        global.SweetAlert.fire({
            title: "Attivare la Classe?",
            text: "Vuoi veramente attivare questa classe? Dopo l'attivazione bloccherai la vendita da parte dei consulenti",
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Si, conferma',
            cancelButtonText: 'Annulla',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ms-1'
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm: function() {
                return new Promise(function(resolve, reject) {
                    setLoading(true);
                    ClassRoomAPI.deploy(id).then(() => {
                        resolve();
                    }).catch(() => {
                        setLoading(false);
                        global.SweetAlert.fire({
                            icon: 'error',
                            title: 'Si è verificato un errore :(',
                            text: 'Purtroppo qualcosa è andato storto e non è stato possibile attivare la classe. Riprova più tardi.',
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        });
                        reject()
                    })
                });
            },
        }).then(function (result) {
            if (result.value) {
                getDetailClassroom();
                global.SweetAlert.fire({
                    icon: 'success',
                    title: 'Classe attivata!',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                });
            }
        })
    }

    useEffect(() => {
        if (dateEnd !== null && dateEnd !== classroom.date_end) {
            setLoading(() => true)
            ClassRoomAPI.editEnd(id, dateEnd).then(() => {
                getDetailClassroom();
                setDateEnd(() => null);
                global.SweetAlert.fire({
                    icon: 'success',
                    title: 'Data fine classe modificata!',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                });
            }).catch((e) => {
                setDateEnd(() => null)
                setLoading(() => false)

                let message = 'Non è stato possibile modificare la data di fine classe. Riprova più tardi.';

                if (e.status === "CLASSROOM_CONFLICT")
                    message = "La data di fine classi va in conflitto con altre classi già esistenti.";

                global.SweetAlert.fire({
                    icon: 'error',
                    title: 'Si è verificato un errore :(',
                    text: message,
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                });
            })
        }

        if (dateStart !== null && dateStart !== classroom.date_start) {
            setLoading(() => true)
            ClassRoomAPI.editStart(id, dateStart).then(() => {
                getDetailClassroom();
                setDateStart(() => null);
                global.SweetAlert.fire({
                    icon: 'success',
                    title: 'Data inizio classe modificata!',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                });
            }).catch((e) => {
                setDateStart(() => null)
                setLoading(() => false)

                let message = 'Non è stato possibile modificare la data di inizio classe. Riprova più tardi.';

                if (e.status === "CLASSROOM_CONFLICT")
                    message = "La data di inizio classe va in conflitto con altre classi già esistenti.";

                global.SweetAlert.fire({
                    icon: 'error',
                    title: 'Si è verificato un errore :(',
                    text: message,
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                });
            })
        }

    }, [dateEnd, dateStart]);

    const completeClassroom = () => {
        global.SweetAlert.fire({
            title: "Completare la Classe?",
            text: "Vuoi veramente completare questa classe?",
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Si, conferma',
            cancelButtonText: 'Annulla',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ms-1'
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm: function() {
                return new Promise(function(resolve, reject) {
                    setLoading(true);
                    ClassRoomAPI.complete(id).then(() => {
                        resolve();
                    }).catch(() => {
                        setLoading(false);
                        global.SweetAlert.fire({
                            icon: 'error',
                            title: 'Si è verificato un errore :(',
                            text: 'Purtroppo qualcosa è andato storto e non è stato possibile completare la classe. Riprova più tardi.',
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        });
                        reject()
                    })
                });
            },
        }).then(function (result) {
            if (result.value) {
                getDetailClassroom();
                global.SweetAlert.fire({
                    icon: 'success',
                    title: 'Classe completata!',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                });
            }
        })
    }

    const insertGroup = (waiting, group) => {
        if (group) {
            ClassRoomAPI.insertGroup(classroom.id, group.id, waiting.id).then(() => {
                getDetailClassroom();
                global.onSetModal({isOpen: false, content: ""})
            }).catch((err) => {
                console.error(err)
                global.onSetModal({isOpen: false, content: ""})
            })
            return;
        }

        if (classroom.groups.length === 1) {
            setLoadingWaiting(() => waiting.id)
            ClassRoomAPI.insertGroup(classroom.id, classroom.groups[0].id, waiting.id).then(() => {
                setLoadingWaiting(() => null);
                getDetailClassroom();
            }).catch((err) => {
                console.error(err)
                setLoadingWaiting(() => null)
            })
        } else {
            global.onSetModal({
                title: "SELEZIONA GRUPPO",
                content: "",
                isOpen: true,
                toggle: false,
                size: 'md',
                type: 'info',
                buttons: classroom.groups.map((group) => {
                    return { label: group.slot, disabled: (group.limit <= group.partecipants.length), color: 'info', onClick: () => insertGroup(waiting, group) }
                }).concat([{ label: "ANNULLA", color: 'danger', onClick: () => global.onSetModal({isOpen: false, content: ""}) }])
            })
        }

    }

    const removeGroup = async (sell, lead) => {

        if (lead) {
            LeadAPI.assignGroup({id: sell.lead_id, id_group: null}).then(() => {
                getDetailClassroom();
            }).catch((err) => {
                console.error(err)
            })
            return;
        }


        setLoadingSell(() => sell.sell_id)

        ClassRoomAPI.removeGroup(sell.sell_id).then(() => {
            setLoadingSell(() => null);
            getDetailClassroom();
        }).catch((err) => {
            console.error(err)
            setLoadingSell(() => null)
        })
    }

    const printStatus = () => {
        switch(classroom.status) {
            case "WAITING":
                return <Badge color="warning" style={{fontSize: "14px"}}>IN PROGRAMMAZIONE</Badge>;
            case "ACTIVE":
                return <Badge color="primary" style={{fontSize: "14px"}}>ATTIVA</Badge>;
            case "COMPLETED":
                return <Badge color="success" style={{fontSize: "14px"}}>COMPLETATA</Badge>;
            default:
                return null;
        }
    }

    const printStatusPayment = (payments, total) => {
        let totalPayed = 0;
        let verified = true;
        for (let i = 0; i < payments.length; i++) {
            totalPayed = totalPayed + payments[i].value;
            if (verified)
                verified = payments[i].verified;
        }

        if (total === 0)
            return <Badge color="primary">Omaggio</Badge>

        if (totalPayed === 0)
            return <Badge color="danger">Non Effettuato</Badge>

        if (totalPayed >= total)
            return (verified) ? <Badge color="success" >PAGATO</Badge> : <Badge color="warning" >DA VERIFICARE</Badge>

        return <Badge color="info">Acconto</Badge>
    }



    if (error) {
        return <React.Fragment>
            <div className="page-content">
                <Alert color="danger" >Si è verificato un errore durante il recupero del dettaglio classe.</Alert>
            </div>
        </React.Fragment>
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Programmazione Classe" pageTitle="CLTV Cloud"/>
                    {(loading) ? <Loading/> : <div>
                        <Row>
                            <Col>
                                {(isAdmin) ? <div className="d-flex justify-content-start">
                                    {(classroom.status !== "ACTIVE" && classroom.status !== "COMPLETED") ? <button
                                        type="button"
                                        className="btn btn-success btn-sm"
                                        onClick={() => deployClassroom()}>
                                        <i className="ri-check-fill align-bottom me-1"></i> ATTIVA CLASSE
                                    </button> : null}
                                    &nbsp;&nbsp;
                                    {(classroom.status === "ACTIVE") ? <button
                                        type="button"
                                        className="btn btn-success btn-sm"
                                        onClick={() => completeClassroom()}>
                                        <i className="ri-check-fill align-bottom me-1"></i> COMPLETA CLASSE
                                    </button> : null}
                                    &nbsp;&nbsp;
                                    {(classroom.status !== "COMPLETED") ? <RevertClassroom classroom={{id: classroom.id}} onResolve={() => window.location.replace("/classroom/edit/" + classroom.id)}/> : null}
                                </div> : null}

                                <div className="d-flex justify-content-end">
                                    {printStatus()}
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <div className='mb-2'>
                                    <h4 className="text-primary">{classroom.name}</h4>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className='mb-2'>
                                    <h5 className="text-secondary">{classroom.teacher.firstname} {classroom.teacher.lastname}</h5>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>
                                    Data di Inizio: <Input type="date" value={classroom.date_start} disabled={(!isAdmin)} onChange={(e) => setDateStart(e.target.value)} />
                                    Data di Fine: <Input type="date" defaultValue={classroom.date_end} min={moment(classroom.date_start).add(2, "days").toISOString().split("T")[0]} onChange={(e) => setDateEnd(e.target.value)} disabled={(!isAdmin && !isTeacher)}/>
                                </p>
                            </Col>
                        </Row>

                        <Row>
                            {classroom.groups.map((group, i) => {
                                return <Col xl={(classroom.groups.length > 1) ? 4 : ((isAdmin && classroom.status !== "COMPLETED") ? 6 : 12)} key={i}>
                                    <Card>
                                        <CardBody>
                                            <div className='mb-2'>
                                                <div className="justify-content-start">
                                                    <h5>{group.slot}</h5>
                                                </div>
                                                <div className="justify-content-end">
                                                    <h5 className={(group.partecipants.length + group.leads.length === group.limit) ? "text-danger" : undefined}><strong>{group.partecipants.length + group.leads.length} / {group.limit}</strong></h5>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className='gridjs gridjs-container'>
                                                <div className='gridjs-wrapper'>
                                                    <table role="grid" className="gridjs-table">
                                                        <thead className="gridjs-thead">
                                                            <tr className="gridjs-tr">
                                                                {(!isTeacher && classroom.status !== "COMPLETED") ? <th className="gridjs-th"></th> : null}
                                                                {(isAdmin) ? <th className="gridjs-th">DATA</th> : null}
                                                                <th className="gridjs-th">NOME</th>
                                                                {(isAdmin || !isTeacher) ? <th className="gridjs-th">PAGAMENTO</th> : null}
                                                                {(!isTeacher) ? <th className="gridjs-th">NOTA</th> : null}
                                                                {(isAdmin) ? <th className="gridjs-th">CONSULENTE</th> : null}
                                                                {(!isAdmin) ? <th className="gridjs-th">EMAIL</th> : null}
                                                                {(!isAdmin) ? <th className="gridjs-th">TEL</th> : null}
                                                            </tr>
                                                        </thead>
                                                        <tbody className="gridjs-tbody">
                                                            {group.partecipants.map((partecipant, i) => {
                                                                return <tr className="gridjs-tr" key={i} >
                                                                    {(!isTeacher) ? <td className="gridjs-td text-nowrap text-center" width="15">
                                                                        {(isAdmin || partecipant.sell_id !== null) ? ((loadingSell === null) ? <i className="ri-close-circle-fill fs-3 align-bottom me-1 text-danger cursor-pointer" onClick={() => removeGroup(partecipant, false)}></i> : ((loadingSell === partecipant.sell_id) ? <Spinner style={{ width: '1rem', height: '1rem' }} /> : null)) : null}
                                                                    </td> : null}
                                                                    {(isAdmin) ? <td className="gridjs-td text-nowrap" width="15">
                                                                        <small>{moment(partecipant.createdAt).format("DD/MM/YYYY")}</small>
                                                                    </td> : null}
                                                                    <td className="gridjs-td text-nowrap" width="100">
                                                                        {(partecipant.client_id !== null) ? <Link to={"/client/" + partecipant.client_id}><b>{partecipant.student}</b></Link> : <b>{partecipant.student}</b>}
                                                                    </td>
                                                                    {(isAdmin || !isTeacher) ? <td className="gridjs-td text-nowrap text-center" width="100">
                                                                        {(partecipant.sell_id !== null) ? printStatusPayment(partecipant.payments, partecipant.total) : "--"}<br />
                                                                        {(partecipant.sell_id !== null) ? <Link to={"/sell/" + partecipant.sell_id}><small className="text-primary cursor-pointer">Dettaglio Vendita</small></Link> : null}
                                                                    </td> : null}
                                                                    {(!isTeacher) ? <td className="gridjs-td text-nowrap" width="100">
                                                                        <small>{partecipant.note}</small>
                                                                    </td> : null}
                                                                    {(isAdmin) ? <td className="gridjs-td text-nowrap" width="100">
                                                                        <b>{partecipant.consulent}</b>
                                                                    </td> : null}
                                                                    {(!isAdmin) ? <td className="gridjs-td text-nowrap" width="100">
                                                                        <a href={"mailto:" + partecipant.email}>{partecipant.email}</a>
                                                                    </td> : null}
                                                                    {(!isAdmin) ? <td className="gridjs-td text-nowrap" width="100">
                                                                        <a href={"tel:" + partecipant.cell}>{partecipant.cell}</a>
                                                                    </td> : null}
                                                                </tr>
                                                            })}
                                                            {(!isTeacher) ? group.leads.map((lead, i) => {
                                                                return <tr className="gridjs-tr" key={i} >
                                                                    {(!isTeacher) ? <td className="gridjs-td text-nowrap text-center" width="15">
                                                                        {(lead.lead_id !== null) ? <i className="ri-close-circle-fill fs-3 align-bottom me-1 text-danger cursor-pointer" onClick={() => removeGroup(lead, true)}></i> : null}
                                                                    </td> : null}
                                                                    {(isAdmin) ? <td className="gridjs-td text-nowrap" width="15">

                                                                    </td> : null}
                                                                    <td className="gridjs-td text-nowrap" width="100">
                                                                        {(lead.lead_id !== null) ? <Link to={"/lead/detail/" + lead.lead_id}><b>{lead.student}</b></Link> : <b>{lead.student}</b>}
                                                                    </td>
                                                                    <td className="gridjs-td text-nowrap text-center" width="100">

                                                                    </td>
                                                                    <td className="gridjs-td text-nowrap" width="100">
                                                                        LEAD
                                                                    </td>
                                                                    {(isAdmin) ? <td className="gridjs-td text-nowrap" width="100">
                                                                        <b>{lead.consulent}</b>
                                                                    </td> : null}
                                                                    {(!isAdmin) ? <td className="gridjs-td text-nowrap" width="100">
                                                                        <a href={"mailto:" + lead.email}>{lead.email}</a>
                                                                    </td> : null}
                                                                    {(!isAdmin) ? <td className="gridjs-td text-nowrap" width="100">
                                                                        <a href={"tel:" + lead.cell}>{lead.cell}</a>
                                                                    </td> : null}
                                                                </tr>
                                                            }) : null}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            })}

                            {(!isTeacher) ? <Col xl={(classroom.groups.length > 1) ? 12 : 6}>
                                <Card>
                                    <CardBody>
                                        <div className='mb-2'>
                                            <h5><i className="mdi mdi-clock fs-16 align-middle"></i> LISTA DI ATTESA</h5>
                                            <p>Livello {classroom.level.name}</p>
                                        </div>
                                        <hr />
                                        <Grid
                                            data={waitingList}
                                            columns={[
                                                {
                                                    id: 'id',
                                                    name: "",
                                                    width: "100",
                                                    formatter: (cell, row) => _(<span className="text-nowrap">
                                                        {(loadingWaiting === null && (classroom.groups.length > 1 || classroom.groups[0].limit > classroom.groups[0].partecipants.length)) ? <i className="ri-checkbox-fill fs-3 align-bottom me-1 text-success cursor-pointer" onClick={() => insertGroup(waitingList[waitingList.findIndex((w) => w.id === cell)])}></i> : ((loadingWaiting === cell) ? <Spinner style={{ width: '1rem', height: '1rem' }} /> : null)}
                                                    </span>)
                                                },
                                                {
                                                    id: 'createdAt',
                                                    name: 'DATA',
                                                    width: "100",
                                                    formatter: (cell, row) => _(<span className="text-nowrap">
                                                        <small>{moment(cell).format("DD/MM/YYYY")}</small>
                                                    </span>)
                                                },
                                                {
                                                    id: 'client_id',
                                                    hidden: true
                                                },
                                                {
                                                    id: 'student',
                                                    name: 'NOME',
                                                    formatter: (cell, row) => _(<span className="text-nowrap">
                                                        <a href="javascript:void(0)" onClick={() => history.push("/client/" + row.cells[2].data)}><b>{cell}</b></a>
                                                    </span>)
                                                },
                                                {
                                                    id: 'total',
                                                    hidden: true
                                                },
                                                {
                                                    id: 'sell_id',
                                                    hidden: true
                                                },
                                                {
                                                    id: 'payments',
                                                    name: 'PAGAMENTO',
                                                    formatter: (cell, row) => _(<div className="text-nowrap text-center">
                                                        {printStatusPayment(cell, row.cells[4].data)}<br />
                                                        <a href="javascript:void(0)" onClick={() => history.push("/sell/" + row.cells[5].data)}><small className="text-primary cursor-pointer">Dettaglio Vendita</small></a>
                                                    </div>)
                                                },
                                                {
                                                    id: 'note',
                                                    name: 'NOTA',
                                                    formatter: (cell, row) => _(<span className="text-nowrap">
                                                        <small>{cell}</small>
                                                    </span>)
                                                },
                                                {
                                                    id: 'consulent',
                                                    name: 'CONSULENTE',
                                                    formatter: (cell, row) => _(<span className="text-nowrap">
                                                        <b>{cell}</b>
                                                    </span>)
                                                }
                                            ]}
                                            style={{table: {'white-space': 'nowrap'}}}
                                            resizable={true}
                                            search={true}
                                            sort={true}
                                            pagination={{ enabled: true, limit: 20}}
                                            language={{
                                                'search': {
                                                    'placeholder': '🔍 Cerca...'
                                                },
                                                'pagination': {
                                                    'previous': 'Indietro',
                                                    'next': 'Avanti',
                                                    'showing': 'Stai visualizzando da',
                                                    'results': 'Partecipanti',
                                                    'to': 'a',
                                                    'of': 'di',
                                                },
                                                'noRecordsFound': 'Nessun partecipante trovato',
                                                'error': 'Si è verificato un errore non previsto',
                                            }}
                                        />
                                    </CardBody>
                                </Card>
                            </Col> : null}
                        </Row>
                    </div>}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ClassroomMain;
