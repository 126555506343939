import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import {withRouter} from "react-router-dom";

import LeadTable from "./LeadTable";

class LeadList extends Component {

    render() {
        document.title ="Leads | CLTV Cloud";

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <BreadCrumb title="Leads" pageTitle="CLTV Cloud"/>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <LeadTable />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(LeadList);
