let autocomplete = function (input, type) {
    return new Promise(function (resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.autocompleteGeo, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': hash
            },
            body: JSON.stringify({
                input: input,
                type: type
            })
        }).then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('autocompleteGeo', responseJson);
                if (responseJson.status === "PLACE_AUTOCOMPLETE_OK")
                    resolve(responseJson.list);
                else
                    reject(responseJson.message);
            })
            .catch((e) => {
                console.error(e.message);
            });
    });
};

let detail = function (place_id) {
    return new Promise(function (resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.detailGeo, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': hash
            },
            body: JSON.stringify({
                place_id: place_id
            })
        }).then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('detailGeo', responseJson);
                if (responseJson.status === "PLACE_DETAIL_OK")
                    resolve(responseJson.place);
                else
                    reject(responseJson.message);
            })
            .catch((e) => {
                console.error(e.message);
            });
    });
};

let geoGps = function (params) {
    return new Promise(function (resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.geoGps, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': hash
            },
            body: JSON.stringify({
                lat: params.lat,
                lon: params.lon
            })
        }).then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('gps detail', responseJson);
                if (responseJson.status === "PLACE_GPS_OK")
                    resolve(responseJson.place);
                else
                    reject(responseJson.message);
            })
            .catch((e) => {
                console.error(e.message);
            });
    });
};

const GeoAPI = {
    autocomplete: autocomplete,
    detail: detail,
    geoGps: geoGps
};

module.exports = GeoAPI;
