import React, { Component } from 'react';
import {
    Alert,
    Badge,
    Card,
    CardHeader,
    CardBody,
    Col,
    Container,
    Row,
    Nav,
    NavItem,
    NavLink,
    TabPane,
    TabContent,
    Table,
    Input, Label, FormFeedback, CardFooter, Button, FormText
} from 'reactstrap';
import { withRouter, Redirect, Link, } from "react-router-dom";

import {AdminAPI, ClientAPI, LeadAPI, ReferentAPI} from "api";

import { Loading } from "custom";
import BreadCrumb from "../../Components/Common/BreadCrumb";

import { GeoForm, GestReferent, Utils } from "custom";
import Select from "react-select";
import Question from "../Lead/nav/Question";

class ClientGest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            errors: false,
            form: null,
            mode: "edit"
        };
        document.title = "Gestione Cliente | CLTV Cloud";
    }

    componentDidMount() {
        if (typeof this.props.match.params.id !== "undefined") {
            this.getDetailClient()
        } else {
            const query = new URLSearchParams(window.location.search)
            const data = query.get('info');
            if (query.get('info')) {
                try {
                    const info = data && JSON.parse(atob(data))
                    const leadId = query.get('lead');

                    LeadAPI.detail(leadId).then((leadDetail) => {

                        this.setState({
                            form: {
                                id: null,
                                id_lead: leadId,
                                firstname: info?.name,
                                lastname: "",
                                country: leadDetail.details?.country,
                                vat_id: "",
                                job: "",
                                shift: (leadDetail.details !== null) ? leadDetail.details.shift : false,
                                cell: info?.phone,
                                email: info?.email,
                                fiscal_code: "",
                                full_address: "",
                                details: leadDetail.details
                            }, loading: false, marker: null, referents: [], mode: "add"
                        });
                    }).catch((err) => {
                        console.log('error',err)
                    })
                } catch (error) {
                    console.log('error',error)
                }

            } else {

                this.setState({
                    form: {
                        id: null,
                        id_lead: query.get('lead'),
                        firstname: "",
                        lastname: "",
                        country: "",
                        vat_id: "",
                        cell: "",
                        job: "",
                        shift: false,
                        email: "",
                        fiscal_code: "",
                        full_address: "",
                        details: null
                    }, loading: false, marker: null, referents: [], mode: "add"
                });
            }
        }
    }

    getDetailClient() {
        ClientAPI.detail(this.props.match.params.id).then((client) => {
            this.setState({ form: client, loading: false, mode: "edit" })
        }).catch((err) => {
            console.error(err);
            this.setState({ error: true })
        })
    }

    changeForm(key, text) {
        if (key === 'cell') {
            if ((isNaN(text) && text !== "+") || text.indexOf(".") > -1)
                return;

            if (text.length >= 3 && text.indexOf("+") === -1)
                text = "+39" + text;
        }

        const form = this.state.form;
        form[key] = text;
        this.setState({ form: form });
    }

    saveClient() {
        const self = this;
        let form = this.state.form;
        let error = {};

        if (form.firstname === '')
            error.firstname = "Il campo nome è obbligatorio";

        if (form.cell === '')
            error.cell = "Il campo telefono è obbligatorio";

        if (form.country === '')
            error.country = "Il campo nazione è obbligatorio";

        if (form.email !== '' && !Utils.isValidEmail(form.email))
            error.email = "La mail inserita non è corretta";

        if (form.cell !== '' && !Utils.isValidCell(form.cell))
            error.cell = "Il cellulare inserito non è corretto";

        if (form.fiscal_code !== '' && !Utils.isValidFiscalCode(form.fiscal_code))
            error.fiscal_code = "Il codice fiscale non è corretto";

        if (form.vat_id !== '' && !Utils.isPiva(form.vat_id))
            error.vat_id = "La partita iva non è corretta";

            this.setState({ errors: error });

        if (Object.keys(error).length === 0) {
            this.setState({ loading: true });

            let add = (form.id === null);
            let api = (add) ? ClientAPI.add : ClientAPI.edit;

            api(form).then((response) => {
                global.onSetModal({ loading: false });
                global.SweetAlert.fire({
                    title: (add) ? 'Cliente creato' : 'Cliente modificato',
                    text: (add) ? 'Il cliente è stato creato correttamente.' : 'Il cliente è stato modificato correttamente.',
                    icon: 'success',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    confirmButtonText: (add) ? 'Vai alla vendita' : 'Chiudi',
                    buttonsStyling: false
                }).then(function (result) {
                    if (result.value) {
                        (add) ? self.props.history.replace("/sell/create?client=" + response.id) : window.location.replace("/client/" + form.id);
                    }
                })
            }).catch((error) => {
                console.log('error',error);
                this.setState({ loading: false });
                if(error.status == 'USER_EXIST'){
                    this.setState({ errors: {email: "La mail risulta già registrata"}});
                } else {
                    global.SweetAlert.fire({
                        title: 'Errore',
                        text: (add) ? 'Si è verificato un errore durante la creazione del cliente' : 'Si è verificato un errore durante la modifica del cliente',
                        icon: 'error',
                        customClass: {
                            confirmButton: 'btn btn-primary'
                        }
                    });
                }
            })
        }
    }

    render() {

        if (this.state.error) {
            return <React.Fragment>
                <div className="page-content">
                    <Alert color="danger">Si è verificato un errore durante il recupero dei dettagli del cliente.</Alert>
                </div>
            </React.Fragment>
        }

        const form = this.state.form;
        const errors = this.state.errors;

        const countries = Utils.printListCountries();

        return (
            <React.Fragment>
                {(this.state.loading) ? <Loading /> : <div className="page-content">
                    <Container fluid>
                        <BreadCrumb title={(this.state.mode === "edit") ? "MODIFICA CLIENTE" : "CREA CLIENTE"}
                            pageTitle="CLTV Cloud" />
                        <Button className="btn btn-sm btn-primary m-1" onClick={() => window.history.go(-1)}><i
                            className="ri-arrow-left-circle-line fs-5 text-white-75 align-middle" /> Torna indietro</Button>
                        <Card>
                            <CardHeader>
                                <h5>
                                    <i className="ri-user-2-fill align-bottom me-1" style={{ margin: 10 }}></i> ANAGRAFICA
                                </h5>
                            </CardHeader>
                            <CardBody>
                                {(form.isRegistered && Utils.getUserData().role === "ADMIN") ? <Row>
                                    <Col>
                                        <div className="form-check form-switch form-switch-custom form-switch-primary mb-3">
                                            <Input className="form-check-input" type="checkbox" role="switch" checked={form.active}
                                                onChange={(e) => this.changeForm('active', e.target.checked)} />
                                            <Label className="form-check-label">Disattiva / Attiva Utente</Label>
                                        </div>
                                    </Col>
                                </Row> : null}
                                <Row>
                                    <Col md="6">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Nome*
                                            </Label>
                                            <Input type="text" value={form.firstname}
                                                invalid={Boolean(errors.firstname)}
                                                onChange={(e) => this.changeForm('firstname', e.target.value)} />
                                            <FormFeedback>{errors.firstname}</FormFeedback>
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Cognome
                                            </Label>
                                            <Input type="text" value={form.lastname}
                                                invalid={Boolean(errors.lastname)}
                                                onChange={(e) => this.changeForm('lastname', e.target.value)} />
                                            <FormFeedback>{errors.lastname}</FormFeedback>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Cellulare*
                                            </Label>
                                            <Input type="tel" value={form.cell}
                                                invalid={Boolean(errors.cell)}
                                                onChange={(e) => this.changeForm('cell', e.target.value)} />
                                            <FormFeedback>{errors.cell}</FormFeedback>
                                            <FormText>
                                                Assicurati di digitare correttamente il <u>numero di cellulare</u>, è necessario inserire anche il prefisso internazionale (+39 per Italia)
                                            </FormText>
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Email
                                            </Label>
                                            <Input type="email" value={form.email}
                                                invalid={Boolean(errors.email)}
                                                onChange={(e) => this.changeForm('email', e.target.value)} />
                                            <FormFeedback>{errors.email}</FormFeedback>
                                            <FormText>
                                                Per evitare la duplicazione di dati assicurati di inserire la <u>mail corretta</u>,
                                            </FormText>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="4">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Occupazione
                                            </Label>
                                            <Input type="text" value={form.job}
                                                   invalid={Boolean(errors.job)}
                                                   onChange={(e) => this.changeForm('job', e.target.value)} />
                                            <FormFeedback>{errors.job}</FormFeedback>
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Codice Fiscale
                                            </Label>
                                            <Input type="text" value={form.fiscal_code}
                                                invalid={Boolean(errors.fiscal_code)}
                                                onChange={(e) => this.changeForm('fiscal_code', e.target.value)} />
                                            <FormFeedback>{errors.fiscal_code}</FormFeedback>
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Partita IVA
                                            </Label>
                                            <Input type="text" value={form.vat_id}
                                                invalid={Boolean(errors.vat_id)}
                                                onChange={(e) => this.changeForm('vat_id', e.target.value)} />
                                            <FormFeedback>{errors.vat_id}</FormFeedback>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="3">
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Nazionalità*
                                            </Label>
                                            <Select
                                                value={(form.country !== "") ? countries[countries.findIndex(val => val.value === form.country)] : undefined}
                                                placeholder={"Seleziona la nazione..."}
                                                onChange={(selectedOption) => {
                                                    this.changeForm('country', selectedOption.value)
                                                }}
                                                options={countries}
                                                formatOptionLabel={country => (
                                                    <div className="country-option">
                                                        <img src={country.img} alt="country" width="25px" />
                                                        <span>&nbsp;&nbsp;{country.label}</span>
                                                    </div>
                                                )}
                                            />
                                            {(errors.country) ? <span className="text-danger">{errors.country}</span> : null}
                                        </div>
                                    </Col>
                                    <Col md="9">
                                        <Col md="12">
                                            <div className='mb-2'>
                                                <Label className='form-label'>
                                                    Indirizzo
                                                </Label>
                                                <Input type="text" value={form.full_address}
                                                    invalid={Boolean(errors.full_address)}
                                                    onChange={(e) => this.changeForm('full_address', e.target.value)} />
                                                <FormFeedback>{errors.full_address}</FormFeedback>
                                            </div>
                                        </Col>
                                        {/*<div className='mb-2'>*/}
                                        {/*    <Label className='form-label'>*/}
                                        {/*        Indirizzo*/}
                                        {/*    </Label>*/}
                                        {/*    <GeoForm hideDetail preset={{full_address: form.full_address}}*/}
                                        {/*             errors={errors} onResolve={(full_address) => this.changeForm('full_address', full_address)}/>*/}
                                        {/*    {(errors.country) ? <FormFeedback>{errors.country}</FormFeedback> : null}*/}
                                        {/*</div>*/}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="form-check form-switch form-switch-custom form-switch-primary mb-3">
                                            <Input className="form-check-input" type="checkbox" role="switch" checked={form.shift}
                                                   onChange={(e) => this.changeForm('shift', e.target.checked)} />
                                            <Label className="form-check-label">Turnista</Label>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardBody>
                                <button
                                    type="button"
                                    className="btn btn-success float-end" onClick={() => this.saveClient()}>
                                    <i className="ri-save-2-fill align-bottom me-1"></i> SALVA
                                </button>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader>
                                <h5>
                                    <i className="ri-question-answer-fill align-bottom me-1" style={{ margin: 10 }}></i> DETTAGLI
                                </h5>
                            </CardHeader>
                            <CardBody>
                                <Question id={this.state.form.id} details={this.state.form.details} onReload={this.getDetailClient.bind(this)}/>
                            </CardBody>
                        </Card>
                    </Container>
                </div>}
            </React.Fragment>
        );
    }
}

export default withRouter(ClientGest);
