import React, {useEffect, useState} from 'react';
import {Alert, Card, CardBody, Col, Container, Input, Row} from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
// import ReactApexChart from 'react-apexcharts';
import CountUp from "react-countup";
import {Loading, Utils} from "custom";
import {Link} from "react-router-dom";
import Flatpickr from "react-flatpickr";

import { Italian } from "flatpickr/dist/l10n/it";
import Select from "react-select";
import {ConsulentAPI, CourseAPI, LevelAPI} from "api";

const moment = require("moment");


const PaymentSetting = ({onFilterPayments, onReload}) => {

    const [consulents, setConsulents] = useState([]);

    const [filterType, setFilterType] = useState(null);
    const [filterConsulent, setFilterConsulent] = useState(null);
    const [filterMethod, setFilterMethod] = useState(null);

    const [range, setRange] = useState({start_date: moment().startOf("month"), end_date: moment()});

    const isAdmin = Utils.getUserData().role === "ADMIN";

    useEffect(() => {

        if (isAdmin) {
            ConsulentAPI.list().then((resCons) => {
                let consulentsArr = [
                    {value: -1, label: <small><i>Non Assegnato</i></small>}
                ];

                setConsulents(() => consulentsArr.concat(resCons.map((c) => {
                    return {value: c.id, label: c.firstname + " " + c.lastname}
                })))
            }).catch((e) => {
                console.error(e.message)
            })
        }

    }, [])

    useEffect(() => {
        onReload({start_date: moment(range.start_date).format("DD-MM-YYYY"), end_date: moment(range.end_date).format("DD-MM-YYYY")})
    }, [range])


    useEffect(() => {
        onFilterPayments(filterType, filterConsulent, filterMethod)
    }, [filterType, filterConsulent, filterMethod])


    return (
        <React.Fragment>
            <Row className="mb-1">
                <Col xl={4} className="p-1">
                    <div className="input-group">
                        <Flatpickr
                            className="form-control border-0 dash-filter-picker shadow"
                            options={{
                                mode: "range",
                                dateFormat: "d/m/Y",
                                locale: Italian,
                            }}
                            value={[range.start_date.format("DD/MM/YYYY"), range.end_date.format("DD/MM/YYYY")]}
                            onChange={(selectedDates) => {
                                if (selectedDates.length === 2) {
                                    setRange({
                                        start_date: moment(selectedDates[0]),
                                        end_date: moment(selectedDates[1]),
                                    });
                                }
                            }}
                        />
                        <div className="input-group-text bg-primary border-primary text-white"><i className="ri-calendar-2-line"></i></div>
                    </div>
                </Col>
                {(isAdmin) ? <Col xl={4} className="p-1">
                    <Select
                        value={filterConsulent}
                        options={consulents}
                        isClearable
                        placeholder="Filtra consulente"
                        className='me-5 w-100'
                        onChange={(val) => {
                            setFilterConsulent(() => (val !== null) ? val : null)
                        }}
                    ></Select>
                </Col> : null}
                <Col xl={4} className="p-1">
                    <div className="input-group">
                        <Select
                            value={filterType}
                            options={[
                                {value: false, label: "DA VERIFICARE"},
                                {value: true, label: "VERIFICATI"}
                            ]}
                            isClearable
                            placeholder="Filtra stato"
                            className='me-5 w-100'
                            onChange={(val) => {
                                setFilterType(() => (val !== null) ? val : null)
                            }}
                        ></Select>
                    </div>
                </Col>
                <Col xl={4} className="p-1">
                    <div className="input-group">
                        <Select
                            value={filterMethod}
                            options={[
                                {value: "BANK_TRANSFER", label: "BONIFICO BANCARIO"},
                                {value: "PAYPAL", label: "PAYPAL"},
                                {value: "KLARNA", label: "STRIPE"}
                            ]}
                            isClearable
                            placeholder="Filtra metodo pagamento"
                            className='me-5 w-100'
                            onChange={(val) => {
                                setFilterMethod(() => (val !== null) ? val : null)
                            }}
                        ></Select>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default PaymentSetting;




