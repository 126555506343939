import React, {Fragment} from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
    Row,
    Col,
    Label,
    FormFeedback,
    Input,
    FormText, Badge
} from 'reactstrap';

import { ClassRoomTrialAPI } from "api";
import {Utils} from "../../index";


class GestLinkClassroomTrial extends React.Component {
    constructor(props) {
        super(props);
        let preset = this.props.preset;

        this.state = {
            form: {
                id: (preset) ? preset.id : null,
                link: (preset) ? preset.link : null,
            },
            loading: false,
            errors: false
        };
    }

    changeForm(key, text) {
        const form = this.state.form;
        form[key] = text;
        this.setState({form: form}, () => this.showModal());
    }

    showModal() {

            let form = this.state.form;
            let errors = this.state.errors;

            global.onSetModal({
                title: "MODIFICA LINK LEZIONE DI PROVA",
                content: <div>
                    <Row>
                        <Col>
                            <div className='mb-2'>
                                <Label className='form-label'>
                                    Link Lezione di Prova*
                                </Label>
                                <Input type="url" value={form.link}
                                       invalid={Boolean(errors.link)}
                                       onChange={(e) => this.changeForm('link', e.target.value)}/>
                                <small><strong>Attenzione:</strong> inserendo il link abiliterai l'accesso alla lezione di prova</small>
                                <FormFeedback>{errors.link}</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                </div>,
                isOpen: true,
                toggle: false,
                size: 'lg',
                type: 'info',
                buttons: [
                    {label: 'SALVA', color: 'success', onClick: () => this.saveTrialClassroom()},
                    {
                        label: 'CHIUDI',
                        color: 'danger',
                        onClick: () => this.resetForm()
                    }
                ]
            })



    }

    resetForm() {
        let preset = this.props.preset;

        this.setState({
            form: {
                id: (preset) ? preset.id : null,
                link: (preset) ?  preset.link : null
            },
            errors: false
        })
        global.onSetModal({isOpen: false, loading: false, content: ""})
    }

    saveTrialClassroom() {
        const self = this;
        let form = this.state.form;
        let error = {};

        if (form.link !== "" && form.link !== null && !Utils.isValidUrl(form.link))
            error.link = "Il link non è valido";

        this.setState({errors: error}, () => this.showModal());

        if (Object.keys(error).length === 0) {
            global.onSetModal({loading: true});

            ClassRoomTrialAPI.editLink({
                id: form.id,
                link: (form.link !== "") ? form.link : null
            }).then(() => {
                global.onSetModal({loading: false});
                global.SweetAlert.fire({
                    title: 'Link modificato correttamente',
                    icon: 'success',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    confirmButtonText: 'Chiudi',
                    buttonsStyling: false
                }).then(function (result) {
                    if (result.value) {
                        self.resetForm();
                        self.props.onResolve();
                    }
                })
            }).catch((err) => {
                global.onSetModal({loading: false});
                global.SweetAlert.fire({
                    title: 'Errore',
                    text: 'Si è verificato un errore durante la modifica del link',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                });
            })
        }
    }


    render() {
        return (
            <React.Fragment>
                <li className="list-inline-item cursor-pointer" onClick={() => this.showModal()}>
                    <i className={"ri-link fs-20 " + ((this.state.form.link !== null) ? "text-success" : "text-danger")}></i>
                </li>
            </React.Fragment>
        )
    }
}

export default GestLinkClassroomTrial;
