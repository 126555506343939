import React, {Fragment} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert, DropdownItem} from 'reactstrap';
import {CourseAPI} from "api";

class DeleteCourse extends React.Component {
    constructor(props) {
        super(props);
    }

    showModal() {
        const self = this;
        global.SweetAlert.fire({
            title: "Elimina corso",
            text: 'Attenzione, eliminando il corso annullerai tutti gli accessi ad esso associato. Vuoi procedere?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, elimina',
            cancelButtonText: 'Annulla',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ms-1'
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm: function() {
                return new Promise(function(resolve, reject) {
                    CourseAPI.remove(self.props.id).then(() => {
                        resolve();
                    }).catch(() => {
                        global.SweetAlert.fire({
                            icon: 'error',
                            title: 'Si è verificato un errore :(',
                            text: 'Purtroppo qualcosa è andato storto e non è stato possibile eliminare il corso. Riprova più tardi.',
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        });
                        reject()
                    })
                });
            },
        }).then(function (result) {
            if (result.value) {
                global.SweetAlert.fire({
                    icon: 'success',
                    title: 'Corso eliminato!',
                    text: 'Corso eliminato correttamente.',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                });
                self.props.onResolve();
            }
        })
    }


    render() {
        return (
            <React.Fragment>
                <DropdownItem className="dropdown-item remove-list" onClick={() => this.showModal()}>
                    <i className="ri-delete-bin-5-line me-2 align-bottom text-muted"></i>Elimina
                </DropdownItem>
            </React.Fragment>
        )
    }
}

export default DeleteCourse;
