import { LessonAPI } from 'api';
import React, { Component } from 'react'
import Loader from 'react-loader-spinner';
import { Button, Col, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';

export default class ReUploadFile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            errors: null,
            showCreateFile: false,
            file: null
        };
    }
    CreateFile = () => {
        let form = this.state;
        let error = {};

        if (form.file == null)
            error.file = "Il campo file è obbligatorio";

        if (form.file.size > 104857600)
            error.file = "Il file supera 100MB";

        this.setState({ errors: error });
        console.log('error',error);
        if (Object.keys(error).length === 0) {
            this.setState({ loading: true });
            LessonAPI.uploadFile(this.state.file, this.props.id).then((res2) => {
                if (res2.status == 'FILE_UPLOAD_OK') {
                    this.setState({ loading: false, showCreateFile: false });
                    global.SweetAlert.fire({
                        title: 'File creato',
                        text: 'File caricato con successo',
                        icon: 'success',
                        allowOutsideClick: false,
                        customClass: {
                            confirmButton: 'btn btn-primary'
                        },
                        confirmButtonText: 'Chiudi',
                        buttonsStyling: false
                    })
                    this.props.onResolve()
                }
            }).catch(() => {
                this.setState({ loading: false });
                global.SweetAlert.fire({
                    title: 'Errore',
                    text: 'Errore durante il caricamento del file',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                });
            })
        }

    }

render() {
    return (
        <>
            <Button
                type="button"
                size="sm"
                color='primary'
                onClick={() => this.setState({ showCreateFile: true })}
            >
                <i className='ri-upload-2-fill align-bottom me-1'></i>
                Ricaricare
            </Button>
            <Modal style={{ overflowY: "auto" }} isOpen={this.state.showCreateFile} scrollable={true} className={'modal-dialog-scrollable modal-lg'}>
                <ModalBody className='px-sm-5 pt-5 pb-50'>
                    <div className='text-center mb-5'>
                        <h3 className='mb-1'>Ricaricare il file</h3>
                    </div>
                    {this.state.loading ?
                        <div style={{
                            textAlign: 'center',
                            height: '200px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Loader type={'TailSpin'} color='#E24815' />
                        </div> :
                        <Row>
                            <Col sm={12}>
                                <FormGroup>
                                    <Label for="exampleSelect">Seleziona file</Label>
                                    <Input
                                        type="file"
                                        name="select"
                                        onChange={(e) => this.setState({ file: e.target.files[0] })}
                                        accept="image/*,video/*,audio/*,application/pdf,application/vnd.ms-excel"
                                        invalid={Boolean(this.state.errors?.file)}
                                    />
                                    <small>Massima dimensione di caricamento: <b>100 MB</b></small>
                                    <FormFeedback>{this.state.errors?.file}</FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>}
                </ModalBody>
                <ModalFooter>
                    <Button color='success' onClick={this.CreateFile} >
                        SALVA
                    </Button>
                    <Button color='danger' onClick={() => this.setState({ showCreateFile: false, })} >
                        CHIUDI
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}
}
