import React, { Component } from 'react'
import { Col, FormFeedback, Input, Label, Row } from 'reactstrap';
import Select, { components } from "react-select";
import { Utils } from '../..';
import { LeadAPI } from 'api';
export default class EditLead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editId: null,
      editName: null,
      editEmail: null,
      editPhone: null,
      loading: false,
      errors: false
    };
  }

  componentDidMount() {
    this.setState({
      editId: this.props.lead.editId,
      editName: this.props.lead.editName,
      editEmail: this.props.lead.editEmail,
      editPhone: this.props.lead.editPhone
    })
  }

  changeForm(key, text) {
    const form = this.state;

    if (key === 'editPhone') {
      if ((isNaN(text) && text !== "+") || text.indexOf(".") > -1)
        return;

      if (text.length >= 3 && text.indexOf("+") === -1)
        text = "+39" + text;

      text = text.replace(/ /g, "");
    }

    form[key] = text;
    this.setState({ form: form }, () => this.showModal());
  }

  saveEditLead = () => {
    const form = this.state;
    let error = {};

    if (form.editName === '')
      error.editName = "Il nome è obbligatorio";

    if (form.editPhone !== '' && !Utils.isValidCell(form.editPhone))
      error.editPhone = "Il cellulare inserito non è corretto";

    if (form.editPhone === '')
      error.editPhone = "Il campo cellulare è obbligatorio";

    if (!Utils.isValidEmail(form.editEmail) && form.editEmail !== "")
      error.editEmail = "La mail inserita non è corretta";


    this.setState({ errors: error });

    if (Object.keys(error).length === 0) {
      var lead = {
        id: form.editId,
        name: form.editName,
        email: form.editEmail,
        phone: form.editPhone
      }

      LeadAPI.edit(lead)
          .then((data) => {
            if (data.status == "LEAD_EDITED") {
              global.SweetAlert.fire({
                title: 'Lead modificato',
                text: 'Lead modificato correttamente',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary'
                },
              });
              this.props.getLeads()
              this.resetForm();
            }
          }).catch((err) => {
            console.log(err)
        global.SweetAlert.fire({
          title: 'Error',
          text: 'Si è verificato un errore durante la modifica del lead',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
        });
        this.resetForm();
      })
    } else {
      this.showModal();
    }
  }

  resetForm = () =>{
    // this.setState({
    //   editId: null,
    //   editName: null,
    //   editEmail: null,
    //   editPhone: null,
    //   editCountry: null
    // },()=> global.onSetModal({ isOpen: false }))
    global.onSetModal({ isOpen: false })
  }

  showModal() {
    let form = this.state.logedit;
    let errors = this.state.errors;
    const countries = Utils.printListCountries();
    global.onSetModal({
      title: this.props.label,
      content: <div>
        <div className='text-center mb-5'>
          <h3 className='mb-1'>MODIFICA LEAD</h3>
        </div>
        <Row>
          <Col md="12">
            <div className='mb-2'>
              <Label className='form-label'>
                Name
              </Label>
              <Input
                type="text"
                value={this.state.editName}
                invalid={Boolean(errors?.editName)}
                onChange={(e) => this.changeForm('editName', e.target.value)}
              />
              <FormFeedback>{errors?.editName}</FormFeedback>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <div className='mb-2'>
              <Label className='form-label'>
                Telefono
              </Label>
              <Input
                type="tel"
                value={this.state.editPhone}
                invalid={Boolean(errors?.editPhone)}
                onChange={(e) => this.changeForm('editPhone', e.target.value)}
              />
              <FormFeedback>{errors?.editPhone}</FormFeedback>
            </div>
            <small>
              Assicurati di digitare correttamente il <u>numero di cellulare</u>, è necessario inserire anche il prefisso internazionale (+39 per Italia)
            </small>
          </Col>
          <Col md="6">
            <div className='mb-3'>
              <Label className='form-label'>
                Email
              </Label>
              <Input
                type="email"
                value={this.state.editEmail}
                invalid={Boolean(errors?.editEmail)}
                onChange={(e) => this.changeForm('editEmail', e.target.value)}
              />
              <FormFeedback>{errors?.editEmail}</FormFeedback>
            </div>
          </Col>
        </Row>
      </div>,
      isOpen: true,
      toggle: false,
      size: 'lg',
      type: 'info',
      buttons: [
        { label: 'SALVA', color: 'success', onClick: () => this.saveEditLead() },
        {
          label: 'CHIUDI',
          color: 'danger',
          onClick: () => this.resetForm()
        },
      ]
    })
  }

  render() {
    return (
      <li className="list-inline-item cursor-pointer" onClick={() => this.showModal()}>
        <i className="ri-pencil-fill fs-20 text-info"></i>
      </li>
    )
  }
}
