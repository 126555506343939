import React, {Fragment} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert} from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay';

import {Loading} from "custom";

class CustomModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: {
                isOpen: false,
                title: '',
                subtitle: '',
                content: '',
                buttons: [],
                toggle: true,
                className: '',
                loading: false,
                loadingText: '',
                size: 'md',
                type: ''
            }
        };

        this.toggle = this.toggle.bind(this);

        const self = this;

        global.onSetModal = function (options) {
            let modal = self.state.modal;
            let arrayKeys = Object.keys(options);
            for (let i = 0; i < arrayKeys.length; i++) {
                modal[arrayKeys[i]] = options[arrayKeys[i]];
            }

            self.setState({modal: modal});
        };
    }

    toggle() {
        let modal = this.state.modal;
        modal.isOpen = (!modal.isOpen);
        this.setState({ modal: modal });
    }

    render() {
        return (
            <LoadingOverlay
                active={this.state.modal.loading}
                spinner
                text={this.state.modal.loadingText}
            >
            <Modal style={{overflowY: "auto"}} isOpen={this.state.modal.isOpen} scrollable={true} toggle={(this.state.modal.toggle) ? this.toggle : undefined} className={'modal-dialog-scrollable modal-' + this.state.modal.size}>

                {(this.state.modal.loading) ? <Loading /> : <React.Fragment>
                    <ModalHeader className='bg-transparent' toggle={(this.state.modal.toggle) ? this.toggle : undefined} />
                    <ModalBody className='px-sm-5 pt-50 pb-5'>
                        <div className='text-center mb-2'>
                            <h3 className='mb-1'>{this.state.modal.title}</h3>
                            <p>{this.state.modal.subtitle}</p>
                        </div>
                        {this.state.modal.content}
                    </ModalBody>
                    <ModalFooter>
                        {this.state.modal.buttons.map(option => (
                            <Button key={option.label} color={option.color} disabled={option.disabled} icon={'close'}
                                    onClick={option.onClick}>{option.label}</Button>
                        ))}
                    </ModalFooter>
                </React.Fragment>}
                </Modal>
            </LoadingOverlay>
        )
    }
}

export default CustomModal;
