import React, {Fragment} from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
    Row,
    Col,
    Label,
    FormFeedback,
    Input,
    Badge
} from 'reactstrap';

import {Utils, Loading, GestSell, DeleteSell, SellSetting, Params} from "custom";
import {SellAPI} from "api";

import {Grid, _} from 'gridjs-react';
import {Link} from "react-router-dom";
import * as XLSX from "xlsx";

const moment = require("moment")

class ListSell extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sells: [],
            sellsFiltered: null,
            loading: true,
            error: false
        };
    }

    componentDidMount() {
        this.getSells();
    }

    componentWillUnmount() {
        return Params.removeHandlePaginator();
    }

    getSells(range) {

        if (!range && !this.props.id_client)
            return;

        let start_date = (range) ? range.start_date : moment().startOf("month").format("DD-MM-YYYY");
        let end_date = (range) ? range.end_date : moment().format("DD-MM-YYYY");

        this.setState({loading: true});

        SellAPI.list(this.props.id_client, start_date, end_date).then((sells) => {
            this.setState({error: false, sells: sells, loading: false});
            setTimeout(() => {
                Params.addHandlePaginator()
            }, 1000)
        }).catch(() => {
            this.setState({error: true});
        })
    }

    printStatusPayment(sell) {
        if (!sell)
            return null

        let badge = "";

        if (sell.totalPayed === 0) {
            badge = <Badge color="danger">Non Effettuato</Badge>
        } else if (sell.totalPayed < sell.total) {
            badge = <Badge color="info">Acconto</Badge>
        } else if (sell.totalPayed >= sell.total) {
            badge = <Badge color="success">Saldato</Badge>
        }


        if (sell.checkPay) {
            return <span>{badge}<br /><small className="text-success">Verificato</small></span>
        } else {
            return <span>{badge}<br /><small className="text-danger">{(sell.totalPayed > 0) ? "Da Verificare" : ""}</small></span>
        }
    }

    onFilterSells(filterType, filterConsulent, filterReconfirm, filterStatus) {

        let sells = this.state.sells;

        if (filterConsulent !== null) {
            if (filterConsulent.value === -1) {
                sells = sells.filter((s) => s.consulent === null);
            } else {
                sells = sells.filter((s) => s.consulent?.id === filterConsulent.value);
            }
        }

        if (filterType !== null) {
            if (filterType.value.type === "VIDEO") {
                sells = sells.filter((s) => (filterType.value.id === null) ? s.type === "VIDEO" : (s.type === "VIDEO" && s.course.id === filterType.value.id));
            } else if (filterType.value.type === "CLASSROOM") {
                sells = sells.filter((s) => (filterType.value.id === null) ? s.type === "CLASSROOM" : (s.type === "CLASSROOM" && s.classroom_level.id === filterType.value.id));
            }
        }

        if (filterReconfirm !== null) {
            sells = sells.filter((s) => s.reconfirm === filterReconfirm.value);
        }

        if (filterStatus !== null) {
            let val = filterStatus.value;
            switch(val) {
                case 1:
                    sells = sells.filter((s) => s.totalPayed === s.total);
                    break;
                case 2:
                    sells = sells.filter((s) => s.totalPayed < s.total && s.totalPayed > 0);
                    break;
                case 3:
                    sells = sells.filter((s) => s.totalPayed === 0);
                    break;
                default:
                    break;
            }
        }

        if (filterConsulent === null && filterType === null && filterReconfirm === null && filterStatus === null) {
            this.setState({sellsFiltered: null})
        } else {
            this.setState({sellsFiltered: sells})
        }
    }

    exportToExcel() {

        let sells = [];

        const fileType =
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        let data = (this.state.sellsFiltered !== null) ? this.state.sellsFiltered : this.state.sells;

        for (let i = 0; i < data.length; i++) {
            sells.push({
                id: data[i].id,
                cliente: data[i].client.firstname + " " + data[i].client.lastname,
                tipologia: (data[i].type === "CLASSROOM") ? "CLASSE" : "VIDEO",
                livello: (data[i].type === "CLASSROOM") ? data[i].classroom_level.name : data[i].course.name,
                consulente: (data[i].consulent !== null) ? data[i].consulent.firstname + " " + data[i].consulent.lastname : "",
                riconferma: (data[i].reconfirm) ? "X" : "",
                totale: data[i].total,
                commissione: data[i].commission,
                pagato: data[i].totalPayed
            })
        }

        const ws = XLSX.utils.json_to_sheet(sells);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
        const dataBlob = new Blob([excelBuffer], {type: fileType});

        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(dataBlob);
        downloadLink.download = "sells_" + moment().format('DD-MM-YYYY_HH-mm') + fileExtension;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    render() {

        if (this.state.error) {
            return <React.Fragment>
                <Alert color="danger">Si è verificato un errore durante il recupero della lista vendite.</Alert>
            </React.Fragment>
        }

        let sells = (this.state.sellsFiltered !== null) ? this.state.sellsFiltered : this.state.sells

        return <React.Fragment>
            {(typeof this.props.id_client === "undefined") ? <SellSetting onFilterSells={this.onFilterSells.bind(this)} onReload={this.getSells.bind(this)} /> : null}

            {(typeof this.props.id_client !== "undefined") ? <div style={{margin: 10}}>
                <button
                    type="button"
                    className="btn btn-success btn-sm"
                    onClick={() => this.props.history.push('/sell/create?client=' + this.props.id_client)}>
                    <i className="ri-add-line align-bottom me-1"></i> CREA VENDITA
                </button>
            </div> : ''}
            {/* {(typeof this.props.id_client !== "undefined") ? <div style={{margin: 10}}>
                <GestSell id_client={this.props.id_client} onResolve={this.getSells.bind(this)}/>
            </div> : null} */}
            <Button color='primary' size="sm" style={{margin: 5}} onClick={() => this.exportToExcel()} disabled={this.state.sells.length === 0}>
                <i className="ri-file-excel-2-fill me-1"></i>
                ESPORTA
            </Button>
            {(this.state.loading) ? <Loading/> : <Grid
                data={sells}
                columns={[
                    {
                        id: 'id',
                        name: '#',
                        formatter: (cell, row) => _(<div className="text-nowrap text-center">
                            #{cell}
                        </div>)
                    },
                    {
                        id: 'client',
                        name: "Cliente",
                        formatter: (cell, row) => _(<div className="text-nowrap text-center">
                            <span className="text-nowrap"><a href="javascript:void(0)" onClick={() => this.props.history.push("/client/" + cell?.id)}><b>{cell?.firstname} {cell.lastname}</b></a> {/*{this.checkNewClient(cell?.id)}*/}</span>
                        </div>)
                    },
                    {
                        id: 'checkPay',
                        hidden: true
                    },
                    {
                        id: 'totalPayed',
                        name: "Pagamento",
                        sort: false,
                        formatter: (cell, row) => _(<div className="text-nowrap text-center">
                            {this.printStatusPayment(sells[sells.findIndex((f) => f.id === row.cells[0].data)])}
                        </div>)
                    },
                    {
                        id: "classroom_level",
                        hidden: true
                    },
                    {
                        id: "course",
                        hidden: true
                    },
                    (Utils.getUserData().role === "ADMIN") ? {
                        id: 'consulent',
                        name: "Consulente",
                        formatter: (cell, row) => _(<div className="text-nowrap">
                            <span className="text-nowrap">{(cell !== null) ? cell.firstname + ' ' + cell.lastname : "N/D"}</span>
                        </div>)
                    } : {
                        id: 'consulent',
                        hidden: true
                    },
                    {
                        id: 'classroomId',
                        hidden: true
                    },
                    {
                        id: 'type',
                        name: "Tipologia",
                        formatter: (cell, row) => _(<div className="text-nowrap text-center">
                            {(cell === "VIDEO") ? <Badge color="primary"><i className="mdi mdi-video"/> VIDEO</Badge> :
                                <a href="javascript:void(0)" onClick={() => ((row.cells[7].data !== null) ? this.props.history.push("/classroom/" + row.cells[7].data) : {})}><Badge color={(row.cells[7].data !== null) ? "secondary" : "warning"}><i className="mdi mdi-school"/> CLASSE</Badge></a>}
                            {(cell === "CLASSROOM") ? <span><br /><small>{row.cells[4]?.data.name}</small></span> : <span><br /><small>{row.cells[5]?.data.name}</small></span>}
                        </div>)
                    },
                    {
                        id: 'total',
                        name: "Totale vendita",
                        formatter: (cell, row) => _(<div className="text-nowrap text-center">
                            <strong>{Utils.getValueMoneyLabel(cell)}</strong>
                        </div>)
                    },
                    {
                        id: 'totalPayed',
                        name: "Da saldare",
                        sort: false,
                        formatter: (cell, row) => _(<div className="text-nowrap text-center">
                            {(cell < row.cells[9].data) ? <strong className={"text-danger"}>{Utils.getValueMoneyLabel(row.cells[9].data - cell)}</strong> : "--"}
                        </div>)
                    },
                    {
                        id: 'commission',
                        name: "Provvigione",
                        formatter: (cell, row) => _(<div className="text-nowrap text-center">
                            <strong>{(cell > 0) ? Utils.getValueMoneyLabel(cell) : null}</strong>
                        </div>)
                    },
                    {
                        id: 'reconfirm',
                        name: "Riconferma",
                        formatter: (cell, row) => _(<div className="text-nowrap text-center">
                            {(cell) ? <i className="mdi mdi-check text-success" style={{fontSize: "25px"}}></i> : null}
                        </div>)
                    },
                    {
                        id: 'createdAt',
                        name: "Data / Ora",
                        formatter: (cell, row) => _(<div className="text-nowrap text-center">
                            {moment(cell).format("DD/MM/YYYY HH:mm")}
                            {/*{(cell === "ADVERTISING") ? <Badge color="primary" >PUBBLICITÀ</Badge> : <Badge color="info">NOLEGGIO</Badge>}*/}
                        </div>)
                    },
                    {
                        name: '',
                        sort: false,
                        formatter: (cell, row, i) => _(<ul
                            className="list-inline hstack gap-2 mb-0">
                            <a href="javascript:void(0)"
                               onClick={() => this.props.history.push('/sell/' + row.cells[0]?.data)}>
                                <li className="list-inline-item cursor-pointer">
                                    <i className="ri-pencil-fill fs-20 text-info"></i>
                                </li>
                            </a>
                            <DeleteSell id={row.cells[0].data} onResolve={() => {
                                    this.getSells();
                                }}/>
                        </ul>)
                    }
                ]}
                sort={true}
                pagination={{enabled: true, limit: 8, page: Params.getActualPage()}}
                search={{
                    selector: (cell, rowIndex, cellIndex) => JSON.stringify(cell)
                }}
                language={{
                    'search': {
                        'placeholder': '🔍 Cerca...'
                    },
                    'pagination': {
                        'previous': 'Indietro',
                        'next': 'Avanti',
                        'showing': 'Stai visualizzando da',
                        'results': 'Vendite',
                        'to': 'a',
                        'of': 'di',
                    },
                    'noRecordsFound': 'Nessuna vendita trovata',
                    'error': 'Si è verificato un errore non previsto',
                }}
            />}
        </React.Fragment>
    }
}

export default ListSell;
