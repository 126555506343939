import { Utils } from 'custom';
import React, { Component } from 'react'
import Select from 'react-select';
import {Alert, CardHeader, Col, FormGroup, Input, Label, Row} from 'reactstrap'
import {ClientAPI, LeadAPI} from "api";

class Question extends Component {
    constructor(props) {
        super(props);
        this.state = {
            details: {
                level_start: null,
                country: null,
                available: [],
                shift: (this.props.leadRef) ? false : undefined,
                motivation: [],
                note: ""
            }
        }
    }

    componentDidMount() {
        if (this.props.details !== null)
            this.setState({details: this.props.details})
    }

    changeForm(key, text) {
        const details = this.state.details;

        if (key === "motivation" || key === "available") {
            let i = details[key].findIndex((c) => c === text);
            if (i > -1) {
                details[key].splice(i, 1)
            } else {
                details[key].push(text)
            }
        } else {
            details[key] = text;
        }

        this.setState({ details: details });
    }

    saveQuestions() {
        let details = this.state.details

        let api = (this.props.leadRef) ? LeadAPI : ClientAPI;

        api.editQuestion(this.props.id, details).then((c) => {
            console.log(c)
            global.SweetAlert.fire({
                title: 'Questionario salvato',
                text: '',
                icon: 'success',
                customClass: {
                    confirmButton: 'btn btn-primary'
                }
            });
            this.props.onReload();
        }).catch((c) => {
            console.error(c)
            global.SweetAlert.fire({
                title: 'Errore',
                text: 'Si è verificato un errore',
                icon: 'error',
                customClass: {
                    confirmButton: 'btn btn-primary'
                }
            });
        })
    }

  render() {

        if (!this.props.leadRef && this.props.id === null && this.props.details === null)
            return <Alert color="warning"> Devi prima creare il cliente</Alert>

    const countries = Utils.printListCountries();
    let details = this.state.details
    return (
        <React.Fragment>
            <div className="flex-grow-1">
                <h5 className="card-title mb-4">Questionario Lead</h5>
            </div>
            <Row>
                <Col sm={12}>
                    <h6>Seleziona livello di partenza</h6>
                    <Select
                        value={{value: details.level_start, label: details.level_start}}
                        placeholder="Seleziona livello  di Partenza"
                        onChange={(selectedOption) => {
                            this.changeForm("level_start", selectedOption.value)
                        }}
                        options={[
                            {value: "A1", label: "A1"},
                            {value: "A2", label: "A2"},
                            {value: "B1", label: "B1"},
                            {value: "B2", label: "B2"},
                            {value: "C1", label: "C1"},
                            {value: "C2", label: "C2"},
                            {value: "TEST", label: "TEST"}
                        ]}
                    />
                </Col>
                <Col sm={12} className='mb-4 mt-3'>
                    <h6>Nazionalità</h6>
                    <Select
                        value={(details.country !== null) ? countries[countries.findIndex(val => val.value === details.country)] : undefined}
                        placeholder={"Seleziona la nazione..."}
                        onChange={(selectedOption) => {
                            this.changeForm('country', selectedOption.value)
                        }}
                        options={countries}
                        formatOptionLabel={country => (
                            <div className="country-option">
                                <img src={country.img} alt="country" width="25px" />
                                <span>&nbsp;&nbsp;{country.label}</span>
                            </div>
                        )}
                    />
                </Col>
                <Col sm={12}>
                    <p className='mb-2'>Seleziona l'orario di disponibilità</p>

                    <p className='mb-2'>Mattina</p>
                    <div className="form-check form-check-outline form-check-primary mb-2">
                        <Input className="form-check-input" type="checkbox" checked={(details.available.includes("08:00 - 09:00"))} onChange={(e) => this.changeForm('available', "08:00 - 09:00")}/>
                        <Label className="form-check-label">08:00 - 09:00</Label>
                    </div>
                    <div className="form-check form-check-outline form-check-primary mb-2">
                        <Input className="form-check-input" type="checkbox" checked={(details.available.includes("09:00 - 10:00"))} onChange={(e) => this.changeForm('available', "09:00 - 10:00")}/>
                        <Label className="form-check-label">09:00 - 10:00</Label>
                    </div>
                    <div className="form-check form-check-outline form-check-primary mb-2">
                        <Input className="form-check-input" type="checkbox" checked={(details.available.includes("10:00 - 11:00"))} onChange={(e) => this.changeForm('available', "10:00 - 11:00")}/>
                        <Label className="form-check-label">10:00 - 11:00</Label>
                    </div>

                    <p className='mb-2'>Pomeriggio</p>
                    <div className="form-check form-check-outline form-check-primary mb-2">
                        <Input className="form-check-input" type="checkbox" checked={(details.available.includes("14:00 - 15:00"))} onChange={(e) => this.changeForm('available', "14:00 - 15:00")}/>
                        <Label className="form-check-label">14:00 - 15:00</Label>
                    </div>
                    <div className="form-check form-check-outline form-check-primary mb-2">
                        <Input className="form-check-input" type="checkbox" checked={(details.available.includes("15:00 - 16:00"))} onChange={(e) => this.changeForm('available', "15:00 - 16:00")}/>
                        <Label className="form-check-label">15:00 - 16:00</Label>
                    </div>

                    <p className='mb-2'>Sera</p>
                    <div className="form-check form-check-outline form-check-primary mb-2">
                        <Input className="form-check-input" type="checkbox" checked={(details.available.includes("18:00 - 19:00"))} onChange={(e) => this.changeForm('available', "18:00 - 19:00")}/>
                        <Label className="form-check-label">18:00 - 19:00</Label>
                    </div>
                    <div className="form-check form-check-outline form-check-primary mb-2">
                        <Input className="form-check-input" type="checkbox" checked={(details.available.includes("19:00 - 20:00"))} onChange={(e) => this.changeForm('available', "19:00 - 20:00")}/>
                        <Label className="form-check-label">19:00 - 20:00</Label>
                    </div>
                    <div className="form-check form-check-outline form-check-primary mb-2">
                        <Input className="form-check-input" type="checkbox" checked={(details.available.includes("20:00 - 21:00"))} onChange={(e) => this.changeForm('available', "20:00 - 21:00")}/>
                        <Label className="form-check-label">20:00 - 21:00</Label>
                    </div>
                </Col>
                {(this.props.leadRef) ? <Col sm={12} className='mt-4'>
                    <div className="form-check form-check-outline form-check-primary mb-2">
                        <Input className="form-check-input" type="checkbox" checked={(details.shift)} onChange={(e) => this.changeForm('shift', e.target.checked)}/>
                        <Label className="form-check-label">Turnista</Label>
                    </div>
                </Col> : null}
                <Col sm={12} className='mt-4'>
                    <h6>Motivazione dell'apprendimento della lingua</h6>
                    <div className="form-check form-check-outline form-check-primary mb-2">
                        <Input className="form-check-input" type="checkbox" checked={(details.motivation.includes("Trasferimento"))} onChange={(e) => this.changeForm('motivation', "Trasferimento")}/>
                        <Label className="form-check-label">Trasferimento</Label>
                    </div>
                    <div className="form-check form-check-outline form-check-primary mb-2">
                        <Input className="form-check-input" type="checkbox" checked={(details.motivation.includes("Vivono già all’estero"))} onChange={(e) => this.changeForm('motivation', "Vivono già all’estero")}/>
                        <Label className="form-check-label">Vivono già all’estero</Label>
                    </div>
                    <div className="form-check form-check-outline form-check-primary mb-2">
                        <Input className="form-check-input" type="checkbox" checked={(details.motivation.includes("Conoscenza personale"))} onChange={(e) => this.changeForm('motivation', "Conoscenza personale")}/>
                        <Label className="form-check-label">Conoscenza personale</Label>
                    </div>
                </Col>
                <Col sm={12} className='mt-4'>
                    <textarea className="form-control bg-light border-light mb-2"
                        rows="3"
                        placeholder="Note"
                        value={details.note}
                        onChange={(e) => this.changeForm("note", e.target.value)}
                    >
                    </textarea>
                    {(this.props.id !== null) ? <button
                        type="button"
                        className="btn  btn-success" onClick={() => this.saveQuestions()}>
                        SALVA
                    </button> : null}
                </Col>
            </Row>
        </React.Fragment>
    )
  }
}
export default Question
