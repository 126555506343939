import React, {Component, useEffect, useState} from 'react';
import {
    Alert,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Badge,
    Label,
    Input, Spinner
} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import {Loading} from 'custom';
import {Grid, _} from 'gridjs-react';
import {AvailableAPI} from 'api';
import {Link} from "react-router-dom";
const moment = require("moment");


const Available = () => {
    const [loading, setLoading] = useState(false);

    const [loadingSlot, setLoadingSlot] = useState([]);

    const [availableList, setAvailableList] = useState([]);

    const slots = [
        '08:00 - 09:00',
        '09:00 - 10:00',
        '10:00 - 11:00',
        '15:00 - 16:00',
        '18:00 - 19:00',
        '19:00 - 20:00',
        '20:00 - 21:00'
    ]

    useEffect(() => {
        getAvailables();
    }, []);

    let getAvailables = (slot) => {
        AvailableAPI.list().then((availables) => {
            setAvailableList(() => availables);
            if (slot) {
                let loadings = [...loadingSlot];
                loadings.splice(loadings.findIndex((e) => e === slot), 1);
                setLoadingSlot(() => loadings);
            }
        })
    }

    let checkIfSelected = (slot) => {
        return Boolean(availableList.find((el) => el.slot === slot))
    }

    let changeAvailable = (slot, checked) => {
        let loadings = [...loadingSlot];
        loadings.push(slot);
        setLoadingSlot(() => loadings);
        if (checked) {
            AvailableAPI.add({slot: slot}).then(() => {
                getAvailables(slot)
            }).catch(() => {
                getAvailables(slot)
            })
        } else {
            let id = availableList[availableList.findIndex((c) => c.slot === slot)].id
            AvailableAPI.remove(id).then(() => {
                getAvailables(slot)
            }).catch(() => {
                getAvailables(slot)
            })
        }
    }

    let changeDate = (id, available_from, available_to) => {
        AvailableAPI.editFrom({id: id, available_from: available_from, available_to: available_to}).then(() => {
            setAvailableList(() => []);
            getAvailables()
        })
    }

    let printClassroom = (slotBusy) => {
        if (slotBusy) {
            return <Link to={"/classroom/" + slotBusy.groups[0].Classroom.id}><b>{slotBusy.groups[0].Classroom.name}</b></Link>
        }
        return null;
    }

    let printStatus = (slotBusy) => {
        if (slotBusy) {
            switch(slotBusy.groups[0].Classroom.status) {
                case "DRAFT":
                    return <Badge color="info" style={{fontSize: "10px"}}>IN PROGRAMMAZIONE</Badge>;
                case "WAITING":
                    return <Badge color="warning" style={{fontSize: "10px"}}>IN PARTENZA</Badge>;
                case "ACTIVE":
                    return <Badge color="primary" style={{fontSize: "10px"}}>ATTIVA</Badge>;
                default:
                    return null;
            }
        }
        return null;
    }

    let getMinEnd = (slot) => {
        let minDate = moment();

        for (let i = 0; i < slot.groups.length; i++) {
            let classroom = slot.groups[i].Classroom;
            if (classroom.date_end !== null) {
                if (minDate.isBefore(moment(classroom.date_end))) {
                    minDate = moment(classroom.date_end);
                }
            }
        }

        return minDate.add(1, "days").toISOString().split("T")[0]
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Dettaglio Disponibilità" pageTitle="CLTV Cloud"/>
                    {(loading) ? <Loading/> : <div>
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <CardBody className="checkout-tab">
                                        <div>
                                            <h5>Seleziona la disponibilità per <b>LEZIONI DI GRUPPO</b></h5>
                                            <small>Le fasce orarie si riferiscono per i giorni dal Lunedì al
                                                Venerdì</small>
                                        </div>
                                        <hr/>
                                        <div className='gridjs gridjs-container'>
                                            <div className='gridjs-wrapper'>
                                                <table role="grid" className="gridjs-table">
                                                    <thead className="gridjs-thead">
                                                    <tr className="gridjs-tr">
                                                        <th className="gridjs-th">FASCIA ORARIA</th>
                                                        <th className="gridjs-th">DISPONIBILITÀ</th>
                                                        <th className="gridjs-th">INIZIO VALIDITÀ</th>
                                                        <th className="gridjs-th">FINE VALIDITÀ</th>
                                                        <th className="gridjs-th">STATO</th>
                                                        <th className="gridjs-th">CLASSE</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody className="gridjs-tbody">
                                                        {slots.map((slot, i) => {
                                                            return <tr className="gridjs-tr" key={i}>
                                                                <td className="gridjs-td" width="100">
                                                                    <b>{slot}</b>
                                                                </td>
                                                                <td className="gridjs-td text-center" width="100">
                                                                    {(!loadingSlot.includes(slot)) ? <Input className="form-check-input cursor-pointer"
                                                                           type="checkbox"
                                                                           checked={checkIfSelected(slot)}
                                                                           disabled={availableList.find((el) => el.slot === slot && el.busy)}
                                                                           onChange={(e) => changeAvailable(slot, e.target.checked)}
                                                                    /> : <Spinner style={{ width: '1rem', height: '1rem' }} />}
                                                                </td>
                                                                <td className="gridjs-td text-center" width="100">
                                                                    {(checkIfSelected(slot)) ? <Input
                                                                        className="form-control-sm"
                                                                        type="date"
                                                                        min={moment().toISOString().split("T")[0]}
                                                                        onChange={(e) => {
                                                                            changeDate(availableList.find((el) => el.slot === slot)?.id, ((e.target.value === "") ? null : e.target.value), availableList.find((el) => el.slot === slot).available_to )
                                                                        }}
                                                                        disabled={availableList.find((el) => el.slot === slot && el.busy)}
                                                                        value={availableList.find((el) => el.slot === slot).available_from}
                                                                    /> : null}
                                                                </td>
                                                                <td className="gridjs-td text-center" width="100">
                                                                    {(checkIfSelected(slot)) ? <Input
                                                                        className="form-control-sm"
                                                                        type="date"
                                                                        min={getMinEnd(availableList.find((el) => el.slot === slot))}
                                                                        onChange={(e) => {
                                                                            changeDate(availableList.find((el) => el.slot === slot)?.id, availableList.find((el) => el.slot === slot).available_from, ((e.target.value === "") ? null : e.target.value))
                                                                        }}
                                                                        value={availableList.find((el) => el.slot === slot).available_to}
                                                                    /> : null}
                                                                </td>
                                                                <td className="gridjs-td" width="100">
                                                                    {printStatus(availableList.find((el) => el.slot === slot && el.busy))}
                                                                </td>
                                                                <td className="gridjs-td">
                                                                    {printClassroom(availableList.find((el) => el.slot === slot && el.busy))}
                                                                </td>
                                                            </tr>
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>}
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Available
