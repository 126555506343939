import React, {useEffect, useState} from 'react';
import {Alert, Card, CardBody, CardHeader, Col, Container, Input, Row, Spinner} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
// import ReactApexChart from 'react-apexcharts';
import CountUp from "react-countup";
import {DashboardAPI} from "api"
import {Loading, Utils} from "custom";
import {Link} from "react-router-dom";
import Flatpickr from "react-flatpickr";

import {Italian} from "flatpickr/dist/l10n/it";
import {_, Grid} from "gridjs-react";

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import logoTikTok from "../../assets/images/logo-tiktok.png";

const moment = require("moment");


const Dashboard = (props) => {
    document.title = "Dashboard | CLTV Cloud";

    const [sessionsCounter, setSessionsCounter] = useState(0);
    const [totalCommissionDev, setTotalCommissionDev] = useState(0);

    const [totalRevenueCounter, setTotalRevenueCounter] = useState(0);
    const [totalRevenueEffectiveCounter, setTotalRevenueEffectiveCounter] = useState(0);

    const [totalCommissionCounter, setTotalCommissionCounter] = useState(0);
    const [totalCommissionEffectiveCounter, setTotalCommissionEffectiveCounter] = useState(0);

    const [totalRevenueVideoCounter, setTotalRevenueVideoCounter] = useState(0);
    const [totalRevenueClassroomCounter, setTotalRevenueClassroomCounter] = useState(0);

    const [totalSellVideoCounter, setTotalSellVideoCounter] = useState(0);
    const [totalSellClassroomCounter, setTotalSellClassroomCounter] = useState(0);

    const [newClientRevenueCounter, setNewClientRevenueCounter] = useState(0);
    const [reconfirmRevenueCounter, setReconfirmRevenueCounter] = useState(0);

    const [newClientSellCounter, setNewClientSellCounter] = useState(0);
    const [reconfirmSellCounter, setReconfirmSellCounter] = useState(0);

    const [totLeadsConverted, setTotLeadsConverted] = useState(0);
    const [totLeads, setTotLeads] = useState(0);

    const [error, setError] = useState(false);

    const [loadingCounters, setLoadingCounters] = useState(true);
    const [loadingConsulents, setLoadingConsulents] = useState(true);
    const [loadingModules, setLoadingModules] = useState(true);

    const [range, setRange] = useState({start_date: moment().startOf("month"), end_date: moment()});

    const [consulents, setConsulents] = useState([]);

    const [modules, setModules] = useState([]);


    useEffect(() => {
        setLoadingCounters(true)
        setLoadingConsulents(true)
        setLoadingModules(true)
        getCounters(range)
    }, [range])


    useEffect(() => {
        if (!loadingCounters)
            if (Utils.getUserData().role === "ADMIN")
                getConsulent(range)
    }, [loadingCounters])

    useEffect(() => {
        if (!loadingConsulents)
            if (Utils.getUserData().role === "ADMIN")
                getModules(range)
    }, [loadingConsulents])

    let getCounters = (range) => {
        setLoadingCounters(true)

        DashboardAPI.getCounters(moment(range.start_date).format("DD-MM-YYYY"), moment(range.end_date).format("DD-MM-YYYY")).then(counters => {

            setSessionsCounter(counters.sessions)
            let totCommissionDev = 0;

            let totRevenue = 0;
            let totRevenueEffective = 0;

            let totCommission = 0;
            let totCommissionEffective = 0;

            let totRevenueVideo = 0;
            let totRevenueClassroom = 0;

            let totSellVideo = 0;
            let totSellClassroom = 0;

            let newClientRevenue = 0;
            let reconfirmRevenue = 0;

            let newClientSell = counters.newClients;
            let reconfirmSell = 0;

            for (let d = 0; d < counters.sells.length; d++) {
                if (counters.sells[d].reconfirm)
                    reconfirmSell = reconfirmSell + 1;

                if (counters.sells[d].type === "VIDEO") {
                    totSellVideo = totSellVideo + 1;
                } else {
                    totSellClassroom = totSellClassroom + 1;
                }
            }

            for (let i = 0; i < counters.values.length; i++) {
                totRevenue = totRevenue + counters.values[i].value
                if (counters.values[i].verified) {
                    totRevenueEffective = totRevenueEffective + counters.values[i].value
                    totCommissionEffective = totCommissionEffective + counters.values[i].commission
                }

                totCommissionDev = totCommissionDev + counters.values[i].commissionDev
                totCommission = totCommission + counters.values[i].commission

                if (counters.values[i].type === "VIDEO") {
                    totRevenueVideo = totRevenueVideo + counters.values[i].value
                } else {
                    totRevenueClassroom = totRevenueClassroom + counters.values[i].value
                }

                if (counters.values[i].reconfirm) {
                    reconfirmRevenue = reconfirmRevenue + counters.values[i].value
                } else {
                    newClientRevenue = newClientRevenue + counters.values[i].value
                }
            }

            setTotalCommissionDev(totCommissionDev);

            setTotalRevenueCounter(totRevenue);
            setTotalRevenueEffectiveCounter(totRevenueEffective);

            setTotalCommissionCounter(totCommission);
            setTotalCommissionEffectiveCounter(totCommissionEffective);

            setTotalRevenueVideoCounter(totRevenueVideo);
            setTotalRevenueClassroomCounter(totRevenueClassroom);

            setTotalSellVideoCounter(totSellVideo);
            setTotalSellClassroomCounter(totSellClassroom);

            setNewClientRevenueCounter(newClientRevenue);
            setReconfirmRevenueCounter(reconfirmRevenue);

            setNewClientSellCounter(newClientSell);
            setReconfirmSellCounter(reconfirmSell);

            setLoadingCounters(false);

        }).catch((e) => {
            console.error(e)
            setError(true)
        })
    }


    let getConsulent = (range) => {
        setLoadingConsulents(true)

        DashboardAPI.getConsulents(moment(range.start_date).format("DD-MM-YYYY"), moment(range.end_date).format("DD-MM-YYYY")).then(res => {

            let resConsulents = [];
            for (let i = 0; i < res.length; i++) {
                let revenue = 0;
                let commission = 0;
                let revenueClassroom = 0;
                let revenueVideo = 0;
                let newClients = res[i].newClients;
                let reconfirms = res[i].sellsReconfirm;
                let sellClassroom = res[i].sellsClassroom;
                let sellVideo = res[i].sellsVideo;

                // let idsNewClients = [];

                for (let d = 0; d < res[i].sells.length; d++) {

                    // if (res[i].sells[d].type === "VIDEO")
                    //     sellVideo = sellVideo + 1;
                    //
                    // if (res[i].sells[d].type === "CLASSROOM")
                    //     sellClassroom = sellClassroom + 1;

                    for (let c = 0; c < res[i].sells[d].payments.length; c++) {
                        revenue = revenue + res[i].sells[d].payments[c].value
                        commission = commission + res[i].sells[d].payments[c].commission

                        if (res[i].sells[d].type === "VIDEO")
                            revenueVideo = revenueVideo + res[i].sells[d].payments[c].value;

                        if (res[i].sells[d].type === "CLASSROOM")
                            revenueClassroom = revenueClassroom + res[i].sells[d].payments[c].value;
                    }

                    // if (res[i].sells[d].newClient) {
                    //     if (!idsNewClients.includes(res[i].sells[d].newClient)) {
                    //         idsNewClients.push(res[i].sells[d].newClient);
                    //         newClients = newClients + 1;
                    //     }
                    // }
                    // if (res[i].sells[d].reconfirm) {
                    //     reconfirms = reconfirms + 1;
                    // }
                }

                resConsulents.push({
                    id: res[i].id,
                    name: res[i].name,
                    revenue: revenue,
                    commission: commission,
                    revenueClassroom: revenueClassroom,
                    revenueVideo: revenueVideo,
                    newClients: newClients,
                    reconfirms: reconfirms,
                    sellClassroom: sellClassroom,
                    sellVideo: sellVideo,
                    leads: res[i].leads.length,
                    leadsConverted: res[i].leads.filter((l) => l.converted).length
                })
            }
            setConsulents(resConsulents)
            setLoadingConsulents(false)
        }).catch((e) => {
            console.error(e)
            setError(true)
        })

    }


    let getReferralDetail = (referral) => {
        switch(referral.name) {
            case "META":
                return {
                    ...referral,
                    name: "META",
                    icon: "ri-facebook-box-fill align-middle fs-20 text-secondary"
                }
            case "FUNNEL":
                return {
                    ...referral,
                    name: "FUNNEL",
                    icon: "ri-global-fill align-middle fs-20 text-primary"
                }
            case "WHATSAPP":
                return {
                    ...referral,
                    name: "WHATSAPP",
                    icon: "ri-whatsapp-fill align-middle fs-20 text-success"
                }
            case "MANUAL":
                return {
                    ...referral,
                    name: "MANUALE/PASSAPAROLA",
                    icon: "ri-git-repository-commits-fill align-middle fs-20 text-info"
                }
            case "REPORT":
                return {
                    ...referral,
                    name: "SEGNALAZIONI",
                    icon: "mdi mdi-bullhorn align-middle fs-20 text-danger"
                }
            case "TIK_TOK":
                return {
                    ...referral,
                    name: "TIK TOK",
                    icon: ""
                }
            default:
                return null;
        }
    }

    let getModules = (range) => {
        setLoadingModules(true)
        DashboardAPI.getModules(moment(range.start_date).format("DD-MM-YYYY"), moment(range.end_date).format("DD-MM-YYYY")).then(res => {
            setTotLeads(res.counterLeads)
            setTotLeadsConverted(res.counterConverted)
            setModules(res.list.map((r) => getReferralDetail(r)))
            setLoadingModules(false)
        }).catch((e) => {
            console.error(e)
            setError(true)
        })

    }


    if (error) {
        return <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Dashboard" pageTitle="CLTV Cloud"/>
                    <div className="row">
                        <Alert color="danger">Si è verificato un errore durante il recupero della dashboard.</Alert>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Dashboard" pageTitle="CLTV Cloud"/>
                    <div>
                        <Row className="mb-3 pb-1">
                            <Col xs={12}>
                                <div className="d-flex align-items-lg-center flex-lg-row flex-column">
                                    <div className="flex-grow-1">
                                        <h4 className="fs-16 mb-1">Hallo, {Utils.getUserData().firstname}!</h4>
                                        <p className="text-muted mb-0">Vediamo come sta andando il tuo andamento!</p>
                                    </div>
                                    <div className="mt-3 mt-lg-0">
                                        <form action="#">
                                            <Row className="g-3 mb-0 align-items-center">
                                                <div className="col-sm-auto">
                                                    <div className="input-group">
                                                        <Flatpickr
                                                            className="form-control border-0 dash-filter-picker shadow"
                                                            options={{
                                                                mode: "range",
                                                                dateFormat: "d/m/Y",
                                                                locale: Italian,
                                                            }}
                                                            value={[range.start_date.format("DD/MM/YYYY"), range.end_date.format("DD/MM/YYYY")]}
                                                            onChange={(selectedDates) => {
                                                                if (selectedDates.length === 2) {
                                                                    setRange({
                                                                        start_date: moment(selectedDates[0]),
                                                                        end_date: moment(selectedDates[1]),
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                        <div
                                                            className="input-group-text bg-primary border-primary text-white">
                                                            <i className="ri-calendar-2-line"></i></div>
                                                    </div>
                                                </div>
                                            </Row>
                                        </form>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            {(totalCommissionDev > 0 && Utils.getUserData().role === "ADMIN") ? <p><b>Mantenimento
                                Piattaforma: {Utils.getValueMoneyLabel(totalCommissionDev)}</b>
                            </p> : null}<br/>
                            {(Utils.getUserData().role === "ADMIN") ?
                                <p><b>Utenti Attivi: {sessionsCounter}</b></p> : null}
                            <Row>
                                <Col md={6} xl={3}>
                                    <Card>
                                        <CardBody>
                                            {(loadingCounters) ? <Skeleton height={45}/> :
                                                <div className="d-flex align-items-center">
                                                    <div className="avatar-sm flex-shrink-0">
                                                <span
                                                    className="avatar-title bg-light text-primary rounded-circle fs-3">
                                                    <i className={"align-middle bx bx-euro"}></i>
                                                </span>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">FATTURATO
                                                            STIMATO</p>
                                                        <h4 className=" mb-0">
                                                            <CountUp
                                                                suffix=""
                                                                prefix="€"
                                                                decimal=","
                                                                separator="."
                                                                start={0}
                                                                decimals={2}
                                                                end={totalRevenueCounter}
                                                                duration={3}
                                                            />
                                                        </h4>
                                                    </div>
                                                </div>}
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md={6} xl={3}>
                                    <Card>
                                        <CardBody>
                                            {(loadingCounters) ? <Skeleton height={45}/> :
                                                <div className="d-flex align-items-center">
                                                    <div className="avatar-sm flex-shrink-0">
                                                <span
                                                    className="avatar-title bg-light text-success rounded-circle fs-3">
                                                    <i className={"align-middle bx bx-euro"}></i>
                                                </span>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">FATTURATO
                                                            EFFETTIVO</p>
                                                        <h4 className=" mb-0">
                                                            <CountUp
                                                                suffix=""
                                                                prefix="€"
                                                                decimal=","
                                                                separator="."
                                                                start={0}
                                                                decimals={2}
                                                                end={totalRevenueEffectiveCounter}
                                                                duration={3}
                                                            />
                                                        </h4>
                                                    </div>
                                                </div>}
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md={6} xl={3}>
                                    <Card>
                                        <CardBody>
                                            {(loadingCounters) ? <Skeleton height={45}/> :
                                                <div className="d-flex align-items-center">
                                                    <div className="avatar-sm flex-shrink-0">
                                                <span
                                                    className="avatar-title bg-light text-primary rounded-circle fs-3">
                                                    <i className="align-middle mdi mdi-wallet"></i>
                                                </span>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">PROVVIGIONI
                                                            STIMATE</p>
                                                        <h4 className=" mb-0">
                                                            <CountUp
                                                                suffix=""
                                                                prefix="€"
                                                                decimal=","
                                                                separator="."
                                                                start={0}
                                                                decimals={0}
                                                                end={totalCommissionCounter}
                                                                duration={3}
                                                            />
                                                        </h4>
                                                    </div>
                                                </div>}
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md={6} xl={3}>
                                    <Card>
                                        <CardBody>
                                            {(loadingCounters) ? <Skeleton height={45}/> :
                                                <div className="d-flex align-items-center">
                                                    <div className="avatar-sm flex-shrink-0">
                                                <span
                                                    className="avatar-title bg-light text-success rounded-circle fs-3">
                                                    <i className="align-middle mdi mdi-wallet"></i>
                                                </span>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">PROVVIGIONI
                                                            EFFETTIVE</p>
                                                        <h4 className=" mb-0">
                                                            <CountUp
                                                                suffix=""
                                                                prefix="€"
                                                                decimal=","
                                                                separator="."
                                                                start={0}
                                                                decimals={0}
                                                                end={totalCommissionEffectiveCounter}
                                                                duration={3}
                                                            />
                                                        </h4>
                                                    </div>
                                                </div>}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} xl={3}>
                                    <Card>
                                        <CardBody>
                                            {(loadingCounters) ? <Skeleton height={45}/> :
                                                <div className="d-flex align-items-center">
                                                    <div className="avatar-sm flex-shrink-0">
                                                <span
                                                    className="avatar-title bg-light text-primary rounded-circle fs-3">
                                                    <i className="align-middle bx bx-video"></i>
                                                </span>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">FATT.
                                                            VIDEO</p>
                                                        <h4 className=" mb-0">
                                                            <CountUp
                                                                suffix=""
                                                                prefix="€"
                                                                decimal=","
                                                                separator="."
                                                                start={0}
                                                                decimals={2}
                                                                end={totalRevenueVideoCounter}
                                                                duration={3}
                                                            />
                                                        </h4>
                                                    </div>
                                                </div>}
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md={6} xl={3}>
                                    <Card>
                                        <CardBody>
                                            {(loadingCounters) ? <Skeleton height={45}/> :
                                                <div className="d-flex align-items-center">
                                                    <div className="avatar-sm flex-shrink-0">
                                                <span
                                                    className="avatar-title bg-light text-primary rounded-circle fs-3">
                                                    <i className="align-middle mdi mdi-school"></i>
                                                </span>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">FATT.
                                                            CLASSI</p>
                                                        <h4 className=" mb-0">
                                                            <CountUp
                                                                suffix=""
                                                                prefix="€"
                                                                decimal=","
                                                                separator="."
                                                                start={0}
                                                                decimals={2}
                                                                end={totalRevenueClassroomCounter}
                                                                duration={3}
                                                            />
                                                        </h4>
                                                    </div>
                                                </div>}
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md={6} xl={3}>
                                    <Card>
                                        <CardBody>
                                            {(loadingCounters) ? <Skeleton height={45}/> :
                                                <div className="d-flex align-items-center">
                                                    <div className="avatar-sm flex-shrink-0">
                                                <span
                                                    className="avatar-title bg-light text-primary rounded-circle fs-3">
                                                    <i className="align-middle bx bx-video"></i>
                                                </span>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">VENDITE
                                                            VIDEO</p>
                                                        <h4 className=" mb-0">
                                                            <CountUp
                                                                suffix=""
                                                                prefix=""
                                                                decimal=""
                                                                separator="."
                                                                start={0}
                                                                decimals={0}
                                                                end={totalSellVideoCounter}
                                                                duration={3}
                                                            />
                                                        </h4>
                                                    </div>
                                                </div>}
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md={6} xl={3}>
                                    <Card>
                                        <CardBody>
                                            {(loadingCounters) ? <Skeleton height={45}/> :
                                                <div className="d-flex align-items-center">
                                                    <div className="avatar-sm flex-shrink-0">
                                                <span
                                                    className="avatar-title bg-light text-primary rounded-circle fs-3">
                                                    <i className="align-middle mdi mdi-school"></i>
                                                </span>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">VENDITE
                                                            CLASSI</p>
                                                        <h4 className=" mb-0">
                                                            <CountUp
                                                                suffix=""
                                                                prefix=""
                                                                decimal=""
                                                                separator="."
                                                                start={0}
                                                                decimals={0}
                                                                end={totalSellClassroomCounter}
                                                                duration={3}
                                                            />
                                                        </h4>
                                                    </div>
                                                </div>}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} xl={3}>
                                    <Card>
                                        <CardBody>
                                            {(loadingCounters) ? <Skeleton height={45}/> :
                                                <div className="d-flex align-items-center">
                                                    <div className="avatar-sm flex-shrink-0">
                                                <span
                                                    className="avatar-title bg-light text-primary rounded-circle fs-3">
                                                    <i className="align-middle mdi mdi-account-multiple-plus"></i>
                                                </span>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">FATT.
                                                            NUOVI ISCRITTI</p>
                                                        <h4 className=" mb-0">
                                                            <CountUp
                                                                suffix=""
                                                                prefix="€"
                                                                decimal=","
                                                                separator="."
                                                                start={0}
                                                                decimals={2}
                                                                end={newClientRevenueCounter}
                                                                duration={3}
                                                            />
                                                        </h4>
                                                    </div>
                                                </div>}
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md={6} xl={3}>
                                    <Card>
                                        <CardBody>
                                            {(loadingCounters) ? <Skeleton height={45}/> :
                                                <div className="d-flex align-items-center">
                                                    <div className="avatar-sm flex-shrink-0">
                                                <span
                                                    className="avatar-title bg-light text-primary rounded-circle fs-3">
                                                    <i className="align-middle mdi mdi-account-check"></i>
                                                </span>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">FATT.
                                                            RICONFERME</p>
                                                        <h4 className=" mb-0">
                                                            <CountUp
                                                                suffix=""
                                                                prefix="€"
                                                                decimal=","
                                                                separator="."
                                                                start={0}
                                                                decimals={2}
                                                                end={reconfirmRevenueCounter}
                                                                duration={3}
                                                            />
                                                        </h4>
                                                    </div>
                                                </div>}
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md={6} xl={3}>
                                    <Card>
                                        <CardBody>
                                            {(loadingCounters) ? <Skeleton height={45}/> :
                                                <div className="d-flex align-items-center">
                                                    <div className="avatar-sm flex-shrink-0">
                                                <span
                                                    className="avatar-title bg-light text-primary rounded-circle fs-3">
                                                    <i className="align-middle mdi mdi-account-multiple-plus"></i>
                                                </span>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">NUOVI
                                                            ISCRITTI</p>
                                                        <h4 className=" mb-0">
                                                            <CountUp
                                                                suffix=""
                                                                prefix=""
                                                                decimal=","
                                                                separator="."
                                                                start={0}
                                                                decimals={0}
                                                                end={newClientSellCounter}
                                                                duration={3}
                                                            />
                                                        </h4>
                                                    </div>
                                                </div>}
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md={6} xl={3}>
                                    <Card>
                                        <CardBody>
                                            {(loadingCounters) ? <Skeleton height={45}/> :
                                                <div className="d-flex align-items-center">
                                                    <div className="avatar-sm flex-shrink-0">
                                                <span
                                                    className="avatar-title bg-light text-primary rounded-circle fs-3">
                                                    <i className="align-middle mdi mdi-account-check"></i>
                                                </span>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">RICONFERME</p>
                                                        <h4 className=" mb-0">
                                                            <CountUp
                                                                suffix=""
                                                                prefix=""
                                                                decimal=""
                                                                separator="."
                                                                start={0}
                                                                decimals={0}
                                                                end={reconfirmSellCounter}
                                                                duration={3}
                                                            />
                                                        </h4>
                                                    </div>
                                                </div>}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Row>
                        {(Utils.getUserData().role === "ADMIN") ? <Row>
                            <Col>
                                <Card>
                                    <CardHeader>
                                        <h5 className="card-title">Statistiche Consulenti</h5>
                                    </CardHeader>
                                    <CardBody>
                                        {(loadingConsulents) ? <div className="mt-1">
                                            <Skeleton height={50}/><br/>
                                            <Skeleton height={50}/><br/>
                                            <Skeleton height={50}/><br/>
                                            <Skeleton height={50}/><br/>
                                            <Skeleton height={50}/><br/>
                                            <Skeleton height={50}/><br/>
                                            <Skeleton height={50}/>
                                        </div> : <Grid
                                            data={consulents.sort((a, b) => b.revenue - a.revenue)}
                                            columns={[
                                                {
                                                    id: 'name',
                                                    name: 'Nome',
                                                    formatter: (cell, row) => _(
                                                        <div className="text-nowrap"><b>{cell}</b></div>
                                                    ),
                                                },
                                                {
                                                    id: 'revenue',
                                                    name: 'Fatturato',
                                                    formatter: (cell, row) => _(
                                                        <div className="text-nowrap text-center">
                                                            <b>{Utils.getValueMoneyLabel(cell)}</b><br/><small
                                                            hidden={isNaN(cell * 100 / totalRevenueCounter)}>{Math.round(cell * 100 / totalRevenueCounter)} %</small>
                                                        </div>
                                                    ),
                                                },
                                                {
                                                    id: 'commission',
                                                    name: 'Provvigioni',
                                                    formatter: (cell, row) => _(
                                                        <div
                                                            className="text-nowrap text-center">{Utils.getValueMoneyLabel(cell)}</div>
                                                    ),
                                                },
                                                {
                                                    id: 'revenueClassroom',
                                                    name: 'Fatturato Classi',
                                                    formatter: (cell, row) => _(
                                                        <div
                                                            className="text-nowrap text-center">{Utils.getValueMoneyLabel(cell)}</div>
                                                    ),
                                                },
                                                {
                                                    id: 'revenueVideo',
                                                    name: 'Fatturato Video',
                                                    formatter: (cell, row) => _(
                                                        <div
                                                            className="text-nowrap text-center">{Utils.getValueMoneyLabel(cell)}</div>
                                                    ),
                                                },
                                                {
                                                    id: 'newClients',
                                                    name: 'Nuovi Iscritti',
                                                    formatter: (cell, row) => _(
                                                        <div className="text-nowrap text-center">{cell}</div>
                                                    ),
                                                    width: '50px'
                                                },
                                                {
                                                    id: 'reconfirms',
                                                    name: 'Riconferme',
                                                    formatter: (cell, row) => _(
                                                        <div className="text-nowrap text-center">{cell}</div>
                                                    ),
                                                    width: '50px'
                                                },
                                                {
                                                    id: 'sellClassroom',
                                                    name: 'Vendite Classi',
                                                    formatter: (cell, row) => _(
                                                        <div className="text-nowrap text-center">{cell}</div>
                                                    ),
                                                    width: '50px'
                                                },
                                                {
                                                    id: 'sellVideo',
                                                    name: 'Vendite Video',
                                                    formatter: (cell, row) => _(
                                                        <div className="text-nowrap text-center">{cell}</div>
                                                    ),
                                                    width: '50px'
                                                },
                                                {
                                                    id: 'leads',
                                                    name: 'Leads',
                                                    formatter: (cell, row) => _(
                                                        <div className="text-nowrap text-center">{cell}</div>
                                                    ),
                                                    width: '50px'
                                                },
                                                {
                                                    id: 'leadsConverted',
                                                    name: 'Leads convertiti',
                                                    formatter: (cell, row) => _(
                                                        <div className="text-nowrap text-center">{cell}<br/><small
                                                            hidden={isNaN(cell * 100 / row.cells[9].data)}>{Math.round(cell * 100 / row.cells[9].data)} %</small>
                                                        </div>
                                                    ),
                                                    width: '50px'
                                                },
                                                // {
                                                //     id: 'leadsTrials',
                                                //     name: 'Leads in prova',
                                                //     formatter: (cell, row) => _(
                                                //         <div className="text-nowrap text-center">{cell}</div>
                                                //     ),
                                                //     width: '50px'
                                                // },
                                                // {
                                                //     id: 'leadsTrialsCheck',
                                                //     name: 'Presenti in prova',
                                                //     formatter: (cell, row) => _(
                                                //         <div className="text-nowrap text-center">{cell}</div>
                                                //     ),
                                                //     width: '50px'
                                                // },
                                                // {
                                                //     id: 'leadsTrialsUncheck',
                                                //     name: 'Assenti in prova',
                                                //     formatter: (cell, row) => _(
                                                //         <div className="text-nowrap text-center">{cell}</div>
                                                //     ),
                                                //     width: '50px'
                                                // }
                                            ]}
                                            style={{table: {'white-space': 'nowrap'}}}
                                            resizable={true}
                                            search={false}
                                            sort={true}
                                            pagination={{enabled: false, limit: 10}}
                                            language={{
                                                'search': {
                                                    'placeholder': '🔍 Cerca...'
                                                },
                                                'pagination': {
                                                    'previous': 'Indietro',
                                                    'next': 'Avanti',
                                                    'showing': 'Stai visualizzando da',
                                                    'results': 'consulenti',
                                                    'to': 'a',
                                                    'of': 'di',
                                                },
                                                'noRecordsFound': 'Nessun consulente trovato',
                                                'error': 'Si è verificato un errore non previsto',
                                            }}
                                        />}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row> : null}
                        {(Utils.getUserData().role === "ADMIN") ? <Row>
                            <Col>
                                <Card>
                                    <CardHeader>
                                        {(loadingModules) ? <Skeleton height={20}/> : <h5>Leads Convertiti: <strong
                                            className="text-success">{totLeadsConverted}</strong> su <strong>{totLeads}</strong>
                                            <span
                                                hidden={isNaN(Math.round(totLeadsConverted * 100 / totLeads))}>({Math.round(totLeadsConverted * 100 / totLeads)} %)</span>
                                        </h5>}
                                    </CardHeader>
                                    <CardBody>
                                        {(loadingModules) ? <div className="mt-1">
                                            <Skeleton height={50}/><br/>
                                            <Skeleton height={50}/><br/>
                                            <Skeleton height={50}/><br/>
                                            <Skeleton height={50}/><br/>
                                            <Skeleton height={50}/>
                                        </div> : <div className='gridjs gridjs-container'>
                                            <div className='gridjs-wrapper'>
                                                <table role="grid" className="gridjs-table">
                                                    <thead className="gridjs-thead">
                                                    <tr className="gridjs-tr">
                                                        <th className="gridjs-th">
                                                            Provenienza
                                                        </th>
                                                        <th className="gridjs-th">
                                                            Risultati
                                                        </th>
                                                        <th className="gridjs-th">
                                                            Moduli
                                                        </th>
                                                    </tr>
                                                    {modules.map((referral, i) => {
                                                        return <tr className="gridjs-tr" key={i}>
                                                            <td className="gridjs-td text-primary text-nowrap">
                                                                <h5>{(referral.name === "TIK TOK") ? <img src={logoTikTok} alt={"tik tok"} width="20px"/> : <i className={referral.icon}/>} {referral.name}
                                                                </h5>
                                                            </td>
                                                            <td className="gridjs-td text-primary text-nowrap">
                                                                <h6>
                                                                    <strong><span
                                                                        className="text-success">{referral.converted}</span> / {referral.quantity}
                                                                        <span
                                                                            hidden={isNaN(Math.round(referral.converted * 100 / referral.quantity))}>({Math.round(referral.converted * 100 / referral.quantity)} %)</span></strong>
                                                                </h6>
                                                            </td>
                                                            <td className="gridjs-td text-nowrap">
                                                                {referral.modules.map((module, d) => {
                                                                    return <h6
                                                                        key={i + "-" + d}>{module.name}: <strong>{module.converted} su {module.quantity}</strong>&nbsp;
                                                                        <span
                                                                            className={"badge badge-soft-success"}> <span
                                                                            hidden={isNaN(Math.round(module.converted * 100 / module.quantity))}>{Math.round(module.converted * 100 / module.quantity)} %</span></span>
                                                                    </h6>
                                                                })}
                                                            </td>
                                                        </tr>
                                                    })}
                                                    </thead>
                                                </table>
                                            </div>
                                        </div>}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row> : null}
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Dashboard;
