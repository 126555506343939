import React, {useEffect, useState} from 'react';
import {Alert, Card, CardBody, Col, Container, Input, Row} from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
// import ReactApexChart from 'react-apexcharts';
import CountUp from "react-countup";
import {Loading, Utils} from "custom";
import {Link} from "react-router-dom";
import Flatpickr from "react-flatpickr";

import { Italian } from "flatpickr/dist/l10n/it";
import Select from "react-select";
import {ConsulentAPI, CourseAPI, LevelAPI, TeacherAPI} from "api";

const moment = require("moment");


const ClassroomSetting = ({onFilterClassroom, onReload}) => {

    const [teachers, setTeachers] = useState([]);
    const [levels, setLevels] = useState([]);

    const [filterTeacher, setFilterTeacher] = useState(null);
    const [filterLevel, setFilterLevel] = useState(null);
    const [filterStatus, setFilterStatus] = useState({value: "WAITING", label: "IN PROGRAMMAZIONE"});

    const isAdmin = Utils.getUserData().role === "ADMIN";

    useEffect(() => {


        TeacherAPI.list().then((resCons) => {
            setTeachers(() => resCons.map((c) => {
                return {value: c.id, label: c.firstname + " " + c.lastname}
            }))

            LevelAPI.list().then((resLevels) => {

                let levelsArr = resLevels.map((l) => {
                    return {value: l.id, label: <span>Livello {l.name}</span>}
                })

                setLevels(() => levelsArr);
            }).catch((e) => {
                console.error(e.message)
            })
        }).catch((e) => {
            console.error(e.message)
        })


    }, [])


    useEffect(() => {
        onFilterClassroom(filterTeacher, filterLevel, filterStatus);
    }, [filterTeacher, filterLevel, filterStatus])


    return (
        <React.Fragment>
            <Row className="mb-1">
                {(Utils.getUserData().role !== "TEACHER") ? <Col xl={4} className="p-1">
                    <Select
                        value={filterTeacher}
                        options={teachers}
                        isClearable
                        placeholder="Filtra docente"
                        className='me-5 w-100'
                        onChange={(val) => {
                            setFilterTeacher(() => (val !== null) ? val : null)
                        }}
                    ></Select>
                </Col> : null}
                <Col xl={4} className="p-1">
                    <div className="input-group">
                        <Select
                            value={filterStatus}
                            options={(!isAdmin) ? [
                                {value: "WAITING", label: "IN PROGRAMMAZIONE"},
                                {value: "ACTIVE", label: "ATTIVA"},
                                {value: "COMPLETED", label: "COMPLETATA"},
                            ] : [
                                {value: "DRAFT", label: "BOZZA"},
                                {value: "WAITING", label: "IN PROGRAMMAZIONE"},
                                {value: "ACTIVE", label: "ATTIVA"},
                                {value: "COMPLETED", label: "COMPLETATA"},
                            ]}
                            isClearable
                            placeholder="Filtra stato"
                            className='me-5 w-100'
                            onChange={(val) => {
                                setFilterStatus(() => (val !== null) ? val : null)
                            }}
                        ></Select>
                    </div>
                </Col>
                <Col xl={4} className="p-1">
                    <div className="input-group">
                        <Select
                            value={filterLevel}
                            options={levels}
                            isClearable
                            placeholder="Filtra livello"
                            className='me-5 w-100'
                            onChange={(val) => {
                                setFilterLevel(() => (val !== null) ? val : null)
                            }}
                        ></Select>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default ClassroomSetting;




