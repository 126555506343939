import { LessonAPI } from 'api';
import React, { Component } from 'react'
import Loader from 'react-loader-spinner';
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';

export default class CreateFile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id_course: '',
            id: '',
            loading: false,
            errors: null,
            showCreateFile: false,
            name:'',
            file: null
        };
    }

    componentDidMount(){
        // console.log(this.props.id)
    }

    CreateLesson = () =>{
        let form = this.state;
        let error = {};

        if (form.name === '')
            error.name = "Il campo nome è obbligatorio";

        if (form.file == null)
            error.file = "Il campo file è obbligatorio";

        if (form.file.size > 104857600)
            error.file = "Il file supera 100MB";

        this.setState({ errors: error });
        if (Object.keys(error).length === 0) {
            this.setState({ loading: true });
            LessonAPI.createFile(this.props.id, this.state.name).then((res)=>{
                if(res.status == 'FILE_CREATED'){
                    LessonAPI.uploadFile(this.state.file, res.fileId).then((res2)=>{
                        if(res2.status == 'FILE_UPLOAD_OK'){
                            this.setState({ loading: false, showCreateFile:false,name:null, file:null });
                            global.SweetAlert.fire({
                                title: 'File creato',
                                text: 'File creato con successo',
                                icon: 'success',
                                allowOutsideClick: false,
                                customClass: {
                                    confirmButton: 'btn btn-primary'
                                },
                                confirmButtonText: 'Chiudi',
                                buttonsStyling: false
                            })
                            this.props.onResolve()
                        }
                    }).catch(()=>{
                        this.setState({ loading: false,name:null, file:null });
                        global.SweetAlert.fire({
                            title: 'Errore',
                            text: 'Si è verificato un errore non previsto',
                            icon: 'error',
                            customClass: {
                                confirmButton: 'btn btn-primary'
                            }
                        });
                    })
                }
                console.log('res----->',res.status);
            }).catch(()=>{
                this.setState({ loading: false,name:null, file:null });
                global.SweetAlert.fire({
                    title: 'Errore',
                    text: 'Errore durante la creazione del file',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                });
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className='my-2 text-center'>
                    <Button
                        type="button"
                        size="sm"
                        color='primary'
                        onClick={() => this.setState({ showCreateFile: true })}
                    >
                        <i className='ri-upload-2-fill align-bottom me-1'></i>
                        Carica file
                    </Button>
                </div>
                <Modal style={{ overflowY: "auto" }} isOpen={this.state.showCreateFile} scrollable={true} className={'modal-dialog-scrollable modal-lg'}>
                    <ModalBody className='px-sm-5 pt-5 pb-50'>
                        <div className='text-center mb-5'>
                            <h3 className='mb-1'>Carica file</h3>
                        </div>
                        {this.state.loading ?
                        <div style={{textAlign: 'center',
                        height: '200px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'}}>
                            <Loader type={'TailSpin'} color='#E24815'/>
                        </div> :
                        <Row>
                            <Col sm={12}>
                                <FormGroup>
                                    <Label for="exampleSelect">Nome</Label>
                                    <Input type="text" name="name" onChange={(e)=>this.setState({name:e.target.value})} value={this.state.name} invalid={Boolean(this.state.errors?.name)}/>
                                    <FormFeedback>{this.state.errors?.name}</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col sm={12}>
                                <FormGroup>
                                    <Label for="exampleSelect">Seleziona file</Label>
                                    <Input
                                        type="file"
                                        name="select"
                                        onChange={(e)=> this.setState({file:e.target.files[0]})}
                                        accept="image/*,video/*,audio/*,application/pdf,application/vnd.ms-excel"
                                        invalid={Boolean(this.state.errors?.file)}
                                    />
                                    <small>Massima dimensione di caricamento: <b>100 MB</b></small>
                                    <FormFeedback>{this.state.errors?.file}</FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>}
                    </ModalBody>
                    <ModalFooter>
                        <Button color='success' onClick={this.CreateLesson} >
                            SALVA
                        </Button>
                        <Button color='danger' onClick={() => this.setState({ showCreateFile: false,name:null, file:null })} >
                            CHIUDI
                        </Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        )
    }
}
