import React, { Component } from 'react'
import { Card, CardBody, Col, Container, FormFeedback, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'

import { ClientAPI, CourseAPI, LeadAPI, SellAPI } from 'api';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import VideoCorsi from './tabs/Video';
import Lession from './tabs/Lesson';

export default class SellCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: 1,
            client:null
        };
    }

    componentDidMount() {
        this.getDetailClient()
    }

    getDetailClient() {
        const query = new URLSearchParams(window.location.search)
        ClientAPI.detail(query.get('client')).then((client) => {
            this.setState({client: client})
        }).catch((err) => {
            console.error(err);
            this.setState({error: true})
        })
    }


    render() {
        const self = this
        return (
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Crea Vendita" pageTitle="CLTV Cloud" />
                    <Card>
                        <CardBody className="checkout-tab">
                            <div className="step-arrow-nav mt-n3 mx-n3">
                                <Nav className="nav-pills nav-justified custom-nav" role="tablist">
                                    <NavItem>
                                        <NavLink href="#"
                                            className={self.state.tab === 1 ? "active fs-15 p-3" : "fs-15 p-3"}
                                            onClick={() => { this.setState({ tab: 1 }) }}
                                        >
                                            <i className="ri-user-2-line fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                                            VIDEOCORSI
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink href="#"
                                            className={self.state.tab === 2 ? "active fs-15 p-3" : "fs-15 p-3"}
                                            onClick={() => { this.setState({ tab: 2 }) }}
                                        >
                                            <i className="ri-user-2-line fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                                            LEZIONI
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={this.state.tab}>
                                    <TabPane tabId={1} id="pills-bill-info">
                                        <VideoCorsi client={self.state.client}/>
                                    </TabPane>
                                    <TabPane tabId={2} id="pills-bill-info">
                                        <Lession client={self.state.client}/>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        )
    }
}
