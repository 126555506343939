import React, { Component } from 'react'
import { Card, CardBody, CardHeader, Col, Container, FormFeedback, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'
import BreadCrumb from '../../Components/Common/BreadCrumb'
import profileBg from '../../assets/images/monitor-bg.jpg';
import { AuthAPI } from 'api';
import md5 from 'md5';

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 1,
            password:'',
            confirmPassword:'',
            newPassword:'',
            errors:{}
        }
    }

    handleChangeTab = (tab) => {
        this.setState({ activeTab: tab })
    }

    handleChangePassword = () =>{
        let form = this.state;
        let error = {};
        if(form.password == ''){
            error.password = 'Il campo password attuale è obbligatorio'
        }
        if(form.confirmPassword == ''){
            error.confirmPassword = 'Il campo conferma password è obbligatorio'
        }
        if(form.newPassword == ''){
            error.newPassword = 'Il campo nuova password è obbligatorio'
        }
        if(form.newPassword != form.confirmPassword){
            error.newPassword = 'Le password non corrispondono'
            error.confirmPassword = 'Le password non corrispondono'
        }
        this.setState({errors: error});
        if (Object.keys(error).length === 0) {
            const payload = {
                old_password: md5(this.state.password),
                new_password: md5(this.state.newPassword)
            }
            AuthAPI.changePassword(payload).then((res)=>{
                console.log('res',res)
                if(res.status == "PASSWORD_CHANGE_OK"){
                    this.setState({
                        errors:{},
                        password:'',
                        confirmPassword:'',
                        newPassword:'',
                    })
                }
            })
        }
    }

    handlePasswordChange = (e) =>{
        this.setState({[e.target.name]:e.target.value})
    }
    render() {
        const form = this.state
        const errors = this.state.errors
        return (
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Profile" pageTitle="CLTV Cloud" />
                    <div className="profile-foreground position-relative mx-n4 mt-n4" style={{ height: 290 }}>
                        <div className="profile-wid-bg">
                            <img src={profileBg} alt="" className="profile-wid-img" />
                        </div>
                    </div>
                    <Row>
                        <Col md={12}>
                            <Card>
                                <CardHeader>
                                    <h5 className="card-title mb-5 d-md-none">Posizione</h5>
                                </CardHeader>
                                <CardBody className="checkout-tab">
                                    <div className="step-arrow-nav mt-n3 mx-n3 mb-3">
                                        <Nav className="nav-pills custom-nav" role="tablist">
                                            <NavItem className='w-25 text-center'>
                                                <NavLink href="#" className={`${this.state.activeTab == 1 ? 'active fs-15 p-3' : 'fs-15 p-3'}`} onClick={() => { this.handleChangeTab(1) }} >
                                                    Informazioni Personali
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className='w-25 text-center'>
                                                <NavLink href="#" className={`${this.state.activeTab == 2 ? 'active fs-15 p-3' : 'fs-15 p-3'}`} onClick={() => { this.handleChangeTab(2) }} >
                                                    Cambia Password
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </div>
                                    <TabContent activeTab={this.state.activeTab}>
                                        <TabPane tabId={1} id="pills-bill-info">
                                            <Row>
                                                <Col md={6}>
                                                    <div className='mb-2'>
                                                        <Label className='form-label'>
                                                            Nome
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                        // value={form.email}
                                                        // invalid={Boolean(errors.email)}
                                                        // onChange={(e) => this.changeForm('email', e.target.value)}
                                                        />
                                                        {/* <FormFeedback>{errors.email}</FormFeedback> */}
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className='mb-2'>
                                                        <Label className='form-label'>
                                                            Cognome
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                        // value={form.email}
                                                        // invalid={Boolean(errors.email)}
                                                        // onChange={(e) => this.changeForm('email', e.target.value)}
                                                        />
                                                        {/* <FormFeedback>{errors.email}</FormFeedback> */}
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className='mb-2'>
                                                        <Label className='form-label'>
                                                            Numero di telefono
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                        // value={form.email}
                                                        // invalid={Boolean(errors.email)}
                                                        // onChange={(e) => this.changeForm('email', e.target.value)}
                                                        />
                                                        {/* <FormFeedback>{errors.email}</FormFeedback> */}
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className='mb-2'>
                                                        <Label className='form-label'>
                                                            Email
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                        // value={form.email}
                                                        // invalid={Boolean(errors.email)}
                                                        // onChange={(e) => this.changeForm('email', e.target.value)}
                                                        />
                                                        {/* <FormFeedback>{errors.email}</FormFeedback> */}
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className='mb-2'>
                                                        <Label className='form-label'>
                                                            CAP
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                        // value={form.email}
                                                        // invalid={Boolean(errors.email)}
                                                        // onChange={(e) => this.changeForm('email', e.target.value)}
                                                        />
                                                        {/* <FormFeedback>{errors.email}</FormFeedback> */}
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className='mb-2'>
                                                        <Label className='form-label'>
                                                            Citta di Residenza
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                        // value={form.email}
                                                        // invalid={Boolean(errors.email)}
                                                        // onChange={(e) => this.changeForm('email', e.target.value)}
                                                        />
                                                        {/* <FormFeedback>{errors.email}</FormFeedback> */}
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className='mb-2'>
                                                        <Label className='form-label'>
                                                            Paese
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                        // value={form.email}
                                                        // invalid={Boolean(errors.email)}
                                                        // onChange={(e) => this.changeForm('email', e.target.value)}
                                                        />
                                                        {/* <FormFeedback>{errors.email}</FormFeedback> */}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId={2} id="pills-bill-info">
                                            <Row>
                                                <Col md={4}>
                                                    <div className='mb-2'>
                                                        <Label className='form-label'>
                                                            Password Attuale
                                                        </Label>
                                                        <Input
                                                            type="password"
                                                            value={form?.password}
                                                            name='password'
                                                            invalid={Boolean(errors?.password)}
                                                            onChange={this.handlePasswordChange}
                                                        />
                                                        <FormFeedback>{errors?.password}</FormFeedback>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className='mb-2'>
                                                        <Label className='form-label'>
                                                            Nuova Password
                                                        </Label>
                                                        <Input
                                                            type="password"
                                                            value={form?.confirmPassword}
                                                            name='confirmPassword'
                                                            invalid={Boolean(errors?.confirmPassword)}
                                                            onChange={this.handlePasswordChange}
                                                        />
                                                        <FormFeedback>{errors?.confirmPassword}</FormFeedback>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className='mb-2'>
                                                        <Label className='form-label'>
                                                            Conferma Nuova Password
                                                        </Label>
                                                        <Input
                                                            type="password"
                                                            value={form?.newPassword}
                                                            name='newPassword'
                                                            invalid={Boolean(errors?.newPassword)}
                                                            onChange={this.handlePasswordChange}
                                                        />
                                                        <FormFeedback>{errors?.newPassword}</FormFeedback>
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <div>
                                                        <button
                                                            type="button"
                                                            className="btn btn-success add-btn me-2"
                                                            id="create-btn"
                                                            onClick={this.handleChangePassword}
                                                        >
                                                            Cambia Password
                                                        </button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
export default Profile
