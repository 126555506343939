import { LessonAPI } from 'api';
import React, { Component } from 'react'
import Loader from 'react-loader-spinner';
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';

export default class EditFile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id_course: '',
            id: '',
            loading: false,
            errors: null,
            showEditFile: false,
            name: '',
        };
    }

    componentDidMount() {
        const { id, name } = this.props.file
        this.setState({ id, name })
    }

    EditFileName = () => {
        let form = this.state;
        let error = {};

        if (form.name === '')
            error.name = "Il campo nome è obbligatorio";

        this.setState({ errors: error });
        if (Object.keys(error).length === 0) {
            this.setState({ loading: true });
            LessonAPI.editFile(this.state.id, this.state.name).then((res) => {
                this.setState({ loading: false, showEditFile: false });
                global.SweetAlert.fire({
                    title: 'File modificato',
                    text: 'Il file è stato modificato con successo',
                    icon: 'success',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    confirmButtonText: 'Chiudi',
                    buttonsStyling: false
                })
                this.props.onResolve()
            }).catch(() => {
                this.setState({ loading: false });
                global.SweetAlert.fire({
                    title: 'Errore',
                    text: 'Si è verificato un errore non previsto',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                });
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className='text-center'>
                    <i className='ri-pencil-fill fs-20 text-info' onClick={() => this.setState({ showEditFile: true })}></i>&nbsp;
                </div>
                <Modal style={{ overflowY: "auto" }} isOpen={this.state.showEditFile} scrollable={true} className={'modal-dialog-scrollable modal-lg'}>
                    <ModalBody className='px-sm-5 pt-5 pb-50'>
                        <div className='text-center mb-5'>
                            <h3 className='mb-1'>MODIFICA FILE</h3>
                        </div>
                        {this.state.loading ?
                            <div style={{
                                textAlign: 'center',
                                height: '200px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <Loader type={'TailSpin'} color='#E24815' />
                            </div> :
                            <Row>
                                <Col sm={12}>
                                    <FormGroup>
                                        <Label for="exampleSelect">Name</Label>
                                        <Input type="text" name="name" onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} invalid={Boolean(this.state.errors?.name)} />
                                        <FormFeedback>{this.state.errors?.name}</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>}
                    </ModalBody>
                    <ModalFooter>
                        <Button color='success' onClick={this.EditFileName} >
                            SALVA
                        </Button>
                        <Button color='danger' onClick={() => this.setState({ showEditFile: false, })} >
                            CHIUDI
                        </Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        )
    }
}
