import React, {useEffect, useState} from 'react';
import {Alert, Card, CardBody, Col, Container, Input, Row} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
// import ReactApexChart from 'react-apexcharts';
import CountUp from "react-countup";
import {Loading, Utils} from "custom";
import {Link} from "react-router-dom";
import Flatpickr from "react-flatpickr";

import { Italian } from "flatpickr/dist/l10n/it";
import Select from "react-select";

const moment = require("moment");


const LeadSetting = ({leads, onFilterLeads, onReload}) => {

    const [counters, setCounters] = useState(null);

    const [start, setStart] = useState(true);

    const [filters, setFilters] = useState([]);

    const [range, setRange] = useState({start_date: moment().subtract(90, "days"), end_date: moment()});

    const isAdmin = Utils.getUserData().role === "ADMIN";

    useEffect(() => {

        let countersCalc = {
            total: leads.length,
            converted: leads.filter((lead) => lead.status === "CONVERTED").length,
            trials: leads.filter((lead) => lead.status === "TRIAL").length,
            trialCheck: leads.filter((lead) => (lead.trial_check && moment(lead.trial_date).isBefore(moment()))).length,
            trialUncheck: leads.filter((lead) => (!lead.trial_check && lead.id_trial !== null && moment(lead.trial_date).isBefore(moment()))).length,
            unassigned: leads.filter((lead) => (lead.id_lead_consulent === null)).length,
            remarketing: leads.filter((lead) => lead.status === "REMARKETING").length,
            recall: leads.filter((lead) => lead.status === "APPOINTMENT").length,
            working: leads.filter((lead) => lead.status === "WORKING").length,
            waiting: leads.filter((lead) => (lead.status === null && lead.id_lead_consulent !== null)).length,
            unreply: leads.filter((lead) => lead.status === "UNREPLY").length,
            ended: leads.filter((lead) => lead.status === "ENDED").length,
            whatsapp: leads.filter((lead) => lead.referral_platform === "WHATSAPP").length,
            manual: leads.filter((lead) => lead.referral_platform === "MANUAL").length,
            funnelBm: leads.filter((lead) => (lead.referral_platform === "META" || lead.referral_platform === "FUNNEL" || lead.referral_platform === "TIK_TOK")).length,
            bm: leads.filter((lead) => lead.referral_platform === "META").length,
            tik_tok: leads.filter((lead) => lead.referral_platform === "TIK_TOK").length,
            funnel: leads.filter((lead) => lead.referral_platform === "FUNNEL").length,
            report: leads.filter((lead) => lead.referral_platform === "REPORT").length
        };

        setCounters(() => countersCalc)

    }, [leads])

    useEffect(() => {
        if (range.start_date && range.end_date)
            onReload({start_date: moment(range.start_date).format("DD-MM-YYYY"), end_date: moment(range.end_date).format("DD-MM-YYYY")})
    }, [range])

    useEffect(() => {
        if (!start)
            onFilterLeads(filters)
    }, [filters])


    let onChangeFilter = (filter, add) => {
        let newFilters = [...filters];

        if (add) {
            newFilters.push(filter);
        } else {
            const indexToRemove = newFilters.findIndex((f) => f === filter);
            if (indexToRemove !== -1) {
                newFilters.splice(indexToRemove, 1);
            }
        }
        setStart(false)
        setFilters(newFilters);
    };


    return (
        <React.Fragment>
            <Row className="mb-3">
                <Col md={4}>
                    <div className="input-group">
                        <Flatpickr
                            className="form-control border-0 dash-filter-picker shadow"
                            options={{
                                mode: "range",
                                dateFormat: "d/m/Y",
                                locale: Italian,
                            }}
                            value={(range.start_date !== null && range.end_date !== null) ? [range.start_date.format("DD/MM/YYYY"), range.end_date.format("DD/MM/YYYY")] : []}
                            onChange={(selectedDates) => {
                                if (selectedDates.length === 2) {
                                    setRange({
                                        start_date: moment(selectedDates[0]),
                                        end_date: moment(selectedDates[1]),
                                    });
                                }
                            }}
                        />
                        <div className="input-group-text bg-primary border-primary text-white"><i className="ri-calendar-2-line"></i></div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="order-xxl-0 order-first">
                    <Row>
                        <Col lg={3} md={3}>
                            <Card>
                                <CardBody>
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1">
                                            <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">TOTALE</p>
                                            <h5 className="mb-0" style={{paddingLeft: 25}}>
                                                <CountUp start={0} end={counters?.total} decimals={0}
                                                         separator={"."} prefix={""}
                                                         duration={3}/>
                                            </h5>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1">
                                            <p className="text-uppercase fw-semibold fs-12 mb-1 text-success"><Input className="form-check-input fs-12 cursor-pointer" type="checkbox" checked={(filters.includes("converted"))} onChange={(e) => onChangeFilter("converted", e.target.checked)}/>&nbsp;&nbsp;CONVERTITI</p>
                                            <h5 className=" mb-0" style={{paddingLeft: 25}}>
                                                <CountUp start={0} end={counters?.converted} decimals={0}
                                                         separator={"."} prefix={""}
                                                         duration={3}/>
                                            </h5>
                                        </div>
                                        {(counters?.total > 0) ? <div className="flex-shrink-0 align-self-end">
                                            <span className={"badge badge-soft-success"}> {Math.round(counters?.converted*100/counters?.total)} %</span>
                                        </div> : null}
                                    </div>
                                    <br />
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1">
                                            <p className="text-uppercase fw-semibold fs-12 mb-1 cursor-pointer text-danger"><Input className="form-check-input fs-12 cursor-pointer" type="checkbox" checked={(filters.includes("ended"))} onChange={(e) => onChangeFilter("ended", e.target.checked)}/>&nbsp;&nbsp;SCARTATI</p>
                                            <h5 className=" mb-0" style={{paddingLeft: 25}}>
                                                <CountUp start={0} end={counters?.ended} decimals={0}
                                                         separator={"."} prefix={""}
                                                         duration={3}/>
                                            </h5>
                                        </div>
                                        {(counters?.total > 0) ? <div className="flex-shrink-0 align-self-end">
                                            <span className={"badge badge-soft-danger"}> {Math.round(counters?.ended*100/counters?.total)} %</span>
                                        </div> : null}
                                    </div>
                                    <br />
                                    {(isAdmin) ? <div className="d-flex align-items-center">
                                        <div className="flex-grow-1">
                                            <p className="text-uppercase fw-semibold fs-12 mb-1 cursor-pointer text-primary"><Input className="form-check-input fs-12 cursor-pointer" type="checkbox" checked={(filters.includes("unassigned"))} onChange={(e) => onChangeFilter("unassigned", e.target.checked)}/>&nbsp;&nbsp;NON ASSEGNATI</p>
                                            <h5 className=" mb-0" style={{paddingLeft: 25}}>
                                                <CountUp start={0} end={counters?.unassigned} decimals={0}
                                                         separator={"."} prefix={""}
                                                         duration={3}/>
                                            </h5>
                                        </div>
                                    </div> : null}
                                    <br />
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1">
                                            <p className="text-uppercase fw-semibold fs-12 mb-1 cursor-pointer text-warning"><Input className="form-check-input fs-12 cursor-pointer" type="checkbox" checked={(filters.includes("waiting"))} onChange={(e) => onChangeFilter("waiting", e.target.checked)}/>&nbsp;&nbsp;IN ATTESA</p>
                                            <h5 className=" mb-0" style={{paddingLeft: 25}}>
                                                <CountUp start={0} end={counters?.waiting} decimals={0}
                                                         separator={"."} prefix={""}
                                                         duration={3}/>
                                            </h5>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={3} md={3}>
                            {(isAdmin) ? <Card>
                                <CardBody>
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1">
                                            <p className="text-uppercase fw-semibold fs-12 text-muted mb-1"><Input className="form-check-input fs-12 cursor-pointer" type="checkbox"  checked={(filters.includes("funnel"))} onChange={(e) => onChangeFilter("funnel", e.target.checked)}/>&nbsp;&nbsp;FUNNEL</p>
                                            <h5 className="mb-0" style={{paddingLeft: 25}}>
                                                <CountUp start={0} end={counters?.funnel} decimals={0}
                                                         separator={"."} prefix={""}
                                                         duration={3}/>
                                            </h5>
                                        </div>
                                        {(counters?.total > 0) ? <div className="flex-shrink-0 align-self-end">
                                            <span className={"badge badge-soft-primary"}> {Math.round(counters?.funnel*100/counters?.total)} %</span>
                                        </div> : null}
                                    </div>
                                    <br />
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1">
                                            <p className="text-uppercase fw-semibold fs-12 mb-1 cursor-pointer text-muted"><Input className="form-check-input fs-12 cursor-pointer" type="checkbox"  checked={(filters.includes("bm"))} onChange={(e) => onChangeFilter("bm", e.target.checked)}/>&nbsp;&nbsp;META</p>
                                            <h5 className=" mb-0" style={{paddingLeft: 25}}>
                                                <CountUp start={0} end={counters?.bm} decimals={0}
                                                         separator={"."} prefix={""}
                                                         duration={3}/>
                                            </h5>
                                        </div>
                                        {(counters?.total > 0) ? <div className="flex-shrink-0 align-self-end">
                                            <span className={"badge badge-soft-primary"}> {Math.round(counters?.bm*100/counters?.total)} %</span>
                                        </div> : null}
                                    </div>
                                    <br />
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1">
                                            <p className="text-uppercase fw-semibold fs-12 mb-1 cursor-pointer text-muted"><Input className="form-check-input fs-12 cursor-pointer" type="checkbox"  checked={(filters.includes("tik_tok"))} onChange={(e) => onChangeFilter("tik_tok", e.target.checked)}/>&nbsp;&nbsp;TIK TOK</p>
                                            <h5 className=" mb-0" style={{paddingLeft: 25}}>
                                                <CountUp start={0} end={counters?.tik_tok} decimals={0}
                                                         separator={"."} prefix={""}
                                                         duration={3}/>
                                            </h5>
                                        </div>
                                        {(counters?.total > 0) ? <div className="flex-shrink-0 align-self-end">
                                            <span className={"badge badge-soft-primary"}> {Math.round(counters?.tik_tok*100/counters?.total)} %</span>
                                        </div> : null}
                                    </div>
                                    <br />
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1">
                                            <p className="text-uppercase fw-semibold fs-12 mb-1 cursor-pointer text-muted"><Input className="form-check-input fs-12 cursor-pointer" type="checkbox" checked={(filters.includes("whatsapp"))} onChange={(e) => onChangeFilter("whatsapp", e.target.checked)}/>&nbsp;&nbsp;WHATSAPP</p>
                                            <h5 className=" mb-0" style={{paddingLeft: 25}}>
                                                <CountUp start={0} end={counters?.whatsapp} decimals={0}
                                                         separator={"."} prefix={""}
                                                         duration={3}/>
                                            </h5>
                                        </div>
                                        {(counters?.total > 0) ? <div className="flex-shrink-0 align-self-end">
                                            <span className={"badge badge-soft-primary"}> {Math.round(counters?.whatsapp*100/counters?.total)} %</span>
                                        </div> : null}
                                    </div>
                                    <br />
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1">
                                            <p className="text-uppercase fw-semibold fs-12 mb-1 cursor-pointer text-muted"><Input className="form-check-input fs-12 cursor-pointer" type="checkbox"  checked={(filters.includes("manual"))} onChange={(e) => onChangeFilter("manual", e.target.checked)}/>&nbsp;&nbsp;PASSAPAROLA</p>
                                            <h5 className=" mb-0" style={{paddingLeft: 25}}>
                                                <CountUp start={0} end={counters?.manual} decimals={0}
                                                         separator={"."} prefix={""}
                                                         duration={3}/>
                                            </h5>
                                        </div>
                                        {(counters?.total > 0) ? <div className="flex-shrink-0 align-self-end">
                                            <span className={"badge badge-soft-primary"}> {Math.round(counters?.manual*100/counters?.total)} %</span>
                                        </div> : null}
                                    </div>
                                    <br />
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1">
                                            <p className="text-uppercase fw-semibold fs-12 mb-1 cursor-pointer text-muted"><Input className="form-check-input fs-12 cursor-pointer" type="checkbox"  checked={(filters.includes("report"))} onChange={(e) => onChangeFilter("report", e.target.checked)}/>&nbsp;&nbsp;SEGNALAZIONI</p>
                                            <h5 className=" mb-0" style={{paddingLeft: 25}}>
                                                <CountUp start={0} end={counters?.report} decimals={0}
                                                         separator={"."} prefix={""}
                                                         duration={3}/>
                                            </h5>
                                        </div>
                                        {(counters?.total > 0) ? <div className="flex-shrink-0 align-self-end">
                                            <span className={"badge badge-soft-primary"}> {Math.round(counters?.report*100/counters?.total)} %</span>
                                        </div> : null}
                                    </div>
                                </CardBody>
                            </Card> : <Card>
                            <CardBody>
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                        <p className="text-uppercase fw-semibold fs-12 text-muted mb-1"><Input className="form-check-input fs-12 cursor-pointer" type="checkbox"  checked={(filters.includes("funnelBm"))} onChange={(e) => onChangeFilter("funnelBm", e.target.checked)}/>&nbsp;&nbsp;FUNNEL/META/TIK TOK</p>
                                        <h5 className="mb-0" style={{paddingLeft: 25}}>
                                            <CountUp start={0} end={counters?.funnelBm} decimals={0}
                                                     separator={"."} prefix={""}
                                                     duration={3}/>
                                        </h5>
                                    </div>
                                    {(counters?.total > 0) ? <div className="flex-shrink-0 align-self-end">
                                        <span className={"badge badge-soft-primary"}> {Math.round((counters?.funnelBm)*100/counters?.total)} %</span>
                                    </div> : null}
                                </div>
                                <br />
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                        <p className="text-uppercase fw-semibold fs-12 mb-1 cursor-pointer text-muted"><Input className="form-check-input fs-12 cursor-pointer" type="checkbox"  checked={(filters.includes("manual"))} onChange={(e) => onChangeFilter("manual", e.target.checked)}/>&nbsp;&nbsp;PASSAPAROLA</p>
                                        <h5 className=" mb-0" style={{paddingLeft: 25}}>
                                            <CountUp start={0} end={counters?.manual} decimals={0}
                                                     separator={"."} prefix={""}
                                                     duration={3}/>
                                        </h5>
                                    </div>
                                    {(counters?.total > 0) ? <div className="flex-shrink-0 align-self-end">
                                        <span className={"badge badge-soft-primary"}> {Math.round(counters?.manual*100/counters?.total)} %</span>
                                    </div> : null}
                                </div>
                                <br />
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                        <p className="text-uppercase fw-semibold fs-12 mb-1 cursor-pointer text-muted"><Input className="form-check-input fs-12 cursor-pointer" type="checkbox" checked={(filters.includes("whatsapp"))} onChange={(e) => onChangeFilter("whatsapp", e.target.checked)}/>&nbsp;&nbsp;WHATSAPP</p>
                                        <h5 className=" mb-0" style={{paddingLeft: 25}}>
                                            <CountUp start={0} end={counters?.whatsapp} decimals={0}
                                                     separator={"."} prefix={""}
                                                     duration={3}/>
                                        </h5>
                                    </div>
                                    {(counters?.total > 0) ? <div className="flex-shrink-0 align-self-end">
                                        <span className={"badge badge-soft-primary"}> {Math.round(counters?.whatsapp*100/counters?.total)} %</span>
                                    </div> : null}
                                </div>
                            </CardBody>
                        </Card>}
                        </Col>
                        <Col lg={3} md={3}>
                            <Card>
                                <CardBody>
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1">
                                            <p className="text-uppercase fw-semibold fs-12 mb-1 status-color-working"><Input className="form-check-input fs-12 cursor-pointer" type="checkbox"  checked={(filters.includes("working"))} onChange={(e) => onChangeFilter("working", e.target.checked)}/>&nbsp;&nbsp;IN LAVORAZIONE</p>
                                            <h5 className="mb-0" style={{paddingLeft: 25}}>
                                                <CountUp start={0} end={counters?.working} decimals={0}
                                                         separator={"."} prefix={""}
                                                         duration={3}/>
                                            </h5>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1">
                                            <p className="text-uppercase fw-semibold fs-12 mb-1 cursor-pointer status-color-appointment"><Input className="form-check-input fs-12 cursor-pointer" type="checkbox" checked={(filters.includes("recall"))} onChange={(e) => onChangeFilter("recall", e.target.checked)}/>&nbsp;&nbsp;DA RICHIAMARE</p>
                                            <h5 className=" mb-0" style={{paddingLeft: 25}}>
                                                <CountUp start={0} end={counters?.recall} decimals={0}
                                                         separator={"."} prefix={""}
                                                         duration={3}/>
                                            </h5>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1">
                                            <p className="text-uppercase fw-semibold fs-12 mb-1 cursor-pointer status-color-unreply"><Input className="form-check-input fs-12 cursor-pointer" type="checkbox"  checked={(filters.includes("unreply"))} onChange={(e) => onChangeFilter("unreply", e.target.checked)}/>&nbsp;&nbsp;NESSUNA RISPOSTA</p>
                                            <h5 className=" mb-0" style={{paddingLeft: 25}}>
                                                <CountUp start={0} end={counters?.unreply} decimals={0}
                                                         separator={"."} prefix={""}
                                                         duration={3}/>
                                            </h5>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1">
                                            <p className="text-uppercase fw-semibold fs-12 mb-1 status-color-remarketing"><Input className="form-check-input fs-12 cursor-pointer" type="checkbox"  checked={(filters.includes("remarketing"))} onChange={(e) => onChangeFilter("remarketing", e.target.checked)}/>&nbsp;&nbsp;REMARKETING</p>
                                            <h5 className="mb-0" style={{paddingLeft: 25}}>
                                                <CountUp start={0} end={counters?.remarketing} decimals={0}
                                                         separator={"."} prefix={""}
                                                         duration={3}/>
                                            </h5>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={3} md={3}>
                            <Card>
                                <CardBody>
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1">
                                            <p className="text-uppercase fw-semibold fs-12 mb-1 status-color-trial"><Input className="form-check-input fs-12 cursor-pointer" type="checkbox" checked={(filters.includes("trials"))} onChange={(e) => onChangeFilter("trials", e.target.checked)}/>&nbsp;&nbsp;IN PROVA</p>
                                            <h5 className="mb-0" style={{paddingLeft: 25}}>
                                                <CountUp start={0} end={counters?.trials} decimals={0}
                                                         separator={"."} prefix={""}
                                                         duration={3}/>
                                            </h5>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1">
                                            <p className="text-uppercase fw-semibold fs-12 mb-1 cursor-pointer text-success"><Input className="form-check-input fs-12 cursor-pointer" type="checkbox" checked={(filters.includes("trialCheck"))} onChange={(e) => onChangeFilter("trialCheck", e.target.checked)}/>&nbsp;&nbsp;PRESENTI</p>
                                            <h5 className=" mb-0" style={{paddingLeft: 25}}>
                                                <CountUp start={0} end={counters?.trialCheck} decimals={0}
                                                         separator={"."} prefix={""}
                                                         duration={3}/>
                                            </h5>
                                        </div>
                                        {(counters?.trials > 0) ? <div className="flex-shrink-0 align-self-end">
                                            <span className={"badge badge-soft-success"}> {Math.round(counters?.trialCheck*100/counters?.trials)} %</span>
                                        </div> : null}
                                    </div>
                                    <br />
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1">
                                            <p className="text-uppercase fw-semibold fs-12 mb-1 cursor-pointer text-danger"><Input className="form-check-input fs-12 cursor-pointer" type="checkbox" checked={(filters.includes("trialUncheck"))} onChange={(e) => onChangeFilter("trialUncheck", e.target.checked)}/>&nbsp;&nbsp;ASSENTI</p>
                                            <h5 className=" mb-0" style={{paddingLeft: 25}}>
                                                <CountUp start={0} end={counters?.trialUncheck} decimals={0}
                                                         separator={"."} prefix={""}
                                                         duration={3}/>
                                            </h5>
                                        </div>
                                        {(counters?.trials > 0) ? <div className="flex-shrink-0 align-self-end">
                                            <span className={"badge badge-soft-danger"}> {Math.round(counters?.trialUncheck*100/counters?.trials)} %</span>
                                        </div> : null}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default LeadSetting;




