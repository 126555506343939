let network = function() {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');
        fetch(global.route.network, {
            headers: {'x-access-token': hash},
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : global.printError(response))
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('network reporter', responseJson);
                if (responseJson.status === "NETWORK_REPORTERS")
                    resolve(responseJson.network);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};
let list = function(startDate, endDate) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');
        let filterDate = "";

        if (startDate && endDate)
            filterDate = "?start_date=" + startDate + "&end_date=" + endDate

        fetch(global.route.reporter + filterDate, {
            headers: {'x-access-token': hash},
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : global.printError(response))
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('reporter list', responseJson);
                if (responseJson.status === "LIST_REPORTERS")
                    resolve(responseJson.list);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let edit = function(reporter) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');
        fetch(global.route.reporter, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                id: reporter.id,
                name: reporter.name,
                email: reporter.email,
                cell: reporter.cell,
                bank_iban: reporter.bank_iban,
                father_id: reporter.father_id,
                user_id: reporter.user_id
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : global.printError(response))
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('reporter edit', responseJson);
                if (responseJson.status === "REPORTER_EDITED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let remove = function(id) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.reporter, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'DELETE',
            body: JSON.stringify({
                id: id
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('reporter delete', responseJson);
                if (responseJson.status === "REPORTER_DELETED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

const ReporterAPI = {
    network: network,
    list: list,
    edit: edit,
    remove: remove,
};

module.exports = ReporterAPI;
