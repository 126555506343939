import React, {Component} from 'react';
import {
    Alert,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Badge,
    Label, Input
} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import {withRouter, Link} from "react-router-dom";
import {
    Utils,
    Loading,
    DeleteLeadLog,
    EditLeadLog,
    AssignLead,
    ListReminder,
    EditLead,
    ListConsulentClassroom
} from "custom";
import {ClassRoomTrialAPI, ConsulentAPI, LeadAPI, LeadLogAPI, LevelAPI} from "api";

import SimpleBar from "simplebar-react";
import LeadInfo from "./nav/LeadInfo"

import 'moment/locale/it';
import Select from "react-select";
import Question from './nav/Question';

const moment = require("moment");

class LeadDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            loading: true,
            lead: null,
            editConsulent: false,
            note: "",
            tabSelected: 1,
            trials: [],
            levelList: [],
            level_selected: null,
            trialSelected: null
        }
        document.title = "Dettaglio Lead | CLTV Cloud";
    }

    componentDidMount() {
        this.getLead();
    }

    async getLead() {
        this.setState({loading: true});

        LeadAPI.detail(this.props.match.params.id).then(async (lead) => {

            let levRes = await LevelAPI.list();

            const levelList = levRes && levRes.length > 0 && levRes.map((ele) => {
                return {
                    value: ele.id,
                    label: ele.language + " - Livello " + ele.name
                }
            })

            let selectedLevel = null;

            if (lead.id_group !== null) {
                selectedLevel = levelList[levelList.findIndex((level) => (level.value === lead.id_group.level_id))];
            }

            this.setState({levelList: levelList, level_selected: selectedLevel})

            ClassRoomTrialAPI.list().then((trials) => {
                let trialsRes = trials.filter(trial => (moment(trial.dateTime).isAfter(moment()) || lead.trial_classroom_id === trial.id)).map(trial => {
                    return {
                        value: trial.id,
                        dateTime: trial.dateTime,
                        label: trial.teacher?.firstname + " | " + moment(trial.dateTime).format("ddd DD MMM HH:mm") + " | " + trial.partecipants + " p."
                    }
                })
                this.setState({
                    loading: false,
                    error: false,
                    lead: lead,
                    editConsulent: false,
                    note: "",
                    trialSelected: (lead.trial_classroom_id !== null) ? trialsRes[trialsRes.findIndex((check) => check.value === lead.trial_classroom_id)] : null,
                    trials: trialsRes
                });
            }).catch((e) => {
                console.error(e)
                this.setState({error: true});
            })
        }).catch((e) => {
            console.error(e)
            this.setState({error: true});
        })
    }

    createLeadLog() {
        const self = this;

        LeadLogAPI.add(this.state.lead.id, this.state.note).then(() => {
            global.SweetAlert.fire({
                title: 'Nota creata',
                text: 'La nota è stata creata correttamente.',
                icon: 'success',
                allowOutsideClick: false,
                customClass: {
                    confirmButton: 'btn btn-primary'
                },
                confirmButtonText: 'Chiudi',
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    self.getLead();
                    self.setState({note: ""})
                }
            })
        }).catch(() => {
            global.SweetAlert.fire({
                title: 'Errore',
                text: 'Si è verificato un errore durante la creazione della nota',
                icon: 'error',
                customClass: {
                    confirmButton: 'btn btn-primary'
                }
            });
        });
    }

    changeStatus(status) {

        const self = this;

        LeadAPI.editStatus(this.state.lead.id, status.value).then((res) => {
            global.SweetAlert.fire({
                title: 'Stato lead cambiato correttamente',
                icon: 'success',
                allowOutsideClick: false,
                customClass: {
                    confirmButton: 'btn btn-primary'
                },
                confirmButtonText: 'Chiudi',
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    self.getLead();
                }
            })
        }).catch(() => {
            global.SweetAlert.fire({
                title: 'Errore',
                text: 'Si è verificato un errore durante il cambio di stato',
                icon: 'error',
                customClass: {
                    confirmButton: 'btn btn-primary'
                }
            });
        });

    }

    printState(status) {
        switch (status) {
            case null:
                return <div className="custom-badge status-unassigned">Non assegnato</div>;
            case "WORKING":
                return <div className="custom-badge status-working">In lavorazione</div>;
            case "TRIAL":
                return <div className="custom-badge status-trial">In prova</div>;
            case "CONVERTED":
                return <div className="custom-badge status-converted">Completato</div>;
            case "APPOINTMENT":
                return <div className="custom-badge status-appointment">Appuntamento</div>;
            case "UNREPLY":
                return <div className="custom-badge status-unreply">Nessuna Risposta</div>;
            case "REMARKETING":
                return <div className="custom-badge status-remarketing">Remarketing</div>;
            case "ENDED":
                return <div className="custom-badge status-closed">Scartato</div>;
            default:
                return null;
        }
    }

    sendTrial(remove) {
        const self = this;

        let value = (remove) ? null : this.state.trialSelected.value;

        ClassRoomTrialAPI.insert(this.state.lead.id, value).then((res) => {
            this.setState({trialSelected: null});

            if (remove) {
                global.SweetAlert.fire({
                    icon: 'success',
                    title: 'Lezione di prova eliminata',
                    timer: 1500,
                    showConfirmButton: false
                });
                self.getLead();
                return;
            }

            const link = res.link;

            const htmlContent = `
                <div>
                    <p>Link da condividere:</p>
                    <Input class="form-text" type="text" value="${link}" id="copyLink" readonly>
                    <Button class="btn btn-sm btn-primary" id="copyButton">Copia</Button><br />
                    <small>Attenzione: il link non sarà mostrato una seconda volta</small>
                </div>
            `;

            global.SweetAlert.fire({
                title: 'Lezione prova confermata',
                html: htmlContent,
                icon: 'success',
                allowOutsideClick: false,
                customClass: {
                    confirmButton: 'btn btn-primary'
                },
                confirmButtonText: 'Chiudi',
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    self.getLead();
                }
            })

            const copyButton = document.getElementById('copyButton');
            copyButton.addEventListener('click', function () {
                const linkInput = document.getElementById('copyLink');
                linkInput.select();
                document.execCommand('copy');
                global.SweetAlert.fire({
                    icon: 'success',
                    title: 'Link Copiato',
                    timer: 1500,
                    showConfirmButton: false
                });
            });
        }).catch(() => {
            global.SweetAlert.fire({
                title: 'Errore',
                text: 'Si è verificato un errore durante la conferma della lezione di prova',
                icon: 'error',
                customClass: {
                    confirmButton: 'btn btn-primary'
                }
            });
        });
    }

    async setGroup(key, text) {

        const lead = this.state.lead;

        await LeadAPI.assignGroup({
            id: lead.id,
            id_group: (text.id === lead.id_group?.id && text.classroom_id === lead.id_group?.classroom_id) ? null : text
        })

        this.getLead();
    }

    render() {
        const self = this;
        const lead = this.state.lead;
        let errors = this.state.errors;
        let isAdmin = Utils.getUserData().role === "ADMIN";

        let endedClosed = (lead !== null) ? (lead.status === "ENDED" && lead.client === null) : true;

        if (this.state.error) {
            return <div className="page-content">
                <Container fluid>
                    <React.Fragment>
                        <Alert color="danger">Si è verificato un errore durante il recupero del lead.</Alert>
                    </React.Fragment>
                </Container>
            </div>
        }

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <BreadCrumb title="Dettaglio Lead" pageTitle="CLTV Cloud"/>
                        {(this.state.loading) ? <Loading/> : <div>
                            <Row>
                                <Col lg={7} xl={8}>
                                    <Card>
                                        <CardBody className="checkout-tab">
                                            <div className="step-arrow-nav mt-n3 mx-n3 mb-3">
                                                <Nav className="nav-pills nav-justified custom-nav" role="tablist">
                                                    <NavItem>
                                                        <NavLink href="#"
                                                                 className={(this.state.tabSelected === 1) ? "active fs-15 p-3" : "fs-15 p-3"}
                                                                 onClick={() => this.setState({tabSelected: 1})}>
                                                            <i className="ri-phone-fill fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                                                            Lead
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink href="#"
                                                                 className={(this.state.tabSelected === 2) ? "active fs-15 p-3" : "fs-15 p-3"}
                                                                 onClick={() => this.setState({tabSelected: 2})}>
                                                            <i className="ri-question-answer-fill fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                                                            Questionario
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink href="#"
                                                                 className={(this.state.tabSelected === 3) ? "active fs-15 p-3" : "fs-15 p-3"}
                                                                 onClick={() => this.setState({tabSelected: 3})}>
                                                            <i className="ri-calendar-2-fill fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                                                            Promemoria&nbsp;{(lead.reminders.length > 0) ? <Badge
                                                            color="danger">{lead.reminders.length}</Badge> : null}
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink href="#"
                                                                 className={(this.state.tabSelected === 4) ? "active fs-15 p-3" : "fs-15 p-3"}
                                                                 onClick={() => this.setState({tabSelected: 4})}>
                                                            <i className="mdi mdi-school fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                                                            Classe&nbsp;{(lead.id_group !== null) ? <Badge color="success">1</Badge> : null}
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>
                                            </div>

                                            <TabContent activeTab={this.state.tabSelected}>
                                                <TabPane tabId={1} id="pills-bill-info">
                                                    <LeadInfo lead={lead} onReload={this.getLead.bind(this)}/>
                                                </TabPane>
                                                <TabPane tabId={2} id="pills-bill-info">
                                                    <Question id={lead.id} details={lead.details}
                                                              onReload={this.getLead.bind(this)} leadRef/>
                                                </TabPane>
                                                <TabPane tabId={3} id="pills-bill-info">
                                                    <ListReminder id_lead={lead.id} reminders={lead.reminders}
                                                                  onResolve={this.getLead.bind(this)}/>
                                                </TabPane>
                                                <TabPane tabId={4} id="pills-bill-info">
                                                    <Select
                                                        isDisabled={(lead.id_group !== null)}
                                                        value={this.state.level_selected}
                                                        placeholder={"Seleziona il Livello..."}
                                                        onChange={(selectedOption) => {
                                                            this.setState({level_selected: selectedOption})
                                                        }}
                                                        options={this.state.levelList}
                                                    />
                                                    <br />
                                                    {(this.state.level_selected !== null) ? <ListConsulentClassroom id_level={this.state.level_selected.value} id_group={lead.id_group} onCheckGroup={this.setGroup.bind(this)}/> : null}
                                                </TabPane>
                                            </TabContent>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg={5} xl={4}>
                                    <Card>
                                        <CardHeader>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <h5 className="card-title mb-0">Riepilogo</h5>
                                                </div>
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                            <div className="mb-5 text-end">
                                                <p className="text-muted">Nominativo</p>
                                                <h5>{(lead.status !== "CONVERTED") ? <EditLead lead={{
                                                    editId: lead.id,
                                                    editName: lead.name,
                                                    editEmail: lead.email,
                                                    editPhone: lead.phone
                                                }} getLeads={this.getLead.bind(this)}/> : null}&nbsp;{(Boolean(lead.id_client)) ? <Link to={'/client/' + lead.id_client}>{lead.name}</Link> : lead.name}</h5>
                                            </div>
                                            <div className="mb-5 text-end">
                                                <p className="text-muted">Telefono</p>
                                                <h5><a href={"tel:" + lead.phone}>{lead.phone.slice(0, 3) + " " + lead.phone.slice(3)}</a>
                                                </h5><br/>
                                                <a href={"https://api.whatsapp.com/send?phone=" + lead.phone.slice(1)}><Badge
                                                    style={{fontSize: "12px"}} color="success"><i
                                                    className="mdi mdi-whatsapp"></i> Whatsapp</Badge></a>&nbsp;&nbsp;
                                                <a href={"tel:" + lead.phone}><Badge
                                                    style={{fontSize: "12px"}} color="primary"><i
                                                    className="mdi mdi-phone"></i> Chiama</Badge></a>
                                            </div>
                                            {(isAdmin) ? <div className="mb-5 text-end">
                                                <p className="text-muted">Consulente</p>
                                                {
                                                    (!this.state.editConsulent)
                                                        ?
                                                        <h5
                                                            className="text-primary"
                                                            // onClick={() => this.setState({editConsulent: true})}
                                                        >
                                                            {(lead.consulent !== null) ? lead.consulent.firstname + " " + lead.consulent.lastname : "NON ASSEGNATO"}
                                                        </h5>
                                                        :
                                                        <></>
                                                    // <AssignLead minWidth={'190px'} selectedLeads={[lead.id]} onResolve={this.getLead.bind(this)} selectedConsulent={lead.consulent}/>
                                                }
                                            </div> : null}
                                            <div className="mb-5 text-end">
                                                <p className="text-muted">Stato</p>
                                                {((lead.status === "CONVERTED") || Utils.getUserData().role === "ADMIN") ? this.printState(lead.status) :
                                                    <Select
                                                        placeholder="Seleziona lo stato..."
                                                        value={Utils.getAlloweedCustomStatus(lead.status)[Utils.getAlloweedCustomStatus(lead.status).findIndex((st) => st.value === lead.status)]}
                                                        options={Utils.getAlloweedCustomStatus(lead.status)}
                                                        onChange={(val) => {
                                                            this.changeStatus(val)
                                                        }}
                                                        formatOptionLabel={status => (
                                                            <div
                                                                className={"custom-badge " + status.class}>{status.label}</div>
                                                        )}
                                                    >
                                                    </Select>}
                                            </div>
                                        </CardBody>
                                    </Card>

                                    <Card>
                                        <CardHeader>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <h5 className="card-title mb-0">Lezione Gratuita</h5>
                                                </div>
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col sm={12}>
                                                    <Select
                                                        placeholder="Lezioni Gratuite"
                                                        isDisabled={(Utils.getUserData().role !== "CONSULENT" || lead.status === "CONVERTED")}
                                                        value={this.state.trialSelected}
                                                        options={this.state.trials}
                                                        onChange={(val) => {
                                                            this.setState({
                                                                trialSelected: val
                                                            })
                                                        }}
                                                    >
                                                    </Select>
                                                </Col>
                                            </Row>
                                            {(lead.trial_classroom_id !== null) ? ((lead.trial_check) ?
                                                <strong className="text-success">PRESENTE</strong> :
                                                <strong className="text-danger">ASSENTE</strong>) : null}
                                            <br/>
                                            {(Utils.getUserData().role === "CONSULENT" && lead.status !== "CONVERTED") ?
                                                <Row hidden={(this.state.trialSelected === null)}>
                                                    <Col className="text-center">
                                                        <button
                                                            onClick={() => this.sendTrial(false)}
                                                            type="button"
                                                            className="btn btn-sm btn-info ">
                                                            {(lead.trial_classroom_id !== null) ? "MODIFICA" : "CONFERMA PROVA"}
                                                        </button>
                                                    </Col>

                                                    {(!lead.trial_check && lead.trial_classroom_id !== null) ? <Col className="text-center">
                                                        <button
                                                            onClick={() => this.sendTrial(true)}
                                                            type="button"
                                                            className="btn btn-sm btn-danger ">
                                                            RIMUOVI
                                                        </button>
                                                    </Col> : null}
                                                </Row> : null}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card>
                                        <CardHeader>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <h5 className="card-title mb-4">Note Lead</h5>
                                                </div>
                                            </div>
                                        </CardHeader>
                                        <CardBody className="p-4">
                                            <SimpleBar style={{maxHeight: "300px"}} className="px-3 mx-n3">
                                                {lead.lead_logs.map((leadLog, i) => {
                                                    return <div className="d-flex mb-4" key={i}>
                                                        <div className="flex-grow-1 ms-3 border-bottom p-2">
                                                            <h5 className={"fs-13" + ((leadLog.user.id === null) ? " text-muted" : "")}>{leadLog.user.firstname} {leadLog.user.lastname} &nbsp;&nbsp;&nbsp;
                                                                <small
                                                                    className="text-muted">{moment(leadLog.date_hour).format("DD MMMM YYYY HH:mm")} {(leadLog.edited) ?
                                                                    <span
                                                                        className="text-muted">(Modificato)</span> : null}</small>
                                                            </h5>
                                                            <p className="text-muted">{leadLog.note}</p>
                                                            {(leadLog.user.id === Utils.getUserData().id && (isAdmin || !endedClosed))
                                                                ?
                                                                <div>
                                                                    <EditLeadLog preset={leadLog}
                                                                                 onResolve={() => this.getLead()}
                                                                                 label='Modifica Nota'/>
                                                                    &nbsp;&nbsp;&nbsp;
                                                                    <DeleteLeadLog id={leadLog.id}
                                                                                   onResolve={() => this.getLead()}/>
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    </div>
                                                })}
                                            </SimpleBar>
                                            {(!isAdmin && endedClosed) ? null : <form action="#" className="mt-3">
                                                <Row className="g-3">
                                                    <Col lg={12}>
                                                        <Label className="form-label">Aggiungi nota</Label>
                                                        <textarea className="form-control bg-light border-light"
                                                                  value={this.state.note} rows="3"
                                                                  placeholder="Inserisci nota"
                                                                  onChange={(e) => this.setState({note: e.target.value})}></textarea>
                                                    </Col>
                                                    {(this.state.note !== "") ? <Col lg={12} className="text-end">
                                                        <span className="btn btn-success"
                                                              onClick={() => this.createLeadLog()}>Conferma</span>
                                                    </Col> : null}
                                                </Row>
                                            </form>}

                                        </CardBody>

                                    </Card>

                                </Col>
                            </Row>
                        </div>}
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(LeadDetail);
