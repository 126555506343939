let add = function(idLead, leadLog) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');
        fetch(global.route.leadlog + "/" + idLead, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify({
                note: leadLog
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : global.printError(response))
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('lead log add', responseJson);
                if (responseJson.status === "LEAD_LOG_CREATED")
                    resolve(responseJson.lead_log);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let edit = function(leadLog) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');
        fetch(global.route.leadlog, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                id: leadLog.id,
                note: leadLog.note
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : global.printError(response))
            .then((responseJson) => {
                 global.checkAuth(responseJson.status);
                console.log('lead log edit', responseJson);
                if (responseJson.status === "LEAD_LOG_EDITED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let remove = function(id) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');
        fetch(global.route.leadlog, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'DELETE',
            body: JSON.stringify({
                id: id
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : global.printError(response))
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('lead log delete', responseJson);
                if (responseJson.status === "LEAD_LOG_DELETED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

const LeadLogAPI = {
    add: add,
    edit: edit,
    remove: remove,
};

module.exports = LeadLogAPI;
