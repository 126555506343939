import React, { useEffect, useState } from 'react';
import {Alert, Badge, Card, CardBody, Col, Label, Row} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { Grid, _ } from 'gridjs-react';

import { Loading, Utils } from 'custom';
import {ReporterAPI, ReportAPI, UserAPI} from 'api';
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import {Italian} from "flatpickr/dist/l10n/it";
import moment from "moment/moment";

const ReportTab = ({ history, reporterFilter, setReporterFilter, range, setCounter }) => {
    const [reports, setReports] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [reporters, setReporters] = useState([]);

    useEffect(() => {
        getReports(range);
    }, [reporterFilter, range]);

    const getReports = async () => {

        let reportersArr = await ReporterAPI.list();

        setReporters(reportersArr.map((r) => {
            return {
                value: r.id,
                label: r.name,
                role: r.user_role
            }
        }))

        let start_date = (range) ? range.start_date : null;
        let end_date = (range) ? range.end_date : null;

        if (range.start_date && range.end_date) {
            start_date = range.start_date.format("DD-MM-YYYY");
            end_date = range.end_date.format("DD-MM-YYYY");
        }

        let countRevenue = 0;
        let countCommission = 0;

        ReportAPI.list(reporterFilter, start_date, end_date).then((reports) => {

                setReports(reports);

                for (let i = 0; i < reports.length; i++) {
                    countRevenue = countRevenue + reports[i].totalRevenue
                    if (reports[i].status_credit === "ACCREDITED" || reports[i].status_credit === "PAYED")
                        countCommission = countCommission + reports[i].commission
                }

                setCounter({revenue: countRevenue, commission: countCommission})

                setLoading(false);
                setError(false);
            })
            .catch(() => {
                setError(true);
            });
    };

    const signPay = (report_id, reporter, status_credit) => {
        if (Utils.getUserData().role !== "ADMIN" || status_credit === "PAYED")
            return;

        global.SweetAlert.fire({
            title: (status_credit === "TO_ACCREDIT") ? "Accredita Segnalazione" : "Bonifica Segnalazione",
            text: 'Sei veramente sicuro di voler modificare la segnalazione? Questa azione è irreversibile',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, conferma',
            cancelButtonText: 'Annulla',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ms-1'
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm: function() {
                return new Promise(function(resolve, reject) {

                    ReportAPI.pay(report_id, (status_credit === "ACCREDITED")).then(() => {
                        resolve();
                    }).catch(() => {
                        global.SweetAlert.fire({
                            icon: 'error',
                            title: 'Si è verificato un errore :(',
                            text: 'Purtroppo qualcosa è andato storto e non è stato possibile segnare la segnalazione come ' + ((status_credit === "TO_ACCREDIT") ? "ACCREDITATA" : "PAGATA") +  '. Riprova più tardi.',
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        });
                        reject()
                    })
                });
            },
        }).then(function (result) {
            if (result.value) {
                global.SweetAlert.fire({
                    icon: 'success',
                    title: 'Segnalazione pagata correttamente!',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                });
                getReports(reporterFilter);
            }
        })
    };

    const getStatus = (report) => {

        if (report.status_credit === "TO_ACCREDIT")
            return <div className="custom-badge status-waiting cursor-pointer" onClick={() => signPay(report.id, report.reporter, report.status_credit)}>Da Accreditare <i className="mdi mdi-piggy-bank"></i></div>;

        if (report.status_credit === "ACCREDITED")
            return <div className="custom-badge status-waiting cursor-pointer" onClick={() => signPay(report.id, report.reporter, report.status_credit)} >Da Bonificare <i className="mdi mdi-bank-transfer-in"></i></div>;

        if (report.status_credit === "PAYED")
            return <div className="custom-badge status-converted" >Bonificato <i className="mdi mdi-bank-check"></i></div>;

        switch (report.status_lead) {
            case null:
                return (report.consulent === null) ? <div className="custom-badge status-unassigned" >Non assegnato</div> : <div className="custom-badge status-unassigned" >In attesa</div>;
            case "WORKING":
                return <div className="custom-badge status-working" >In lavorazione</div>;
            case "TRIAL":
                return <span><div className="custom-badge status-trial" >In prova</div></span>;
            case "CONVERTED":
                return <div className="custom-badge status-working" >Venduto</div>;
            case "APPOINTMENT":
                return <div className="custom-badge status-appointment" >Da Richiamare</div>;
            case "UNREPLY":
                return <div className="custom-badge status-unreply" >Nessuna Risposta</div>;
            case "REMARKETING":
                return <div className="custom-badge status-remarketing" >Remarketing</div>;
            case "ENDED":
                return <div className="custom-badge status-closed" >Scartato</div>;
            default:
                return null;
        }

    };


    if (error) {
        return (
            <React.Fragment>
                <Alert color="danger">Si è verificato un errore durante il recupero della lista delle segnalazioni.</Alert>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            {loading ? (
                <Loading />
            ) : (
                <div>
                    <Row className="mb-1">
                        <Col xl={4} className="p-1">
                            <Select
                                value={reporters.find((r) => reporterFilter === r.value)}
                                options={reporters}
                                isClearable
                                placeholder="Filtra referente"
                                className='me-5 w-100'
                                onChange={(val) => {
                                    console.log(val)
                                    setReporterFilter(val?.value)
                                }}
                                formatOptionLabel={(user) => (
                                    <div className="referral-option">
                                        <span>&nbsp;&nbsp;{user.label}</span>
                                        <small>&nbsp;&nbsp;({Utils.getRoleLabel(user.role)})</small>
                                    </div>
                                )}
                            ></Select>
                        </Col>
                    </Row>

                    <Grid
                        data={reports}
                        columns={[
                            {
                                id: 'id',
                                name: '#',
                                formatter: (cell, row) => _(<span className="text-nowrap">#{cell}</span>),
                            },
                            {
                                id: 'external_id',
                                name: 'ID Esterno',
                                formatter: (cell, row) => _(<span className="text-nowrap">{cell}</span>)
                            },
                            {
                                id: 'lead_id',
                                hidden: true
                            },
                            {
                                id: 'client_id',
                                hidden: true
                            },
                            {
                                id: 'name',
                                name: 'Nome',
                                formatter: (cell, row) => _(
                                    <div className='text-left'>
                                        {<a href="javascript:void(0)" onClick={() => {global.onSetModal({isOpen: false, loading: false, content: "", title: ""});history.push((row.cells[3].data === null) ? "/lead/detail/" + row.cells[2].data : "/client/" + row.cells[3].data)}}><b>{cell}</b></a>}
                                    </div>
                                )
                            },
                            {
                                id: 'reporter',
                                name: 'Referente',
                                formatter: (cell, row) => _(
                                    <div className='text-left'>
                                        {(cell?.role === "CLIENT") ? <a href="javascript:void(0)" onClick={() => history.push("/client/" + cell.id)}><b>{cell.name}</b></a> : <b>{cell?.name}</b>}
                                    </div>
                                )
                            },
                            {
                                id: 'consulent',
                                name: 'Consulente',
                                formatter: (cell, row) => _(
                                    <div className='text-left'>
                                        {(cell !== null) ? <small>{cell.name}</small> : null}
                                    </div>
                                )
                            },
                            {
                                id: 'commission',
                                hidden: true
                            },
                            {
                                id: 'accredited',
                                hidden: true
                            },
                            {
                                id: 'payed',
                                hidden: true
                            },
                            {
                                id: 'sell',
                                name: 'Vendita',
                                sort: false,
                                formatter: (cell, row) => _(<div className="text-nowrap text-center">
                                    {(cell !== null) ? <span className="cursor-pointer" onClick={() => history.push("/sell/" + cell.id)}>{(!cell.classroom) ? <Badge color="primary"><i className="mdi mdi-video"/> VIDEO</Badge> : <Badge color="secondary"><i className="mdi mdi-school"/> CLASSE</Badge>}</span> : null}
                                </div>)
                            },
                            {
                                id: 'status_lead',
                                name: 'Stato',
                                sort: false,
                                formatter: (cell, row) => _(<div className="text-nowrap text-center">
                                    {getStatus(reports.find((r) => r.id === row.cells[0].data))}<br />
                                    <small>{Utils.getValueMoneyLabel(row.cells[7].data)}</small>
                                </div>)
                            }
                        ]}
                        style={{ table: { 'white-space': 'nowrap' } }}
                        resizable={true}
                        search={true}
                        sort={true}
                        pagination={{ enabled: true, limit: 25 }}
                        language={{
                            'search': {
                                'placeholder': '🔍 Cerca...'
                            },
                            'pagination': {
                                'previous': 'Indietro',
                                'next': 'Avanti',
                                'showing': 'Stai visualizzando da',
                                'results': 'Segnalazioni',
                                'to': 'a',
                                'of': 'di',
                            },
                            'noRecordsFound': 'Nessuna segnalazione trovata',
                            'error': 'Si è verificato un errore non previsto',
                        }}
                    />
                </div>
            )}
        </React.Fragment>
    );
};

export default withRouter(ReportTab);
