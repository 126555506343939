import React, { Component } from 'react';
import {
    Alert,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Input, Label, FormFeedback, Button, Accordion, AccordionItem, Collapse, Modal, ModalBody, ModalFooter
} from 'reactstrap';
import { withRouter } from "react-router-dom";

import { CourseAPI, LessonAPI, StreamingAPI } from "api";

import {Loading, Utils} from "custom";
import BreadCrumb from "../../Components/Common/BreadCrumb";


import "moment/locale/it"
import CourseStreaming from "./CourseStreaming";
import EditLesson from './EditLesson';
import DeleteLesson from './DeleteLeson';
import CreateFile from './Files/CreateFile';
import ReUploadFile from './Files/ReUploadFile';
import EditFile from './Files/EditFile';
import DeleteFile from './Files/DeleteFile';
const moment = require("moment")

const cheerio = require('cheerio');

class CourseDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            streaming: null,
            course: null,
            type: null,
            error: false,
            accordionOpen: null,
            loadingStreaming: false,
            errorStreaming: false,
            showCreate: false,
            create_name: '',
            ownCourse: false
        };
        document.title = "Dettaglio Corso | CLTV Cloud";
    }

    componentDidMount() {
        this.getDetailCourse();
    }

    getDetailCourse() {
        CourseAPI.detail(this.props.match.params.id).then((course) => {
            let ownCourse = false;
            if (Utils.getUserData().role === "ADMIN" || course.authorId === Utils.getUserData().id)
                ownCourse = true;

            this.setState({ course: course, loading: false, streaming: null, ownCourse: ownCourse });
        }).catch((err) => {
            console.error(err);
            this.setState({ error: true })
        })
    }

    getIconType(type) {
        switch (type) {
            case "VIDEO":
                return <i className="ri-play-circle-fill fs-18 text-danger align-middle" />;
            case "IMAGE":
                return <i className="ri-image-fill fs-18 text-danger align-middle" />;
            case "PDF":
                return <i className="ri-file-text-fill fs-18 text-danger align-middle" />;
            case "AUDIO":
                return <i className="ri-headphone-fill fs-18 text-danger align-middle" />;
            default:
                return null;
        }
    }

    getStreaming(id) {
        StreamingAPI.request(id).then((streaming) => {
            if (streaming.type !== "VIDEO") {
                StreamingAPI.view(streaming.hash).then((view) => {
                    if (streaming.type === "PDF") {
                        window.location.href = view;
                    }
                    // console.log("TEST", view)
                    this.setState({ type: streaming.type, errorStreaming: false, loadingStreaming: false });
                    this.setState({ streaming: view });
                }).catch((err) => {
                    console.error(err);
                    this.setState({ errorStreaming: true, loadingStreaming: false });
                })
            } else {
                this.setState({ streaming: streaming.hash, loadingStreaming: false, type: streaming.type });
            }
        }).catch((err) => {
            console.error(err);
            this.setState({ errorStreaming: true, loadingStreaming: false, });
        })
    }

    CreateLesson = () => {
        let error = {};
        if (this.state.create_name == '')
            error.create_name = 'Il campo nome è obbligatorio'
        this.setState({ errors: error });
        const payload = {
            name: this.state.create_name,
            id_course: this.props.match.params.id
        }
        if (Object.keys(error).length === 0) {
            LessonAPI.add(payload).then((res) => {
                console.log('res', res)
                this.getDetailCourse();
                this.setState({
                    showCreate: false,
                    create_name: ''
                })
            }).catch(() => {
                global.SweetAlert.fire({
                    icon: 'error',
                    title: 'Esiste già una lezione con lo stesso nome.',
                    // text: 'Purtroppo qualcosa è andato storto e non è stato possibile eliminare la vendita. Riprova più tardi.',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                });
            })
        }
        //
    }
    render() {

        require("video-react/dist/video-react.css");

        const self = this;

        if (this.state.error) {
            return <React.Fragment>
                <div className="page-content">
                    <Alert color="danger" >Si è verificato un errore durante il recupero dei dettagli del corso.</Alert>
                </div>
            </React.Fragment>
        }

        // const form = this.state.form;
        const errors = this.state.errors;
        let course = this.state.course;
        let streaming = this.state.streaming;

        let loading = <div className="page-content text-center">
            <img alt="" src={require("../../assets/icons/loading-video.gif")} width="100" />
            <p>Caricamento in corso...</p>
        </div>;

        let error = <div className="page-content text-center">
            <img alt="" src={require("../../assets/icons/warning.gif")} width="100" />
            <p>Si è verificato un errore durante il recupero del file.</p>
        </div>;

        let cannotEdit = (Utils.getUserData().role === "CLIENT");
        if (Utils.getUserData().role === "TEACHER" && course !== null) {
            cannotEdit = (Utils.getUserData().id === course?.author?.id);
        }

        return (
            <React.Fragment>

                {(this.state.loading) ? <Loading /> : <div className="page-content">
                    <Container fluid>
                        <BreadCrumb title={course.name} pageTitle="CLTV Cloud" />
                        <Button className="btn btn-sm btn-primary m-1" onClick={() => window.history.go(-1)}><i className="ri-arrow-left-circle-line fs-5 text-white-75 align-middle" /> Torna indietro</Button>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col lg="7">
                                        <div className="sticky-side-div">
                                            <Card className="ribbon-box right">
                                                {(streaming === null) ? <div className="text-center">
                                                    <img alt="" src={require("../../assets/icons/video-start.gif")}
                                                        style={{ width: "100px", height: "100px" }}></img>
                                                    <h5 className="mt-4">Seleziona il file per avviare la riproduzione</h5>
                                                </div> : (self.state.loadingStreaming) ? loading : ((!self.state.errorStreaming) ? <CourseStreaming type={self.state.type} streaming={self.state.streaming} /> : error)}
                                            </Card>
                                        </div>
                                    </Col>
                                    <Col lg="5">
                                        <div>
                                            <h3>{course.name}</h3>
                                            <div className="hstack gap-3 flex-wrap">
                                                <div className="text-muted">Autore: <strong>{course.author}</strong></div>
                                                {/*<div className="vr"></div>*/}
                                                {/*<div className="text-muted">Aggiornato il: <span className="text-body fw-medium">{moment(course.updatedAt).format("DD MMMM YYYY")}</span></div>*/}
                                            </div>
                                            <br />
                                            <br />

                                            <Accordion open={self.state.accordionOpen}>
                                                {course.lessons.map((lesson, i) => {
                                                    return <AccordionItem key={i}>
                                                        <h2 className="accordion-header">
                                                            <button
                                                                type="button"
                                                                className='accordion-button bg-white'
                                                                onClick={() => self.setState({ accordionOpen: (self.state.accordionOpen === i) ? null : i })}
                                                            >
                                                            <span
                                                                className={"text-danger "}
                                                                style={{ cursor: "pointer", flex: 1, maxWidth: '85%' }}
                                                            >{lesson.name}</span>
                                                                {(!cannotEdit) ? <span
                                                                    style={{ position: 'absolute', right: '50px', zIndex: 10 }}
                                                                >
                                                                    {(this.state.ownCourse) ? <React.Fragment>
                                                                        <EditLesson id_course={this.props.match.params.id} id={lesson.id} name={lesson.name} onResolve={this.getDetailCourse.bind(this)} />
                                                                        <DeleteLesson id_course={this.props.match.params.id} id={lesson.id} onResolve={this.getDetailCourse.bind(this)} />
                                                                    </React.Fragment> : null}
                                                            </span> : null}
                                                            </button>
                                                        </h2>

                                                        <Collapse isOpen={(self.state.accordionOpen === i)}
                                                                  className="accordion-collapse">
                                                            <div className="table-responsive">
                                                                <table className="table align-middle table-nowrap mb-0">
                                                                    <tbody>
                                                                    {lesson.files.map((file, d) => {
                                                                        return <tr key={d}>
                                                                            <th scope="row">
                                                                                <div className='d-flex align-items-center justify-content-between'>
                                                                                    <div className={"me-2 d-flex align-items-center justify-content-between flex-1" + ((file.id !== -1) ? " cursor-pointer" : "")}>
                                                                                        <span style={{opacity: (file.id !== -1) ? 1 : 0.5}} className='flex-1' onClick={() => (file.id !== -1) ? self.setState({ loadingStreaming: true, streaming: file.id }, () => self.getStreaming(file.id)) : {}}>
                                                                                            {self.getIconType(file.type)}
                                                                                            {/*<img src={Img1} alt="" className="avatar-xs rounded object-cover" />*/}
                                                                                            <span className="mb-0 ms-2">{/*{file.id} */}{file.name}</span>
                                                                                        </span>
                                                                                        {(this.state.ownCourse) ? <React.Fragment>
                                                                                            <EditFile file={file} onResolve={this.getDetailCourse.bind(this)}/>
                                                                                            <DeleteFile id={file.id} onResolve={this.getDetailCourse.bind(this)}/>
                                                                                        </React.Fragment> : null}
                                                                                    </div>
                                                                                    {(this.state.ownCourse) ? <React.Fragment>
                                                                                        {!file.type && <ReUploadFile id={file.id} onResolve={this.getDetailCourse.bind(this)}/>}
                                                                                    </React.Fragment> : null}
                                                                                </div>
                                                                            </th>
                                                                        </tr>
                                                                    })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </Collapse>
                                                        {(this.state.ownCourse) ? <React.Fragment>
                                                            {(["ADMIN", "TEACHER"].includes(Utils.getUserData().role) && !cannotEdit && self.state.accordionOpen === i) ? <CreateFile id={lesson.id} onResolve={this.getDetailCourse.bind(this)}/> : null}
                                                            </React.Fragment> : null}
                                                        </AccordionItem>
                                                })}
                                            </Accordion>
                                            {(this.state.ownCourse) ? <React.Fragment>
                                                {(["ADMIN", "TEACHER"].includes(Utils.getUserData().role)) ? <div className='mt-3 text-center'>
                                                    <Button
                                                        color='success'
                                                        size="sm"
                                                        onClick={() => this.setState({ showCreate: true })}
                                                        disabled={cannotEdit}
                                                    >
                                                        <i className='ri-add-line align-bottom me-1'></i>
                                                        Crea lezione
                                                    </Button>
                                                </div> : null}
                                            </React.Fragment> : null}
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Container>
                </div>}
                <Modal style={{ overflowY: "auto" }} isOpen={this.state.showCreate} scrollable={true} className={'modal-dialog-scrollable modal-lg'}>
                    <ModalBody className='px-sm-5 pt-5 pb-50'>
                        <div className='text-center mb-5'>
                            <h3 className='mb-1'>CREA LEZIONE</h3>
                        </div>
                        <Row>
                            <Col md="12">
                                <div className='mb-2'>
                                    <Label className='form-label'>
                                        Nome
                                    </Label>
                                    <Input
                                        type="text"
                                        value={this.state.create_name}
                                        invalid={Boolean(errors?.create_name)}
                                        onChange={(e) => this.setState({ create_name: e.target.value })}
                                    />
                                    <FormFeedback>{errors?.create_name}</FormFeedback>
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color='success'
                            onClick={this.CreateLesson}
                        >
                            SALVA
                        </Button>
                        <Button
                            color='danger'
                            onClick={() => this.setState({ showCreate: false, create_name: '' })}
                        >
                            CHIUDI
                        </Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withRouter(CourseDetail);
