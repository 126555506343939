import React, { Component } from 'react';
import { Alert, Badge, Button, Col, FormFeedback, Input, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import { withRouter, Link } from "react-router-dom";
import { Grid, _ } from 'gridjs-react';

import { CreateTeacher, Loading, DeleteTeacher, EditTeacher } from "custom";

import { TeacherAPI, UserAPI } from "api";
import moment from "moment/moment";


class TeachersTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            teachers: [],
            loading: true,
            error: false,
            roles: ["TEACHER", "TUTOR", "STAGE", "TRIAL"],

            editModal: false,

            role: [],
        };
    }

    componentDidMount() {
        this.getTeachers()
    }

    getTeachers = () => {
        TeacherAPI.list().then((teachers) => {
            this.setState({ loading: false, error: false, teachers: teachers });
        }).catch(() => {
            this.setState({ error: true });
        })
    }


    resendEmailSignup(id) {
        const self = this;
        global.SweetAlert.fire({
            title: 'Vuoi reinviare la mail di registrazione?',
            text: "Stai per reinviare una mail all'utente con il link per la registrazione, vuoi procedere?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, conferma',
            cancelButtonText: 'Annulla',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ms-1'
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm: function() {
                return new Promise(function(resolve, reject) {
                    UserAPI.resendSignup(id).then(() => {
                        resolve();
                    }).catch(() => {
                        global.SweetAlert.fire({
                            icon: 'error',
                            title: 'Si è verificato un errore :(',
                            text: 'Purtroppo qualcosa è andato storto e non è stato possibile inviare nuovamente il codice di registrazione. Riprova più tardi.',
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        });
                        reject()
                    })
                });
            },
        }).then(function (result) {
            if (result.value) {
                global.SweetAlert.fire({
                    icon: 'success',
                    title: 'Email registrazione inviata!',
                    text: 'È stata inviata la mail di registrazione all\'utente.',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                });
                self.getTeachers();
            }
        })
    }
    render() {
        const columns = [
            {
                id: 'id',
                name: '#',
                formatter: (cell, row) => _(
                    <span className="text-nowrap">{cell}</span>
                ),
                width: "50px"
            },
            {
                id: 'firstname',
                name: 'Nome',
                hidden: true
            },
            {
                id: 'lastname',
                name: 'Nominativo',
                formatter: (cell, row) => _(<span className="text-nowrap">{row.cells[1].data} {row.cells[2].data}</span>)
            },
            {
                id: 'email',
                name: 'Email',
                formatter: (cell, row) => _(<span className="text-nowrap"><a href={"mailto:" + cell}>{cell}</a></span>)
            },
            {
                id: 'cell',
                name: 'Telefono',
                sort: false,
                formatter: (cell, row) => _(<span className="text-nowrap"><a href={"tel:" + cell}>{cell}</a></span>)
            },
            {
                id: 'isRegistered',
                hidden: true
            },
            {
                id: 'active',
                name: 'Stato',
                formatter: (cell, row) => _(<span className="text-nowrap">
                    {(cell) ?
                    <Badge color="success" >Attivo</Badge>
                    :
                    ((row.cells[5].data) ? <Badge color="danger" >Non Attivo</Badge> :
                    <Badge color="warning" className="cursor-pointer" onClick={() => this.resendEmailSignup(row.cells[0].data)}>In attesa registrazione</Badge>
                    )}
                </span>)
            },
            {
                id: 'roles',
                hidden: true
            },
            {
                id: 'lastActivity',
                name: "Ultima Attività",
                formatter: (cell, row) => _(<div className="text-nowrap">
                    {(cell) ? <><small>{moment(cell).format("DD/MM/YYYY HH:mm")}</small><br />
                        <small>{moment(cell).fromNow()}</small></> : <small>N/D</small>}
                </div>)
            },
            {
                id: 'id',
                name: '',
                sort: false,
                formatter: (cell, row, i) => _(
                    <ul className="list-inline hstack gap-2 mb-0">
                        <EditTeacher data={{ id: row.cells[0]?.data, isRegistered:row.cells[5]?.data, active:row.cells[6]?.data, firstname:row.cells[1]?.data, lastname:row.cells[2]?.data, cell:row.cells[4]?.data, email:row.cells[3]?.data, roles:row.cells[7]?.data }} onResolve={this.getTeachers} label="MODIFICA DOCENTE"/>
                        <DeleteTeacher id={row.cells[0]?.data} onResolve={this.getTeachers} />
                    </ul>)
            }
        ]
        let errors = this.state.errors;
        if (this.state.error) {
            return <React.Fragment>
                <Alert color="danger" >Si è verificato un errore durante il recupero della lista docenti.</Alert>
            </React.Fragment>
        }

        return (
            <React.Fragment>
                <div className="flex-shrink-0" style={{ marginBottom: 10 }}>
                    <CreateTeacher onResolve={this.getTeachers} label='CREA DOCENTE' />
                </div>
                {(this.state.loading) ? <Loading /> :
                    <Grid
                        data={this.state.teachers}
                        columns={columns}
                        style={{ table: { 'white-space': 'nowrap' } }}
                        resizable={true}
                        search={true}
                        sort={true}
                        pagination={{ enabled: true, limit: 10 }}
                        language={{
                            'search': {
                                'placeholder': '🔍 Cerca...'
                            },
                            'pagination': {
                                'previous': 'Indietro',
                                'next': 'Avanti',
                                'showing': 'Stai visualizzando da',
                                'results': 'docenti',
                                'to': 'a',
                                'of': 'di',
                            },
                            'noRecordsFound': 'Nessun docente trovato',
                            'error': 'Si è verificato un errore non previsto',
                        }}
                    />}
            </React.Fragment>
        );
    }
}

export default withRouter(TeachersTable);
