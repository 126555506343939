import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import ClassRoomTable from './ClassRoomTable';
import { withRouter } from 'react-router-dom';


class ClassRoomList extends Component {
    constructor(props){
        super(props)
    }
    render() {

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <BreadCrumb title="Elenco Classi" pageTitle="CLTV" />
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <ClassRoomTable />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(ClassRoomList)
