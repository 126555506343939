import React, { Component } from 'react'
import { Grid, _ } from 'gridjs-react';

import {ClassroomSetting, DeleteClassroom, Loading, PaymentSetting, Utils} from "custom";
import {Alert, Badge, Spinner} from 'reactstrap';
import {Link, withRouter} from 'react-router-dom';
import {ClassRoomAPI} from 'api';

const moment = require("moment")

class ClassRoomTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            classrooms: [],
            classroomsFiltered: null,
            loading: true,
            error: false,
            errors: null,
        };
    }
    componentDidMount() {
        this.getClassRoomList()
    }
    getClassRoomList = () => {
        ClassRoomAPI.list().then((classrooms) => {
            this.setState({ classrooms: classrooms, loading: false})
        }).catch((err) => {
            this.setState({ loading: false, error: true });
        })
    }

    buildBadge(status) {
        switch (status) {
            case "DRAFT":
                return <Badge color="info">Bozza</Badge>;
            case "ACTIVE":
                return <Badge color="primary">Attiva</Badge>;
            case "COMPLETED":
                return <Badge color="success">Completata</Badge>;
            case "WAITING":
                return <Badge color="warning">In Programmazione</Badge>;
            default:
                return null;
        }
    }

    createClassroom(hystory) {
        this.setState({loadingCreate: true})

        ClassRoomAPI.add().then((classroom) => {
            this.setState({loadingCreate: false})
            global.SweetAlert.fire({
                title: 'Classe creata',
                text: 'La classe è stata creata correttamente.',
                icon: 'success',
                allowOutsideClick: false,
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-danger'
                },
                confirmButtonText: 'Vai alla programmazione',
                cancelButtonText: 'Chiudi',
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    hystory.push("/classroom/edit/" + classroom.classroom_id)
                }
            })
        }).catch((err) => {
            this.setState({loadingCreate: false})
            global.SweetAlert.fire({
                title: 'Errore',
                text: 'Si è verificato un errore durante la creazione della classe',
                icon: 'error',
                customClass: {
                    confirmButton: 'btn btn-primary'
                }
            });
        })
    }

    printPartecipants(groups) {
        return <div className='gridjs gridjs-container'>
            <div className='gridjs-wrapper'>
                <table role="grid" className="gridjs-table" >
                    <thead className="gridjs-thead">
                        {groups.map((group, i) => {
                            let partecipantsGroup = group.partecipants + group.leads;
                            let color = (group.limit - partecipantsGroup > 3) ? "text-success" : "text-warning";
                            color = (group.limit - partecipantsGroup === 0) ? "text-danger" : color;
                            return <tr className="gridjs-tr" key={i}>
                                <td className="gridjs-td text-primary" style={(groups.length > 1) ? {paddingBottom: "0.10rem", paddingTop: "0.10rem"} : undefined}>
                                    <b className={(group.limit - partecipantsGroup === 0) ? "text-danger" : undefined}>{group.slot}</b>
                                </td>
                                <td className="gridjs-td text-primary" style={(groups.length > 1) ? {paddingBottom: "0.10rem", paddingTop: "0.10rem"} : undefined}>
                                    <strong><span className={color}>{partecipantsGroup}</span> / {group.limit}</strong>
                                </td>
                            </tr>
                        })}
                    </thead>
                </table>
            </div>
        </div>

    }

    printPayments(payments) {
        return <div className='gridjs gridjs-container'>
            <div className='gridjs-wrapper'>
                <table role="grid" className="gridjs-table" >
                    <thead className="gridjs-thead">
                        <tr className="gridjs-tr">
                            <td className="gridjs-td text-success" style={{paddingBottom: "0.10rem", paddingTop: "0.10rem"}}>
                                <b>SALDATO</b>
                            </td>
                            <td className="gridjs-td text-info" style={{paddingBottom: "0.10rem", paddingTop: "0.10rem"}}>
                                <strong className={(payments.payed > 0) ? "text-success" : "text-dark"}>{payments.payed}</strong>
                            </td>
                        </tr>
                        <tr className="gridjs-tr">
                            <td className="gridjs-td text-info" style={{paddingBottom: "0.10rem", paddingTop: "0.10rem"}}>
                                <b>ACCONTO</b>
                            </td>
                            <td className="gridjs-td text-info" style={{paddingBottom: "0.10rem", paddingTop: "0.10rem"}}>
                                <strong className={(payments.deposit > 0) ? "text-info" : "text-dark"}>{payments.deposit}</strong>
                            </td>
                        </tr>
                        <tr className="gridjs-tr">
                            <td className="gridjs-td text-danger" style={{paddingBottom: "0.10rem", paddingTop: "0.10rem"}}>
                                <b>NON PAGATO</b>
                            </td>
                            <td className="gridjs-td text-info" style={{paddingBottom: "0.10rem", paddingTop: "0.10rem"}}>
                                <strong className={(payments.notPayed > 0) ? "text-danger" : "text-dark"}>{payments.notPayed}</strong>
                            </td>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>

    }

    onFilterClassroom(filterTeacher, filterLevel, filterStatus) {
        let classrooms = this.state.classrooms;

        if (filterTeacher !== null) {
            classrooms = classrooms.filter((s) => s.teacherId === filterTeacher.value);
        }

        if (filterLevel !== null) {
            classrooms = classrooms.filter((s) => (s.level?.id === filterLevel.value));
        }

        if (filterStatus !== null) {
            classrooms = classrooms.filter((s) => (s.status === filterStatus.value));
        }

        console.log(filterTeacher, filterLevel, filterStatus)

        if (filterTeacher === null && filterLevel === null && filterStatus === null) {
            this.setState({classroomsFiltered: null})
        } else {
            this.setState({classroomsFiltered: classrooms})
        }
    }

    render() {

        if (this.state.error) {
            return <React.Fragment>
                <Alert color="danger" >Si è verificato un errore durante il recupero della lista delle classi.</Alert>
            </React.Fragment>
        }

        const self = this;
        let isAdmin = Utils.getUserData().role === "ADMIN";

        return (
            <React.Fragment>
                {(isAdmin) ? <div className="flex-shrink-0" style={{margin: 10}}>
                    <button
                        type="button"
                        className="btn btn-success btn-sm"
                        onClick={() => this.createClassroom(self.props.history)}>
                        <span>{(this.state.loadingCreate) ? <Spinner size="sm"/> : <span><i className="ri-add-line align-bottom me-1"></i> CREA CLASSE</span>}</span>
                    </button>
                    <Link to={"/classroom/available"}>
                        <button
                            type="button"
                            className="btn btn-info btn-sm m-1">
                            <span>
                                <i className="ri-calendar-2-fill align-bottom me-1"></i> CALENDARIO DISPONIBILITÀ
                            </span>
                        </button>
                    </Link>
                </div> : null}
                {(!this.state.loading) ? <ClassroomSetting onFilterClassroom={this.onFilterClassroom.bind(this)} onReload={this.getClassRoomList.bind(this)}/> : null}
                {(this.state.loading) ? <Loading /> : <Grid
                    data={(this.state.classroomsFiltered !== null) ? this.state.classroomsFiltered : this.state.classrooms}
                    columns={[
                        {
                            id: 'id',
                            name: '#',
                            sort: false,
                            formatter: (cell, row) => _(
                                <span className="text-nowrap">{cell}</span>
                            ),
                            width: "50px"
                        },
                        {
                            id: 'status',
                            name: 'Stato',
                            sort: false,
                            formatter: (cell, row) => _(<div className="text-nowrap text-center">
                                {this.buildBadge(cell)}<br />
                            </div>)
                        },
                        {
                            id: 'name',
                            name: 'Nome',
                            sort: false,
                            formatter: (cell, row) => _(<span className="text-nowrap">
                                {(row.cells[1].data !== "DRAFT") ? <a href="javascript:void(0)" onClick={() => self.props.history.push("/classroom/" + row.cells[0].data)}><b>{cell}</b></a> : cell}
                            </span>)
                        },
                        {
                            id: 'groups',
                            name: 'Partecipanti',
                            formatter: (cell, row) => _(
                                <span className="text-nowrap">{(cell.length > 0 && row.cells[1].data !== "DRAFT") ? this.printPartecipants(cell) : "N/D"}</span>
                            ),
                        },
                        {
                            id: 'payments',
                            name: 'Pagamenti',
                            formatter: (cell, row) => _(
                                <span className="text-nowrap">{(row.cells[1].data !== "DRAFT" && Utils.getUserData().role !== "TEACHER") ? this.printPayments(cell) : "N/D"}</span>
                            ),
                        },
                        {
                            id: 'teacher',
                            name: 'Docente',
                            sort: false,
                            formatter: (cell, row) => _(
                                <span className="text-nowrap">{(cell !== null) ? cell : "N/D"}</span>
                            ),
                        },
                        {
                            id: 'date_start',
                            name: 'Data di Partenza',
                            formatter: (cell, row) => _(
                                <span className="text-nowrap">{(cell !== null) ? moment(cell).format("DD MMMM YYYY") : ""}</span>
                            ),
                        },
                        {
                            id: 'date_end',
                            name: 'Data di Chiusura',
                            formatter: (cell, row) => _(
                                <span className="text-nowrap">{(cell !== null) ? moment(cell).format("DD MMMM YYYY") : ""}</span>
                            ),
                        },
                        (isAdmin) ? {
                            id: 'id',
                            name: '',
                            sort: false,
                            formatter: (cell, row) => _(
                                <span className="text-nowrap">
                                    {(["DRAFT", "WAITING"].includes(row.cells[1].data)) ? <li className="list-inline-item cursor-pointer" onClick={() => self.props.history.push("/classroom/edit/" + row.cells[0].data)}>
                                        <i className="ri-calendar-2-fill fs-20 text-primary"></i>
                                    </li> : null}
                                    {(row.cells[1].data === "DRAFT") ? <DeleteClassroom classroom={{id: row.cells[0].data}} onResolve={this.getClassRoomList.bind(this)} /> : null}
                                </span>)
                        } : undefined
                    ]}
                    style={{ table: { 'white-space': 'nowrap' } }}
                    resizable={true}
                    search={true}
                    sort={true}
                    pagination={{ enabled: true, limit: 50 }}
                    language={{
                        'search': {
                            'placeholder': '🔍 Cerca...'
                        },
                        'pagination': {
                            'previous': 'Indietro',
                            'next': 'Avanti',
                            'showing': 'Stai visualizzando da',
                            'results': 'Classi',
                            'to': 'a',
                            'of': 'di',
                        },
                        'noRecordsFound': 'Nessuna classe trovata',
                        'error': 'Si è verificato un errore non previsto',
                    }}
                />}
            </React.Fragment>
        )
    }
}
export default withRouter(ClassRoomTable)
