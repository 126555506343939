let list = function(reporter_id, startDate, endDate) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');
        let filterDate = (reporter_id !== null) ? ("?reporter_id=" + reporter_id) : "";

        if (startDate && endDate) {
            let sep = (filterDate === "") ? "?" : "&"
            filterDate = filterDate + sep + "start_date=" + startDate + "&end_date=" + endDate
        }


        fetch(global.route.report + filterDate, {
            headers: {'x-access-token': hash},
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : global.printError(response))
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('report list', responseJson);
                if (responseJson.status === "LIST_REPORTS")
                    resolve(responseJson.list);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let pay = function(id, payed) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.report, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                id: id,
                payed: payed
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('report pay', responseJson);
                if (responseJson.status === "REPORT_PAYED_OK")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

const ReportAPI = {
    list: list,
    pay: pay,
};

module.exports = ReportAPI;
