import React, {useEffect, useState} from 'react';
import {
    Input,
} from 'reactstrap';

import {ClassRoomAPI} from "api";

const ListConsulentClassroom = ({id_level, id_group, onCheckGroup}) => {

    const [classroomOpen, setClassroomOpen] = useState(null);

    const [classrooms, setClassrooms] = useState([]);

    useEffect(() => {

        ClassRoomAPI.listConsulent().then((res) => {

            let resFiltered = res.filter((r) => r.level.id === id_level);

            if (id_group !== null) {
                resFiltered = resFiltered.filter((r) => r.id === id_group.classroom_id)
                setClassroomOpen(() => 0)
            }

            setClassrooms(() => resFiltered)
        }).catch((e) => {
            console.error(e)
        });



    }, [id_level]);

    return (
        <React.Fragment>
            <div className='gridjs gridjs-container'>
                <div className='gridjs-wrapper'>
                    <table role="grid" className="gridjs-table" >
                        <thead className="gridjs-thead">
                            {(classrooms.length === 0) ?  <tr className="gridjs-tr">
                                <th className="gridjs-th">
                                    Non ci sono Classi in programmazione per il livello selezionato
                                </th>
                            </tr> : null}
                            {classrooms.map((classroom, i) => {
                                let limit = 0;
                                let partecipants = 0;
                                for (let c = 0; c < classroom.groups.length; c++) {
                                    limit = limit + classroom.groups[c].limit;
                                    partecipants = partecipants + classroom.groups[c].partecipants + classroom.groups[c].leads;
                                }
                                let color = (limit - partecipants > 3) ? "text-success" : "text-warning";
                                color = (limit - partecipants === 0) ? "text-danger" : color;
                                return <>
                                    <tr className="gridjs-tr cursor-pointer" key={i} onClick={() => setClassroomOpen(i)}>
                                        <th className="gridjs-th text-primary">

                                        </th>
                                        <th className="gridjs-th text-primary">
                                            {classroom.name} - {classroom.teacher.firstname} {classroom.teacher.lastname} {(partecipants === limit) ? <span className="text-danger">(sold out)</span> : null}
                                        </th>
                                        <th className="gridjs-th"><strong><span className={color}>{partecipants}</span> / {limit}</strong></th>
                                    </tr>
                                    {(classroomOpen === i) ? classroom.groups.map((group, d) => {
                                        let partecipantsGroup = group.partecipants + group.leads;
                                        let color = (group.limit - partecipantsGroup > 3) ? "text-success" : "text-warning";
                                        color = (group.limit - partecipantsGroup === 0) ? "text-danger" : color;
                                        return <tr className="gridjs-tr" key={i + "_" + d}>
                                            <td className="gridjs-td text-primary text-center">
                                                <Input
                                                    hidden={(group.limit === partecipantsGroup)}
                                                    className={"form-check-input cursor-pointer border-dark"}
                                                    type="checkbox"
                                                    checked={(group.id === id_group?.id && classroom.id === id_group?.classroom_id)}
                                                    onChange={(e) => onCheckGroup("id_group", {id: group.id, classroom_id: classroom.id})}
                                                />
                                            </td>
                                            <td className="gridjs-td">
                                                {(group.id === null) ? <b><i className="mdi mdi-clock fs-16 align-middle"></i> {group.slot}</b> : <b>FISSO ORE {group.slot}</b>} {(partecipantsGroup === group.limit) ? <span className="text-danger">(sold out)</span> : null}
                                            </td>
                                            <td className="gridjs-td"><strong><span className={color}>{partecipantsGroup}</span> / {group.limit}</strong></td>
                                        </tr>
                                    }) : null}
                                </>
                            })}
                        </thead>
                    </table>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ListConsulentClassroom;
