import {ConsulentAPI, CourseAPI, LevelAPI, SellAPI} from 'api';
import React, {Component} from 'react'
import {Badge, Card, CardBody, CardHeader, Col, FormFeedback, Input, Label, Row} from 'reactstrap';
import Select from "react-select";
import {Link, withRouter} from 'react-router-dom';
import {ListPayment, ListConsulentClassroom, Utils} from "custom";

const moment = require("moment")

class Lesson extends Component {
    constructor(props) {
        super(props);

        this.state = {
            form: {
                id: null,
                id_level: null,
                total: 0,
                date_sell: null,
                id_consulent: null,
                note: "",
                individual: false,
                reconfirm: false,
                id_group: null
            },
            levelList: [],
            consulentList: [],
            selectedLevel: null,
            loading: false,
            errors: false
        };
    }

    componentDidMount() {
        if (this.props.sell !== null) {
            const sell = this.props.sell;
            this.setState({form : {id: sell.id, id_level: sell.level_id, reconfirm: sell.reconfirm, total: sell.total, note: sell.note, individual: sell.individual, commission: sell.commission, id_group: null, date_sell: moment(sell.createdAt).format("YYYY-MM-DD")}})
        } else {
            let form = this.state.form;
            form.reconfirm = this.props.client.reconfirmEnabled;
            this.setState({form : form})
        }
        this.getLevelList(this.props.sell !== null)
    }

    getLevelList = (edit) => {
        LevelAPI.list().then(async (res) => {
            const list = res && res.length > 0 && res.map((ele) => {
                return {
                    ...ele,
                    name: ele.language + " - Livello " + ele.name
                }
            })

            let selectedLevel = (edit) ? list[list.findIndex((level) => (level.id === this.props.sell.level_id))] : null;

            let form = this.state.form;

            if (!edit) {
                let indLeadLevelGroup = (this.props.client.group_lead !== null) ? list.findIndex((level) => (level.id === this.props.client.group_lead.level_id)) : undefined;
                if (typeof indLeadLevelGroup !== "undefined") {
                    selectedLevel = list[indLeadLevelGroup];
                    form.id_group = this.props.client.group_lead;
                }
            }

            let consulents = [];

            if (Utils.getUserData().role === "ADMIN" && !edit) {
                consulents = await ConsulentAPI.list();
                consulents = consulents.map((cons) => {return {id: cons.id, name: cons.firstname + " " + cons.lastname}})
            }

            this.setState({levelList: list, selectedLevel: selectedLevel, consulentList: consulents, form: form})
        })
    }

    changeForm(key, text) {
        const form = this.state.form;

        if (key === "id_group") {
            if (text === true)
                text = null;
        }

        form[key] = text;

        this.setState({form: form});
    }

    resetForm() {
        this.setState({
            form: {
                id: null,
                id_level: null,
                total: 0,
                date_sell: null,
                id_consulent: null,
                note: "",
                individual: false,
                reconfirm: false,
                id_group: null
            },
            errors: false,
        });
    }

    saveSell(type) {
        const self = this;
        let form = this.state.form;

        let error = {};

        if (form.total === '')
            error.total = "Il campo valore è obbligatorio";

        if (this.state.selectedLevel === null)
            error.id_level = "Seleziona il livello acquistato";


        this.setState({errors: error});

        if (Object.keys(error).length === 0) {

            let add = (form.id === null);

            if (add) {
                const query = new URLSearchParams(window.location.search)
                form['id_lead'] = Number(query.get('lead'));
                form["id_client"] = query.get('client');
                form["type"] = type;
                if (form.individual)
                    form.id_group = null;
                if (Utils.getUserData().role === "ADMIN" && form.id_consulent !== null) {
                    form.id_consulent = form.id_consulent.id;
                }
            }

            let api = (add) ? SellAPI.addCourse : SellAPI.edit

            this.setState({loading: true});

            form["id_level"] = this.state.selectedLevel.id

            api(form).then((response) => {
                global.onSetModal({loading: false});
                global.SweetAlert.fire({
                    title: 'Vendita ' + ((add) ? "creata" : "modificata"),
                    text: 'La vendita è stata ' + ((add) ? "creata" : "modificata") + ' correttamente.',
                    icon: 'success',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    confirmButtonText: 'Chiudi',
                    buttonsStyling: false
                }).then(function (result) {
                    if (result.value) {
                        if (add) {
                            window.location.replace("/sell/" + response)
                        } else {
                            self.props.onResolve()
                        }
                    }
                })
            }).catch((err) => {
                this.setState({loading: false});
                let message = 'Si è verificato un errore durante la ' + ((add) ? "creazione" : "modifica") + ' della vendita';
                if (!add && err.status === "INVALID_TOTAL") {
                    message = 'I pagamenti già effettuati non possono superare il totale della vendita'
                }
                if (!add && err.status === "INVALID_COMMISSION") {
                    message = 'La provvigione non può superare il totale della vendita'
                }
                global.SweetAlert.fire({
                    title: 'Errore',
                    text: message,
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                });
            })
        }
    }

    reloadSell() {
        this.props.onResolve()
    }


    printStatus(status) {
        switch(status) {
            case "DRAFT":
                return <Badge color="info" style={{fontSize: "12px"}}>BOZZA</Badge>;
            case "WAITING":
                return <Badge color="warning" style={{fontSize: "12px"}}>IN PROGRAMMAZIONE</Badge>;
            case "ACTIVE":
                return <Badge color="primary" style={{fontSize: "12px"}}>ATTIVA</Badge>;
            case "CLOSED":
                return <Badge color="success" style={{fontSize: "12px"}}>CONCLUSA</Badge>;
            default:
                return null;
        }
    }

    render() {
        const self = this;
        let form = this.state.form;
        let errors = this.state.errors;

        return (
            <div className='p-4'>
                <Card>
                    <CardBody>
                        <Row>
                            {(this.props.sell || Utils.getUserData().role === "CONSULENT") ? null : <Col md="12">
                                <div className='mb-2'>
                                    <Label className='form-label'>
                                        Data
                                    </Label>
                                    <Input type="date" value={(form.date_sell === null) ? moment().format("YYYY-MM-DD") : form.date_sell} onChange={(e) => this.changeForm('date_sell', e.target.value)}/>
                                </div>
                            </Col>}
                            {(Utils.getUserData().role === "ADMIN") ? <Col md="12">
                                <div className='mb-4'>
                                    <Label className='form-label'>
                                        Consulente
                                    </Label>
                                    {(this.props.sell === null) ? <Select
                                        value={this.state.form.id_consulent}
                                        placeholder={"Seleziona il Consulente..."}
                                        onChange={(selectedOption) => {
                                            let form = this.state.form;
                                            form.id_consulent = selectedOption;
                                            this.setState({form: form})
                                        }}
                                        getOptionLabel={(opt) => opt.name}
                                        getOptionValue={(opt) => opt.id}
                                        options={this.state.consulentList}
                                    /> : <h5>{(this.props.sell.consulent !== null) ? this.props.sell.consulent.firstname + " " + this.props.sell.consulent.lastname : "N/D"}</h5>}
                                </div>
                            </Col> : null}
                            <Col md="12">
                                <div className='mb-4'>
                                    <Label className='form-label'>
                                        Cliente
                                    </Label>
                                    {(this.props.sell === null) ?
                                        <h4>{(this.props.client?.firstname + ' ' + this.props.client?.lastname)}</h4> :
                                        <h4 className="text-primary"><Link
                                            to={"/client/" + this.props.sell.client.id}>{(this.props.sell.client?.firstname + ' ' + this.props.sell.client?.lastname)}</Link>
                                        </h4>}
                                </div>
                            </Col>
                            <Col md="12">
                                <div className='mb-2'>
                                    <Label className='form-label'>
                                        Livello acquistato
                                    </Label>
                                    <Select
                                        isDisabled={(form.id !== null)}
                                        value={this.state.selectedLevel}
                                        placeholder={"Seleziona il Livello..."}
                                        onChange={(selectedOption) => {
                                            this.setState({selectedLevel: selectedOption})
                                        }}
                                        getOptionLabel={(opt) => opt.name}
                                        getOptionValue={(opt) => opt.id}
                                        options={this.state.levelList}
                                    />
                                    {(errors.id_level) ? <span className="text-danger">La selezione del livello è obbligatoria</span> : null}
                                </div>
                            </Col>
                            <Col md="12">
                                <div className="form-check form-check-outline form-check-primary mb-3">
                                    <Input className="form-check-input" type="checkbox" checked={form.reconfirm}
                                           onChange={(e) => {
                                               this.changeForm('reconfirm', e.target.checked)
                                           }}/>
                                    <Label className="form-check-label">
                                        Riconferma
                                    </Label>
                                </div>
                            </Col>
                            <Col md="12">
                                <div className="form-check form-check-outline form-check-primary mb-3">
                                    <Input className="form-check-input" type="checkbox" checked={form.individual} disabled={(form.id !== null)}
                                           onChange={(e) => {
                                               this.changeForm('individual', e.target.checked)
                                           }}/>
                                    <Label className="form-check-label">
                                        Corso Individuale
                                    </Label>
                                </div>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col md="12">
                                <div className='mb-2'>
                                    <Label className='form-label'>
                                        Valore (€)
                                    </Label>
                                    <Input
                                        type="number"
                                        value={form.total}
                                        invalid={Boolean(errors.total)}
                                        onChange={(e) => this.changeForm('total', e.target.value)}
                                    />
                                    <FormFeedback>{errors.total}</FormFeedback>
                                </div>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col md="12">
                                <div className='mb-2'>
                                    <Label className='form-label'>
                                        Note vendita
                                    </Label>
                                    <Input type="textarea" rows={5} value={form.note}
                                           onChange={(e) => this.changeForm('note', e.target.value)}/>
                                </div>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <div className='mt-3 text-end'>
                                <button
                                    type="button"
                                    className="btn btn-success"
                                    onClick={() => this.saveSell('CLASSROOM')}
                                >
                                    <i className="ri-save-2-fill align-bottom me-1"></i> SALVA
                                </button>
                            </div>
                        </Row>
                    </CardBody>
                </Card>
                {(!form.individual) ? <Card>
                    <CardHeader>
                        <h5>
                            <i className="mdi mdi-school align-bottom me-1" style={{ margin: 10 }}></i> CLASSE
                        </h5>
                    </CardHeader>
                    <CardBody>
                        {(this.props.sell === null) ? <Row>
                            <div className="form-check form-check-outline form-check-primary mb-3" hidden={this.state.selectedLevel === null}>
                                <Input className="form-check-input" type="checkbox" checked={(this.state.form.id_group === null)}
                                       onChange={(e) => (e.target.checked) ? this.changeForm('id_group', e.target.checked) : {}}/>
                                <Label className="form-check-label">
                                    Inserisci in Lista di Attesa
                                </Label>
                            </div>
                            {(this.state.selectedLevel !== null) ? <ListConsulentClassroom id_level={this.state.selectedLevel.id} id_group={this.state.form.id_group} onCheckGroup={this.changeForm.bind(this)}/> : null}
                        </Row> : <Row>
                            {(this.props.sell.classroom.id) ? <div className='gridjs gridjs-container'>
                                <div className='gridjs-wrapper'>
                                    <table role="grid" className="gridjs-table" >
                                        <thead className="gridjs-thead">
                                            <tr className="gridjs-tr">
                                                <th className="gridjs-th">
                                                    Nome
                                                </th>
                                                <th className="gridjs-th">
                                                    Stato
                                                </th>
                                            </tr>
                                            <tr className="gridjs-tr">
                                                <td className="gridjs-td text-primary">
                                                    <Link to={"/classroom/" + this.props.sell.classroom.id}><b>{this.props.sell.classroom.name}</b></Link>
                                                </td>
                                                <td className="gridjs-td text-primary">
                                                    {this.printStatus(this.props.sell.classroom.status)}
                                                </td>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div> : <strong><i className="mdi mdi-clock fs-16 align-middle"></i> IN LISTA DI ATTESA</strong>}
                        </Row>}
                    </CardBody>
                </Card> : null}
                {(this.props.sell !== null) ? <Card>
                    <CardHeader>
                        <h5>
                            <i className="ri-money-euro-circle-fill align-bottom me-1" style={{ margin: 10 }}></i> PAGAMENTI
                        </h5>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <ListPayment payments={this.props.sell.payments} sell={this.props.sell} onReload={this.reloadSell.bind(this)}/>
                        </Row>
                    </CardBody>
                </Card> : null}
            </div>
        )
    }
}

export default withRouter(Lesson)
