import React, {Component} from 'react';
import {Alert, Badge, Button, Col, Container, FormFeedback, Input, Label, Row} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import {withRouter, Link} from "react-router-dom";
import {Grid, _} from 'gridjs-react';

import {
    Loading,
    Utils,
    GestModule,
    DeleteModule, ReassignModule
} from "custom";

import {ModuleAPI, LevelAPI, TeacherAPI, ConsulentAPI} from "api";
import Select from "react-select";
import logoTikTok from "../../assets/images/logo-tiktok.png";

const moment = require("moment")

class ModuleTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modules: [],
            consulents: [],
            loading: true,
            error: false
        };
    }

    componentDidMount() {
        this.getModules();
    }

    getModules() {
        ModuleAPI.list().then(async (modules) => {
            let consulents = await ConsulentAPI.list();
            this.setState({loading: false, error: false, modules: modules, consulents: consulents.map((c) => {return {value: c.id, label: c.firstname + " " + c.lastname}})});
        }).catch(() => {
            this.setState({error: true});
        })
    }

    getReferralLabel(referral) {
        switch (referral) {
            case "META":
                return <i className="ri-facebook-box-fill align-middle fs-20 text-secondary"/>;
            case "FUNNEL":
                return <i className="ri-global-fill align-middle fs-20 text-primary"/>;
            case "REPORT":
                return <i className="mdi mdi-bullhorn align-middle fs-20 text-danger"/>;
            case "WHATSAPP":
                return <i className="ri-whatsapp-fill align-middle fs-20 text-success" />
            case "TIK_TOK":
                return <img src={logoTikTok} alt={"tik tok"} width="20px"/>
            default:
                return null
        }
    }

    copyUrl(url) {
        navigator.clipboard.writeText(url)
        global.SweetAlert.fire({
            icon: 'success',
            title: 'Link Copiato',
            timer: 1500,
            showConfirmButton: false
        });
    }

    render() {
        if (this.state.error) {
            return <React.Fragment>
                <Alert color="danger">Si è verificato un errore durante il recupero della lista moduli.</Alert>
            </React.Fragment>
        }

        return (
            <React.Fragment>

                {(this.state.loading) ? <Loading/> : <div>
                    <div className="flex-shrink-0" style={{margin: 10}}>
                        <GestModule consulents={this.state.consulents} onResolve={this.getModules.bind(this)} />
                    </div>

                    <Grid
                        data={this.state.modules}
                        columns={[
                            {
                                id: 'id',
                                name: "#",
                                formatter: (cell, row) => _(<span className="text-nowrap">
                                    {cell}
                                </span>)
                            },
                            {
                                id: 'referral',
                                name: 'Referral',
                                formatter: (cell, row) => _(<div className="text-nowrap text-center">
                                    {this.getReferralLabel(cell)}
                                </div>)
                            },
                            {
                                id: 'name',
                                name: 'Nome',
                                formatter: (cell, row) => _(<span className="text-nowrap">
                                    {cell}
                                </span>)
                            },
                            {
                                id: 'leads',
                                name: 'Lead',
                                formatter: (cell, row) => _(<span className="text-nowrap">
                                    {cell}
                                </span>)
                            },
                            {
                                id: 'consulents',
                                name: 'Consulenti assegnazione automatica',
                                sort: false,
                                formatter: (cell, row) => _(<span className="text-nowrap">
                                    <small>{cell.map(c => c.firstname + " " + c.lastname).join(" | ")}</small>
                                </span>)
                            },
                            {
                                id: 'url',
                                name: 'Url Webhook',
                                sort: false,
                                formatter: (cell, row) => _(<div className="text-nowrap text-center">
                                    <button className="btn btn-sm btn-primary" onClick={() => this.copyUrl(cell)}>Copia
                                        URL
                                    </button>
                                </div>)
                            },
                            {
                                id: 'id',
                                name: '',
                                sort: false,
                                formatter: (cell, row, i) => _(<ul className="list-inline hstack gap-2 mb-0">
                                    <ReassignModule modules={this.state.modules} id_old={this.state.modules[this.state.modules.findIndex((m) => m.id === row.cells[0].data)].id} onResolve={this.getModules.bind(this)} />
                                    <GestModule consulents={this.state.consulents} preset={this.state.modules[this.state.modules.findIndex((m) => m.id === row.cells[0].data)]} onResolve={this.getModules.bind(this)} />
                                    <DeleteModule module={{id: row.cells[0].data}} onResolve={this.getModules.bind(this)} />
                                </ul>)
                            }
                        ]}
                        style={{table: {'white-space': 'nowrap'}}}
                        resizable={true}
                        search={true}
                        sort={true}
                        pagination={{enabled: true, limit: 10}}
                        language={{
                            'search': {
                                'placeholder': '🔍 Cerca...'
                            },
                            'pagination': {
                                'previous': 'Indietro',
                                'next': 'Avanti',
                                'showing': 'Stai visualizzando da',
                                'results': 'Moduli',
                                'to': 'a',
                                'of': 'di',
                            },
                            'noRecordsFound': 'Nessun modulo trovato',
                            'error': 'Si è verificato un errore non previsto',
                        }}
                    /></div>}
            </React.Fragment>
        );
    }
}

export default withRouter(ModuleTable);
