import React, { useEffect, useState } from 'react';
import {Alert, Badge, Button, Card, CardBody} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { Grid, _ } from 'gridjs-react';

import {Loading, Utils, DeleteReporter, EditReporter, ContactReporter, Params} from 'custom';
import {ReporterAPI, ReportAPI, UserAPI, NetworkAPI} from 'api';

const ReporterTab = ({ history, onClickCommission, range, onPromoteSuccess }) => {
    const [reporters, setReporters] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const [users, setUsers] = useState([])

    useEffect(() => {
        document.title = 'Network | CLTV';
        return () => {
            Params.removeHandlePaginator()
        };
    }, []);


    useEffect(() => {
        getReporters(range);
    }, [range]);

    const getReporters = async (range) => {

        if (Utils.getUserData().role === "ADMIN") {
            let users = await UserAPI.list();
            setUsers(users);
        }

        let start_date = (range) ? range.start_date : null;
        let end_date = (range) ? range.end_date : null;

        if (range.start_date && range.end_date) {
            start_date = range.start_date.format("DD-MM-YYYY");
            end_date = range.end_date.format("DD-MM-YYYY");
        }

        ReporterAPI.list(start_date, end_date).then((rep) => {
                setReporters(rep);
                setLoading(false);
                setError(false);

                setTimeout(() => {
                    Params.addHandlePaginator()
                }, 1000)
            })
            .catch(() => {
                setError(true);
            });
    };

    const printCommission = (commissions, id) => {
        return <div onClick={() => onClickCommission(id)} className="cursor-pointer">
            {(commissions.toAccredit > 0) ? <><Badge color="warning" style={{fontSize: 11}}>Da Accreditare: <b>{Utils.getValueMoneyLabel(commissions.toAccredit)}</b></Badge><br /></> : null}
            {(commissions.toPay > 0) ? <><Badge color="warning" style={{fontSize: 11}}>Da Bonificare: <b>{Utils.getValueMoneyLabel(commissions.toPay)}</b></Badge><br /></> : null}
            {(commissions.total > 0) ? <Badge color="primary" style={{fontSize: 11}}>Totale: <b>{Utils.getValueMoneyLabel(commissions.total)}</b></Badge> : null}
        </div>
    };


    const promoteNetworker = (reporter_id, name) => {

        global.SweetAlert.fire({
            title: "Promuovi a Manager",
            text: 'Sei veramente sicuro di voler promuovere ' + name + ' a Manager?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Si, conferma',
            cancelButtonText: 'Annulla',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ms-1'
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm: function() {
                return new Promise(function(resolve, reject) {
                    NetworkAPI.create(reporter_id).then(() => {
                        resolve();
                    }).catch(() => {
                        global.SweetAlert.fire({
                            icon: 'error',
                            title: 'Si è verificato un errore :(',
                            text: 'Purtroppo qualcosa è andato storto e non è stato possibile promuovere il segnalatore a manager. Riprova più tardi.',
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        });
                        reject()
                    })
                });
            },
        }).then(function (result) {
            if (result.value) {
                global.SweetAlert.fire({
                    icon: 'success',
                    title: 'Segnalatore promosso a Manager',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                });
                getReporters(range);
                if (onPromoteSuccess) {
                    onPromoteSuccess();
                }
            }
        })
    };

    if (error) {
        return (
            <React.Fragment>
                <Alert color="danger">Si è verificato un errore durante il recupero della lista dei segnalatori.</Alert>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            {loading ? (
                <Loading />
            ) : (
                <div>
                    <Grid
                        data={reporters.sort((a, b) => b.reports - a.reports)}
                        columns={[
                            {
                                id: 'id',
                                name: '#',
                                formatter: (cell, row) => _(<span className="text-nowrap">#{cell}</span>),
                            },
                            {
                                id: 'external_id',
                                name: 'ID Esterno',
                                formatter: (cell, row) => _(<span className="text-nowrap">{cell}</span>)
                            },
                            {
                                id: 'client_id',
                                hidden: true
                            },
                            {
                                id: 'name',
                                name: 'Nome',
                                formatter: (cell, row) => _(
                                    <div className='text-left'>
                                        {(row.cells[11].data) ? <span><i className="ri-star-fill text-warning"></i>&nbsp;</span> : null}{(row.cells[2].data !== null) ? <a href="javascript:void(0)" onClick={() => history.push("/client/" + row.cells[2].data)}><b>{cell}</b></a> : <b>{cell}</b>}
                                        <br />
                                        {<small>{Utils.getRoleLabel(row.cells[10].data)}</small>}
                                    </div>
                                )
                            },
                            {
                                id: 'father',
                                name: 'Referente',
                                sort: false,
                                formatter: (cell, row) => _(
                                    <div className='text-left'>
                                        {(cell?.role === "CLIENT") ? <a href="javascript:void(0)" onClick={() => history.push("/client/" + cell.id)}><b>{cell.name}</b></a> : <b>{cell?.name}</b>}
                                        <br />
                                        {<small>{Utils.getRoleLabel(cell?.role)}</small>}
                                    </div>
                                )
                            },
                            {
                                id: 'cell',
                                hidden: true
                            },
                            {
                                id: 'email',
                                hidden: true
                            },
                            {
                                id: 'bank_iban',
                                hidden: true
                            },
                            {
                                id: 'reports',
                                name: 'Segnalazioni',
                                sort: true,
                                formatter: (cell, row) => _(<div className="text-nowrap text-center">
                                    <Badge className="cursor-pointer" color="secondary" onClick={() => onClickCommission(row.cells[0].data)} style={{fontSize: 11}}><b>{cell}</b></Badge>&nbsp;
                                    {(cell >= 5 && Utils.getUserData().role === "ADMIN" && !row.cells[11].data) ? <Badge title="Promuovi a Manager" className="cursor-pointer" color="warning" onClick={() => promoteNetworker(row.cells[0].data, row.cells[3].data)} style={{fontSize: 11}}><i className="ri-star-fill text-white"></i></Badge> : null}
                                </div>)
                            },
                            {
                                id: 'commissions',
                                name: 'Commissione',
                                sort: false,
                                formatter: (cell, row) => _(<div className="text-nowrap text-center">
                                    {printCommission(cell, row.cells[0].data)}
                                </div>)
                            },
                            {
                                id: 'user_role',
                                hidden: true
                            },
                            {
                                id: 'is_networker',
                                hidden: true
                            },
                            (Utils.getUserData().role === "ADMIN") ? {
                                id: 'id',
                                name: '',
                                sort: false,
                                formatter: (cell, row, i) => _(<ul
                                    className="list-inline hstack gap-2 mb-0">
                                    <ContactReporter reporter={reporters.find((r) => r.id === row.cells[0].data)} users={users} onResolve={getReporters}/>
                                    <EditReporter reporter={reporters.find((r) => r.id === row.cells[0].data)} users={users} onResolve={getReporters}/>
                                    <DeleteReporter reporter={{id: row.cells[0].data}} onResolve={getReporters}/>
                                </ul>)
                            } : undefined
                        ]}
                        style={{ table: { 'white-space': 'nowrap' } }}
                        resizable={true}
                        search={true}
                        sort={true}
                        pagination={{ enabled: true, limit: 25, page: Params.getActualPage() }}
                        language={{
                            'search': {
                                'placeholder': '🔍 Cerca...'
                            },
                            'pagination': {
                                'previous': 'Indietro',
                                'next': 'Avanti',
                                'showing': 'Stai visualizzando da',
                                'results': 'Segnalatori',
                                'to': 'a',
                                'of': 'di',
                            },
                            'noRecordsFound': 'Nessun segnalatore trovato',
                            'error': 'Si è verificato un errore non previsto',
                        }}
                    />
                </div>
            )}
        </React.Fragment>
    );
};

export default withRouter(ReporterTab);
