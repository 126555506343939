
let list = function() {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');
        fetch(global.route.classroomTrial, {
            headers: {'x-access-token': hash},
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('trial classroom list', responseJson);
                if (responseJson.status === "LIST_CLASSROOMS_TRIAL")
                    resolve(responseJson.list);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let detail = function(id) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.classroomTrial + '/' + id, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('classroom trial detail', responseJson);
                if (responseJson.status === "DETAIL_CLASSROOM_TRIAL")
                    resolve(responseJson.classroom);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let add = function(trial) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.classroomTrial, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify({
                dateTime: trial.dateTime,
                id_teacher: trial.id_teacher
            })
        })
        .then((response) => (response.status !== 500) ? response.json() : reject())
        .then((responseJson) => {
            global.checkAuth(responseJson.status);
            console.log('classroom trial created', responseJson);
            if (responseJson.status === "CLASSROOM_TRIAL_CREATED")
                resolve(responseJson);
            else
                reject(responseJson);
        })
        .catch((e) => {
            console.error(e.message);
            reject('APP_ERROR');
        });
    });
};

let insert = function(id_lead, id_trial_classroom) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.classroomTrialInsert, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify({
                id_lead: id_lead,
                id_trial_classroom: id_trial_classroom
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('classroom trial insert', responseJson);
                if (responseJson.status === "CLASSROOM_TRIAL_INSERT")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};



let edit = function(trial) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.classroomTrial, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                id: trial.id,
                dateTime: trial.dateTime,
                id_teacher: trial.id_teacher,
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('classroom trial edit', responseJson);
                if (responseJson.status === "CLASSROOM_TRIAL_EDITED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let editLink = function(trial) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.classroomTrialLink, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                id: trial.id,
                link: trial.link
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('classroom trial edit link', responseJson);
                if (responseJson.status === "CLASSROOM_TRIAL_EDITED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let sign = function(id_classroom, id_partecipant) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.classroomTrialSign, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                id_classroom: id_classroom,
                id_partecipant: id_partecipant
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('classroom trial sign', responseJson);
                if (responseJson.status === "CLASSROOM_TRIAL_CHECKED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let startTrial = function(token) {
    return new Promise(function (resolve, reject) {

        fetch(global.route.classroomTrialStart + '?token=' + token, {
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                console.log("classroomTrialStart", responseJson);
                if (responseJson.status === "CLASSROOM_TRIAL_START") {
                    resolve(responseJson);
                } else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject()
            });
    });
};

let remove = function(id) {
    return new Promise(function(resolve, reject) {
        const hash = localStorage.getItem('hash');

        fetch(global.route.classroomTrial, {
            headers: {
                'x-access-token': hash,
                'Content-Type': 'application/json'
            },
            method: 'DELETE',
            body: JSON.stringify({
                id: id
            })
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('classrrom trial delete', responseJson);
                if (responseJson.status === "CLASSROOM_TRIAL_DELETED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

const ClassRoomTrialAPI = {
    list: list,
    detail: detail,
    add: add,
    edit: edit,
    insert: insert,
    startTrial: startTrial,
    editLink: editLink,
    sign: sign,
    remove: remove
};

module.exports = ClassRoomTrialAPI;
