import React, { Component } from 'react'
import {
    Alert, Button,
    Card,
    CardBody,
    Col,
    Container,
    FormFeedback,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from 'reactstrap'

import { ClientAPI, CourseAPI, LeadAPI, SellAPI } from 'api';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import Video from './tabs/Video';
import Lesson from './tabs/Lesson';
import {Loading} from "custom";
import {Link} from "react-router-dom";

export default class SellGest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: 1,
            client: null,
            sell: null,
            error: false,
            loading: true
        };
    }

    componentDidMount() {
        const query = new URLSearchParams(window.location.search)

        if (query.get('client') !== null) {
            this.getDetailClient();
        } else {
            this.getDetailSell();
        }
    }

    getDetailClient() {
        const query = new URLSearchParams(window.location.search)
        ClientAPI.detail(query.get('client')).then((client) => {
            this.setState({client: client, loading: false})
        }).catch((err) => {
            console.error(err);
            this.setState({error: true})
        })
    }

    getDetailSell() {
        SellAPI.detail(this.props.match.params.id).then((sell) => {
            this.setState({sell: sell, loading: false, tab: (sell.type === "CLASSROOM") ? 1 : 2})
        }).catch((err) => {
            console.error(err);
            this.setState({error: true})
        })
    }




    render() {
        const self = this

        if (this.state.error) {
            return <div className="page-content">
                <Container fluid>
                    <React.Fragment>
                        <Alert color="danger">Si è verificato un errore durante il recupero della vendita.</Alert>
                    </React.Fragment>
                </Container>
            </div>
        }

        return (
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Gestione Vendita" pageTitle="CLTV Cloud" />
                    <Card>
                        {(this.state.loading) ? <Loading /> : <CardBody className="checkout-tab">
                            <div className="step-arrow-nav mt-n3 mx-n3">
                                {(self.state.sell !== null) ? <Button className="btn btn-sm btn-info m-1" onClick={() => self.props.history.push("/client/" + self.state.sell.client.id)}><i
                                    className="ri-arrow-left-circle-line fs-5 text-white-75 align-middle" /> Torna al cliente</Button> : null}

                                <Nav className="nav-pills nav-justified custom-nav" role="tablist">
                                    {(self.state.sell === null) ? <React.Fragment>
                                        <NavItem>
                                            <NavLink href="#"
                                                     className={self.state.tab === 1 ? "active fs-15 p-3" : "fs-15 p-3"}
                                                     onClick={() => { this.setState({ tab: 1 }) }}
                                            >
                                                <i className="mdi mdi-school fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                                                CLASSE
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink href="#"
                                                     className={self.state.tab === 2 ? "active fs-15 p-3" : "fs-15 p-3"}
                                                     onClick={() => { this.setState({ tab: 2 }) }}
                                            >
                                                <i className="ri-video-fill fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                                                VIDEOCORSO
                                            </NavLink>
                                        </NavItem>
                                    </React.Fragment> : <React.Fragment>
                                        {(self.state.sell.type === "CLASSROOM") ? <NavItem>
                                            <NavLink href="#"
                                                     className={self.state.tab === 1 ? "active fs-15 p-3" : "fs-15 p-3"}
                                                     onClick={() => { this.setState({ tab: 1 }) }}
                                            >
                                                <i className="mdi mdi-school fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                                                CLASSE
                                            </NavLink>
                                        </NavItem> : <NavItem>
                                            <NavLink href="#"
                                                     className={self.state.tab === 2 ? "active fs-15 p-3" : "fs-15 p-3"}
                                                     onClick={() => { this.setState({ tab: 2 }) }}
                                            >
                                                <i className="ri-video-fill fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                                                VIDEOCORSO
                                            </NavLink>
                                        </NavItem>}
                                    </React.Fragment>}
                                </Nav>
                                <TabContent activeTab={this.state.tab}>
                                    {(self.state.sell === null) ? <React.Fragment>
                                        <TabPane tabId={1} id="pills-bill-info">
                                            <Lesson client={self.state.client} sell={self.state.sell}/>
                                        </TabPane>
                                        <TabPane tabId={2} id="pills-bill-info">
                                            <Video client={self.state.client} sell={self.state.sell}/>
                                        </TabPane>
                                    </React.Fragment> : <React.Fragment>
                                        {(self.state.sell.type === "CLASSROOM") ? <TabPane tabId={1} id="pills-bill-info">
                                            <Lesson client={self.state.client} sell={self.state.sell} onResolve={this.getDetailSell.bind(this)}/>
                                        </TabPane> : <TabPane tabId={2} id="pills-bill-info">
                                            <Video client={self.state.client} sell={self.state.sell} onResolve={this.getDetailSell.bind(this)}/>
                                        </TabPane>}
                                    </React.Fragment>}
                                </TabContent>
                            </div>
                        </CardBody>}
                    </Card>
                </Container>
            </div>
        )
    }
}
