import React, {useEffect, useState} from 'react';
import {
    Alert,
    Badge,
    Card,
    CardBody,
    Col,
    Container, FormFeedback, FormText,
    Input,
    Label,
    Row
} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';

import {LevelAPI, ClassRoomAPI, AvailableAPI} from "api";
import {useParams} from "react-router-dom";
import {Loading, RevertClassroom} from "custom";

import Select from "react-select";

const moment = require("moment");

const ClassroomEdit = () => {
    document.title = "Programmazione Classe | CLTV Cloud";

    const [loading, setLoading] = useState(true);
    const [form, setForm] = useState(true);

    const [levels, setLevels] = useState([]);
    const [teachers, setTeachers] = useState([]);
    const [availables, setAvailables] = useState([]);
    const [slots, setSlots] = useState([]);

    const [deployAllow, setDeployAllow] = useState(false);

    const [error, setError] = useState(false);

    let minDefault = moment().subtract(12, "months").format("YYYY-MM-DD");
    let maxDefault = moment().add(12, "months").format("YYYY-MM-DD");


    const [limits, setLimits] = useState(null);

    const {id} = useParams();

    useEffect(() => {
        resetLimit();
        getDetailClassroom();
    }, []);


    const getDetailClassroom = () => {
        ClassRoomAPI.detail(id).then(async (classroom) => {
            if (["DRAFT", "WAITING", "ACTIVE"].includes(classroom.status)) {

                if (["DRAFT", "WAITING"].includes(classroom.status)) {
                    let levelsList = await LevelAPI.list();
                    setLevels(() => levelsList.map((l) => {
                        return {
                            label: l.language + " " + l.name,
                            value: l.id
                        }
                    }));
                }

                let avRes = await AvailableAPI.list();

                let teachersA = [];
                let slotsA = [];

                for (let d = 0; d < avRes.length; d++) {
                    if (!slotsA.find((s) => avRes[d].slot === s)) {
                        slotsA.push(avRes[d].slot)
                    }
                    if (!teachersA.find((s) => avRes[d].teacher.id === s.id)) {
                        teachersA.push({id: avRes[d].teacher.id, name: avRes[d].teacher.firstname + " " + avRes[d].teacher.lastname})
                    }
                }

                let teachersRes = teachersA.sort((a,b) => a.name.localeCompare(b.name))

                setAvailables(() => avRes);
                setTeachers(() => teachersRes);
                setSlots(() => slotsA);
                setForm(() => classroom);
                setLoading(false);
                setError(false);

                setDeployAllow(() => (classroom.id_level !== null && classroom.date_start !== null && classroom.status === "DRAFT" && classroom.groups.length > 0))
            } else {
                setError(true);
            }
        }).catch((err) => {
            setError(true);
            console.error(err);
        })
    }

    const resetLimit = () => {
        let defaultLimits = {start: [minDefault, maxDefault], end: [minDefault, maxDefault]}
        setLimits(() => defaultLimits)
    }

    const deployClassroom = () => {
        global.SweetAlert.fire({
            title: "Pubblicare la Classe?",
            text: "Vuoi rendere disponibile per la vendita questa classe? Dopo la pubblicazione non potrai più modificare orari e livello di questa classe",
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Si, conferma',
            cancelButtonText: 'Annulla',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ms-1'
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm: function() {
                return new Promise(function(resolve, reject) {
                    setLoading(true);
                    ClassRoomAPI.deploy(id).then(() => {
                        resolve();
                    }).catch(() => {
                        setLoading(false);
                        global.SweetAlert.fire({
                            icon: 'error',
                            title: 'Si è verificato un errore :(',
                            text: 'Purtroppo qualcosa è andato storto e non è stato possibile pubblicare la classe. Riprova più tardi.',
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        });
                        reject()
                    })
                });
            },
        }).then(function (result) {
            if (result.value) {
                setDeployAllow(false);
                getDetailClassroom();
                global.SweetAlert.fire({
                    icon: 'success',
                    title: 'Classe pubblicata!',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                });
            }
        })
    }

    const printStatus = () => {
        switch(form.status) {
            case "DRAFT":
                return <Badge color="info" style={{fontSize: "14px"}}>BOZZA</Badge>;
            case "WAITING":
                return <Badge color="warning" style={{fontSize: "14px"}}>IN PROGRAMMAZIONE</Badge>;
            case "ACTIVE":
                return <Badge color="primary" style={{fontSize: "14px"}}>ATTIVA</Badge>;
            default:
                return null;
        }
    }

    const changeForm = (key, value) => {
        if (key === "shifter_limit") {
            if (isNaN(Number(value)) && value !== "")
                return;

            value = Number(value)
        }

        if (key === "date_start" || key === "date_end") {
            if (value === "")
                value = null;
        }

        setForm((prevForm) => ({ ...prevForm, [key]: value }));
    }

    const changeLimitGroup = (group, value) => {
        if (isNaN(Number(value)) && value !== "")
            return;

        let groups = form.groups;
        groups[groups.findIndex((g) => g.available_id === group.available_id)].limit = Number(value)

        setForm((prevForm) => ({ ...prevForm, groups: groups }));
    }

    const selectSlot = (slot, checked) => {
        // if (!checkLimits({available: {slot: slot, checked: checked}, form: null}))
        //     return false;

        console.log(slot)

        let groups = form.groups;
        if (checked) {
            groups.push({limit: (groups.length > 1) ? 7 : 12, available_id: slot.id})
        } else {
            groups.splice(groups.findIndex((g) => g.available_id === slot.id), 1)
        }
        setForm((prevForm) => ({ ...prevForm, groups: groups, shifter_limit: (groups.length > 1) ? 7 : null }));
    }

    const checkLimits = (obj) => {
        let actualLimits = {
            start_min: limits.start[0],
            start_max: limits.start[1],
            end_min: limits.end[0],
            end_max: limits.end[1]
        };

        if (obj.form !== null) {

            if (obj.form.key === "date_start") {
                if (obj.form.value !== "") {
                    actualLimits.end_min = obj.form.value;
                } else {
                    actualLimits.end_min = minDefault;
                }
            } else if (obj.form.key === "date_end") {
                if (obj.form.value !== "") {
                    actualLimits.start_max = obj.form.value;
                } else {
                    actualLimits.start_max = maxDefault;
                }
            }

            setForm((prevForm) => ({ ...prevForm, groups: []}));

            actualLimits = {start: [actualLimits.start_min, actualLimits.start_max], end: [actualLimits.end_min, actualLimits.end_max]};
            setLimits(() => actualLimits);
        }

        return true;
    }

    const saveClassroom = () => {

        if (form.groups.length > 1 && form.shifter_limit === 0)
            return;

        if (form.groups.find((g) => g.limit === 0))
            return;


        if (form.date_end !== null && form.date_start !== null) {
            let diff = moment(form.date_end).diff(form.date_start, "days");
            if (diff <= 0) {
                global.SweetAlert.fire({
                    title: 'Si è verificato un errore',
                    text: 'Le date partenza / fine classi non sono corrette',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                });
                return;
            }
        }

        setLoading(() => true);

        ClassRoomAPI.edit(form).then(() => {
            getDetailClassroom();
            global.SweetAlert.fire({
                title: 'Classe modificata',
                text: 'La classe è stata modificata correttamente.',
                icon: 'success',
                allowOutsideClick: false,
                customClass: {
                    confirmButton: 'btn btn-primary'
                },
                confirmButtonText: 'Chiudi',
                buttonsStyling: false
            })
        }).catch((err) => {
            setLoading(() => false);
            global.SweetAlert.fire({
                title: 'Si è verificato un errore',
                text: err.message,
                icon: 'error',
                customClass: {
                    confirmButton: 'btn btn-primary'
                }
            });
            getDetailClassroom();
        })

    }

    const printAlertSlot = (slot) => {
        let alertFrom = false;
        let alertTo = false;

        if (slot) {

            let available_from = slot.available_from;
            let available_to = slot.available_to;

            if (form.date_start !== null) {
                if (available_from !== null)
                    if (moment(form.date_start).isBefore(available_from))
                        alertFrom = true

                if (available_to !== null)
                    if (moment(form.date_start).isAfter(available_to))
                        alertTo = true
            }

            if (form.date_end !== null) {
                if (available_from !== null)
                    if (moment(form.date_end).isBefore(available_from))
                        alertFrom = true

                if (available_to !== null)
                    if (moment(form.date_end).isAfter(available_to))
                        alertTo = true
            }

            if (form.date_end !== null && form.date_start !== null) {
                for (let i = 0; i < slot.groups.length; i++) {
                    if (slot.groups[i].Classroom.id !== Number(id)) {
                        let classroomStart = (slot.groups[i].Classroom.date_start !== null) ? moment(slot.groups[i].Classroom.date_start) : null;
                        let classroomEnd = (slot.groups[i].Classroom.date_end !== null) ? moment(slot.groups[i].Classroom.date_end) : null;

                        if (classroomStart !== null && classroomEnd !== null) {
                            if (moment(form.date_start).isBetween(classroomStart, classroomEnd) || moment(form.date_end).isBetween(classroomStart, classroomEnd)) {
                                available_from = classroomEnd;
                                alertFrom = true;
                                break;
                            }

                            if (moment(classroomStart).isBetween(form.date_start, form.date_end) || moment(classroomEnd).isBetween(form.date_start, form.date_end)) {
                                available_from = classroomEnd;
                                alertFrom = true;
                                break;
                            }
                        }
                    }
                }
            }


            return {alert: <span>
                {(available_from !== null) ? <Badge color={(alertFrom) ? "danger" : "secondary"}>dal {moment(available_from).format("DD MMM")}</Badge> : null}<br />
                {(available_to !== null) ? <Badge color={(alertTo) ? "danger" : "secondary"}>fino al {moment(available_to).format("DD MMM")}</Badge> : null}
            </span>, locked: (alertFrom || alertTo)}
        }

        return {alert: null, locked: (alertFrom || alertTo)}
    }


    if (error) {
        return <React.Fragment>
            <div className="page-content">
                <Alert color="danger" >Si è verificato un errore durante il recupero del dettaglio classe.</Alert>
            </div>
        </React.Fragment>
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Programmazione Classe" pageTitle="CLTV Cloud"/>
                    {(loading) ? <Loading/> : <div>
                        <Row>
                            <Col>
                                <div className='mb-2'>
                                    {(deployAllow) ? <button
                                        type="button"
                                        className="btn btn-primary btn-sm"
                                        onClick={() => deployClassroom()}>
                                        <i className="ri-rocket-2-fill align-bottom me-1"></i> PUBBLICA CLASSE
                                    </button> : null}
                                </div>
                                <div className='mb-2'>
                                    {(form.status !== "DRAFT") ? <RevertClassroom classroom={{id: form.id}} onResolve={getDetailClassroom}/> : null}
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <div className='mb-2'>
                                    <h4 className="text-primary">{form.name}</h4>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <button
                                    type="button"
                                    className="btn btn-success btn-sm float-end m-1"
                                    onClick={() => saveClassroom()}>
                                    <i className="ri-save-2-fill align-bottom me-1"></i> SALVA
                                </button>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg="3">
                                <Card>
                                    <CardBody>
                                        <div className='mb-2'>
                                            {/*<Badge color="secondary">{(classroom.individual) ? "CLASSE INDIVIDUALE" : "CLASSE DI GRUPPO"}</Badge>*/}
                                        </div>
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Livello
                                            </Label>
                                            <Select
                                                value={levels[levels.findIndex((f) => f.value === form.id_level)]}
                                                onChange={(e) => {
                                                    changeForm('id_level', e.value)
                                                }}
                                                isDisabled={(form.status !== "DRAFT")}
                                                options={levels}
                                                placeholder='Seleziona Livello'
                                            />
                                        </div>
                                        {(limits !== null) ? <span><div className='mb-2'>
                                            <Label className='form-label'>
                                                Data Partenza
                                            </Label>
                                            <Input
                                                type="date"
                                                min={moment(limits.start[0]).add(1, "days").toISOString().split("T")[0]}
                                                max={moment(limits.start[1]).toISOString().split("T")[0]}
                                                onChange={(e) => {
                                                    if(checkLimits({available: null, form: {key: 'date_start', value: e.target.value}}))
                                                        changeForm('date_start', e.target.value)
                                                }}
                                                invalid={(form.date_start === null)}
                                                value={form.date_start}
                                            />
                                            {(form.date_start === null) ? <FormFeedback color="warning"></FormFeedback> : null}
                                        </div>
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Data Fine
                                            </Label>
                                            <Input
                                                type="date"
                                                min={moment(limits.end[0]).add(1, "days").toISOString().split("T")[0]}
                                                max={moment(limits.end[1]).toISOString().split("T")[0]}
                                                onChange={(e) => {
                                                    if(checkLimits({available: null, form: {key: 'date_end', value: e.target.value}}))
                                                        changeForm('date_end', e.target.value)
                                                }}
                                                invalid={(form.date_end === null)}
                                                value={form.date_end}
                                            />
                                            {(form.date_end === null) ? <FormFeedback color="warning"></FormFeedback> : null}
                                        </div></span> : null}
                                        <div className='mb-2'>
                                            <Label className='form-label'>
                                                Stato
                                            </Label>
                                            <br />
                                            {printStatus()}
                                        </div>
                                        <hr />
                                        {form.groups.map((group, i) => {
                                            return <div className='mb-2' key={i}>
                                                <Label className='form-label'>
                                                    Limite partecipanti
                                                </Label>
                                                <Input
                                                    type="text"
                                                    disabled={(!["DRAFT", "WAITING"].includes(form.status))}
                                                    onChange={(e) => {
                                                        changeLimitGroup(group, e.target.value)
                                                    }}
                                                    value={group.limit}
                                                    invalid={Boolean(Number(group.limit) === 0)}
                                                />
                                                <FormText>
                                                    FISSO ORE {availables[availables.findIndex((a) => a.id === group.available_id)].slot.split(" - ")[0]}
                                                </FormText>
                                            </div>
                                        })}
                                        {(form.groups.length > 1) ? <div className='mb-2'>
                                            <Label className='form-label'>
                                                Limite partecipanti
                                            </Label>
                                            <Input
                                                type="text"
                                                disabled={(!["DRAFT", "WAITING"].includes(form.status))}
                                                onChange={(e) => {
                                                    changeForm('shifter_limit', e.target.value)
                                                }}
                                                value={form.shifter_limit}
                                                invalid={Boolean(Number(form.shifter_limit) === 0)}
                                            />
                                            <FormText>
                                                TURNISTI
                                            </FormText>
                                        </div> : null}

                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="9">
                                <Card>
                                    <CardBody className="checkout-tab">
                                        <div className='gridjs gridjs-container'>
                                            <div className='gridjs-wrapper'>
                                                <table role="grid" className="gridjs-table">
                                                    <thead className="gridjs-thead">
                                                    <tr className="gridjs-tr">
                                                        <th className="gridjs-th">DOCENTE</th>
                                                        {slots.map((slot, i) => {
                                                            return <th className="gridjs-th text-center" key={i}>{slot.split(" - ")[0]}</th>
                                                        })}
                                                    </tr>
                                                    </thead>
                                                    <tbody className="gridjs-tbody">
                                                    {teachers.map((teacher, i) => {
                                                        return <tr className="gridjs-tr" key={i} hidden={(form.groups.length > 0) ? (availables.find((a) => form.groups[0].available_id === a.id).teacher.id !== teacher.id) : false}>
                                                            <td className="gridjs-td text-nowrap" width="100">
                                                                <b>{teacher.name}</b>
                                                            </td>
                                                            {slots.map((slot, i) => {
                                                                let sIndex = availables.findIndex((a) => a.teacher.id === teacher.id && a.slot === slot);
                                                                // let otherBusy = (Boolean(availables[sIndex])) ? (availables[sIndex].busy && form.id !== availables[sIndex].groups[0].Classroom.id) : false;
                                                                // let checked = (Boolean(form.groups.find((g) => availables[sIndex].id === g.available_id)) || otherBusy)
                                                                let result = printAlertSlot(availables[sIndex])
                                                                return <td className="gridjs-td text-center" key={i}>
                                                                    {(availables[sIndex]) ? <span><Input className="form-check-input cursor-pointer"
                                                                        type="checkbox"
                                                                         disabled={!["DRAFT", "WAITING"].includes(form.status) || result.locked || form.date_start === null || form.date_end === null}
                                                                        checked={Boolean(form.groups.find((g) => availables[sIndex].id === g.available_id))}
                                                                        // disabled={(form.status === "ACTIVE" || otherBusy || (form.groups.length === 2 && !form.groups.find((g) => availables[sIndex].id === g.available_id)))}
                                                                        onChange={(e) => selectSlot(availables[sIndex], e.target.checked)}
                                                                        /><br />
                                                                        {result.alert}
                                                                    </span> : null}
                                                                </td>
                                                            })}
                                                        </tr>
                                                    })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ClassroomEdit;
