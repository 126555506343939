import React, { Component } from 'react';
import {Alert, Badge, Button, Col, Container, FormFeedback, Input, Label, NavLink, Row} from 'reactstrap';
import {withRouter, Link} from "react-router-dom";
import { Grid, _ } from 'gridjs-react';

import { Loading } from "custom";
import { CourseAPI } from "api";
const moment = require("moment");


class ListAvailableCourse extends Component {

    constructor(props) {
        super(props);
        this.state = {
            availableCourses: [],
            loading: true,
            error: false
        };
    }

    componentDidMount() {
        this.getAvailableCourses();
    }

    getAvailableCourses() {
        CourseAPI.availableList().then((availableCourses) => {
            this.setState({loading: false, error: false, availableCourses: availableCourses});
        }).catch(() => {
            this.setState({error: true});
        })
    }

    enableCourse(id) {
        this.setState({loading: true})
        CourseAPI.add(id).then((result) => {
            this.setState({loading: false})
            let message = {t: "Corso creato!", m: "Corso creato correttamente!"};
            if (result.status === "COURSE_REACTIVATED_OK")
                message = {t: "Corso riattivato!", m: "Il corso risulta già registrato ed è stato riattivato!"};

            global.SweetAlert.fire({
                icon: 'success',
                title: message.t,
                text: message.m,
                customClass: {
                    confirmButton: 'btn btn-success'
                }
            });
            this.props.onResolve();
            global.onSetModal({isOpen: false})
        }).catch(() => {
            this.setState({loading: false})
            global.SweetAlert.fire({
                icon: 'error',
                title: 'Si è verificato un errore :(',
                text: 'Purtroppo qualcosa è andato storto e non è stato possibile abilitare il corso. Riprova più tardi.',
                customClass: {
                    confirmButton: 'btn btn-success'
                }
            });
        })
    }

    render() {

        const self = this;

        if (this.state.error) {
            return <React.Fragment>
                <Alert color="danger" >Si è verificato un errore durante il recupero della lista corsi.</Alert>
            </React.Fragment>
        }

        return (
            <React.Fragment>
                {(this.state.loading) ? <Loading label={"Caricamento in corso... questa operazione può richiedere fino ad un minuto"}/> : <div>
                        <Grid
                            data={this.state.availableCourses}
                            columns={[
                                {
                                    id: 'id',
                                    name: '',
                                    sort: false,
                                    formatter: (cell, row, i) => _(<ul
                                        className="list-inline hstack gap-2 mb-0">
                                        <li className="list-inline-item cursor-pointer" onClick={() => self.enableCourse(cell)}>
                                            <i className="ri-add-box-fill fs-20 text-success"></i>
                                        </li>
                                    </ul>)
                                },
                                {
                                    id: 'name',
                                    name: "Nome",
                                    formatter: (cell, row) => _(
                                        <span className="text-nowrap"><b>{cell}</b></span>
                                    )
                                }
                            ]}
                            style={{table: {'white-space': 'nowrap'}}}
                            resizable={true}
                            search={true}
                            sort={true}
                            pagination={{ enabled: true, limit: 10}}
                            language={{
                                'search': {
                                    'placeholder': '🔍 Cerca...'
                                },
                                'pagination': {
                                    'previous': 'Indietro',
                                    'next': 'Avanti',
                                    'showing': 'Stai visualizzando da',
                                    'results': 'Corsi',
                                    'to': 'a',
                                    'of': 'di',
                                },
                                'noRecordsFound': 'Nessun corso trovato',
                                'error': 'Si è verificato un errore non previsto',
                            }}
                        />

                </div>}
            </React.Fragment>
        );
    }
}

export default withRouter(ListAvailableCourse);
