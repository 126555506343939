import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert } from 'reactstrap';
import ParticlesAuth from "./ParticlesAuth";

import {withRouter, Link, Redirect} from "react-router-dom";

import logoLight from "../../assets/images/logo-light.png";

import LoginForm from "./LoginForm";
import ForgotPasswordForm from "./ForgotPasswordForm";

import { AuthAPI } from "api";

import {Utils, Loading} from "custom";



class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            authenticated: false,
            user: null,
            modeLogin: true,
            preLoading: false
        };
    }

    componentDidMount() {
        this.checkLogin();
    }

    checkLogin() {
        AuthAPI.permission().then((res) => {
            this.setState({authenticated: true});
        }).catch(err => {
            this.setState({authenticated: false, preLoading: false});
        })
    }

    render() {
        if (this.state.authenticated) {
            return <Redirect to={"/"}/>;
        }

        document.title = "Autenticazione | CLTV Cloud";

        return <React.Fragment>
            {(this.state.preLoading) ? <Loading /> : (<ParticlesAuth>
                <div className="auth-page-content">

                    <Container>

                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            <img src={logoLight} alt="" height="180"/>
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    <CardBody className="p-4">
                                        {(this.state.modeLogin) ? <LoginForm changeModeLogin={() => this.setState({modeLogin: !(this.state.modeLogin)})}/> : <ForgotPasswordForm changeModeLogin={() => this.setState({modeLogin: !(this.state.modeLogin)})}/>}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>)}
        </React.Fragment>
    }
}

export default withRouter(Login);
