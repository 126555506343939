import React, {Fragment} from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
    Row,
    Col,
    Label,
    FormFeedback,
    Input,
    FormText, Badge
} from 'reactstrap';

import {Utils} from "custom";
import {ModuleAPI} from "api";
import Select from "react-select";

const moment = require("moment")

const referral_list = [
    {value: "META", label: "META", icon: "ri-facebook-box-fill align-middle fs-20 text-secondary"},
    {value: "FUNNEL", label: "FUNNEL", icon: "ri-global-fill align-middle fs-20 text-primary"},
]

class ReassignModule extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            form: {
                id_old: this.props.id_old,
                id_new: null,
            },
            loading: false,
            errors: false
        };

        console.log(this.props.modules)
    }

    changeForm(key, text) {
        const form = this.state.form;
        form[key] = text;
        this.setState({form: form}, () => this.showModal());
    }

    showModal() {

        let form = this.state.form;
        let errors = this.state.errors;

        global.onSetModal({
            title: "RIASSEGNA LEADS MODULO",
            content: <div>
                <Row>
                    <Col>
                        <div className='mb-2'>
                            <Label className='form-label'>
                                Seleziona nuovo modulo
                            </Label>
                            <Select
                                value={form.id_new}
                                options={this.props.modules.filter((f) => f.id !== form.id_old).map((m) => {return {value: m.id, label: m.name, icon: referral_list.find((r) => r.value === m.referral)?.icon}})}
                                onChange={(val) => {
                                    this.changeForm('id_new', val)
                                }}
                                formatOptionLabel={referral => (
                                    <div className="referral-option">
                                        <i className={referral.icon}/>
                                        <span>&nbsp;&nbsp;{referral.label}</span>
                                    </div>
                                )}
                            ></Select>
                            <Input invalid={Boolean(errors?.id_new)} hidden/>
                            <FormFeedback>{errors?.id_new}</FormFeedback>
                        </div>
                    </Col>
                </Row>
                <br />
                <br />
                <br />
            </div>,
            isOpen: true,
            toggle: false,
            size: 'lg',
            type: 'info',
            buttons: [
                {label: 'SALVA', color: 'success', onClick: () => this.reassignModule()},
                {
                    label: 'CHIUDI',
                    color: 'danger',
                    onClick: () => this.resetForm()
                }
            ]
        })
    }

    resetForm() {

        this.setState({
            form: {
                id_old: this.props.id_old,
                id_new: null,
            }
        })
        global.onSetModal({isOpen: false, loading: false, content: ""})
    }

    reassignModule() {
        const self = this;
        let form = this.state.form;
        let error = {};

        if (form.id_new === null)
            error.id_new = "Seleziona prima il nuovo modulo";

        this.setState({errors: error}, () => this.showModal());

        if (Object.keys(error).length === 0) {
            global.onSetModal({loading: true});

            ModuleAPI.reAssign({
                id_old: form.id_old,
                id_new: form.id_new.value
            }).then(() => {
                global.onSetModal({loading: false});
                global.SweetAlert.fire({
                    title: 'Leads modulo riassegnati',
                    text: 'La riassegnazione è avvenuta correttamente.',
                    icon: 'success',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    confirmButtonText: 'Chiudi',
                    buttonsStyling: false
                }).then(function (result) {
                    if (result.value) {
                        self.resetForm();
                        self.props.onResolve();
                    }
                })
            }).catch((err) => {
                global.onSetModal({loading: false});
                global.SweetAlert.fire({
                    title: 'Errore',
                    text: 'Si è verificato un errore durante la riassegnazione',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    }
                });
            })
        }
    }


    render() {
        return (
            <React.Fragment>
                <li className="list-inline-item cursor-pointer" onClick={() => this.showModal()}>
                    <i className="ri-arrow-left-right-fill fs-20 text-warning"></i>
                </li>
            </React.Fragment>
        )
    }
}

export default ReassignModule;
