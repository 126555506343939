import { ConsulentAPI, NetworkAPI } from 'api';
import React, { Component } from 'react';
import {
    Alert,
    Badge,
    Col,
    Container,
    Row,
    Label,
    FormFeedback,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    Button,
    FormText
} from 'reactstrap';
import { Utils } from '../..';

class TransferNetworker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            cell: '',
            loading: true,
            error: false,
            errors: null,
            showCreateModal: false,
            showEditModal: false
        };
    }
    transferNetworker = async () => {
        try {
            let error = {};

            if (this.state.cell !== '' && !Utils.isValidCell(this.state.cell))
                error.cell = "Il cellulare inserito non è corretto";

            if (this.state.cell === '')
                error.cell = "Il campo cellulare è obbligatorio";

            if (!Utils.isValidEmail(this.state.email))
                error.email = "La mail inserita non è corretta";

            if (this.state.email === '')
                error.email = "Il campo email è obbligatorio";

            this.setState({ errors: error })
            if (Object.keys(error)?.length === 0) {
                const user = {
                    name: this.state.name,
                    email: this.state.email,
                    cell: this.state.cell
                }
                NetworkAPI.transfer(user).then((data) => {
                    this.setState({ showCreateModal: false });
                    this.props.onResolve();
                }).catch((err) => {
                    if (err.status === "REPORTER_NOT_FOUND")
                        return this.setState({ errors: {general: "L'affiliato inserito non risulta corretto, assicurati che i dati inseriti siano corretti"} });
                    if (err.status === "REPORTER_NOT_ALLOWED")
                        return this.setState({ errors: {general: "L'affiliato inserito non è disponibile"} });
                    if (err.status === "REPORTER_ALREADY_AFFILIATE")
                        return this.setState({ errors: {general: "L'utente inserito risulta già affiliato"} });
                    if (err.status === "ALREADY_REPORTER")
                        return this.setState({ errors: {general: "L'utente inserito risulta già affiliato"} });

                    return this.setState({ errors: {general: "Si è verificato un errore"} });
                })
            }
        } catch (err) {
            return this.setState({ errors: {general: "Si è verificato un errore non previsto"} });
        }
    }

    changeForm(key, text) {
        const form = this.state;

        if (key === 'cell') {
            if ((isNaN(text) && text !== "+") || text.indexOf(".") > -1)
                return;

            if (text.length >= 3 && text.indexOf("+") === -1)
                text = "+39" + text;

            text = text.replace(/ /g, "");
        }

        form[key] = text;
        this.setState({ [key]: text });
    }

    render() {
        let errors = this.state.errors;
        return (
            <>
                <button
                    type="button"
                    className="btn btn-success btn-sm"
                    onClick={() => this.setState({ showCreateModal: true })}
                >
                    <i className="ri-add-line align-bottom me-1"></i> COLLEGA AFFILIATO
                </button>
                <Modal style={{ overflowY: "auto" }} isOpen={this.state.showCreateModal} size="lg" scrollable={true} className={'modal-dialog-scrollable modal-md'}>
                    <ModalBody className='px-sm-5 pt-5 pb-50'>
                        <div className='text-center mb-5'>
                            <h3 className='mb-1'>COLLEGA AFFILIATO</h3>
                        </div>
                        <div>
                            <Row>
                                <Col md="12">
                                    <p>Per collegare un tuo nuovo affiliato inserisci le informazioni di contatto</p>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col>
                                    <div className='mb-2'>
                                        <Label className='form-label'>
                                            Nome
                                        </Label>
                                        <Input
                                            type="text"
                                            value={this.state.name}
                                            invalid={Boolean(errors?.name)}
                                            onChange={(e) => this.changeForm("name", e.target.value)}
                                        />
                                        <FormFeedback>{errors?.name}</FormFeedback>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <div className='mb-2'>
                                        <Label className='form-label'>
                                            Email
                                        </Label>
                                        <Input
                                            type="email"
                                            value={this.state.email}
                                            invalid={Boolean(errors?.email)}
                                            onChange={(e) => this.changeForm("email", e.target.value)}
                                        />
                                        <FormFeedback>{errors?.email}</FormFeedback>
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className='mb-3'>
                                        <Label className='form-label'>
                                            Cellulare
                                        </Label>
                                        <Input
                                            type="tel"
                                            value={this.state.cell}
                                            invalid={Boolean(errors?.cell)}
                                            onChange={(e) => this.changeForm("cell", e.target.value)}
                                        />
                                        <FormText>
                                            Assicurati di digitare correttamente il <u>numero di cellulare</u>, è necessario inserire anche il prefisso internazionale (+39 per Italia)
                                        </FormText>
                                        <FormFeedback>{errors?.cell}</FormFeedback>
                                    </div>
                                </Col>
                            </Row>
                            {(errors?.general) ? <Row>
                                <Alert color="danger">{errors?.general}</Alert>
                            </Row> : null}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color='success'
                            onClick={this.transferNetworker}
                        >
                            SALVA
                        </Button>
                        <Button
                            color='danger'
                            onClick={() => this.setState({
                                email: "",
                                cell: "",
                                showCreateModal: false,
                                errors: null
                            })}
                        >
                            CHIUDI
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}
export default TransferNetworker
