import React, {useEffect, useState} from 'react';
import {Alert, Card, CardBody, Col, Container, Input, Row} from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
// import ReactApexChart from 'react-apexcharts';
import CountUp from "react-countup";
import {Loading, Utils} from "custom";
import {Link} from "react-router-dom";
import Flatpickr from "react-flatpickr";

import { Italian } from "flatpickr/dist/l10n/it";
import Select from "react-select";
import {ConsulentAPI, CourseAPI, LevelAPI} from "api";

const moment = require("moment");


const SellSetting = ({onFilterSells, onReload}) => {

    const [typologies, setTypologies] = useState([]);
    const [consulents, setConsulents] = useState([]);

    const [filterType, setFilterType] = useState(null);
    const [filterConsulent, setFilterConsulent] = useState(null);
    const [filterReconfirm, setFilterReconfirm] = useState(null);
    const [filterStatus, setFilterStatus] = useState(null);

    const [range, setRange] = useState({start_date: moment().startOf("month"), end_date: moment()});

    const isAdmin = Utils.getUserData().role === "ADMIN";

    useEffect(() => {

        if (isAdmin) {
            ConsulentAPI.list().then((resCons) => {
                let consulentsArr = [
                    {value: -1, label: <small><i>Non Assegnato</i></small>}
                ];

                setConsulents(() => consulentsArr.concat(resCons.map((c) => {
                    return {value: c.id, label: c.firstname + " " + c.lastname}
                })))
            }).catch((e) => {
                console.error(e.message)
            })
        }

        LevelAPI.list().then((resLevels) => {
            let typologiesArr = [
                {value: {type: "CLASSROOM", id: null}, label: <b>CLASSE: tutti i livelli</b>}
            ];
            typologiesArr = typologiesArr.concat(resLevels.map((l) => {
                return {value: {type: "CLASSROOM", id: l.id}, label: <span><b>CLASSE:</b> Livello {l.name}</span>}
            }))

            typologiesArr.push({value: {type: "VIDEO", id: null}, label: <b>VIDEO: tutti i corsi</b>});

            CourseAPI.list().then((resCourses) => {
                typologiesArr = typologiesArr.concat(resCourses.map((c) => {
                    return {value: {type: "VIDEO", id: c.id}, label: <span><b>VIDEO:</b> {c.name}</span>}
                }))

                setTypologies(() => typologiesArr);

            }).catch((e) => {
                console.error(e.message)
            })

        }).catch((e) => {
            console.error(e.message)
        })

    }, [])

    useEffect(() => {
        onReload({start_date: moment(range.start_date).format("DD-MM-YYYY"), end_date: moment(range.end_date).format("DD-MM-YYYY")})
    }, [range])


    useEffect(() => {
        onFilterSells(filterType, filterConsulent, filterReconfirm, filterStatus)
    }, [filterType, filterConsulent, filterReconfirm, filterStatus])


    return (
        <React.Fragment>
            <Row className="mb-1">
                <Col xl={4} className="p-1">
                    <div className="input-group">
                        <Flatpickr
                            className="form-control border-0 dash-filter-picker shadow"
                            options={{
                                mode: "range",
                                dateFormat: "d/m/Y",
                                locale: Italian,
                            }}
                            value={[range.start_date.format("DD/MM/YYYY"), range.end_date.format("DD/MM/YYYY")]}
                            onChange={(selectedDates) => {
                                if (selectedDates.length === 2) {
                                    setRange({
                                        start_date: moment(selectedDates[0]),
                                        end_date: moment(selectedDates[1]),
                                    });
                                }
                            }}
                        />
                        <div className="input-group-text bg-primary border-primary text-white"><i className="ri-calendar-2-line"></i></div>
                    </div>
                </Col>
                {(isAdmin) ? <Col xl={4} className="p-1">
                    <Select
                        value={filterConsulent}
                        options={consulents}
                        isClearable
                        placeholder="Filtra consulente"
                        className='me-5 w-100'
                        onChange={(val) => {
                            setFilterConsulent(() => (val !== null) ? val : null)
                        }}
                    ></Select>
                </Col> : null}
                <Col xl={4} className="p-1">
                    <div className="input-group">
                        <Select
                            value={filterType}
                            options={typologies}
                            isClearable
                            placeholder="Filtra tipologia"
                            className='me-5 w-100'
                            onChange={(val) => {
                                setFilterType(() => (val !== null) ? val : null)
                            }}
                        ></Select>
                    </div>
                </Col>
                <Col xl={4} className="p-1">
                    <div className="input-group">
                        <Select
                            value={filterReconfirm}
                            options={[
                                {value: false, label: "Solo Nuovi Iscritti"},
                                {value: true, label: "Solo Riconferme"}
                            ]}
                            isClearable
                            placeholder="Filtra Riconferme"
                            className='me-5 w-100'
                            onChange={(val) => {
                                setFilterReconfirm(() => (val !== null) ? val : null)
                            }}
                        ></Select>
                    </div>
                </Col>
                <Col xl={4} className="p-1">
                    <div className="input-group">
                        <Select
                            value={filterStatus}
                            options={[
                                {value: 1, label: "Pagato"},
                                {value: 2, label: "Acconto"},
                                {value: 3, label: "Non Pagato"}
                            ]}
                            isClearable
                            placeholder="Filtra Pagamento"
                            className='me-5 w-100'
                            onChange={(val) => {
                                setFilterStatus(() => (val !== null) ? val : null)
                            }}
                        ></Select>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default SellSetting;




