import React, { Component } from 'react';
import {Alert, Badge, Col, Container, FormFeedback, Input, Label, Row} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import {withRouter, Link} from "react-router-dom";
import { Grid, _ } from 'gridjs-react';

import {GestClassroomTrial, GestLinkClassroomTrial, DeleteClassroomTrial, Loading, Utils} from "custom";

import {ClassRoomTrialAPI, LevelAPI, TeacherAPI} from "api";
import Select from "react-select";

const moment = require("moment")

class ClassroomTrialTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            classrooms: [],
            teachers: [],
            loading: true,
            error: false,
            showEnded: false
        };
    }

    componentDidMount() {
        this.getClassroomsTrial();
    }

    getClassroomsTrial() {
        ClassRoomTrialAPI.list().then(async (classrooms) => {
            let teachers = await TeacherAPI.list();
            this.setState({loading: false, error: false, classrooms: classrooms, teachers: teachers});
        }).catch(() => {
            this.setState({error: true});
        })
    }

    signCheck(id_classroom, id_partecipant) {
        const self = this;
        global.SweetAlert.fire({
            title: "Segna presenza lezione di prova",
            text: 'Sei veramente sicuro di voler segnare la presenza alla lezione di prova? Questa azione è irreversibile',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, conferma',
            cancelButtonText: 'Annulla',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ms-1'
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm: function() {
                return new Promise(function(resolve, reject) {
                    ClassRoomTrialAPI.sign(id_classroom, id_partecipant).then(() => {
                        resolve();
                    }).catch(() => {
                        global.SweetAlert.fire({
                            icon: 'error',
                            title: 'Si è verificato un errore :(',
                            text: 'Purtroppo qualcosa è andato storto e non è stato possibile segnare la presenza alla lezione di prova. Riprova più tardi.',
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        });
                        reject()
                    })
                });
            },
        }).then(function (result) {
            if (result.value) {
                global.SweetAlert.fire({
                    icon: 'success',
                    title: 'Presenza segnata correttamente!',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                });
                self.detailPartecipants(id_classroom, 1);
            }
        })
    }

    detailPartecipants(id, partecipants) {
        if (partecipants === 0)
            return;

        ClassRoomTrialAPI.detail(id).then((detail) => {
            global.onSetModal({
                title: "LISTA PARTECIPANTI",
                content: <div>
                    <Row>
                        <Col md="12">
                            <Grid
                                data={detail.partecipants}
                                language={{
                                    'noRecordsFound': 'Nessun partecipante trovato',
                                    'error': 'Si è verificato un errore non previsto',
                                }}
                                columns={[
                                    {
                                        id: "id",
                                        hidden: true
                                    },
                                    {
                                        id: 'trial_check',
                                        name: '',
                                        formatter: (cell, row) => _(
                                            <div className='text-left'>
                                                {((cell) ? <Badge color="success">Presente</Badge> : <Badge color="danger" className="cursor-pointer" onClick={() => this.signCheck(id, row.cells[0].data)}>Assente</Badge>)}
                                            </div>
                                        )
                                    },
                                    {
                                        id: 'name',
                                        name: 'Nome',
                                        formatter: (cell, row) => _(
                                            <div className='text-left'>
                                                <b>{cell}</b>
                                            </div>
                                        )
                                    },
                                    {
                                        id: 'phone',
                                        name: 'Telefono',
                                        formatter: (cell, row) => _(
                                            <div className='text-left'>
                                                <span className="text-nowrap"><a href={"tel:" + cell}>{cell}</a></span>
                                            </div>
                                        )
                                    },
                                    {
                                        id: 'email',
                                        name: 'Email',
                                        formatter: (cell, row) => _(
                                            <div className='text-left'>
                                                <span className="text-nowrap"><a href={"mailto:" + cell}>{cell}</a></span>
                                            </div>
                                        )
                                    },
                                    {
                                        id: 'consulent',
                                        name: 'Consulente',
                                        formatter: (cell, row) => _(
                                            <div className='text-left'>
                                                <span className="text-nowrap">{cell}</span>
                                            </div>
                                        )
                                    }
                                ]}

                            />
                        </Col>
                    </Row>
                </div>,
                isOpen: true,
                toggle: false,
                loading: false,
                size: 'lg',
                type: 'info',
                buttons: [
                    {
                        label: 'CHIUDI',
                        color: 'danger',
                        onClick: () => global.onSetModal({isOpen: false, loading: false, content: "", title: ""})
                    }
                ]
            })
        }).catch((err) => {
            console.error(err)
            global.SweetAlert.fire({
                title: 'Errore',
                text: 'Si è verificato un errore durante il recupero dei partecipanti',
                icon: 'error',
                customClass: {
                    confirmButton: 'btn btn-primary'
                }
            });
        })
    }

    render() {
        if (this.state.error) {
            return <React.Fragment>
                <Alert color="danger" >Si è verificato un errore durante il recupero della lista delle lezioni gratuite.</Alert>
            </React.Fragment>
        }

        let role = Utils.getUserData().role;

        return (
            <React.Fragment>

                {(this.state.loading) ? <Loading /> : <div>
                    {(role === "ADMIN") ? <div className="flex-shrink-0" style={{margin: 10}}>
                        <GestClassroomTrial onResolve={this.getClassroomsTrial.bind(this)} levels={this.state.levels} teachers={this.state.teachers}/>
                    </div> : null}

                    <div className="form-check form-switch form-switch-custom form-switch-primary mb-3">
                        <Input className="form-check-input" type="checkbox" role="switch" checked={this.state.showEnded}
                               onChange={(e) => this.setState({showEnded: e.target.checked})} />
                        <Label className="form-check-label">Mostra Lezioni passate</Label>
                    </div>

                <Grid
                    data={(this.state.showEnded) ? this.state.classrooms : this.state.classrooms.filter((trial) => !trial.ended)}
                    columns={[
                        {
                            id: 'id',
                            hidden: true
                        },
                        {
                            id: 'dateTime',
                            name: 'Data Ora',
                            formatter: (cell, row) => _(<span className="text-nowrap"><b>{moment(cell).format("ddd DD MMM HH:mm")}</b><span className="text-danger" hidden={!(row.cells[4].data)}> <b>(conclusa)</b></span></span>)
                        },
                        {
                            id: 'teacher',
                            name: 'Docente',
                            formatter: (cell, row) => _(<span className="text-nowrap">{(cell !== null) ? (cell.firstname + " " + cell.lastname) : "N/D"}</span>)
                        },
                        {
                            id: 'partecipants',
                            name: 'Partecipanti',
                            formatter: (cell, row) => _(<span className="text-nowrap">
                                <Badge style={{fontSize: "13px"}} color={(cell > 0) ? "warning" : "info"} className={(cell > 0) ? "cursor-pointer" : ""} onClick={() => this.detailPartecipants(row.cells[0].data, cell)}><b>{cell}</b></Badge>
                                &nbsp;
                                {(!row.cells[4].data && row.cells[5].data !== null) ? <a href={row.cells[5].data} target="_blank"><Badge color="primary">Accedi</Badge></a> : null}
                            </span>)
                        },
                        {
                            id: 'ended',
                            hidden: true
                        },
                        {
                            id: 'link',
                            hidden: true
                        },
                        (role === "ADMIN" || role === "TEACHER") ? {
                            id: 'id',
                            name: '',
                            sort: false,
                            formatter: (cell, row, i) => _(<ul
                                className="list-inline hstack gap-2 mb-0">
                                {<GestLinkClassroomTrial preset={{id: row.cells[0].data, link: row.cells[5].data}} onResolve={this.getClassroomsTrial.bind(this)}/>}
                                {(role === "ADMIN") ? <GestClassroomTrial preset={{id: row.cells[0].data, dateTime: row.cells[1].data, id_teacher: (row.cells[2].data !== null) ? row.cells[2].data.id : null}} onResolve={this.getClassroomsTrial.bind(this)} teachers={this.state.teachers}/> : null}
                                {(role === "ADMIN") ? <DeleteClassroomTrial classroom={{id: row.cells[0].data}} onResolve={this.getClassroomsTrial.bind(this)} teachers={this.state.teachers}/> : null}
                            </ul>)
                        } : undefined
                    ]}
                    style={{table: {'white-space': 'nowrap'}}}
                    resizable={true}
                    search={true}
                    sort={true}
                    pagination={{ enabled: true, limit: 10}}
                    language={{
                        'search': {
                            'placeholder': '🔍 Cerca...'
                        },
                        'pagination': {
                            'previous': 'Indietro',
                            'next': 'Avanti',
                            'showing': 'Stai visualizzando da',
                            'results': 'Lezioni gratuite',
                            'to': 'a',
                            'of': 'di',
                        },
                        'noRecordsFound': 'Nessuna lezione gratuita trovata',
                        'error': 'Si è verificato un errore non previsto',
                    }}
                /></div>}
            </React.Fragment>
        );
    }
}

export default withRouter(ClassroomTrialTable);
