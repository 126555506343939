import React, { Component } from 'react';
import {
    Card,
    CardBody,
    Col,
    Container,
    Input,
    Label,
    Row,
    Button,
    Form,
    FormFeedback,
    Alert,
    Spinner
} from 'reactstrap';
import {Link} from "react-router-dom";

import {Utils} from "custom";

import {AuthAPI} from "api";

import { Turnstile } from '@marsidev/react-turnstile'

class ForgotPasswordForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            errors: false,
            loading: false,
            turnstilePassed: false
        };
    }

    onForgotPasswordPress(e) {
        let email = this.state.email;
        let error = {};

        if (email.trim() !== "" && !Utils.isValidEmail(email)) {
            error.email = 'L\'email inserita non è valida';
        }
        if (email.trim() === "") {
            error.email = 'Inserisci la tua email per continuare';
        }

        if (!this.state.turnstilePassed)
            error.general = "Accesso Negato: utente non verificato"

        this.setState({errors: (Object.keys(error).length > 0) ? error : false});

        if (Object.keys(error).length === 0) {
            this.setState({loading: true});

            const formData = new FormData(e.target);
            const turnstileToken = formData.get('cf-turnstile-response');

            AuthAPI.resetPassword(email, turnstileToken).then(() => {
                global.SweetAlert.fire({
                    title: 'Reset password richiesto',
                    text: 'Hai richiesto correttamente il reset della password, controlla la tua mail e segui le istruzioni indicate.',
                    icon: 'success',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    confirmButtonText: 'Torna a login',
                    buttonsStyling: false
                }).then(function (result) {
                    if (result.value) {
                        window.location.replace('/login');
                    }
                })
            }).catch(() => {
                this.setState({loading: false, email: email});
                this.setState({
                    error: 'Si è verificato un errore non previsto',
                });
            })
        }
    }


    render() {
        document.title = "Reset Password | CLTV Cloud";

        return <React.Fragment>
            <div className="text-center mt-2">
                <h5 className="text-primary">Password dimenticata ?</h5>
                <p className="text-muted">Nessun problema. Inserisci la tua email per ricevere un link e reimpostare la tua password.</p>
            </div>
            <div className="p-2 mt-4">
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        this.onForgotPasswordPress(e)
                    }}
                    action="#">
                    <div className="mb-3">
                        <Label htmlFor="email" className="form-label">Email</Label>
                        <Input
                            name="email"
                            className="form-control"
                            placeholder="Inserisci la tua Email"
                            type="email"
                            value={this.state.email}
                            invalid={this.state.errors.email}
                            onChange={(e) => this.setState({email: e.target.value})}
                        />
                        {(this.state.errors.email) ? <FormFeedback type="invalid">{this.state.errors.email}</FormFeedback> : null}
                    </div>
                    <Turnstile onSuccess={() => this.setState({turnstilePassed: true})} siteKey={(process.env.REACT_APP_NODE_ENV === "development") ? "1x00000000000000000000AA" : process.env.REACT_APP_CLOUDFLARE_TURNSTILE_SITE_KEY} />
                    <div className="mt-4">
                        <Button color="success" className="btn btn-success w-100" type="submit">Reset Password {(this.state.loading ? <Spinner size='sm' type='grow' /> : null)}</Button><br /><br />
                        <div className="float-start" >
                            <Link onClick={() => this.props.changeModeLogin()} className="text-muted">Torna a login</Link>
                        </div>
                    </div>
                </Form>
            </div>

        </React.Fragment>
    }
}

export default ForgotPasswordForm;
