import { LessonAPI } from 'api';
import React, { Component } from 'react'
import Loader from 'react-loader-spinner';
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';

export default class DeleteFile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            errors: null,
            showDelete: false,
        };
    }


    deleteFileName = () =>{
        this.setState({loading: false });
        LessonAPI.deleteFile(this.props.id)
        .then((res)=>{
            console.log('res',res);
            global.SweetAlert.fire({
                title: 'File eliminato',
                text: 'Il file è stato eliminato correttamente.',
                icon: 'success',
                allowOutsideClick: false,
                customClass: {
                    confirmButton: 'btn btn-primary'
                },
                confirmButtonText: 'Chiudi',
                buttonsStyling: false
            })
            this.setState({showDelete:false})
            this.props.onResolve()
        }).catch(() => {
            this.setState({showDelete:false, loading: false });
            global.SweetAlert.fire({
                title: 'Errore',
                text: 'Errore durante la cancellazione del file',
                icon: 'error',
                customClass: {
                    confirmButton: 'btn btn-primary'
                }
            });
        })

    }

    render() {
        return (
            <React.Fragment>
                <div className='text-center'>
                    <i className='ri-delete-bin-5-fill fs-18 text-danger' onClick={() => this.setState({ showDelete: true })}></i>&nbsp;
                </div>
                <Modal style={{ overflowY: "auto" }} isOpen={this.state.showDelete} scrollable={true} className={'modal-dialog-scrollable modal-lg'}>
                    <ModalBody className='px-sm-5 pt-5 pb-50'>
                        <div className='text-center mb-5'>
                            <h3 className='mb-1'>Elimina File</h3>
                        </div>
                        {this.state.loading ?
                        <div style={{textAlign: 'center',
                        height: '200px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'}}>
                            <Loader type={'TailSpin'} color='#E24815'/>
                        </div> :
                        <Row>
                            <Col sm={12}>
                            <h3 className='mb-1'>sei sicuro di voler eliminare il file</h3>
                            </Col>
                        </Row>}
                    </ModalBody>
                    <ModalFooter>
                        <Button color='success' onClick={this.deleteFileName} >
                            SALVA
                        </Button>
                        <Button color='danger' onClick={() => this.setState({ showDelete: false, })} >
                            CHIUDI
                        </Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        )
    }
}
