import React, { useState } from 'react';
import { Alert, Badge, Col, Container, Row, Input, Spinner, Button, Modal, ModalBody, ModalFooter, FormGroup, Label, FormText, FormFeedback } from 'reactstrap';
import { withRouter, Link } from "react-router-dom";
import Select, { components } from "react-select";
import { Utils } from 'custom';
import { LeadAPI } from 'api'

const LeadAdd = ({ getLeads, modules }) => {
    const [showCreate, setShowCreate] = useState(false);

    const [create, setCreate] = useState({
        createName: '',
        createEmail: '',
        createPhone: '',
        createReferral: {value: "WHATSAPP", label: "WHATSAPP", icon: "ri-whatsapp-fill align-middle fs-20 text-success"}
    });

    const [errors, setErrors] = useState({});

    const [loading, setLoading] = useState(false);

    const createFormChange = (key, val) => {

        if (key === 'createPhone') {
            if ((isNaN(val) && val !== "+") || val.indexOf(".") > -1)
                return;

            if (val.length >= 3 && val.indexOf("+") === -1)
                val = "+39" + val;

            val = val.replace(/ /g, "");
        }

        setCreate({
            ...create,
            [key]: val
        })
    }

    const createLead = () => {
        let error = {};
        if (create.createName == '')
            error.createName = "Il nome è obbligatorio";

        if (create.createPhone == '')
            error.createPhone = "Il campo cellulare è obbligatorio";

        if (!Utils.isValidEmail(create.createEmail) && create.createEmail !== "")
            error.createEmail = "La mail inserita non è corretta";

        if (create.createPhone !== '' && !Utils.isValidCell(create.createPhone))
            error.createPhone = "Il cellulare inserito non è corretto";

        setErrors(error)
        if (Object.keys(error).length === 0) {

            setLoading(true)

            const lead = {
                name: create.createName,
                email: create.createEmail,
                phone: create.createPhone,
                referral: (!["WHATSAPP", "MANUAL"].includes(create.createReferral.value)) ? "MODULE" : create.createReferral.value,
                module_id: (!["WHATSAPP", "MANUAL"].includes(create.createReferral.value)) ? create.createReferral.value : undefined
            }

            LeadAPI.add(lead)
                .then(() => {
                    getLeads()
                    setLoading(false)
                    onCloseModal()
                    global.SweetAlert.fire({
                        title: 'Lead creato',
                        text: 'Lead creato correttamente',
                        icon: 'success',
                        customClass: {
                            confirmButton: 'btn btn-primary'
                        },
                    })

                }).catch((err) => {
                    console.log('err', err);
                    setLoading(false)
                    // this.setState({ error: true });
                })
        }
    }

    const onCloseModal = () => {
        setShowCreate(false)
        setErrors({})
        setLoading(false)
        setCreate({
            createName: '',
            createEmail: '',
            createPhone: '',
            createReferral: {value: "WHATSAPP", label: "WHATSAPP", icon: "ri-whatsapp-fill align-middle fs-20 text-success"},
        })
    }
    return (
        <>
            <Button color='success' className='me-1' size="sm" onClick={() => setShowCreate(true)}>
                <i className="ri-add-line me-1"></i>
                CREA LEAD
            </Button>

            <Modal style={{ overflowY: "auto" }} isOpen={showCreate} scrollable={true} className={'modal-dialog-scrollable modal-lg'}>
                <ModalBody className='px-sm-5 pt-5 pb-50' style={{ height: '40vh' }}>
                    <div className='text-center mb-5'>
                        <h3 className='mb-1'>CREA LEAD</h3>
                    </div>
                    <Row>
                        <Col md="6">
                            <div className='mb-2'>
                                <Label className='form-label'>
                                    Nominativo*
                                </Label>
                                <Input
                                    type="text"
                                    value={create.createName}
                                    invalid={Boolean(errors?.createName)}
                                    onChange={(e) => createFormChange('createName', e.target.value)}
                                />
                                <FormFeedback>{errors?.createName}</FormFeedback>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className='mb-2'>
                                <Label className='form-label'>
                                    Provenienza*
                                </Label>
                                <Select
                                    value={create.createReferral}
                                    options={(Utils.getUserData().role === "ADMIN") ? [
                                        {value: "WHATSAPP", label: "WHATSAPP", icon: "ri-whatsapp-fill align-middle fs-20 text-success"},
                                        {value: "MANUAL", label: "MANUALE/PASSAPAROLA", icon: "ri-git-repository-commits-fill align-middle fs-20 text-info"}

                                        // {value: "BM", label: "META", icon: "ri-facebook-box-fill align-middle fs-20 text-secondary"},
                                        // {value: "FUNNEL", label: "FUNNEL", icon: "ri-global-fill align-middle fs-20 text-primary"},

                                    ].concat(modules.map((m) => {
                                        return {
                                            value: m.id,
                                            label: m.name,
                                            icon: (m.referral === "META") ? "ri-facebook-box-fill align-middle fs-20 text-secondary" : ((m.referral === "FUNNEL") ? "ri-global-fill align-middle fs-20 text-primary" : "mdi mdi-bullhorn align-middle fs-20 text-danger")
                                        }
                                    })) : [
                                        {value: "WHATSAPP", label: "WHATSAPP", icon: "ri-whatsapp-fill align-middle fs-20 text-success"},
                                        {value: "MANUAL", label: "MANUALE/PASSAPAROLA", icon: "ri-git-repository-commits-fill align-middle fs-20 text-info"}
                                    ]}
                                    onChange={(val) => createFormChange('createReferral', val)}
                                    formatOptionLabel={referral => (
                                        <div className="referral-option">
                                            <i className={referral.icon} />
                                            <span>&nbsp;&nbsp;{referral.label}</span>
                                        </div>
                                    )}
                                ></Select>
                                <Input type="text" hidden invalid={Boolean(errors?.createName)} />
                            </div>
                        </Col>
                        <Col md="6">
                            <div className='mb-2'>
                                <Label className='form-label'>
                                    Telefono*
                                </Label>
                                <Input
                                    type="tel"
                                    value={create.createPhone}
                                    invalid={Boolean(errors?.createPhone)}
                                    onChange={(e) => createFormChange('createPhone', e.target.value)}
                                />
                                <FormFeedback>{errors?.createPhone}</FormFeedback>
                            </div>
                            <small>
                                Assicurati di digitare correttamente il <u>numero di cellulare</u>, è necessario inserire anche il prefisso internazionale (+39 per Italia)
                            </small>
                        </Col>
                        <Col md="6">
                            <div className='mb-3'>
                                <Label className='form-label'>
                                    Email
                                </Label>
                                <Input
                                    type="email"
                                    value={create.createEmail}
                                    invalid={Boolean(errors?.createEmail)}
                                    onChange={(e) => createFormChange('createEmail', e.target.value)}
                                />
                                <FormFeedback>{errors?.createEmail}</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color='success'
                        onClick={createLead}
                        disabled={loading}
                    >
                        {(loading) ? <Spinner size="sm"/> : "SALVA"}
                    </Button>
                    <Button
                        color='danger'
                        onClick={onCloseModal}
                    >
                        CHIUDI
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default LeadAdd
